.Y4bPTG_component {
  padding: 40px;
}

.PHrwnG_homeComponent {
  padding: 20px;
}

.PHrwnG_homeComponent h2 {
  margin-top: 0;
  font-size: 21px;
  line-height: 25px;
}

.PHrwnG_homeComponent .PHrwnG_loadingAnimationContainer {
  text-align: center;
  width: 200px;
  height: 200px;
  margin-top: -100px;
  margin-left: -100px;
  position: absolute;
  top: 50%;
  left: 50%;
}

.PHrwnG_homeComponent .PHrwnG_contentContainer {
  gap: 20px;
  width: 100%;
  display: flex;
}

.PHrwnG_homeComponent .PHrwnG_contentContainer .PHrwnG_leftColumn {
  flex: 0 0 75%;
  padding-right: 20px;
}

.PHrwnG_homeComponent .PHrwnG_contentContainer .PHrwnG_rightColumn {
  flex: 0 0 25%;
}

.PHrwnG_homeComponent .PHrwnG_contentContainer .PHrwnG_sectionHeading {
  margin: 0;
  padding-bottom: 10px;
  font-size: 16px;
  font-weight: 600;
  line-height: 32px;
}

.fHw-tq_workbooksComponent .fHw-tq_workbookCatalogContainer {
  margin-bottom: 30px;
}

.fHw-tq_workbooksComponent .fHw-tq_blockingOverlayContainer {
  z-index: 10000;
  background-color: #fff9;
  position: absolute;
  inset: 0;
}

.fHw-tq_workbooksComponent .fHw-tq_blockingOverlayContainer .fHw-tq_blockingOverlay {
  background: #fff;
  border: 1px solid #e4e7ec;
  border-radius: 3px;
  width: 400px;
  padding: 20px;
  position: relative;
  top: 200px;
  left: calc(50vw - 200px);
}

.fHw-tq_workbooksComponent .fHw-tq_blockingOverlayContainer .fHw-tq_blockingOverlay .fHw-tq_description {
  text-align: center;
  width: 90%;
  margin: auto;
  padding: 20px 0;
  font-weight: 500;
  line-height: 1.5em;
}

.zxKZqq_component {
  justify-content: center;
  align-items: center;
  display: flex;
}

.zxKZqq_component .zxKZqq_loader1 {
  animation-name: zxKZqq_rotate;
  animation-duration: 2s;
  animation-timing-function: ease;
  animation-iteration-count: infinite;
  animation-direction: normal;
  animation-delay: 0s;
  animation-fill-mode: none;
}

.zxKZqq_component .zxKZqq_loader1 img {
  height: 50px;
}

@keyframes zxKZqq_rotate {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes zxKZqq_jelly {
  0% {
    animation-timing-function: cubic-bezier(.1441, .1912, .6583, 1.1029);
    transform: translate(0)skewX(0);
  }

  31% {
    animation-timing-function: cubic-bezier(.0667, .1419, .6667, 1.1415);
    transform: translate(-7.8px)skewX(7.8deg);
  }

  45% {
    animation-timing-function: cubic-bezier(.0542, .1151, .5697, 1.181);
    transform: translate(4.68px)skewX(-4.68deg);
  }

  59% {
    animation-timing-function: cubic-bezier(.0497, .1058, .4541, 1.231);
    transform: translate(-2.81px)skewX(2.81deg);
  }

  73% {
    animation-timing-function: cubic-bezier(.0808, .1711, .4109, 1.2519);
    transform: translate(1.68px)skewX(-1.68deg);
  }

  87% {
    animation-timing-function: cubic-bezier(.2073, .3705, .4064, .8839);
    transform: translate(-1.01px)skewX(1.01deg);
  }

  100% {
    transform: translate(.78px)skewX(-.78deg);
  }
}

@keyframes zxKZqq_slide-ltr {
  0% {
    opacity: 1;
    animation-timing-function: cubic-bezier(.4652, .1051, .774, .6426);
    transform: translate(0)rotate(0)scale(1)skewX(0)skewY(0);
  }

  22.5% {
    opacity: 1;
    animation-timing-function: cubic-bezier(.4142, .3131, .7623, .6513);
    transform: translate(47.8px)rotate(0)scale(1)skewX(0)skewY(0);
  }

  45% {
    opacity: 1;
    animation-timing-function: cubic-bezier(.3615, .331, .9646, 1.3461);
    transform: translate(147.8px)rotate(0)scale(1)skewX(0)skewY(0);
  }

  47.5% {
    opacity: 0;
    animation-timing-function: cubic-bezier(.7006, .3332, .6667, .6667);
    transform: translate(165.6px)rotate(0)scale(1)skewX(0)skewY(0);
  }

  50% {
    opacity: 0;
    animation-timing-function: cubic-bezier(.1604, .3176, -.0218, .6965);
    transform: translate(-200px)rotate(0)scale(1)skewX(0)skewY(0);
  }

  51% {
    opacity: 0;
    animation-timing-function: cubic-bezier(.1983, .3529, .5263, .6945);
    transform: translate(-179.8px)rotate(0)scale(1)skewX(0)skewY(0);
  }

  80.4% {
    opacity: 1;
    animation-timing-function: cubic-bezier(.2342, .3708, .5422, .9065);
    transform: translate(-38.4px)rotate(0)scale(1)skewX(0)skewY(0);
  }

  100% {
    opacity: 1;
    transform: translate(0)rotate(0)scale(1)skewX(0)skewY(0);
  }
}

.zJlRyq_workbooksCatalogComponent .zJlRyq_workbooksCatalog {
  cursor: pointer;
  flex-direction: column;
  align-items: stretch;
  display: flex;
}

.zJlRyq_workbooksCatalogComponent .zJlRyq_workbooksCatalog .zJlRyq_workbookCard {
  border-bottom: 1px solid #e4e7ec;
  border-left: 1px solid #e4e7ec;
  border-right: 1px solid #e4e7ec;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  padding: 7px;
  display: flex;
}

.zJlRyq_workbooksCatalogComponent .zJlRyq_workbooksCatalog .zJlRyq_workbookCard:first-child {
  border-top: 1px solid #e4e7ec;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}

.zJlRyq_workbooksCatalogComponent .zJlRyq_workbooksCatalog .zJlRyq_workbookCard:last-child {
  border-bottom-right-radius: 6px;
  border-bottom-left-radius: 6px;
}

.zJlRyq_workbooksCatalogComponent .zJlRyq_workbooksCatalog .zJlRyq_workbookCard .zJlRyq_workbookCardHeader {
  cursor: pointer;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.zJlRyq_workbooksCatalogComponent .zJlRyq_workbooksCatalog .zJlRyq_workbookCard .zJlRyq_workbookCardHeader .zJlRyq_icon {
  color: #427762;
  opacity: .7;
  margin-right: 10px;
  font-size: 20px;
}

.zJlRyq_workbooksCatalogComponent .zJlRyq_workbooksCatalog .zJlRyq_workbookCard .zJlRyq_workbookCardHeader .zJlRyq_workbookName {
  color: #3c4357;
  flex-grow: 1;
  font-weight: 600;
  line-height: 20px;
}

.zJlRyq_workbooksCatalogComponent .zJlRyq_workbooksCatalog .zJlRyq_workbookCard .zJlRyq_workbookCardHeader .zJlRyq_workbookStores {
  color: #797986;
  justify-content: flex-end;
  gap: 10px;
  font-size: 11px;
  line-height: 1.5em;
  display: flex;
}

.zJlRyq_workbooksCatalogComponent .zJlRyq_workbooksCatalog .zJlRyq_workbookCard:hover {
  box-shadow: 0 4px 30px #3030360a;
}

.zJlRyq_workbooksCatalogComponent .zJlRyq_workbooksCatalog .zJlRyq_workbookCard:hover span {
  opacity: 1;
}

.-tZPpW_helpArticlesComponent {
  margin-bottom: 50px;
  padding-right: 20px;
}

.-tZPpW_helpArticlesComponent .-tZPpW_helpArticlesCatalog {
  cursor: pointer;
  align-items: stretch;
  gap: 10px;
  display: grid;
}

.-tZPpW_helpArticlesComponent .-tZPpW_helpArticlesCatalog .-tZPpW_helpArticlesCard {
  border: 1px solid #fff;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 0 20px 5px 0;
  display: flex;
}

.-tZPpW_helpArticlesComponent .-tZPpW_helpArticlesCatalog .-tZPpW_helpArticlesCard .-tZPpW_helpArticlesCardHeader {
  color: #427762;
  height: -moz-fit-content;
  height: fit-content;
  margin-bottom: 3px;
  padding-right: 20px;
  display: flex;
}

.-tZPpW_helpArticlesComponent .-tZPpW_helpArticlesCatalog .-tZPpW_helpArticlesCard .-tZPpW_helpArticlesCardHeader span {
  color: #427762;
  opacity: .7;
  margin: 1px 10px 0 1px;
  font-size: 16px;
}

.-tZPpW_helpArticlesComponent .-tZPpW_helpArticlesCatalog .-tZPpW_helpArticlesCard .-tZPpW_helpArticlesCardHeader h3 {
  overflow-wrap: anywhere;
  margin: 0;
  font-size: 14px;
  font-weight: 600;
}

.-tZPpW_helpArticlesComponent .-tZPpW_helpArticlesCatalog .-tZPpW_helpArticlesCard .-tZPpW_helpArticlesCardBody {
  color: #697386;
  flex-direction: column;
  flex-grow: 1;
  justify-content: flex-start;
  margin-left: 28px;
  font-size: 12px;
  display: flex;
}

.qbr47G_templateCatalogComponent .qbr47G_templateIntro {
  padding-bottom: 20px;
}

.qbr47G_templateCatalogComponent .qbr47G_templatesList {
  flex-wrap: wrap;
  align-items: stretch;
  gap: 10px;
  display: flex;
}

.qbr47G_templateCatalogComponent .qbr47G_template {
  cursor: pointer;
  background: #fff;
  border: 1px solid #e4e7ec;
  border-radius: 3px;
  flex: 0 0 calc(25% - 10px);
  width: calc(25% - 10px);
  min-height: 80px;
  position: relative;
}

.qbr47G_templateCatalogComponent .qbr47G_template .qbr47G_sectionIcon {
  vertical-align: middle;
  opacity: .8;
  width: 15px;
  height: 15px;
  margin-right: 8px;
  position: absolute;
  top: 10px;
  left: 7px;
}

.qbr47G_templateCatalogComponent .qbr47G_template:hover {
  box-shadow: 0 4px 30px #3030360a;
}

.qbr47G_templateCatalogComponent .qbr47G_template:hover span {
  opacity: 1;
}

.qbr47G_templateCatalogComponent .qbr47G_template .qbr47G_content {
  padding: 7px 7px 7px 30px;
}

.qbr47G_templateCatalogComponent .qbr47G_template .qbr47G_content .qbr47G_title {
  padding-bottom: 2px;
  padding-right: 16px;
  font-weight: 600;
}

.qbr47G_templateCatalogComponent .qbr47G_template .qbr47G_content .qbr47G_description {
  padding: 3px 0 0;
  font-size: .8em;
  line-height: 1.5em;
}

.qbr47G_templateCatalogComponent .qbr47G_checkboxContainer {
  position: absolute;
  top: 10px;
  right: 0;
}

.qbr47G_templateCatalogComponent .qbr47G_callout {
  margin-bottom: 30px;
}

.qbr47G_templateCatalogComponent .qbr47G_emptyPlaceholder {
  height: 60px;
}

.qbr47G_templateCatalogComponent .qbr47G_createWorkbookButton {
  margin: auto;
  padding-top: 10px;
}

._2CI_4a_workbookPageHeaderComponent ._2CI_4a_headerContainer {
  justify-content: space-between;
  align-items: center;
  margin-bottom: 50px;
  display: flex;
}

._2CI_4a_workbookPageHeaderComponent ._2CI_4a_headerContainer ._2CI_4a_welcomeMessage {
  text-align: right;
  font-size: 16px;
}

._2CI_4a_workbookPageHeaderComponent ._2CI_4a_headerContainer ._2CI_4a_welcomeMessage h1 {
  margin: 0;
  padding: 0;
  font-size: 24px;
  font-weight: 500;
  line-height: 1em;
}

._2CI_4a_workbookPageHeaderComponent ._2CI_4a_headerContainer ._2CI_4a_left ._2CI_4a_createWorkbookButtons {
  gap: 10px;
  display: flex;
}

.WpBq2W_initialDownloadWidgetComponent .WpBq2W_initialDataLoading {
  background: #fff;
  border-radius: 3px;
  width: 100%;
  margin-bottom: 30px;
  padding-bottom: 20px;
  line-height: 1.5em;
  box-shadow: 0 1px 10px #427762b3;
}

.WpBq2W_initialDownloadWidgetComponent .WpBq2W_initialDataLoading .WpBq2W_headline {
  color: #fff;
  background: #427762;
  border-bottom: 1px solid #e4e7ec;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  margin-bottom: 3px;
  padding: 10px 10px 10px 30px;
  position: relative;
}

.WpBq2W_initialDownloadWidgetComponent .WpBq2W_initialDataLoading .WpBq2W_downloadStatus {
  background-color: #0000;
  border-radius: 50%;
  width: 15px;
  height: 15px;
  padding-right: 10px;
  position: absolute;
  top: 3px;
  left: 0;
}

@keyframes WpBq2W_slideInOut {
  0% {
    opacity: 1;
    transform: translateY(-100%);
  }

  50% {
    opacity: 1;
    transform: translateY(0);
  }

  100% {
    opacity: 1;
    transform: translateY(100%);
  }
}

.WpBq2W_initialDownloadWidgetComponent .WpBq2W_initialDataLoading .WpBq2W_downloadStatus.WpBq2W_done {
  color: #0e6245;
}

.WpBq2W_initialDownloadWidgetComponent .WpBq2W_initialDataLoading .WpBq2W_downloadStatus.WpBq2W_inProgress {
  border: 2px solid #0e6245;
  overflow: hidden;
}

.WpBq2W_initialDownloadWidgetComponent .WpBq2W_initialDataLoading .WpBq2W_downloadStatus.WpBq2W_inProgress svg {
  color: #0e6245;
  animation: 1.1s linear infinite WpBq2W_slideInOut;
  position: absolute;
  top: 0;
  left: 1px;
  transform: translateX(-50%);
}

.WpBq2W_initialDownloadWidgetComponent .WpBq2W_initialDataLoading .WpBq2W_downloadStatus.WpBq2W_inProgressInHeadline {
  border: 2px solid #fff;
  top: 14px;
  left: 7px;
  overflow: hidden;
}

.WpBq2W_initialDownloadWidgetComponent .WpBq2W_initialDataLoading .WpBq2W_downloadStatus.WpBq2W_inProgressInHeadline svg {
  color: #fff;
  animation: 1.1s linear infinite WpBq2W_slideInOut;
  position: absolute;
  top: 0;
  left: 1px;
  transform: translateX(-50%);
}

.WpBq2W_initialDownloadWidgetComponent .WpBq2W_initialDataLoading .WpBq2W_downloadStatus.WpBq2W_notStarted {
  border: 1px solid #e4e7ec;
}

.WpBq2W_initialDownloadWidgetComponent .WpBq2W_initialDataLoading .WpBq2W_nextUpDownloading .WpBq2W_currentlyDownloading {
  margin: 5px 0 0;
}

.WpBq2W_initialDownloadWidgetComponent .WpBq2W_initialDataLoading .WpBq2W_initialDataLoadingExpanded {
  margin: 10px 10px 0 20px;
}

.WpBq2W_initialDownloadWidgetComponent .WpBq2W_initialDataLoading .WpBq2W_initialDataLoadingExpanded .WpBq2W_initialDownloadExpandedRow {
  align-items: center;
  margin: 5px 0 0;
  padding-left: 25px;
  position: relative;
}

.WpBq2W_initialDownloadWidgetComponent .WpBq2W_initialDataLoading .WpBq2W_initialDataLoadingExpanded .WpBq2W_initialDownloadExpandedRow .WpBq2W_initiateManualDataDownload {
  margin: 1px 0 15px;
  font-size: 12px;
  line-height: 15px;
}

.WpBq2W_initialDownloadWidgetComponent .WpBq2W_initialDataLoading .WpBq2W_initialDataLoadingExpanded .WpBq2W_initialDownloadExpandedRow .WpBq2W_initiateManualDataDownload .WpBq2W_initialManualDataDownloadButton {
  margin-top: 5px;
}

.WpBq2W_initialDownloadWidgetComponent .WpBq2W_initialDataLoading .WpBq2W_initialDataLoadingExpanded .WpBq2W_initialDownloadExpandedRow .WpBq2W_initiateManualDataDownload .WpBq2W_initialManualDataDownloadButton button {
  min-height: 0;
  font-size: 12px;
}

.B6Kczq_component {
  background: #fcfcfc;
  width: 100%;
  height: 100%;
}

.B6Kczq_component .B6Kczq_pricingPlansContainer {
  width: 800px;
  margin: 0 auto;
}

.B6Kczq_component .B6Kczq_pricingPlansContainer h2 {
  margin: 0;
  padding: 50px 0 10px 5px;
  font-weight: 600;
  line-height: 1em;
}

.B6Kczq_component .B6Kczq_pricingPlansContainer .B6Kczq_content {
  background: #fff;
  border: 1px solid #f6f6f6;
  border-radius: 3px;
  padding: 40px 20px;
}

.B6Kczq_component .B6Kczq_pricingPlansContainer .B6Kczq_column {
  vertical-align: top;
  width: 33%;
  padding-right: 3px;
  display: inline-block;
}

.B6Kczq_component .B6Kczq_pricingPlansContainer .B6Kczq_column .B6Kczq_headingCell {
  padding: 5px 0;
  font-size: 15px;
  font-weight: 600;
  line-height: 15px;
}

.B6Kczq_component .B6Kczq_pricingPlansContainer .B6Kczq_column .B6Kczq_cell {
  border-bottom: 1px solid #e4e7ec;
  padding: 10px 0;
  line-height: 1.5em;
}

.B6Kczq_component .B6Kczq_pricingPlansContainer .B6Kczq_column .B6Kczq_cell .B6Kczq_checkmark {
  color: #427762;
  text-align: left;
  padding-left: 10px;
  font-size: 20px;
  font-weight: 500;
}

.B6Kczq_component .B6Kczq_pricingPlansContainer .B6Kczq_column .B6Kczq_cell .B6Kczq_featureBreakdown {
  padding-left: 20px;
}

.B6Kczq_component .B6Kczq_pricingPlansContainer .B6Kczq_column .B6Kczq_planName {
  font-size: 20px;
  font-weight: 500;
  line-height: 20px;
}

.B6Kczq_component .B6Kczq_pricingPlansContainer .B6Kczq_column .B6Kczq_planPrice {
  padding: 15px 0 10px;
}

.B6Kczq_component .B6Kczq_pricingPlansContainer .B6Kczq_column .B6Kczq_planPrice .B6Kczq_value {
  vertical-align: top;
  padding-bottom: 5px;
  font-size: 20px;
  font-weight: 600;
  line-height: 20px;
  display: inline-block;
}

.B6Kczq_component .B6Kczq_pricingPlansContainer .B6Kczq_column .B6Kczq_planPrice .B6Kczq_qualifier {
  text-transform: none;
  vertical-align: top;
  padding-left: 10px;
  font-size: .8em;
  display: inline-block;
}

.B6Kczq_component .B6Kczq_pricingPlansContainer .B6Kczq_column .B6Kczq_planCta button {
  margin: 5px;
}

.B6Kczq_component .B6Kczq_pricingPlansContainer .B6Kczq_footer {
  text-align: center;
  opacity: .5;
  padding: 20px 0 0;
}

.emGcFG_component {
  padding: 20px;
}

.-f0bJq_component {
  padding: 10px;
}

.-f0bJq_component h3 {
  margin-bottom: 5px;
  padding-bottom: 0;
  font-weight: 600;
}

.-f0bJq_component .-f0bJq_workspaceWorkbooks {
  padding-bottom: 10px;
  padding-left: 20px;
}

.-f0bJq_component .-f0bJq_workspaceWorkbooks .-f0bJq_workbookList {
  padding-bottom: 20px;
  padding-left: 5px;
}

.-f0bJq_component .-f0bJq_workspaceWorkbooks .-f0bJq_workbookList .-f0bJq_workbookEntry {
  padding: 4px 0;
  display: block;
}

.-f0bJq_component .-f0bJq_workspaceWorkbooks .-f0bJq_workbookList .-f0bJq_addWorkbookButton {
  margin-top: 15px;
}

.-f0bJq_component .-f0bJq_integrations {
  padding-top: 10px;
}

.-f0bJq_component .-f0bJq_integrations .-f0bJq_workspaceName {
  padding-bottom: 5px;
}

.-f0bJq_component .-f0bJq_integrations .-f0bJq_workspaceIntegrationConfigs {
  padding-left: 20px;
}

.-f0bJq_component .-f0bJq_integrations .-f0bJq_workspaceIntegrationConfigs .-f0bJq_integrationConfig {
  margin-bottom: 20px;
}

.-f0bJq_component .-f0bJq_integrations .-f0bJq_workspaceIntegrationConfigs .-f0bJq_integrationConfig .-f0bJq_targetId {
  margin-bottom: 10px;
  font-weight: 600;
}

.-f0bJq_component .-f0bJq_integrations .-f0bJq_workspaceIntegrationConfigs .-f0bJq_integrationConfig .-f0bJq_dataLoadingControls {
  vertical-align: top;
  width: 30%;
  display: inline-block;
}

.-f0bJq_component .-f0bJq_integrations .-f0bJq_workspaceIntegrationConfigs .-f0bJq_integrationConfig .-f0bJq_dataLoadingControls .-f0bJq_button {
  margin-bottom: 5px;
}

.-f0bJq_component .-f0bJq_integrations .-f0bJq_workspaceIntegrationConfigs .-f0bJq_integrationConfig .-f0bJq_subscriptionControls {
  vertical-align: top;
  width: 30%;
  display: inline-block;
}

.-f0bJq_component .-f0bJq_integrations .-f0bJq_workspaceIntegrationConfigs .-f0bJq_integrationConfig .-f0bJq_subscriptionControls .-f0bJq_currentSubscription {
  padding-bottom: 20px;
}

.-f0bJq_component .-f0bJq_integrations .-f0bJq_workspaceIntegrationConfigs .-f0bJq_integrationConfig .-f0bJq_subscriptionControls .-f0bJq_button {
  margin-bottom: 5px;
}

.-f0bJq_component .-f0bJq_integrations .-f0bJq_workspaceIntegrationConfigs .-f0bJq_integrationConfig .-f0bJq_adminControls {
  vertical-align: top;
  width: 30%;
  display: inline-block;
}

.-f0bJq_component .-f0bJq_integrations .-f0bJq_workspaceIntegrationConfigs .-f0bJq_integrationConfig .-f0bJq_adminControls .-f0bJq_button {
  margin-bottom: 5px;
}

.DF98vG_component {
  padding: 10px;
}

.DF98vG_component h3 {
  margin-bottom: 5px;
  padding-bottom: 0;
  font-weight: 600;
}

.DF98vG_component .DF98vG_events {
  width: 100%;
  padding: 10px 0 0;
}

.DF98vG_component .DF98vG_events .DF98vG_event {
  padding-bottom: 5px;
}

.DF98vG_component .DF98vG_events .DF98vG_event .DF98vG_dateTime, .DF98vG_component .DF98vG_events .DF98vG_event .DF98vG_name, .DF98vG_component .DF98vG_events .DF98vG_event .DF98vG_target, .DF98vG_component .DF98vG_events .DF98vG_event .DF98vG_details {
  vertical-align: top;
  width: 20%;
  display: inline-block;
}

.s3yrFW_component {
  padding: 40px;
}

.P6kryW_component {
  justify-content: center;
  align-items: center;
  height: 100vh;
  display: flex;
}

#root {
  height: 100%;
}

html {
  box-sizing: border-box;
}

*, :before, :after {
  box-sizing: inherit;
}

p {
  margin-top: 0;
  margin-bottom: 10px;
}

small {
  font-size: 12px;
}

strong {
  font-weight: 600;
}

::selection {
  background: #7dbcff99;
}

.bp5-text-muted {
  color: #5f6b7c;
}

.bp5-text-disabled {
  color: #5f6b7c99;
}

.bp5-running-text hr {
  border-color: #11141826;
}

a .bp5-icon, a .bp5-icon-standard, a .bp5-icon-large, a code {
  color: inherit;
}

.bp5-code, .bp5-running-text code {
  color: #5f6b7c;
  background: #ffffffb3;
  box-shadow: inset 0 0 0 1px #11141833;
}

a > .bp5-code, a > .bp5-running-text code {
  color: #2d72d2;
}

.bp5-code-block, .bp5-running-text pre {
  color: #1c2127;
  background: #ffffffb3;
  box-shadow: inset 0 0 0 1px #11141826;
}

.bp5-code-block > code, .bp5-running-text pre > code {
  box-shadow: none;
  color: inherit;
  background: none;
}

@media (forced-colors: active) and (prefers-color-scheme: dark) {
  .bp5-code-block, .bp5-running-text pre {
    border: 1px solid buttonborder;
    box-shadow: none;
  }
}

.bp5-key, .bp5-running-text kbd {
  color: #5f6b7c;
  background: #fff;
  box-shadow: 0 0 0 1px #1114181a, 0 1px 1px #11141833;
}

.bp5-icon.bp5-intent-primary, .bp5-icon-standard.bp5-intent-primary, .bp5-icon-large.bp5-intent-primary {
  color: #215db0;
}

.bp5-icon.bp5-intent-success, .bp5-icon-standard.bp5-intent-success, .bp5-icon-large.bp5-intent-success {
  color: #1c6e42;
}

.bp5-icon.bp5-intent-warning, .bp5-icon-standard.bp5-intent-warning, .bp5-icon-large.bp5-intent-warning {
  color: #935610;
}

.bp5-icon.bp5-intent-danger, .bp5-icon-standard.bp5-intent-danger, .bp5-icon-large.bp5-intent-danger {
  color: #ac2f33;
}

.bp5-heading {
  color: #1c2127;
  margin: 0 0 10px;
  padding: 0;
  font-weight: 600;
}

.bp5-dark .bp5-heading {
  color: #f6f7f9;
}

.bp5-heading.bp5-text-muted {
  color: #5f6b7c;
}

.bp5-dark .bp5-heading.bp5-text-muted {
  color: #abb3bf;
}

.bp5-running-text h1, h1.bp5-heading {
  font-size: 36px;
  line-height: 40px;
}

.bp5-running-text h2, h2.bp5-heading {
  font-size: 28px;
  line-height: 32px;
}

.bp5-running-text h3, h3.bp5-heading {
  font-size: 22px;
  line-height: 25px;
}

.bp5-running-text h4, h4.bp5-heading {
  font-size: 18px;
  line-height: 21px;
}

.bp5-running-text h5, h5.bp5-heading {
  font-size: 16px;
  line-height: 19px;
}

.bp5-running-text h6, h6.bp5-heading {
  font-size: 14px;
  line-height: 16px;
}

.bp5-ui-text {
  letter-spacing: 0;
  text-transform: none;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.28581;
}

.bp5-monospace-text {
  text-transform: none;
  font-family: monospace;
}

.bp5-text-overflow-ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  word-wrap: normal;
  overflow: hidden;
}

.bp5-running-text {
  font-size: 14px;
  line-height: 1.5;
}

.bp5-running-text h1 {
  color: #1c2127;
  margin-top: 40px;
  margin-bottom: 20px;
  font-weight: 600;
}

.bp5-dark .bp5-running-text h1 {
  color: #f6f7f9;
}

.bp5-running-text h1.bp5-text-muted {
  color: #5f6b7c;
}

.bp5-dark .bp5-running-text h1.bp5-text-muted {
  color: #abb3bf;
}

.bp5-running-text h2 {
  color: #1c2127;
  margin-top: 40px;
  margin-bottom: 20px;
  font-weight: 600;
}

.bp5-dark .bp5-running-text h2 {
  color: #f6f7f9;
}

.bp5-running-text h2.bp5-text-muted {
  color: #5f6b7c;
}

.bp5-dark .bp5-running-text h2.bp5-text-muted {
  color: #abb3bf;
}

.bp5-running-text h3 {
  color: #1c2127;
  margin-top: 40px;
  margin-bottom: 20px;
  font-weight: 600;
}

.bp5-dark .bp5-running-text h3 {
  color: #f6f7f9;
}

.bp5-running-text h3.bp5-text-muted {
  color: #5f6b7c;
}

.bp5-dark .bp5-running-text h3.bp5-text-muted {
  color: #abb3bf;
}

.bp5-running-text h4 {
  color: #1c2127;
  margin-top: 40px;
  margin-bottom: 20px;
  font-weight: 600;
}

.bp5-dark .bp5-running-text h4 {
  color: #f6f7f9;
}

.bp5-running-text h4.bp5-text-muted {
  color: #5f6b7c;
}

.bp5-dark .bp5-running-text h4.bp5-text-muted {
  color: #abb3bf;
}

.bp5-running-text h5 {
  color: #1c2127;
  margin-top: 40px;
  margin-bottom: 20px;
  font-weight: 600;
}

.bp5-dark .bp5-running-text h5 {
  color: #f6f7f9;
}

.bp5-running-text h5.bp5-text-muted {
  color: #5f6b7c;
}

.bp5-dark .bp5-running-text h5.bp5-text-muted {
  color: #abb3bf;
}

.bp5-running-text h6 {
  color: #1c2127;
  margin-top: 40px;
  margin-bottom: 20px;
  font-weight: 600;
}

.bp5-dark .bp5-running-text h6 {
  color: #f6f7f9;
}

.bp5-running-text h6.bp5-text-muted {
  color: #5f6b7c;
}

.bp5-dark .bp5-running-text h6.bp5-text-muted {
  color: #abb3bf;
}

.bp5-running-text hr {
  border: none;
  border-bottom: 1px solid #11141826;
  margin: 20px 0;
}

.bp5-running-text p {
  margin: 0 0 10px;
  padding: 0;
}

.bp5-text-large {
  font-size: 16px;
}

.bp5-text-small {
  font-size: 12px;
}

a {
  text-decoration: none;
}

a:hover {
  cursor: pointer;
  text-decoration: underline;
}

.bp5-code, .bp5-running-text code {
  text-transform: none;
  border-radius: 2px;
  padding: 2px 5px;
  font-family: monospace;
  font-size: smaller;
}

.bp5-code-block, .bp5-running-text pre {
  text-transform: none;
  word-break: break-all;
  word-wrap: break-word;
  border-radius: 2px;
  margin: 10px 0;
  padding: 13px 15px 12px;
  font-family: monospace;
  font-size: 13px;
  line-height: 1.4;
  display: block;
}

.bp5-code-block > code, .bp5-running-text pre > code {
  font-size: inherit;
  padding: 0;
}

.bp5-key, .bp5-running-text kbd {
  vertical-align: middle;
  border-radius: 2px;
  justify-content: center;
  align-items: center;
  min-width: 24px;
  height: 24px;
  padding: 2px 4px;
  font-family: inherit;
  font-size: 12px;
  line-height: 24px;
  display: inline-flex;
}

.bp5-key .bp5-icon, .bp5-running-text kbd .bp5-icon, .bp5-key .bp5-icon-standard, .bp5-running-text kbd .bp5-icon-standard, .bp5-key .bp5-icon-large, .bp5-running-text kbd .bp5-icon-large {
  margin-right: 5px;
}

.bp5-blockquote, .bp5-running-text blockquote {
  border-left: 4px solid #abb3bf80;
  margin: 0 0 10px;
  padding: 0 20px;
}

.bp5-dark .bp5-blockquote, .bp5-dark .bp5-running-text blockquote, .bp5-running-text .bp5-dark blockquote {
  border-color: #73809180;
}

.bp5-list, .bp5-running-text ul, .bp5-running-text ol {
  margin: 10px 0;
  padding-left: 30px;
}

.bp5-list li:not(:last-child), .bp5-running-text ul li:not(:last-child), .bp5-running-text ol li:not(:last-child) {
  margin-bottom: 5px;
}

.bp5-list ol, .bp5-running-text ul ol, .bp5-running-text ol ol, .bp5-list ul, .bp5-running-text ul ul, .bp5-running-text ol ul {
  margin-top: 5px;
}

.bp5-list-unstyled {
  margin: 0;
  padding: 0;
  list-style: none;
}

.bp5-list-unstyled li {
  padding: 0;
}

.bp5-rtl {
  text-align: right;
}

.bp5-dark {
  color: #f6f7f9;
}

.bp5-dark .bp5-text-muted {
  color: #abb3bf;
}

.bp5-dark .bp5-text-disabled {
  color: #abb3bf99;
}

.bp5-dark .bp5-running-text hr {
  border-color: #fff3;
}

.bp5-dark a, .bp5-dark a:hover {
  color: #8abbff;
}

.bp5-dark a .bp5-icon, .bp5-dark a .bp5-icon-standard, .bp5-dark a .bp5-icon-large, .bp5-dark a code {
  color: inherit;
}

.bp5-dark .bp5-code, .bp5-dark .bp5-running-text code {
  color: #abb3bf;
  background: #1114184d;
  box-shadow: inset 0 0 0 1px #11141866;
}

a > .bp5-dark .bp5-code, a > .bp5-dark .bp5-running-text code {
  color: inherit;
}

.bp5-dark .bp5-code-block, .bp5-dark .bp5-running-text pre {
  color: #f6f7f9;
  background: #1114184d;
  box-shadow: inset 0 0 0 1px #11141866;
}

.bp5-dark .bp5-code-block > code, .bp5-dark .bp5-running-text pre > code {
  box-shadow: none;
  color: inherit;
  background: none;
}

.bp5-dark .bp5-key, .bp5-dark .bp5-running-text kbd {
  color: #abb3bf;
  background: #383e47;
  box-shadow: inset 0 0 0 1px #fff3, 0 1px 1px #11141866;
}

.bp5-dark .bp5-icon.bp5-intent-primary, .bp5-dark .bp5-icon-standard.bp5-intent-primary, .bp5-dark .bp5-icon-large.bp5-intent-primary {
  color: #8abbff;
}

.bp5-dark .bp5-icon.bp5-intent-success, .bp5-dark .bp5-icon-standard.bp5-intent-success, .bp5-dark .bp5-icon-large.bp5-intent-success {
  color: #72ca9b;
}

.bp5-dark .bp5-icon.bp5-intent-warning, .bp5-dark .bp5-icon-standard.bp5-intent-warning, .bp5-dark .bp5-icon-large.bp5-intent-warning {
  color: #fbb360;
}

.bp5-dark .bp5-icon.bp5-intent-danger, .bp5-dark .bp5-icon-standard.bp5-intent-danger, .bp5-dark .bp5-icon-large.bp5-intent-danger {
  color: #fa999c;
}

.bp5-focus-disabled :focus:not(.bp5-focus-style-manager-ignore *), .bp5-focus-disabled :focus:not(.bp5-focus-style-manager-ignore *) ~ .bp5-control-indicator {
  outline: none !important;
}

.bp5-dark {
  color-scheme: dark;
}

.bp5-alert {
  max-width: 400px;
  padding: 20px;
}

.bp5-alert-body {
  display: flex;
}

.bp5-alert-body .bp5-icon {
  margin-top: 0;
  margin-right: 20px;
  font-size: 40px;
}

.bp5-alert-contents {
  word-break: break-word;
}

.bp5-alert-footer {
  flex-direction: row-reverse;
  margin-top: 10px;
  display: flex;
}

.bp5-alert-footer .bp5-button {
  margin-left: 10px;
}

.bp5-breadcrumbs {
  cursor: default;
  flex-wrap: wrap;
  align-items: center;
  height: 30px;
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
}

.bp5-breadcrumbs > li {
  align-items: center;
  display: flex;
}

.bp5-breadcrumbs > li:after {
  content: "";
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M10.71 7.29l-4-4a1.003 1.003 0 00-1.42 1.42L8.59 8 5.3 11.29c-.19.18-.3.43-.3.71a1.003 1.003 0 001.71.71l4-4c.18-.18.29-.43.29-.71 0-.28-.11-.53-.29-.71z' fill='%235f6b7c'/%3e%3c/svg%3e");
  width: 16px;
  height: 16px;
  margin: 0 5px;
  display: block;
}

.bp5-breadcrumbs > li:last-of-type:after {
  display: none;
}

.bp5-breadcrumb, .bp5-breadcrumb-current, .bp5-breadcrumbs-collapsed {
  align-items: center;
  font-size: 16px;
  display: inline-flex;
}

.bp5-breadcrumb, .bp5-breadcrumbs-collapsed {
  color: #5f6b7c;
}

.bp5-breadcrumb:hover {
  text-decoration: none;
}

.bp5-breadcrumb.bp5-disabled {
  color: #5f6b7c99;
  cursor: not-allowed;
}

.bp5-breadcrumb .bp5-icon {
  margin-right: 5px;
}

.bp5-breadcrumb-current {
  color: inherit;
  font-weight: 600;
}

.bp5-breadcrumb-current .bp5-input {
  font-size: inherit;
  font-weight: inherit;
  vertical-align: baseline;
}

.bp5-breadcrumbs-collapsed {
  cursor: pointer;
  vertical-align: text-bottom;
  background: #8f99a826;
  border: none;
  border-radius: 2px;
  margin-right: 2px;
  padding: 1px 5px;
}

.bp5-breadcrumbs-collapsed:before {
  content: "";
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cg fill='%235f6b7c'%3e%3cpath d='M2 6.03a2 2 0 100 4 2 2 0 100-4zM14 6.03a2 2 0 100 4 2 2 0 100-4zM8 6.03a2 2 0 100 4 2 2 0 100-4z'/%3e%3c/g%3e%3c/svg%3e") center no-repeat;
  width: 16px;
  height: 16px;
  display: block;
}

.bp5-breadcrumbs-collapsed:hover {
  color: #1c2127;
  background: #8f99a84d;
  text-decoration: none;
}

.bp5-dark .bp5-breadcrumb, .bp5-dark .bp5-breadcrumbs-collapsed {
  color: #abb3bf;
}

.bp5-dark .bp5-breadcrumbs > li:after {
  color: #abb3bf;
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M10.71 7.29l-4-4a1.003 1.003 0 00-1.42 1.42L8.59 8 5.3 11.29c-.19.18-.3.43-.3.71a1.003 1.003 0 001.71.71l4-4c.18-.18.29-.43.29-.71 0-.28-.11-.53-.29-.71z' fill='%23abb3bf'/%3e%3c/svg%3e");
}

.bp5-dark .bp5-breadcrumb.bp5-disabled {
  color: #abb3bf99;
}

.bp5-dark .bp5-breadcrumb-current {
  color: #f6f7f9;
}

.bp5-dark .bp5-breadcrumbs-collapsed {
  background: #8f99a833;
}

.bp5-dark .bp5-breadcrumbs-collapsed:before {
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cg fill='%23abb3bf'%3e%3cpath d='M2 6.03a2 2 0 100 4 2 2 0 100-4zM14 6.03a2 2 0 100 4 2 2 0 100-4zM8 6.03a2 2 0 100 4 2 2 0 100-4z'/%3e%3c/g%3e%3c/svg%3e") center no-repeat;
}

.bp5-dark .bp5-breadcrumbs-collapsed:hover {
  color: #f6f7f9;
  background: #8f99a84d;
}

.bp5-button {
  cursor: pointer;
  text-align: left;
  vertical-align: middle;
  border: none;
  border-radius: 2px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  min-width: 30px;
  min-height: 30px;
  padding: 5px 10px;
  font-size: 14px;
  display: inline-flex;
}

.bp5-button > * {
  flex-grow: 0;
  flex-shrink: 0;
}

.bp5-button > .bp5-fill {
  flex-grow: 1;
  flex-shrink: 1;
}

.bp5-button:before, .bp5-button > * {
  margin-right: 7px;
}

.bp5-button:empty:before, .bp5-button > :last-child {
  margin-right: 0;
}

.bp5-button:empty {
  padding: 0 !important;
}

.bp5-button:disabled, .bp5-button.bp5-disabled {
  cursor: not-allowed;
}

.bp5-button.bp5-fill {
  width: 100%;
  display: flex;
}

.bp5-button.bp5-align-right, .bp5-align-right .bp5-button {
  text-align: right;
}

.bp5-button.bp5-align-left, .bp5-align-left .bp5-button {
  text-align: left;
}

.bp5-button:not([class*="bp5-intent-"]) {
  color: #1c2127;
  background-color: #f6f7f9;
  box-shadow: inset 0 0 0 1px #11141833, 0 1px 2px #1114181a;
}

.bp5-button:not([class*="bp5-intent-"]):hover {
  background-color: #edeff2;
  background-clip: padding-box;
  box-shadow: inset 0 0 0 1px #11141833, 0 1px 2px #11141833;
}

.bp5-button:not([class*="bp5-intent-"]):active, .bp5-button:not([class*="bp5-intent-"]).bp5-active {
  background-color: #dce0e5;
  box-shadow: inset 0 0 0 1px #11141833, 0 1px 2px #11141833;
}

@media (forced-colors: active) and (prefers-color-scheme: dark) {
  .bp5-button:not([class*="bp5-intent-"]):active, .bp5-button:not([class*="bp5-intent-"]).bp5-active {
    background: highlight;
  }
}

.bp5-button:not([class*="bp5-intent-"]):disabled, .bp5-button:not([class*="bp5-intent-"]).bp5-disabled {
  box-shadow: none;
  color: #5f6b7c99;
  cursor: not-allowed;
  background-color: #d3d8de80;
  outline: none;
}

.bp5-button:not([class*="bp5-intent-"]):disabled.bp5-active, .bp5-button:not([class*="bp5-intent-"]):disabled.bp5-active:hover, .bp5-button:not([class*="bp5-intent-"]).bp5-disabled.bp5-active, .bp5-button:not([class*="bp5-intent-"]).bp5-disabled.bp5-active:hover {
  background: #d3d8deb3;
}

@media (forced-colors: active) and (prefers-color-scheme: dark) {
  .bp5-button:not([class*="bp5-intent-"]) {
    border: 1px solid buttonborder;
  }
}

.bp5-button.bp5-intent-primary {
  color: #fff;
  background-color: #427762;
}

.bp5-button.bp5-intent-primary:hover, .bp5-button.bp5-intent-primary:active, .bp5-button.bp5-intent-primary.bp5-active {
  color: #fff;
}

.bp5-button.bp5-intent-primary:hover {
  background-color: #06492e;
  box-shadow: inset 0 0 0 1px #11141833, 0 1px 2px #11141833;
}

.bp5-button.bp5-intent-primary:active, .bp5-button.bp5-intent-primary.bp5-active {
  background-color: #184a90;
  box-shadow: inset 0 0 0 1px #11141833, 0 1px 2px #11141833;
}

.bp5-button.bp5-intent-primary:disabled, .bp5-button.bp5-intent-primary.bp5-disabled {
  box-shadow: none;
  color: #fff9;
  background-color: #06492e80;
  border-color: #0000;
}

@media (forced-colors: active) and (prefers-color-scheme: dark) {
  .bp5-button.bp5-intent-primary:disabled, .bp5-button.bp5-intent-primary.bp5-disabled {
    border-color: graytext;
    color: graytext;
  }

  .bp5-button.bp5-intent-primary {
    border: 1px solid buttonborder;
    box-shadow: none;
  }
}

.bp5-button.bp5-intent-success {
  color: #fff;
  background-color: #238551;
  box-shadow: inset 0 0 0 1px #11141833, 0 1px 2px #1114181a;
}

.bp5-button.bp5-intent-success:hover, .bp5-button.bp5-intent-success:active, .bp5-button.bp5-intent-success.bp5-active {
  color: #fff;
}

.bp5-button.bp5-intent-success:hover {
  background-color: #1c6e42;
  box-shadow: inset 0 0 0 1px #11141833, 0 1px 2px #11141833;
}

.bp5-button.bp5-intent-success:active, .bp5-button.bp5-intent-success.bp5-active {
  background-color: #165a36;
  box-shadow: inset 0 0 0 1px #11141833, 0 1px 2px #11141833;
}

.bp5-button.bp5-intent-success:disabled, .bp5-button.bp5-intent-success.bp5-disabled {
  box-shadow: none;
  color: #fff9;
  background-color: #23855180;
  border-color: #0000;
}

@media (forced-colors: active) and (prefers-color-scheme: dark) {
  .bp5-button.bp5-intent-success:disabled, .bp5-button.bp5-intent-success.bp5-disabled {
    border-color: graytext;
    color: graytext;
  }

  .bp5-button.bp5-intent-success {
    border: 1px solid buttonborder;
    box-shadow: none;
  }
}

.bp5-button.bp5-intent-warning {
  color: #fff;
  background-color: #c87619;
}

.bp5-button.bp5-intent-warning:hover, .bp5-button.bp5-intent-warning:active, .bp5-button.bp5-intent-warning.bp5-active {
  color: #fff;
}

.bp5-button.bp5-intent-warning:hover {
  background-color: #935610;
  box-shadow: inset 0 0 0 1px #11141833, 0 1px 2px #11141833;
}

.bp5-button.bp5-intent-warning:active, .bp5-button.bp5-intent-warning.bp5-active {
  background-color: #77450d;
  box-shadow: inset 0 0 0 1px #11141833, 0 1px 2px #11141833;
}

.bp5-button.bp5-intent-warning:disabled, .bp5-button.bp5-intent-warning.bp5-disabled {
  box-shadow: none;
  color: #fff9;
  background-color: #c8761980;
  border-color: #0000;
}

@media (forced-colors: active) and (prefers-color-scheme: dark) {
  .bp5-button.bp5-intent-warning:disabled, .bp5-button.bp5-intent-warning.bp5-disabled {
    border-color: graytext;
    color: graytext;
  }

  .bp5-button.bp5-intent-warning {
    border: 1px solid buttonborder;
    box-shadow: none;
  }
}

.bp5-button.bp5-intent-danger {
  color: #fff;
  background-color: #cd4246;
  box-shadow: inset 0 0 0 1px #11141833, 0 1px 2px #1114181a;
}

.bp5-button.bp5-intent-danger:hover, .bp5-button.bp5-intent-danger:active, .bp5-button.bp5-intent-danger.bp5-active {
  color: #fff;
}

.bp5-button.bp5-intent-danger:hover {
  background-color: #ac2f33;
  box-shadow: inset 0 0 0 1px #11141833, 0 1px 2px #11141833;
}

.bp5-button.bp5-intent-danger:active, .bp5-button.bp5-intent-danger.bp5-active {
  background-color: #8e292c;
  box-shadow: inset 0 0 0 1px #11141833, 0 1px 2px #11141833;
}

.bp5-button.bp5-intent-danger:disabled, .bp5-button.bp5-intent-danger.bp5-disabled {
  box-shadow: none;
  color: #fff9;
  background-color: #cd424680;
  border-color: #0000;
}

@media (forced-colors: active) and (prefers-color-scheme: dark) {
  .bp5-button.bp5-intent-danger:disabled, .bp5-button.bp5-intent-danger.bp5-disabled {
    border-color: graytext;
    color: graytext;
  }

  .bp5-button.bp5-intent-danger {
    border: 1px solid buttonborder;
    box-shadow: none;
  }
}

.bp5-button[class*="bp5-intent-"] .bp5-button-spinner .bp5-spinner-head {
  stroke: #fff;
}

.bp5-button.bp5-large, .bp5-large .bp5-button {
  min-width: 40px;
  min-height: 40px;
  padding: 5px 15px;
  font-size: 16px;
}

.bp5-button.bp5-large:before, .bp5-button.bp5-large > *, .bp5-large .bp5-button:before, .bp5-large .bp5-button > * {
  margin-right: 10px;
}

.bp5-button.bp5-large:empty:before, .bp5-button.bp5-large > :last-child, .bp5-large .bp5-button:empty:before, .bp5-large .bp5-button > :last-child {
  margin-right: 0;
}

.bp5-button.bp5-small, .bp5-small .bp5-button {
  min-width: 24px;
  min-height: 24px;
  padding: 0 7px;
}

.bp5-button.bp5-loading {
  position: relative;
}

.bp5-button.bp5-loading[class*="bp5-icon-"]:before {
  visibility: hidden;
}

.bp5-button.bp5-loading .bp5-button-spinner {
  margin: 0;
  position: absolute;
}

.bp5-button.bp5-loading > :not(.bp5-button-spinner) {
  visibility: hidden;
}

.bp5-button[class*="bp5-icon-"]:before {
  font-variant: normal;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  color: #5f6b7c;
  width: 16px;
  height: 16px;
  font-family: blueprint-icons-16, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 1;
}

.bp5-button .bp5-icon, .bp5-button .bp5-icon-standard, .bp5-button .bp5-icon-large {
  color: #5f6b7c;
}

.bp5-button .bp5-icon.bp5-align-right, .bp5-button .bp5-icon-standard.bp5-align-right, .bp5-button .bp5-icon-large.bp5-align-right {
  margin-left: 7px;
}

.bp5-button .bp5-icon:first-child:last-child, .bp5-button .bp5-spinner + .bp5-icon:last-child {
  margin: 0 -7px;
}

.bp5-dark .bp5-button:not([class*="bp5-intent-"]) {
  color: #f6f7f9;
  background-color: #383e47;
  box-shadow: inset 0 0 0 1px #ffffff1a, 0 1px 2px #11141833;
}

.bp5-dark .bp5-button:not([class*="bp5-intent-"]):hover, .bp5-dark .bp5-button:not([class*="bp5-intent-"]):active, .bp5-dark .bp5-button:not([class*="bp5-intent-"]).bp5-active {
  color: #f6f7f9;
}

.bp5-dark .bp5-button:not([class*="bp5-intent-"]):hover {
  background-color: #2f343c;
  box-shadow: inset 0 0 0 1px #ffffff1a, 0 1px 2px #11141866;
}

.bp5-dark .bp5-button:not([class*="bp5-intent-"]):active, .bp5-dark .bp5-button:not([class*="bp5-intent-"]).bp5-active {
  background-color: #1c2127;
  box-shadow: inset 0 0 0 1px #ffffff1a, 0 1px 2px #11141866;
}

.bp5-dark .bp5-button:not([class*="bp5-intent-"]):disabled, .bp5-dark .bp5-button:not([class*="bp5-intent-"]).bp5-disabled {
  box-shadow: none;
  color: #abb3bf99;
  background-color: #40485480;
}

.bp5-dark .bp5-button:not([class*="bp5-intent-"]):disabled.bp5-active, .bp5-dark .bp5-button:not([class*="bp5-intent-"]).bp5-disabled.bp5-active {
  background: #404854b3;
}

.bp5-dark .bp5-button:not([class*="bp5-intent-"]) .bp5-button-spinner .bp5-spinner-head {
  stroke: #8f99a8;
  background: #11141880;
}

.bp5-dark .bp5-button:not([class*="bp5-intent-"])[class*="bp5-icon-"]:before, .bp5-dark .bp5-button:not([class*="bp5-intent-"]) .bp5-icon:not([class*="bp5-intent-"]), .bp5-dark .bp5-button:not([class*="bp5-intent-"]) .bp5-icon-standard:not([class*="bp5-intent-"]), .bp5-dark .bp5-button:not([class*="bp5-intent-"]) .bp5-icon-large:not([class*="bp5-intent-"]) {
  color: #abb3bf;
}

.bp5-dark .bp5-button[class*="bp5-intent-"], .bp5-dark .bp5-button[class*="bp5-intent-"]:hover {
  box-shadow: inset 0 0 0 1px #ffffff1a, 0 1px 2px #11141833;
}

.bp5-dark .bp5-button[class*="bp5-intent-"]:active, .bp5-dark .bp5-button[class*="bp5-intent-"].bp5-active {
  box-shadow: inset 0 0 0 1px #ffffff1a, 0 1px 2px #11141866;
}

.bp5-dark .bp5-button[class*="bp5-intent-"]:disabled, .bp5-dark .bp5-button[class*="bp5-intent-"].bp5-disabled {
  box-shadow: none;
  color: #ffffff4d;
}

.bp5-dark .bp5-button[class*="bp5-intent-"] .bp5-button-spinner .bp5-spinner-head {
  stroke: #8f99a8;
}

.bp5-button:disabled:before, .bp5-button:disabled .bp5-icon, .bp5-button:disabled .bp5-icon-standard, .bp5-button:disabled .bp5-icon-large, .bp5-button.bp5-disabled:before, .bp5-button.bp5-disabled .bp5-icon, .bp5-button.bp5-disabled .bp5-icon-standard, .bp5-button.bp5-disabled .bp5-icon-large, .bp5-button[class*="bp5-intent-"]:before, .bp5-button[class*="bp5-intent-"] .bp5-icon, .bp5-button[class*="bp5-intent-"] .bp5-icon-standard, .bp5-button[class*="bp5-intent-"] .bp5-icon-large {
  color: inherit !important;
}

.bp5-button.bp5-minimal {
  box-shadow: none;
  background: none;
}

.bp5-button.bp5-minimal:hover {
  box-shadow: none;
  color: #1c2127;
  background: #8f99a826;
  text-decoration: none;
}

.bp5-button.bp5-minimal:active, .bp5-button.bp5-minimal.bp5-active {
  box-shadow: none;
  color: #1c2127;
  background: #8f99a84d;
}

.bp5-button.bp5-minimal:disabled, .bp5-button.bp5-minimal:disabled:hover, .bp5-button.bp5-minimal.bp5-disabled, .bp5-button.bp5-minimal.bp5-disabled:hover {
  color: #5f6b7c99;
  cursor: not-allowed;
  background: none;
}

.bp5-button.bp5-minimal:disabled.bp5-active, .bp5-button.bp5-minimal:disabled:hover.bp5-active, .bp5-button.bp5-minimal.bp5-disabled.bp5-active, .bp5-button.bp5-minimal.bp5-disabled:hover.bp5-active {
  background: #8f99a84d;
}

.bp5-dark .bp5-button.bp5-minimal, .bp5-dark .bp5-button.bp5-minimal:hover, .bp5-dark .bp5-button.bp5-minimal:active, .bp5-dark .bp5-button.bp5-minimal.bp5-active {
  box-shadow: none;
  color: #fff;
  background: none;
}

.bp5-dark .bp5-button.bp5-minimal:hover {
  background: #8f99a826;
}

.bp5-dark .bp5-button.bp5-minimal:active, .bp5-dark .bp5-button.bp5-minimal.bp5-active {
  background: #8f99a84d;
}

.bp5-dark .bp5-button.bp5-minimal:disabled, .bp5-dark .bp5-button.bp5-minimal:disabled:hover, .bp5-dark .bp5-button.bp5-minimal.bp5-disabled, .bp5-dark .bp5-button.bp5-minimal.bp5-disabled:hover {
  color: #abb3bf99;
  cursor: not-allowed;
  background: none;
}

.bp5-dark .bp5-button.bp5-minimal:disabled.bp5-active, .bp5-dark .bp5-button.bp5-minimal:disabled:hover.bp5-active, .bp5-dark .bp5-button.bp5-minimal.bp5-disabled.bp5-active, .bp5-dark .bp5-button.bp5-minimal.bp5-disabled:hover.bp5-active {
  background: #8f99a84d;
}

.bp5-button.bp5-minimal.bp5-intent-primary {
  color: #215db0;
}

.bp5-button.bp5-minimal.bp5-intent-primary:hover, .bp5-button.bp5-minimal.bp5-intent-primary:active, .bp5-button.bp5-minimal.bp5-intent-primary.bp5-active {
  box-shadow: none;
  color: #215db0;
  background: none;
}

.bp5-button.bp5-minimal.bp5-intent-primary:hover {
  color: #215db0;
  background: #2d72d226;
}

.bp5-button.bp5-minimal.bp5-intent-primary:active, .bp5-button.bp5-minimal.bp5-intent-primary.bp5-active {
  color: #184a90;
  background: #2d72d24d;
}

.bp5-button.bp5-minimal.bp5-intent-primary:disabled, .bp5-button.bp5-minimal.bp5-intent-primary.bp5-disabled {
  color: #215db080;
  background: none;
}

.bp5-button.bp5-minimal.bp5-intent-primary:disabled.bp5-active, .bp5-button.bp5-minimal.bp5-intent-primary.bp5-disabled.bp5-active {
  background: #2d72d24d;
}

.bp5-button.bp5-minimal.bp5-intent-primary .bp5-button-spinner .bp5-spinner-head {
  stroke: #215db0;
}

.bp5-dark .bp5-button.bp5-minimal.bp5-intent-primary {
  color: #8abbff;
}

.bp5-dark .bp5-button.bp5-minimal.bp5-intent-primary:hover {
  color: #8abbff;
  background: #2d72d233;
}

.bp5-dark .bp5-button.bp5-minimal.bp5-intent-primary:active, .bp5-dark .bp5-button.bp5-minimal.bp5-intent-primary.bp5-active {
  color: #99c4ff;
  background: #2d72d24d;
}

.bp5-dark .bp5-button.bp5-minimal.bp5-intent-primary:disabled, .bp5-dark .bp5-button.bp5-minimal.bp5-intent-primary.bp5-disabled {
  color: #8abbff80;
  background: none;
}

.bp5-dark .bp5-button.bp5-minimal.bp5-intent-primary:disabled.bp5-active, .bp5-dark .bp5-button.bp5-minimal.bp5-intent-primary.bp5-disabled.bp5-active {
  background: #2d72d24d;
}

.bp5-button.bp5-minimal.bp5-intent-success {
  color: #1c6e42;
}

.bp5-button.bp5-minimal.bp5-intent-success:hover, .bp5-button.bp5-minimal.bp5-intent-success:active, .bp5-button.bp5-minimal.bp5-intent-success.bp5-active {
  box-shadow: none;
  color: #1c6e42;
  background: none;
}

.bp5-button.bp5-minimal.bp5-intent-success:hover {
  color: #1c6e42;
  background: #23855126;
}

.bp5-button.bp5-minimal.bp5-intent-success:active, .bp5-button.bp5-minimal.bp5-intent-success.bp5-active {
  color: #165a36;
  background: #2385514d;
}

.bp5-button.bp5-minimal.bp5-intent-success:disabled, .bp5-button.bp5-minimal.bp5-intent-success.bp5-disabled {
  color: #1c6e4280;
  background: none;
}

.bp5-button.bp5-minimal.bp5-intent-success:disabled.bp5-active, .bp5-button.bp5-minimal.bp5-intent-success.bp5-disabled.bp5-active {
  background: #2385514d;
}

.bp5-button.bp5-minimal.bp5-intent-success .bp5-button-spinner .bp5-spinner-head {
  stroke: #1c6e42;
}

.bp5-dark .bp5-button.bp5-minimal.bp5-intent-success {
  color: #72ca9b;
}

.bp5-dark .bp5-button.bp5-minimal.bp5-intent-success:hover {
  color: #72ca9b;
  background: #23855133;
}

.bp5-dark .bp5-button.bp5-minimal.bp5-intent-success:active, .bp5-dark .bp5-button.bp5-minimal.bp5-intent-success.bp5-active {
  color: #7cd7a2;
  background: #2385514d;
}

.bp5-dark .bp5-button.bp5-minimal.bp5-intent-success:disabled, .bp5-dark .bp5-button.bp5-minimal.bp5-intent-success.bp5-disabled {
  color: #72ca9b80;
  background: none;
}

.bp5-dark .bp5-button.bp5-minimal.bp5-intent-success:disabled.bp5-active, .bp5-dark .bp5-button.bp5-minimal.bp5-intent-success.bp5-disabled.bp5-active {
  background: #2385514d;
}

.bp5-button.bp5-minimal.bp5-intent-warning {
  color: #935610;
}

.bp5-button.bp5-minimal.bp5-intent-warning:hover, .bp5-button.bp5-minimal.bp5-intent-warning:active, .bp5-button.bp5-minimal.bp5-intent-warning.bp5-active {
  box-shadow: none;
  color: #935610;
  background: none;
}

.bp5-button.bp5-minimal.bp5-intent-warning:hover {
  color: #935610;
  background: #c8761926;
}

.bp5-button.bp5-minimal.bp5-intent-warning:active, .bp5-button.bp5-minimal.bp5-intent-warning.bp5-active {
  color: #77450d;
  background: #c876194d;
}

.bp5-button.bp5-minimal.bp5-intent-warning:disabled, .bp5-button.bp5-minimal.bp5-intent-warning.bp5-disabled {
  color: #93561080;
  background: none;
}

.bp5-button.bp5-minimal.bp5-intent-warning:disabled.bp5-active, .bp5-button.bp5-minimal.bp5-intent-warning.bp5-disabled.bp5-active {
  background: #c876194d;
}

.bp5-button.bp5-minimal.bp5-intent-warning .bp5-button-spinner .bp5-spinner-head {
  stroke: #935610;
}

.bp5-dark .bp5-button.bp5-minimal.bp5-intent-warning {
  color: #fbb360;
}

.bp5-dark .bp5-button.bp5-minimal.bp5-intent-warning:hover {
  color: #fbb360;
  background: #c8761933;
}

.bp5-dark .bp5-button.bp5-minimal.bp5-intent-warning:active, .bp5-dark .bp5-button.bp5-minimal.bp5-intent-warning.bp5-active {
  color: #f5c186;
  background: #c876194d;
}

.bp5-dark .bp5-button.bp5-minimal.bp5-intent-warning:disabled, .bp5-dark .bp5-button.bp5-minimal.bp5-intent-warning.bp5-disabled {
  color: #fbb36080;
  background: none;
}

.bp5-dark .bp5-button.bp5-minimal.bp5-intent-warning:disabled.bp5-active, .bp5-dark .bp5-button.bp5-minimal.bp5-intent-warning.bp5-disabled.bp5-active {
  background: #c876194d;
}

.bp5-button.bp5-minimal.bp5-intent-danger {
  color: #ac2f33;
}

.bp5-button.bp5-minimal.bp5-intent-danger:hover, .bp5-button.bp5-minimal.bp5-intent-danger:active, .bp5-button.bp5-minimal.bp5-intent-danger.bp5-active {
  box-shadow: none;
  color: #ac2f33;
  background: none;
}

.bp5-button.bp5-minimal.bp5-intent-danger:hover {
  color: #ac2f33;
  background: #cd424626;
}

.bp5-button.bp5-minimal.bp5-intent-danger:active, .bp5-button.bp5-minimal.bp5-intent-danger.bp5-active {
  color: #8e292c;
  background: #cd42464d;
}

.bp5-button.bp5-minimal.bp5-intent-danger:disabled, .bp5-button.bp5-minimal.bp5-intent-danger.bp5-disabled {
  color: #ac2f3380;
  background: none;
}

.bp5-button.bp5-minimal.bp5-intent-danger:disabled.bp5-active, .bp5-button.bp5-minimal.bp5-intent-danger.bp5-disabled.bp5-active {
  background: #cd42464d;
}

.bp5-button.bp5-minimal.bp5-intent-danger .bp5-button-spinner .bp5-spinner-head {
  stroke: #ac2f33;
}

.bp5-dark .bp5-button.bp5-minimal.bp5-intent-danger {
  color: #fa999c;
}

.bp5-dark .bp5-button.bp5-minimal.bp5-intent-danger:hover {
  color: #fa999c;
  background: #cd424633;
}

.bp5-dark .bp5-button.bp5-minimal.bp5-intent-danger:active, .bp5-dark .bp5-button.bp5-minimal.bp5-intent-danger.bp5-active {
  color: #ffa1a4;
  background: #cd42464d;
}

.bp5-dark .bp5-button.bp5-minimal.bp5-intent-danger:disabled, .bp5-dark .bp5-button.bp5-minimal.bp5-intent-danger.bp5-disabled {
  color: #fa999c80;
  background: none;
}

.bp5-dark .bp5-button.bp5-minimal.bp5-intent-danger:disabled.bp5-active, .bp5-dark .bp5-button.bp5-minimal.bp5-intent-danger.bp5-disabled.bp5-active {
  background: #cd42464d;
}

.bp5-button.bp5-outlined {
  box-shadow: none;
  box-sizing: border-box;
  background: none;
  border: 1px solid #1c212733;
}

.bp5-button.bp5-outlined:hover {
  box-shadow: none;
  color: #1c2127;
  background: #8f99a826;
  text-decoration: none;
}

.bp5-button.bp5-outlined:active, .bp5-button.bp5-outlined.bp5-active {
  box-shadow: none;
  color: #1c2127;
  background: #8f99a84d;
}

.bp5-button.bp5-outlined:disabled, .bp5-button.bp5-outlined:disabled:hover, .bp5-button.bp5-outlined.bp5-disabled, .bp5-button.bp5-outlined.bp5-disabled:hover {
  color: #5f6b7c99;
  cursor: not-allowed;
  background: none;
}

.bp5-button.bp5-outlined:disabled.bp5-active, .bp5-button.bp5-outlined:disabled:hover.bp5-active, .bp5-button.bp5-outlined.bp5-disabled.bp5-active, .bp5-button.bp5-outlined.bp5-disabled:hover.bp5-active {
  background: #8f99a84d;
}

.bp5-dark .bp5-button.bp5-outlined, .bp5-dark .bp5-button.bp5-outlined:hover, .bp5-dark .bp5-button.bp5-outlined:active, .bp5-dark .bp5-button.bp5-outlined.bp5-active {
  box-shadow: none;
  color: #fff;
  background: none;
}

.bp5-dark .bp5-button.bp5-outlined:hover {
  background: #8f99a826;
}

.bp5-dark .bp5-button.bp5-outlined:active, .bp5-dark .bp5-button.bp5-outlined.bp5-active {
  background: #8f99a84d;
}

.bp5-dark .bp5-button.bp5-outlined:disabled, .bp5-dark .bp5-button.bp5-outlined:disabled:hover, .bp5-dark .bp5-button.bp5-outlined.bp5-disabled, .bp5-dark .bp5-button.bp5-outlined.bp5-disabled:hover {
  color: #abb3bf99;
  cursor: not-allowed;
  background: none;
}

.bp5-dark .bp5-button.bp5-outlined:disabled.bp5-active, .bp5-dark .bp5-button.bp5-outlined:disabled:hover.bp5-active, .bp5-dark .bp5-button.bp5-outlined.bp5-disabled.bp5-active, .bp5-dark .bp5-button.bp5-outlined.bp5-disabled:hover.bp5-active {
  background: #8f99a84d;
}

.bp5-button.bp5-outlined.bp5-intent-primary {
  color: #215db0;
}

.bp5-button.bp5-outlined.bp5-intent-primary:hover, .bp5-button.bp5-outlined.bp5-intent-primary:active, .bp5-button.bp5-outlined.bp5-intent-primary.bp5-active {
  box-shadow: none;
  color: #215db0;
  background: none;
}

.bp5-button.bp5-outlined.bp5-intent-primary:hover {
  color: #215db0;
  background: #2d72d226;
}

.bp5-button.bp5-outlined.bp5-intent-primary:active, .bp5-button.bp5-outlined.bp5-intent-primary.bp5-active {
  color: #184a90;
  background: #2d72d24d;
}

.bp5-button.bp5-outlined.bp5-intent-primary:disabled, .bp5-button.bp5-outlined.bp5-intent-primary.bp5-disabled {
  color: #215db080;
  background: none;
}

.bp5-button.bp5-outlined.bp5-intent-primary:disabled.bp5-active, .bp5-button.bp5-outlined.bp5-intent-primary.bp5-disabled.bp5-active {
  background: #2d72d24d;
}

.bp5-button.bp5-outlined.bp5-intent-primary .bp5-button-spinner .bp5-spinner-head {
  stroke: #215db0;
}

.bp5-dark .bp5-button.bp5-outlined.bp5-intent-primary {
  color: #8abbff;
}

.bp5-dark .bp5-button.bp5-outlined.bp5-intent-primary:hover {
  color: #8abbff;
  background: #2d72d233;
}

.bp5-dark .bp5-button.bp5-outlined.bp5-intent-primary:active, .bp5-dark .bp5-button.bp5-outlined.bp5-intent-primary.bp5-active {
  color: #99c4ff;
  background: #2d72d24d;
}

.bp5-dark .bp5-button.bp5-outlined.bp5-intent-primary:disabled, .bp5-dark .bp5-button.bp5-outlined.bp5-intent-primary.bp5-disabled {
  color: #8abbff80;
  background: none;
}

.bp5-dark .bp5-button.bp5-outlined.bp5-intent-primary:disabled.bp5-active, .bp5-dark .bp5-button.bp5-outlined.bp5-intent-primary.bp5-disabled.bp5-active {
  background: #2d72d24d;
}

.bp5-button.bp5-outlined.bp5-intent-success {
  color: #1c6e42;
}

.bp5-button.bp5-outlined.bp5-intent-success:hover, .bp5-button.bp5-outlined.bp5-intent-success:active, .bp5-button.bp5-outlined.bp5-intent-success.bp5-active {
  box-shadow: none;
  color: #1c6e42;
  background: none;
}

.bp5-button.bp5-outlined.bp5-intent-success:hover {
  color: #1c6e42;
  background: #23855126;
}

.bp5-button.bp5-outlined.bp5-intent-success:active, .bp5-button.bp5-outlined.bp5-intent-success.bp5-active {
  color: #165a36;
  background: #2385514d;
}

.bp5-button.bp5-outlined.bp5-intent-success:disabled, .bp5-button.bp5-outlined.bp5-intent-success.bp5-disabled {
  color: #1c6e4280;
  background: none;
}

.bp5-button.bp5-outlined.bp5-intent-success:disabled.bp5-active, .bp5-button.bp5-outlined.bp5-intent-success.bp5-disabled.bp5-active {
  background: #2385514d;
}

.bp5-button.bp5-outlined.bp5-intent-success .bp5-button-spinner .bp5-spinner-head {
  stroke: #1c6e42;
}

.bp5-dark .bp5-button.bp5-outlined.bp5-intent-success {
  color: #72ca9b;
}

.bp5-dark .bp5-button.bp5-outlined.bp5-intent-success:hover {
  color: #72ca9b;
  background: #23855133;
}

.bp5-dark .bp5-button.bp5-outlined.bp5-intent-success:active, .bp5-dark .bp5-button.bp5-outlined.bp5-intent-success.bp5-active {
  color: #7cd7a2;
  background: #2385514d;
}

.bp5-dark .bp5-button.bp5-outlined.bp5-intent-success:disabled, .bp5-dark .bp5-button.bp5-outlined.bp5-intent-success.bp5-disabled {
  color: #72ca9b80;
  background: none;
}

.bp5-dark .bp5-button.bp5-outlined.bp5-intent-success:disabled.bp5-active, .bp5-dark .bp5-button.bp5-outlined.bp5-intent-success.bp5-disabled.bp5-active {
  background: #2385514d;
}

.bp5-button.bp5-outlined.bp5-intent-warning {
  color: #935610;
}

.bp5-button.bp5-outlined.bp5-intent-warning:hover, .bp5-button.bp5-outlined.bp5-intent-warning:active, .bp5-button.bp5-outlined.bp5-intent-warning.bp5-active {
  box-shadow: none;
  color: #935610;
  background: none;
}

.bp5-button.bp5-outlined.bp5-intent-warning:hover {
  color: #935610;
  background: #c8761926;
}

.bp5-button.bp5-outlined.bp5-intent-warning:active, .bp5-button.bp5-outlined.bp5-intent-warning.bp5-active {
  color: #77450d;
  background: #c876194d;
}

.bp5-button.bp5-outlined.bp5-intent-warning:disabled, .bp5-button.bp5-outlined.bp5-intent-warning.bp5-disabled {
  color: #93561080;
  background: none;
}

.bp5-button.bp5-outlined.bp5-intent-warning:disabled.bp5-active, .bp5-button.bp5-outlined.bp5-intent-warning.bp5-disabled.bp5-active {
  background: #c876194d;
}

.bp5-button.bp5-outlined.bp5-intent-warning .bp5-button-spinner .bp5-spinner-head {
  stroke: #935610;
}

.bp5-dark .bp5-button.bp5-outlined.bp5-intent-warning {
  color: #fbb360;
}

.bp5-dark .bp5-button.bp5-outlined.bp5-intent-warning:hover {
  color: #fbb360;
  background: #c8761933;
}

.bp5-dark .bp5-button.bp5-outlined.bp5-intent-warning:active, .bp5-dark .bp5-button.bp5-outlined.bp5-intent-warning.bp5-active {
  color: #f5c186;
  background: #c876194d;
}

.bp5-dark .bp5-button.bp5-outlined.bp5-intent-warning:disabled, .bp5-dark .bp5-button.bp5-outlined.bp5-intent-warning.bp5-disabled {
  color: #fbb36080;
  background: none;
}

.bp5-dark .bp5-button.bp5-outlined.bp5-intent-warning:disabled.bp5-active, .bp5-dark .bp5-button.bp5-outlined.bp5-intent-warning.bp5-disabled.bp5-active {
  background: #c876194d;
}

.bp5-button.bp5-outlined.bp5-intent-danger {
  color: #ac2f33;
}

.bp5-button.bp5-outlined.bp5-intent-danger:hover, .bp5-button.bp5-outlined.bp5-intent-danger:active, .bp5-button.bp5-outlined.bp5-intent-danger.bp5-active {
  box-shadow: none;
  color: #ac2f33;
  background: none;
}

.bp5-button.bp5-outlined.bp5-intent-danger:hover {
  color: #ac2f33;
  background: #cd424626;
}

.bp5-button.bp5-outlined.bp5-intent-danger:active, .bp5-button.bp5-outlined.bp5-intent-danger.bp5-active {
  color: #8e292c;
  background: #cd42464d;
}

.bp5-button.bp5-outlined.bp5-intent-danger:disabled, .bp5-button.bp5-outlined.bp5-intent-danger.bp5-disabled {
  color: #ac2f3380;
  background: none;
}

.bp5-button.bp5-outlined.bp5-intent-danger:disabled.bp5-active, .bp5-button.bp5-outlined.bp5-intent-danger.bp5-disabled.bp5-active {
  background: #cd42464d;
}

.bp5-button.bp5-outlined.bp5-intent-danger .bp5-button-spinner .bp5-spinner-head {
  stroke: #ac2f33;
}

.bp5-dark .bp5-button.bp5-outlined.bp5-intent-danger {
  color: #fa999c;
}

.bp5-dark .bp5-button.bp5-outlined.bp5-intent-danger:hover {
  color: #fa999c;
  background: #cd424633;
}

.bp5-dark .bp5-button.bp5-outlined.bp5-intent-danger:active, .bp5-dark .bp5-button.bp5-outlined.bp5-intent-danger.bp5-active {
  color: #ffa1a4;
  background: #cd42464d;
}

.bp5-dark .bp5-button.bp5-outlined.bp5-intent-danger:disabled, .bp5-dark .bp5-button.bp5-outlined.bp5-intent-danger.bp5-disabled {
  color: #fa999c80;
  background: none;
}

.bp5-dark .bp5-button.bp5-outlined.bp5-intent-danger:disabled.bp5-active, .bp5-dark .bp5-button.bp5-outlined.bp5-intent-danger.bp5-disabled.bp5-active {
  background: #cd42464d;
}

.bp5-button.bp5-outlined:disabled, .bp5-button.bp5-outlined.bp5-disabled, .bp5-button.bp5-outlined:disabled:hover, .bp5-button.bp5-outlined.bp5-disabled:hover {
  border-color: #5f6b7c1a;
}

.bp5-dark .bp5-button.bp5-outlined {
  border-color: #fff6;
}

.bp5-dark .bp5-button.bp5-outlined:disabled, .bp5-dark .bp5-button.bp5-outlined:disabled:hover, .bp5-dark .bp5-button.bp5-outlined.bp5-disabled, .bp5-dark .bp5-button.bp5-outlined.bp5-disabled:hover {
  border-color: #fff3;
}

.bp5-button.bp5-outlined.bp5-intent-primary {
  border-color: #215db099;
}

.bp5-button.bp5-outlined.bp5-intent-primary:disabled, .bp5-button.bp5-outlined.bp5-intent-primary.bp5-disabled {
  border-color: #215db033;
}

.bp5-dark .bp5-button.bp5-outlined.bp5-intent-primary {
  border-color: #8abbff99;
}

.bp5-dark .bp5-button.bp5-outlined.bp5-intent-primary:disabled, .bp5-dark .bp5-button.bp5-outlined.bp5-intent-primary.bp5-disabled {
  border-color: #8abbff33;
}

.bp5-button.bp5-outlined.bp5-intent-success {
  border-color: #1c6e4299;
}

.bp5-button.bp5-outlined.bp5-intent-success:disabled, .bp5-button.bp5-outlined.bp5-intent-success.bp5-disabled {
  border-color: #1c6e4233;
}

.bp5-dark .bp5-button.bp5-outlined.bp5-intent-success {
  border-color: #72ca9b99;
}

.bp5-dark .bp5-button.bp5-outlined.bp5-intent-success:disabled, .bp5-dark .bp5-button.bp5-outlined.bp5-intent-success.bp5-disabled {
  border-color: #72ca9b33;
}

.bp5-button.bp5-outlined.bp5-intent-warning {
  border-color: #93561099;
}

.bp5-button.bp5-outlined.bp5-intent-warning:disabled, .bp5-button.bp5-outlined.bp5-intent-warning.bp5-disabled {
  border-color: #93561033;
}

.bp5-dark .bp5-button.bp5-outlined.bp5-intent-warning {
  border-color: #fbb36099;
}

.bp5-dark .bp5-button.bp5-outlined.bp5-intent-warning:disabled, .bp5-dark .bp5-button.bp5-outlined.bp5-intent-warning.bp5-disabled {
  border-color: #fbb36033;
}

.bp5-button.bp5-outlined.bp5-intent-danger {
  border-color: #ac2f3399;
}

.bp5-button.bp5-outlined.bp5-intent-danger:disabled, .bp5-button.bp5-outlined.bp5-intent-danger.bp5-disabled {
  border-color: #ac2f3333;
}

.bp5-dark .bp5-button.bp5-outlined.bp5-intent-danger {
  border-color: #fa999c99;
}

.bp5-dark .bp5-button.bp5-outlined.bp5-intent-danger:disabled, .bp5-dark .bp5-button.bp5-outlined.bp5-intent-danger.bp5-disabled {
  border-color: #fa999c33;
}

.bp5-button.bp5-intent-warning {
  color: #1c2127;
  background: #fbb360;
}

.bp5-button.bp5-intent-warning:not(.bp5-disabled).bp5-icon > svg {
  fill: #1c2127b3;
}

.bp5-button.bp5-intent-warning:not(.bp5-disabled):not(.bp5-minimal):not(.bp5-outlined):hover {
  color: #1c2127;
  background: #ec9a3c;
}

.bp5-button.bp5-intent-warning:not(.bp5-disabled):not(.bp5-minimal):not(.bp5-outlined):active, .bp5-button.bp5-intent-warning:not(.bp5-disabled):not(.bp5-minimal):not(.bp5-outlined).bp5-active {
  color: #1c2127;
  background: #c87619;
}

@media (forced-colors: active) and (prefers-color-scheme: dark) {
  .bp5-button.bp5-intent-warning:not(.bp5-disabled):not(.bp5-minimal):not(.bp5-outlined):active, .bp5-button.bp5-intent-warning:not(.bp5-disabled):not(.bp5-minimal):not(.bp5-outlined).bp5-active {
    background: highlight;
  }
}

.bp5-button.bp5-intent-warning:disabled, .bp5-button.bp5-intent-warning.bp5-disabled {
  color: #1c212759;
  background: #c8761980;
}

.bp5-dark .bp5-button.bp5-intent-warning:disabled, .bp5-dark .bp5-button.bp5-intent-warning.bp5-disabled {
  color: #1c212799;
}

.bp5-button.bp5-intent-warning.bp5-minimal, .bp5-button.bp5-intent-warning.bp5-outlined {
  background: none;
}

.bp5-dark .bp5-button.bp5-intent-warning.bp5-minimal:not(.bp5-disabled).bp5-icon > svg, .bp5-dark .bp5-button.bp5-intent-warning.bp5-outlined:not(.bp5-disabled).bp5-icon > svg {
  fill: #fbb360;
}

a.bp5-button {
  text-align: left;
  text-decoration: none;
  transition: none;
}

a.bp5-button, a.bp5-button:hover, a.bp5-button:active {
  color: #1c2127;
}

a.bp5-button.bp5-disabled {
  color: #5f6b7c99;
}

.bp5-button-text {
  flex: 0 auto;
}

.bp5-button.bp5-align-left .bp5-button-text, .bp5-button.bp5-align-right .bp5-button-text, .bp5-button-group.bp5-align-left .bp5-button-text, .bp5-button-group.bp5-align-right .bp5-button-text {
  flex: auto;
}

.bp5-button-group {
  display: inline-flex;
}

.bp5-button-group .bp5-button {
  z-index: 4;
  flex: none;
  position: relative;
}

.bp5-button-group .bp5-button:focus {
  z-index: 5;
}

.bp5-button-group .bp5-button:hover {
  z-index: 6;
}

.bp5-button-group .bp5-button:active, .bp5-button-group .bp5-button.bp5-active {
  z-index: 7;
}

.bp5-button-group .bp5-button:disabled, .bp5-button-group .bp5-button.bp5-disabled {
  z-index: 3;
}

.bp5-button-group .bp5-button[class*="bp5-intent-"] {
  z-index: 9;
}

.bp5-button-group .bp5-button[class*="bp5-intent-"]:focus {
  z-index: 10;
}

.bp5-button-group .bp5-button[class*="bp5-intent-"]:hover {
  z-index: 11;
}

.bp5-button-group .bp5-button[class*="bp5-intent-"]:active, .bp5-button-group .bp5-button[class*="bp5-intent-"].bp5-active {
  z-index: 12;
}

.bp5-button-group .bp5-button[class*="bp5-intent-"]:disabled, .bp5-button-group .bp5-button[class*="bp5-intent-"].bp5-disabled {
  z-index: 8;
}

.bp5-button-group:not(.bp5-minimal) > .bp5-popover-wrapper:not(:first-child) .bp5-button, .bp5-button-group:not(.bp5-minimal) > .bp5-button:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.bp5-button-group:not(.bp5-minimal) > .bp5-popover-wrapper:not(:last-child) .bp5-button, .bp5-button-group:not(.bp5-minimal) > .bp5-button:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  margin-right: -1px;
}

.bp5-button-group.bp5-minimal .bp5-button {
  box-shadow: none;
  background: none;
}

.bp5-button-group.bp5-minimal .bp5-button:hover {
  box-shadow: none;
  color: #1c2127;
  background: #8f99a826;
  text-decoration: none;
}

.bp5-button-group.bp5-minimal .bp5-button:active, .bp5-button-group.bp5-minimal .bp5-button.bp5-active {
  box-shadow: none;
  color: #1c2127;
  background: #8f99a84d;
}

.bp5-button-group.bp5-minimal .bp5-button:disabled, .bp5-button-group.bp5-minimal .bp5-button:disabled:hover, .bp5-button-group.bp5-minimal .bp5-button.bp5-disabled, .bp5-button-group.bp5-minimal .bp5-button.bp5-disabled:hover {
  color: #5f6b7c99;
  cursor: not-allowed;
  background: none;
}

.bp5-button-group.bp5-minimal .bp5-button:disabled.bp5-active, .bp5-button-group.bp5-minimal .bp5-button:disabled:hover.bp5-active, .bp5-button-group.bp5-minimal .bp5-button.bp5-disabled.bp5-active, .bp5-button-group.bp5-minimal .bp5-button.bp5-disabled:hover.bp5-active {
  background: #8f99a84d;
}

.bp5-dark .bp5-button-group.bp5-minimal .bp5-button, .bp5-dark .bp5-button-group.bp5-minimal .bp5-button:hover, .bp5-dark .bp5-button-group.bp5-minimal .bp5-button:active, .bp5-dark .bp5-button-group.bp5-minimal .bp5-button.bp5-active {
  box-shadow: none;
  color: #fff;
  background: none;
}

.bp5-dark .bp5-button-group.bp5-minimal .bp5-button:hover {
  background: #8f99a826;
}

.bp5-dark .bp5-button-group.bp5-minimal .bp5-button:active, .bp5-dark .bp5-button-group.bp5-minimal .bp5-button.bp5-active {
  background: #8f99a84d;
}

.bp5-dark .bp5-button-group.bp5-minimal .bp5-button:disabled, .bp5-dark .bp5-button-group.bp5-minimal .bp5-button:disabled:hover, .bp5-dark .bp5-button-group.bp5-minimal .bp5-button.bp5-disabled, .bp5-dark .bp5-button-group.bp5-minimal .bp5-button.bp5-disabled:hover {
  color: #abb3bf99;
  cursor: not-allowed;
  background: none;
}

.bp5-dark .bp5-button-group.bp5-minimal .bp5-button:disabled.bp5-active, .bp5-dark .bp5-button-group.bp5-minimal .bp5-button:disabled:hover.bp5-active, .bp5-dark .bp5-button-group.bp5-minimal .bp5-button.bp5-disabled.bp5-active, .bp5-dark .bp5-button-group.bp5-minimal .bp5-button.bp5-disabled:hover.bp5-active {
  background: #8f99a84d;
}

.bp5-button-group.bp5-minimal .bp5-button.bp5-intent-primary {
  color: #215db0;
}

.bp5-button-group.bp5-minimal .bp5-button.bp5-intent-primary:hover, .bp5-button-group.bp5-minimal .bp5-button.bp5-intent-primary:active, .bp5-button-group.bp5-minimal .bp5-button.bp5-intent-primary.bp5-active {
  box-shadow: none;
  color: #215db0;
  background: none;
}

.bp5-button-group.bp5-minimal .bp5-button.bp5-intent-primary:hover {
  color: #215db0;
  background: #2d72d226;
}

.bp5-button-group.bp5-minimal .bp5-button.bp5-intent-primary:active, .bp5-button-group.bp5-minimal .bp5-button.bp5-intent-primary.bp5-active {
  color: #184a90;
  background: #2d72d24d;
}

.bp5-button-group.bp5-minimal .bp5-button.bp5-intent-primary:disabled, .bp5-button-group.bp5-minimal .bp5-button.bp5-intent-primary.bp5-disabled {
  color: #215db080;
  background: none;
}

.bp5-button-group.bp5-minimal .bp5-button.bp5-intent-primary:disabled.bp5-active, .bp5-button-group.bp5-minimal .bp5-button.bp5-intent-primary.bp5-disabled.bp5-active {
  background: #2d72d24d;
}

.bp5-button-group.bp5-minimal .bp5-button.bp5-intent-primary .bp5-button-spinner .bp5-spinner-head {
  stroke: #215db0;
}

.bp5-dark .bp5-button-group.bp5-minimal .bp5-button.bp5-intent-primary {
  color: #8abbff;
}

.bp5-dark .bp5-button-group.bp5-minimal .bp5-button.bp5-intent-primary:hover {
  color: #8abbff;
  background: #2d72d233;
}

.bp5-dark .bp5-button-group.bp5-minimal .bp5-button.bp5-intent-primary:active, .bp5-dark .bp5-button-group.bp5-minimal .bp5-button.bp5-intent-primary.bp5-active {
  color: #99c4ff;
  background: #2d72d24d;
}

.bp5-dark .bp5-button-group.bp5-minimal .bp5-button.bp5-intent-primary:disabled, .bp5-dark .bp5-button-group.bp5-minimal .bp5-button.bp5-intent-primary.bp5-disabled {
  color: #8abbff80;
  background: none;
}

.bp5-dark .bp5-button-group.bp5-minimal .bp5-button.bp5-intent-primary:disabled.bp5-active, .bp5-dark .bp5-button-group.bp5-minimal .bp5-button.bp5-intent-primary.bp5-disabled.bp5-active {
  background: #2d72d24d;
}

.bp5-button-group.bp5-minimal .bp5-button.bp5-intent-success {
  color: #1c6e42;
}

.bp5-button-group.bp5-minimal .bp5-button.bp5-intent-success:hover, .bp5-button-group.bp5-minimal .bp5-button.bp5-intent-success:active, .bp5-button-group.bp5-minimal .bp5-button.bp5-intent-success.bp5-active {
  box-shadow: none;
  color: #1c6e42;
  background: none;
}

.bp5-button-group.bp5-minimal .bp5-button.bp5-intent-success:hover {
  color: #1c6e42;
  background: #23855126;
}

.bp5-button-group.bp5-minimal .bp5-button.bp5-intent-success:active, .bp5-button-group.bp5-minimal .bp5-button.bp5-intent-success.bp5-active {
  color: #165a36;
  background: #2385514d;
}

.bp5-button-group.bp5-minimal .bp5-button.bp5-intent-success:disabled, .bp5-button-group.bp5-minimal .bp5-button.bp5-intent-success.bp5-disabled {
  color: #1c6e4280;
  background: none;
}

.bp5-button-group.bp5-minimal .bp5-button.bp5-intent-success:disabled.bp5-active, .bp5-button-group.bp5-minimal .bp5-button.bp5-intent-success.bp5-disabled.bp5-active {
  background: #2385514d;
}

.bp5-button-group.bp5-minimal .bp5-button.bp5-intent-success .bp5-button-spinner .bp5-spinner-head {
  stroke: #1c6e42;
}

.bp5-dark .bp5-button-group.bp5-minimal .bp5-button.bp5-intent-success {
  color: #72ca9b;
}

.bp5-dark .bp5-button-group.bp5-minimal .bp5-button.bp5-intent-success:hover {
  color: #72ca9b;
  background: #23855133;
}

.bp5-dark .bp5-button-group.bp5-minimal .bp5-button.bp5-intent-success:active, .bp5-dark .bp5-button-group.bp5-minimal .bp5-button.bp5-intent-success.bp5-active {
  color: #7cd7a2;
  background: #2385514d;
}

.bp5-dark .bp5-button-group.bp5-minimal .bp5-button.bp5-intent-success:disabled, .bp5-dark .bp5-button-group.bp5-minimal .bp5-button.bp5-intent-success.bp5-disabled {
  color: #72ca9b80;
  background: none;
}

.bp5-dark .bp5-button-group.bp5-minimal .bp5-button.bp5-intent-success:disabled.bp5-active, .bp5-dark .bp5-button-group.bp5-minimal .bp5-button.bp5-intent-success.bp5-disabled.bp5-active {
  background: #2385514d;
}

.bp5-button-group.bp5-minimal .bp5-button.bp5-intent-warning {
  color: #935610;
}

.bp5-button-group.bp5-minimal .bp5-button.bp5-intent-warning:hover, .bp5-button-group.bp5-minimal .bp5-button.bp5-intent-warning:active, .bp5-button-group.bp5-minimal .bp5-button.bp5-intent-warning.bp5-active {
  box-shadow: none;
  color: #935610;
  background: none;
}

.bp5-button-group.bp5-minimal .bp5-button.bp5-intent-warning:hover {
  color: #935610;
  background: #c8761926;
}

.bp5-button-group.bp5-minimal .bp5-button.bp5-intent-warning:active, .bp5-button-group.bp5-minimal .bp5-button.bp5-intent-warning.bp5-active {
  color: #77450d;
  background: #c876194d;
}

.bp5-button-group.bp5-minimal .bp5-button.bp5-intent-warning:disabled, .bp5-button-group.bp5-minimal .bp5-button.bp5-intent-warning.bp5-disabled {
  color: #93561080;
  background: none;
}

.bp5-button-group.bp5-minimal .bp5-button.bp5-intent-warning:disabled.bp5-active, .bp5-button-group.bp5-minimal .bp5-button.bp5-intent-warning.bp5-disabled.bp5-active {
  background: #c876194d;
}

.bp5-button-group.bp5-minimal .bp5-button.bp5-intent-warning .bp5-button-spinner .bp5-spinner-head {
  stroke: #935610;
}

.bp5-dark .bp5-button-group.bp5-minimal .bp5-button.bp5-intent-warning {
  color: #fbb360;
}

.bp5-dark .bp5-button-group.bp5-minimal .bp5-button.bp5-intent-warning:hover {
  color: #fbb360;
  background: #c8761933;
}

.bp5-dark .bp5-button-group.bp5-minimal .bp5-button.bp5-intent-warning:active, .bp5-dark .bp5-button-group.bp5-minimal .bp5-button.bp5-intent-warning.bp5-active {
  color: #f5c186;
  background: #c876194d;
}

.bp5-dark .bp5-button-group.bp5-minimal .bp5-button.bp5-intent-warning:disabled, .bp5-dark .bp5-button-group.bp5-minimal .bp5-button.bp5-intent-warning.bp5-disabled {
  color: #fbb36080;
  background: none;
}

.bp5-dark .bp5-button-group.bp5-minimal .bp5-button.bp5-intent-warning:disabled.bp5-active, .bp5-dark .bp5-button-group.bp5-minimal .bp5-button.bp5-intent-warning.bp5-disabled.bp5-active {
  background: #c876194d;
}

.bp5-button-group.bp5-minimal .bp5-button.bp5-intent-danger {
  color: #ac2f33;
}

.bp5-button-group.bp5-minimal .bp5-button.bp5-intent-danger:hover, .bp5-button-group.bp5-minimal .bp5-button.bp5-intent-danger:active, .bp5-button-group.bp5-minimal .bp5-button.bp5-intent-danger.bp5-active {
  box-shadow: none;
  color: #ac2f33;
  background: none;
}

.bp5-button-group.bp5-minimal .bp5-button.bp5-intent-danger:hover {
  color: #ac2f33;
  background: #cd424626;
}

.bp5-button-group.bp5-minimal .bp5-button.bp5-intent-danger:active, .bp5-button-group.bp5-minimal .bp5-button.bp5-intent-danger.bp5-active {
  color: #8e292c;
  background: #cd42464d;
}

.bp5-button-group.bp5-minimal .bp5-button.bp5-intent-danger:disabled, .bp5-button-group.bp5-minimal .bp5-button.bp5-intent-danger.bp5-disabled {
  color: #ac2f3380;
  background: none;
}

.bp5-button-group.bp5-minimal .bp5-button.bp5-intent-danger:disabled.bp5-active, .bp5-button-group.bp5-minimal .bp5-button.bp5-intent-danger.bp5-disabled.bp5-active {
  background: #cd42464d;
}

.bp5-button-group.bp5-minimal .bp5-button.bp5-intent-danger .bp5-button-spinner .bp5-spinner-head {
  stroke: #ac2f33;
}

.bp5-dark .bp5-button-group.bp5-minimal .bp5-button.bp5-intent-danger {
  color: #fa999c;
}

.bp5-dark .bp5-button-group.bp5-minimal .bp5-button.bp5-intent-danger:hover {
  color: #fa999c;
  background: #cd424633;
}

.bp5-dark .bp5-button-group.bp5-minimal .bp5-button.bp5-intent-danger:active, .bp5-dark .bp5-button-group.bp5-minimal .bp5-button.bp5-intent-danger.bp5-active {
  color: #ffa1a4;
  background: #cd42464d;
}

.bp5-dark .bp5-button-group.bp5-minimal .bp5-button.bp5-intent-danger:disabled, .bp5-dark .bp5-button-group.bp5-minimal .bp5-button.bp5-intent-danger.bp5-disabled {
  color: #fa999c80;
  background: none;
}

.bp5-dark .bp5-button-group.bp5-minimal .bp5-button.bp5-intent-danger:disabled.bp5-active, .bp5-dark .bp5-button-group.bp5-minimal .bp5-button.bp5-intent-danger.bp5-disabled.bp5-active {
  background: #cd42464d;
}

@media (forced-colors: active) and (prefers-color-scheme: dark) {
  .bp5-button-group.bp5-minimal:not(:first-child) {
    border-left: none;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .bp5-button-group.bp5-minimal:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    margin-right: -1px;
  }
}

.bp5-button-group .bp5-popover-wrapper, .bp5-button-group .bp5-popover-target {
  flex: auto;
  display: flex;
}

.bp5-button-group.bp5-fill {
  width: 100%;
  display: flex;
}

.bp5-button-group .bp5-button.bp5-fill, .bp5-button-group.bp5-fill .bp5-button:not(.bp5-fixed) {
  flex: auto;
}

.bp5-button-group.bp5-vertical {
  vertical-align: top;
  flex-direction: column;
  align-items: stretch;
}

.bp5-button-group.bp5-vertical.bp5-fill {
  width: unset;
  height: 100%;
}

.bp5-button-group.bp5-vertical .bp5-button {
  width: 100%;
  margin-right: 0 !important;
}

.bp5-button-group.bp5-vertical:not(.bp5-minimal) > .bp5-popover-wrapper:first-child .bp5-button, .bp5-button-group.bp5-vertical:not(.bp5-minimal) > .bp5-button:first-child {
  border-radius: 2px 2px 0 0;
}

.bp5-button-group.bp5-vertical:not(.bp5-minimal) > .bp5-popover-wrapper:last-child .bp5-button, .bp5-button-group.bp5-vertical:not(.bp5-minimal) > .bp5-button:last-child {
  border-radius: 0 0 2px 2px;
}

.bp5-button-group.bp5-vertical:not(.bp5-minimal) > .bp5-popover-wrapper:not(:last-child) .bp5-button, .bp5-button-group.bp5-vertical:not(.bp5-minimal) > .bp5-button:not(:last-child) {
  margin-bottom: -1px;
}

.bp5-button-group.bp5-align-left .bp5-button {
  text-align: left;
}

.bp5-callout {
  background-color: #8f99a826;
  border-radius: 2px;
  width: 100%;
  padding: 15px;
  font-size: 14px;
  line-height: 1.5;
  position: relative;
}

.bp5-callout[class*="bp5-icon-"] {
  padding-left: 38px;
}

.bp5-callout[class*="bp5-icon-"]:before {
  font-variant: normal;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  color: #5f6b7c;
  width: 16px;
  height: 16px;
  font-family: blueprint-icons-16, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 1;
  position: absolute;
  top: 17px;
  left: 15px;
}

@media (forced-colors: active) and (prefers-color-scheme: dark) {
  .bp5-callout {
    border: 1px solid buttonborder;
  }
}

.bp5-callout.bp5-callout-icon {
  padding-left: 38px;
}

.bp5-callout.bp5-callout-icon > .bp5-icon:first-child {
  color: #5f6b7c;
  position: absolute;
  top: 17px;
  left: 15px;
}

.bp5-callout .bp5-heading {
  margin-top: 2px;
  margin-bottom: 0;
  line-height: 16px;
}

.bp5-callout.bp5-callout-has-body-content .bp5-heading {
  margin-bottom: 5px;
}

.bp5-callout.bp5-compact {
  padding: 10px;
}

.bp5-callout.bp5-compact.bp5-callout-icon {
  padding-left: 33px;
}

.bp5-callout.bp5-compact.bp5-callout-icon > .bp5-icon:first-child {
  top: 12px;
  left: 10px;
}

.bp5-dark .bp5-callout {
  background-color: #8f99a833;
}

.bp5-dark .bp5-callout[class*="bp5-icon-"]:before, .bp5-dark .bp5-callout.bp5-callout-icon > .bp5-icon:first-child {
  color: #abb3bf;
}

.bp5-callout.bp5-intent-primary {
  color: #215db0;
  background-color: #2d72d21a;
}

@media (forced-colors: active) and (prefers-color-scheme: dark) {
  .bp5-callout.bp5-intent-primary {
    border: 1px solid buttonborder;
  }
}

.bp5-callout.bp5-intent-primary[class*="bp5-icon-"]:before, .bp5-callout.bp5-intent-primary > .bp5-icon:first-child, .bp5-callout.bp5-intent-primary .bp5-heading {
  color: #215db0;
}

.bp5-dark .bp5-callout.bp5-intent-primary {
  color: #8abbff;
  background-color: #2d72d233;
}

.bp5-dark .bp5-callout.bp5-intent-primary[class*="bp5-icon-"]:before, .bp5-dark .bp5-callout.bp5-intent-primary > .bp5-icon:first-child, .bp5-dark .bp5-callout.bp5-intent-primary .bp5-heading {
  color: #8abbff;
}

.bp5-callout.bp5-intent-success {
  color: #1c6e42;
  background-color: #2385511a;
}

@media (forced-colors: active) and (prefers-color-scheme: dark) {
  .bp5-callout.bp5-intent-success {
    border: 1px solid buttonborder;
  }
}

.bp5-callout.bp5-intent-success[class*="bp5-icon-"]:before, .bp5-callout.bp5-intent-success > .bp5-icon:first-child, .bp5-callout.bp5-intent-success .bp5-heading {
  color: #1c6e42;
}

.bp5-dark .bp5-callout.bp5-intent-success {
  color: #72ca9b;
  background-color: #23855133;
}

.bp5-dark .bp5-callout.bp5-intent-success[class*="bp5-icon-"]:before, .bp5-dark .bp5-callout.bp5-intent-success > .bp5-icon:first-child, .bp5-dark .bp5-callout.bp5-intent-success .bp5-heading {
  color: #72ca9b;
}

.bp5-callout.bp5-intent-warning {
  color: #935610;
  background-color: #c876191a;
}

@media (forced-colors: active) and (prefers-color-scheme: dark) {
  .bp5-callout.bp5-intent-warning {
    border: 1px solid buttonborder;
  }
}

.bp5-callout.bp5-intent-warning[class*="bp5-icon-"]:before, .bp5-callout.bp5-intent-warning > .bp5-icon:first-child, .bp5-callout.bp5-intent-warning .bp5-heading {
  color: #935610;
}

.bp5-dark .bp5-callout.bp5-intent-warning {
  color: #fbb360;
  background-color: #c8761933;
}

.bp5-dark .bp5-callout.bp5-intent-warning[class*="bp5-icon-"]:before, .bp5-dark .bp5-callout.bp5-intent-warning > .bp5-icon:first-child, .bp5-dark .bp5-callout.bp5-intent-warning .bp5-heading {
  color: #fbb360;
}

.bp5-callout.bp5-intent-danger {
  color: #ac2f33;
  background-color: #cd42461a;
}

@media (forced-colors: active) and (prefers-color-scheme: dark) {
  .bp5-callout.bp5-intent-danger {
    border: 1px solid buttonborder;
  }
}

.bp5-callout.bp5-intent-danger[class*="bp5-icon-"]:before, .bp5-callout.bp5-intent-danger > .bp5-icon:first-child, .bp5-callout.bp5-intent-danger .bp5-heading {
  color: #ac2f33;
}

.bp5-dark .bp5-callout.bp5-intent-danger {
  color: #fa999c;
  background-color: #cd424633;
}

.bp5-dark .bp5-callout.bp5-intent-danger[class*="bp5-icon-"]:before, .bp5-dark .bp5-callout.bp5-intent-danger > .bp5-icon:first-child, .bp5-dark .bp5-callout.bp5-intent-danger .bp5-heading {
  color: #fa999c;
}

.bp5-callout.bp5-intent-primary a {
  text-decoration: underline;
}

.bp5-callout.bp5-intent-primary a:hover {
  color: #184a90;
}

.bp5-dark .bp5-callout.bp5-intent-primary a:hover {
  color: #99c4ff;
}

.bp5-running-text .bp5-callout {
  margin: 20px 0;
}

.bp5-card {
  background-color: #fff;
  border-radius: 2px;
  padding: 20px;
  transition: transform .2s cubic-bezier(.4, 1, .75, .9), box-shadow .2s cubic-bezier(.4, 1, .75, .9);
  box-shadow: 0 0 0 1px #11141826;
}

.bp5-card.bp5-dark, .bp5-dark .bp5-card {
  background-color: #2f343c;
  box-shadow: inset 0 0 0 1px #fff3;
}

@media (forced-colors: active) and (prefers-color-scheme: dark) {
  .bp5-card {
    border: 1px solid buttonborder;
    box-shadow: none;
  }
}

.bp5-elevation-0 {
  box-shadow: 0 0 0 1px #11141826;
}

.bp5-elevation-0.bp5-dark, .bp5-dark .bp5-elevation-0 {
  box-shadow: inset 0 0 0 1px #fff3;
}

@media (forced-colors: active) and (prefers-color-scheme: dark) {
  .bp5-elevation-0 {
    border: 1px solid buttonborder;
  }
}

.bp5-elevation-1 {
  box-shadow: 0 0 0 1px #1114181a, 0 1px 1px #11141833;
}

.bp5-elevation-1.bp5-dark, .bp5-dark .bp5-elevation-1 {
  box-shadow: inset 0 0 0 1px #fff3, 0 1px 1px #11141866;
}

@media (forced-colors: active) and (prefers-color-scheme: dark) {
  .bp5-elevation-1 {
    border: 1px solid buttonborder;
  }
}

.bp5-elevation-2 {
  box-shadow: 0 0 0 1px #1114181a, 0 1px 1px #11141833, 0 2px 6px #11141833;
}

.bp5-elevation-2.bp5-dark, .bp5-dark .bp5-elevation-2 {
  box-shadow: inset 0 0 0 1px #fff3, 0 1px 1px #11141866, 0 2px 6px #11141866;
}

@media (forced-colors: active) and (prefers-color-scheme: dark) {
  .bp5-elevation-2 {
    border: 1px solid buttonborder;
  }
}

.bp5-elevation-3 {
  box-shadow: 0 0 0 1px #1114181a, 0 2px 4px #11141833, 0 8px 24px #11141833;
}

.bp5-elevation-3.bp5-dark, .bp5-dark .bp5-elevation-3 {
  box-shadow: inset 0 0 0 1px #fff3, 0 2px 4px #11141866, 0 8px 24px #11141866;
}

@media (forced-colors: active) and (prefers-color-scheme: dark) {
  .bp5-elevation-3 {
    border: 1px solid buttonborder;
  }
}

.bp5-elevation-4 {
  box-shadow: 0 0 0 1px #1114181a, 0 4px 8px #11141833, 0 18px 46px 6px #11141833;
}

.bp5-elevation-4.bp5-dark, .bp5-dark .bp5-elevation-4 {
  box-shadow: inset 0 0 0 1px #fff3, 0 4px 8px #11141866, 0 18px 46px 6px #11141866;
}

@media (forced-colors: active) and (prefers-color-scheme: dark) {
  .bp5-elevation-4 {
    border: 1px solid buttonborder;
  }
}

.bp5-card.bp5-compact {
  padding: 15px;
}

.bp5-card.bp5-interactive:hover {
  cursor: pointer;
  box-shadow: 0 0 0 1px #1114181a, 0 2px 4px #11141833, 0 8px 24px #11141833;
}

.bp5-card.bp5-interactive:hover.bp5-dark, .bp5-dark .bp5-card.bp5-interactive:hover {
  box-shadow: inset 0 0 0 1px #fff3, 0 2px 4px #11141866, 0 8px 24px #11141866;
}

.bp5-card.bp5-interactive.bp5-selected {
  box-shadow: 0 0 0 3px #4c90f033, 0 0 0 1px #4c90f0;
}

.bp5-card.bp5-interactive.bp5-selected.bp5-dark, .bp5-dark .bp5-card.bp5-interactive.bp5-selected {
  box-shadow: 0 0 0 3px #8abbff66, 0 0 0 1px #8abbff;
}

.bp5-card.bp5-interactive:active {
  transition-duration: 0;
  box-shadow: 0 0 0 1px #1114181a, 0 1px 1px #11141833;
}

.bp5-card.bp5-interactive:active.bp5-dark, .bp5-dark .bp5-card.bp5-interactive:active {
  box-shadow: inset 0 0 0 1px #fff3, 0 1px 1px #11141866;
}

.bp5-card-list {
  width: 100%;
  padding: 0;
  overflow: auto;
}

.bp5-card-list > .bp5-card {
  box-shadow: none;
  border-radius: 0;
  align-items: center;
  min-height: 51px;
  padding: 10px 20px;
  display: flex;
}

.bp5-card-list > .bp5-card.bp5-interactive:hover, .bp5-card-list > .bp5-card.bp5-interactive:active {
  box-shadow: none;
  background-color: #f6f7f9;
}

.bp5-dark .bp5-card-list > .bp5-card.bp5-interactive:hover, .bp5-dark .bp5-card-list > .bp5-card.bp5-interactive:active {
  background-color: #383e47;
}

.bp5-card-list > .bp5-card.bp5-selected {
  box-shadow: none;
  background-color: #edeff2;
}

.bp5-dark .bp5-card-list > .bp5-card.bp5-selected {
  box-shadow: none;
  background-color: #404854;
}

.bp5-card-list > .bp5-card:not(:last-child) {
  border-bottom: 1px solid #1114181a;
}

.bp5-dark .bp5-card-list > .bp5-card:not(:last-child) {
  border-color: #ffffff1a;
}

.bp5-card-list.bp5-compact {
  padding: 0;
}

.bp5-card-list.bp5-compact > .bp5-card {
  min-height: 45px;
  padding: 7px 15px;
}

.bp5-dark .bp5-card-list {
  padding: 1px;
}

.bp5-card-list:not(.bp5-card-list-bordered) {
  box-shadow: none;
  border-radius: 0;
}

.bp5-dark .bp5-card-list:not(.bp5-card-list-bordered) {
  width: calc(100% - 2px);
  margin: 1px;
}

.bp5-collapse {
  height: 0;
  transition: height .2s cubic-bezier(.4, 1, .75, .9);
  overflow-y: hidden;
}

.bp5-collapse .bp5-collapse-body {
  transition: transform .2s cubic-bezier(.4, 1, .75, .9);
}

.bp5-collapse .bp5-collapse-body[aria-hidden="true"] {
  display: none;
}

.bp5-context-menu-virtual-target {
  position: fixed;
}

.bp5-card.bp5-control-card, .bp5-card-list > .bp5-card.bp5-control-card {
  min-height: auto;
  padding: 0;
}

.bp5-control-card .bp5-control.bp5-control.bp5-control {
  align-items: flex-start;
  gap: 10px;
  width: 100%;
  margin: 0;
  padding: 20px;
  display: flex;
}

.bp5-control-card .bp5-control.bp5-control.bp5-control.bp5-align-left {
  flex-direction: row;
  justify-content: flex-start;
}

.bp5-control-card .bp5-control.bp5-control.bp5-control.bp5-align-right {
  flex-direction: row-reverse;
  justify-content: space-between;
}

.bp5-card-list .bp5-control-card .bp5-control.bp5-control.bp5-control {
  padding: 20px;
}

.bp5-card-list.bp5-compact .bp5-control-card .bp5-control.bp5-control.bp5-control {
  padding: 15px;
}

.bp5-control-card .bp5-control.bp5-control.bp5-control .bp5-control-indicator {
  margin: 0;
}

.bp5-control-card.bp5-compact .bp5-control.bp5-control.bp5-control {
  padding: 15px;
}

.bp5-dialog-container {
  opacity: 1;
  pointer-events: none;
  -webkit-user-select: none;
  user-select: none;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 100%;
  display: flex;
  transform: scale(1);
}

.bp5-dialog-container.bp5-overlay-enter > .bp5-dialog, .bp5-dialog-container.bp5-overlay-appear > .bp5-dialog {
  opacity: 0;
  transform: scale(.5);
}

.bp5-dialog-container.bp5-overlay-enter-active > .bp5-dialog, .bp5-dialog-container.bp5-overlay-appear-active > .bp5-dialog {
  opacity: 1;
  transition-delay: 0;
  transition-property: opacity, transform;
  transition-duration: .3s;
  transition-timing-function: cubic-bezier(.54, 1.12, .38, 1.11);
  transform: scale(1);
}

.bp5-dialog-container.bp5-overlay-exit > .bp5-dialog {
  opacity: 1;
  transform: scale(1);
}

.bp5-dialog-container.bp5-overlay-exit-active > .bp5-dialog {
  opacity: 0;
  transition-delay: 0;
  transition-property: opacity, transform;
  transition-duration: .3s;
  transition-timing-function: cubic-bezier(.54, 1.12, .38, 1.11);
  transform: scale(.5);
}

.bp5-dialog {
  pointer-events: all;
  -webkit-user-select: text;
  user-select: text;
  background: #f6f7f9;
  border-radius: 4px;
  flex-direction: column;
  width: 500px;
  margin: 30px 0;
  display: flex;
  box-shadow: 0 0 0 1px #1114181a, 0 2px 4px #11141833, 0 8px 24px #11141833;
}

.bp5-dialog:focus {
  outline: 0;
}

.bp5-dialog.bp5-dark, .bp5-dark .bp5-dialog {
  color: #f6f7f9;
  background: #252a31;
  box-shadow: inset 0 0 0 1px #fff3, 0 2px 4px #11141866, 0 8px 24px #11141866;
}

@media (forced-colors: active) and (prefers-color-scheme: dark) {
  .bp5-dialog {
    border: 1px solid buttonborder;
  }
}

.bp5-dialog-header {
  z-index: 0;
  background: #fff;
  border-radius: 4px 4px 0 0;
  flex: none;
  align-items: center;
  min-height: 40px;
  padding: 5px 5px 5px 15px;
  display: flex;
  box-shadow: 0 1px #11141826;
}

.bp5-dialog-header .bp5-icon-large, .bp5-dialog-header .bp5-icon {
  color: #5f6b7c;
  flex: none;
  margin-left: -3px;
  margin-right: 7.5px;
}

.bp5-dialog-header .bp5-heading {
  text-overflow: ellipsis;
  white-space: nowrap;
  word-wrap: normal;
  line-height: inherit;
  flex: auto;
  margin: 0;
  overflow: hidden;
}

.bp5-dialog-header .bp5-heading:last-child {
  margin-right: 15px;
}

.bp5-dark .bp5-dialog-header {
  background: #2f343c;
  box-shadow: inset 0 0 0 1px #fff3;
}

.bp5-dark .bp5-dialog-header .bp5-icon-large, .bp5-dark .bp5-dialog-header .bp5-icon {
  color: #abb3bf;
}

@media (forced-colors: active) and (prefers-color-scheme: dark) {
  .bp5-dialog-header {
    border-bottom: 1px solid buttonborder;
  }
}

.bp5-dialog-body {
  flex: auto;
  margin: 15px;
}

.bp5-dialog-body-scroll-container {
  max-height: 70vh;
  margin: 0;
  padding: 15px;
  overflow: auto;
}

.bp5-dialog-footer {
  flex: none;
  margin: 15px;
}

.bp5-dialog-footer-fixed {
  background-color: #fff;
  border-top: 1px solid #11141826;
  border-radius: 0 0 4px 4px;
  justify-content: space-between;
  align-items: center;
  gap: 15px;
  margin: 0;
  padding: 10px 10px 10px 15px;
  display: flex;
}

.bp5-dark .bp5-dialog-footer-fixed {
  background: #383e47;
  border-top: 1px solid #fff3;
}

.bp5-dialog-footer-main-section {
  flex: auto;
}

.bp5-dialog-footer-actions {
  justify-content: flex-end;
  display: flex;
}

.bp5-dialog-footer-actions .bp5-button {
  margin-left: 10px;
}

.bp5-multistep-dialog-panels {
  display: flex;
}

.bp5-multistep-dialog-panels:first-child .bp5-dialog-step-container:first-child {
  border-radius: 4px 0 0;
}

.bp5-multistep-dialog-panels:first-child .bp5-multistep-dialog-right-panel {
  border-top-right-radius: 4px;
}

.bp5-multistep-dialog-nav-top .bp5-multistep-dialog-panels {
  flex-direction: column;
}

.bp5-multistep-dialog-nav-top .bp5-multistep-dialog-panels:first-child .bp5-dialog-step-container:first-child {
  border-radius: 4px 0 0;
}

.bp5-multistep-dialog-nav-top .bp5-multistep-dialog-panels:first-child .bp5-dialog-step-container:last-child {
  border-radius: 0 4px 0 0;
}

.bp5-multistep-dialog-nav-top .bp5-multistep-dialog-panels .bp5-multistep-dialog-left-panel {
  flex-direction: row;
}

.bp5-multistep-dialog-nav-top .bp5-multistep-dialog-panels .bp5-dialog-step-container {
  flex-grow: 1;
}

.bp5-multistep-dialog-nav-top .bp5-multistep-dialog-panels .bp5-dialog-step-container:not(:first-child) {
  border-left: 1px solid #11141826;
}

.bp5-dark .bp5-multistep-dialog-nav-top .bp5-multistep-dialog-panels .bp5-dialog-step-container {
  border-color: #11141866;
}

.bp5-multistep-dialog-nav-top .bp5-multistep-dialog-panels .bp5-multistep-dialog-right-panel {
  border-left: none;
}

.bp5-multistep-dialog-nav-top .bp5-multistep-dialog-panels .bp5-multistep-dialog-right-panel, .bp5-multistep-dialog-nav-top .bp5-multistep-dialog-panels .bp5-multistep-dialog-footer {
  border-radius: 0 0 4px 4px;
}

.bp5-multistep-dialog-nav-right .bp5-multistep-dialog-panels {
  flex-direction: row-reverse;
}

.bp5-multistep-dialog-nav-right .bp5-multistep-dialog-panels:first-child .bp5-multistep-dialog-right-panel {
  border-radius: 4px 0 0 4px;
}

.bp5-multistep-dialog-nav-right .bp5-multistep-dialog-panels:first-child .bp5-dialog-step-container:first-child {
  border-radius: 0 4px 0 0;
}

.bp5-multistep-dialog-nav-right .bp5-multistep-dialog-panels .bp5-multistep-dialog-left-panel {
  border-radius: 0 0 4px;
}

.bp5-multistep-dialog-nav-right .bp5-multistep-dialog-panels .bp5-multistep-dialog-right-panel {
  border-left: none;
  border-right: 1px solid #11141826;
  border-radius: 4px 0 0 4px;
}

.bp5-dark .bp5-multistep-dialog-nav-right .bp5-multistep-dialog-panels .bp5-multistep-dialog-right-panel {
  border-color: #11141866;
}

.bp5-multistep-dialog-nav-right .bp5-multistep-dialog-panels .bp5-dialog-footer {
  border-bottom-left-radius: 0;
}

.bp5-multistep-dialog-left-panel {
  flex-direction: column;
  flex: 1;
  display: flex;
}

.bp5-dark .bp5-multistep-dialog-left-panel {
  background: #252a31;
  border-bottom: 1px solid #fff3;
  border-left: 1px solid #fff3;
  border-bottom-left-radius: 4px;
}

.bp5-multistep-dialog-right-panel {
  background-color: #f6f7f9;
  border-left: 1px solid #11141826;
  border-radius: 0 0 4px;
  flex: 3;
  min-width: 0;
}

.bp5-dark .bp5-multistep-dialog-right-panel {
  background-color: #2f343c;
  border-bottom: 1px solid #fff3;
  border-left: 1px solid #fff3;
  border-right: 1px solid #fff3;
  border-bottom-right-radius: 4px;
}

.bp5-dialog-step-container {
  background-color: #f6f7f9;
  border-bottom: 1px solid #11141826;
}

.bp5-dark .bp5-dialog-step-container {
  background: #2f343c;
  border-bottom: 1px solid #fff3;
}

.bp5-dialog-step-container.bp5-dialog-step-viewed {
  background-color: #fff;
}

.bp5-dark .bp5-dialog-step-container.bp5-dialog-step-viewed {
  background: #383e47;
}

.bp5-dialog-step {
  cursor: not-allowed;
  border-radius: 4px;
  align-items: center;
  margin: 4px;
  padding: 6px 14px;
  display: flex;
}

.bp5-dark .bp5-dialog-step {
  background: #2f343c;
}

.bp5-dialog-step-viewed .bp5-dialog-step {
  cursor: pointer;
  background-color: #fff;
}

.bp5-dark .bp5-dialog-step-viewed .bp5-dialog-step {
  background: #383e47;
}

.bp5-dialog-step:hover {
  background-color: #f6f7f9;
}

.bp5-dark .bp5-dialog-step:hover {
  background: #2f343c;
}

.bp5-dialog-step-icon {
  color: #fff;
  background-color: #5f6b7c99;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  width: 25px;
  height: 25px;
  display: flex;
}

.bp5-dark .bp5-dialog-step-icon {
  background-color: #abb3bf99;
}

.bp5-active.bp5-dialog-step-viewed .bp5-dialog-step-icon {
  background-color: #4c90f0;
}

.bp5-dialog-step-viewed .bp5-dialog-step-icon {
  background-color: #8f99a8;
}

.bp5-dialog-step-title {
  color: #5f6b7c99;
  flex: 1;
  padding-left: 10px;
}

.bp5-dark .bp5-dialog-step-title {
  color: #abb3bf99;
}

.bp5-active.bp5-dialog-step-viewed .bp5-dialog-step-title {
  color: #4c90f0;
}

.bp5-dialog-step-viewed:not(.bp5-active) .bp5-dialog-step-title {
  color: #1c2127;
}

.bp5-dark .bp5-dialog-step-viewed:not(.bp5-active) .bp5-dialog-step-title {
  color: #f6f7f9;
}

.bp5-drawer {
  background: #fff;
  flex-direction: column;
  margin: 0;
  padding: 0;
  display: flex;
  box-shadow: 0 0 0 1px #1114181a, 0 4px 8px #11141833, 0 18px 46px 6px #11141833;
}

.bp5-drawer:focus {
  outline: 0;
}

.bp5-drawer.bp5-position-top {
  height: 50%;
  top: 0;
  left: 0;
  right: 0;
}

.bp5-drawer.bp5-position-top.bp5-overlay-enter, .bp5-drawer.bp5-position-top.bp5-overlay-appear {
  transform: translateY(-100%);
}

.bp5-drawer.bp5-position-top.bp5-overlay-enter-active, .bp5-drawer.bp5-position-top.bp5-overlay-appear-active {
  transition-delay: 0;
  transition-property: transform;
  transition-duration: .2s;
  transition-timing-function: cubic-bezier(.4, 1, .75, .9);
  transform: translateY(0);
}

.bp5-drawer.bp5-position-top.bp5-overlay-exit {
  transform: translateY(0);
}

.bp5-drawer.bp5-position-top.bp5-overlay-exit-active {
  transition-delay: 0;
  transition-property: transform;
  transition-duration: .1s;
  transition-timing-function: cubic-bezier(.4, 1, .75, .9);
  transform: translateY(-100%);
}

@media (forced-colors: active) and (prefers-color-scheme: dark) {
  .bp5-drawer.bp5-position-top {
    border-bottom: 1px solid buttonborder;
  }
}

.bp5-drawer.bp5-position-bottom {
  height: 50%;
  bottom: 0;
  left: 0;
  right: 0;
}

.bp5-drawer.bp5-position-bottom.bp5-overlay-enter, .bp5-drawer.bp5-position-bottom.bp5-overlay-appear {
  transform: translateY(100%);
}

.bp5-drawer.bp5-position-bottom.bp5-overlay-enter-active, .bp5-drawer.bp5-position-bottom.bp5-overlay-appear-active {
  transition-delay: 0;
  transition-property: transform;
  transition-duration: .2s;
  transition-timing-function: cubic-bezier(.4, 1, .75, .9);
  transform: translateY(0);
}

.bp5-drawer.bp5-position-bottom.bp5-overlay-exit {
  transform: translateY(0);
}

.bp5-drawer.bp5-position-bottom.bp5-overlay-exit-active {
  transition-delay: 0;
  transition-property: transform;
  transition-duration: .1s;
  transition-timing-function: cubic-bezier(.4, 1, .75, .9);
  transform: translateY(100%);
}

@media (forced-colors: active) and (prefers-color-scheme: dark) {
  .bp5-drawer.bp5-position-bottom {
    border-top: 1px solid buttonborder;
  }
}

.bp5-drawer.bp5-position-left {
  width: 50%;
  top: 0;
  bottom: 0;
  left: 0;
}

.bp5-drawer.bp5-position-left.bp5-overlay-enter, .bp5-drawer.bp5-position-left.bp5-overlay-appear {
  transform: translateX(-100%);
}

.bp5-drawer.bp5-position-left.bp5-overlay-enter-active, .bp5-drawer.bp5-position-left.bp5-overlay-appear-active {
  transition-delay: 0;
  transition-property: transform;
  transition-duration: .2s;
  transition-timing-function: cubic-bezier(.4, 1, .75, .9);
  transform: translateX(0);
}

.bp5-drawer.bp5-position-left.bp5-overlay-exit {
  transform: translateX(0);
}

.bp5-drawer.bp5-position-left.bp5-overlay-exit-active {
  transition-delay: 0;
  transition-property: transform;
  transition-duration: .1s;
  transition-timing-function: cubic-bezier(.4, 1, .75, .9);
  transform: translateX(-100%);
}

@media (forced-colors: active) and (prefers-color-scheme: dark) {
  .bp5-drawer.bp5-position-left {
    border-right: 1px solid buttonborder;
  }
}

.bp5-drawer.bp5-position-right {
  width: 50%;
  top: 0;
  bottom: 0;
  right: 0;
}

.bp5-drawer.bp5-position-right.bp5-overlay-enter, .bp5-drawer.bp5-position-right.bp5-overlay-appear {
  transform: translateX(100%);
}

.bp5-drawer.bp5-position-right.bp5-overlay-enter-active, .bp5-drawer.bp5-position-right.bp5-overlay-appear-active {
  transition-delay: 0;
  transition-property: transform;
  transition-duration: .2s;
  transition-timing-function: cubic-bezier(.4, 1, .75, .9);
  transform: translateX(0);
}

.bp5-drawer.bp5-position-right.bp5-overlay-exit {
  transform: translateX(0);
}

.bp5-drawer.bp5-position-right.bp5-overlay-exit-active {
  transition-delay: 0;
  transition-property: transform;
  transition-duration: .1s;
  transition-timing-function: cubic-bezier(.4, 1, .75, .9);
  transform: translateX(100%);
}

@media (forced-colors: active) and (prefers-color-scheme: dark) {
  .bp5-drawer.bp5-position-right {
    border-left: 1px solid buttonborder;
  }
}

.bp5-drawer.bp5-dark, .bp5-dark .bp5-drawer {
  color: #f6f7f9;
  background: #383e47;
  box-shadow: inset 0 0 0 1px #fff3, 0 2px 4px #11141866, 0 8px 24px #11141866;
}

.bp5-drawer-header {
  border-radius: 0;
  flex: none;
  align-items: center;
  min-height: 40px;
  padding: 5px 5px 5px 20px;
  display: flex;
  position: relative;
  box-shadow: 0 1px #11141826;
}

@media (forced-colors: active) and (prefers-color-scheme: dark) {
  .bp5-drawer-header {
    border-bottom: 1px solid buttonborder;
  }
}

.bp5-drawer-header .bp5-icon-large, .bp5-drawer-header .bp5-icon {
  color: #5f6b7c;
  flex: none;
  margin-right: 10px;
}

.bp5-drawer-header .bp5-heading {
  text-overflow: ellipsis;
  white-space: nowrap;
  word-wrap: normal;
  line-height: inherit;
  flex: auto;
  margin: 0;
  overflow: hidden;
}

.bp5-drawer-header .bp5-heading:last-child {
  margin-right: 20px;
}

.bp5-dark .bp5-drawer-header {
  box-shadow: 0 1px #11141866;
}

.bp5-dark .bp5-drawer-header .bp5-icon-large, .bp5-dark .bp5-drawer-header .bp5-icon {
  color: #abb3bf;
}

.bp5-drawer-body {
  flex: auto;
  line-height: 18px;
  overflow: auto;
}

.bp5-drawer-footer {
  flex: none;
  padding: 10px 20px;
  position: relative;
  box-shadow: inset 0 1px #11141826;
}

.bp5-dark .bp5-drawer-footer {
  box-shadow: inset 0 1px #11141866;
}

.bp5-editable-text {
  cursor: text;
  vertical-align: top;
  white-space: nowrap;
  max-width: 100%;
  display: inline-block;
  position: relative;
}

.bp5-editable-text:before {
  content: "";
  border-radius: 2px;
  transition: background-color .1s cubic-bezier(.4, 1, .75, .9), box-shadow .1s cubic-bezier(.4, 1, .75, .9);
  position: absolute;
  inset: -2px;
}

.bp5-editable-text:hover:before {
  box-shadow: 0 0 #2d72d200, 0 0 #2d72d200, inset 0 0 0 1px #11141826;
}

.bp5-editable-text.bp5-editable-text-editing:before {
  background-color: #fff;
  box-shadow: inset 0 0 0 1px #2d72d2, 0 0 0 2px #2d72d24d, inset 0 1px 1px #11141833;
}

@media (forced-colors: active) and (prefers-color-scheme: dark) {
  .bp5-editable-text:not(.bp5-disabled):before {
    border: 1px solid buttonborder;
  }
}

.bp5-editable-text.bp5-intent-primary .bp5-editable-text-input::-moz-placeholder {
  color: #2d72d2;
}

.bp5-editable-text.bp5-intent-primary .bp5-editable-text-input:-ms-input-placeholder {
  color: #2d72d2;
}

.bp5-editable-text.bp5-intent-primary .bp5-editable-text-content, .bp5-editable-text.bp5-intent-primary .bp5-editable-text-input, .bp5-editable-text.bp5-intent-primary .bp5-editable-text-input::placeholder {
  color: #2d72d2;
}

.bp5-editable-text.bp5-intent-primary:hover:before {
  box-shadow: 0 0 #2d72d200, 0 0 #2d72d200, inset 0 0 0 1px #2d72d266;
}

.bp5-editable-text.bp5-intent-primary.bp5-editable-text-editing:before {
  box-shadow: inset 0 0 0 1px #2d72d2, 0 0 0 2px #2d72d24d, inset 0 1px 1px #11141833;
}

.bp5-editable-text.bp5-intent-success .bp5-editable-text-input::-moz-placeholder {
  color: #238551;
}

.bp5-editable-text.bp5-intent-success .bp5-editable-text-input:-ms-input-placeholder {
  color: #238551;
}

.bp5-editable-text.bp5-intent-success .bp5-editable-text-content, .bp5-editable-text.bp5-intent-success .bp5-editable-text-input, .bp5-editable-text.bp5-intent-success .bp5-editable-text-input::placeholder {
  color: #238551;
}

.bp5-editable-text.bp5-intent-success:hover:before {
  box-shadow: 0 0 #23855100, 0 0 #23855100, inset 0 0 0 1px #23855166;
}

.bp5-editable-text.bp5-intent-success.bp5-editable-text-editing:before {
  box-shadow: inset 0 0 0 1px #238551, 0 0 0 2px #2385514d, inset 0 1px 1px #11141833;
}

.bp5-editable-text.bp5-intent-warning .bp5-editable-text-input::-moz-placeholder {
  color: #c87619;
}

.bp5-editable-text.bp5-intent-warning .bp5-editable-text-input:-ms-input-placeholder {
  color: #c87619;
}

.bp5-editable-text.bp5-intent-warning .bp5-editable-text-content, .bp5-editable-text.bp5-intent-warning .bp5-editable-text-input, .bp5-editable-text.bp5-intent-warning .bp5-editable-text-input::placeholder {
  color: #c87619;
}

.bp5-editable-text.bp5-intent-warning:hover:before {
  box-shadow: 0 0 #c8761900, 0 0 #c8761900, inset 0 0 0 1px #c8761966;
}

.bp5-editable-text.bp5-intent-warning.bp5-editable-text-editing:before {
  box-shadow: inset 0 0 0 1px #c87619, 0 0 0 2px #c876194d, inset 0 1px 1px #11141833;
}

.bp5-editable-text.bp5-intent-danger .bp5-editable-text-input::-moz-placeholder {
  color: #cd4246;
}

.bp5-editable-text.bp5-intent-danger .bp5-editable-text-input:-ms-input-placeholder {
  color: #cd4246;
}

.bp5-editable-text.bp5-intent-danger .bp5-editable-text-content, .bp5-editable-text.bp5-intent-danger .bp5-editable-text-input, .bp5-editable-text.bp5-intent-danger .bp5-editable-text-input::placeholder {
  color: #cd4246;
}

.bp5-editable-text.bp5-intent-danger:hover:before {
  box-shadow: 0 0 #cd424600, 0 0 #cd424600, inset 0 0 0 1px #cd424666;
}

.bp5-editable-text.bp5-intent-danger.bp5-editable-text-editing:before {
  box-shadow: inset 0 0 0 1px #cd4246, 0 0 0 2px #cd42464d, inset 0 1px 1px #11141833;
}

.bp5-dark .bp5-editable-text:hover:before {
  box-shadow: 0 0 #4c90f000, 0 0 #4c90f000, inset 0 0 0 1px #fff3;
}

.bp5-dark .bp5-editable-text.bp5-editable-text-editing:before {
  background-color: #1114184d;
  box-shadow: inset 0 0 0 1px #4c90f0, 0 0 0 2px #4c90f04d, inset 0 0 0 1px #fff3, inset 0 -1px 1px #ffffff4d;
}

.bp5-dark .bp5-editable-text.bp5-disabled:before {
  box-shadow: none;
}

.bp5-dark .bp5-editable-text.bp5-intent-primary .bp5-editable-text-input::-moz-placeholder {
  color: #8abbff;
}

.bp5-dark .bp5-editable-text.bp5-intent-primary .bp5-editable-text-input:-ms-input-placeholder {
  color: #8abbff;
}

.bp5-dark .bp5-editable-text.bp5-intent-primary .bp5-editable-text-content, .bp5-dark .bp5-editable-text.bp5-intent-primary .bp5-editable-text-input, .bp5-dark .bp5-editable-text.bp5-intent-primary .bp5-editable-text-input::placeholder {
  color: #8abbff;
}

.bp5-dark .bp5-editable-text.bp5-intent-primary:hover:before {
  box-shadow: 0 0 #8abbff00, 0 0 #8abbff00, inset 0 0 0 1px #8abbff66;
}

.bp5-dark .bp5-editable-text.bp5-intent-primary.bp5-editable-text-editing:before {
  box-shadow: inset 0 0 0 1px #8abbff, 0 0 0 2px #8abbff4d, inset 0 0 0 1px #fff3, inset 0 -1px 1px #ffffff4d;
}

.bp5-dark .bp5-editable-text.bp5-intent-success .bp5-editable-text-input::-moz-placeholder {
  color: #72ca9b;
}

.bp5-dark .bp5-editable-text.bp5-intent-success .bp5-editable-text-input:-ms-input-placeholder {
  color: #72ca9b;
}

.bp5-dark .bp5-editable-text.bp5-intent-success .bp5-editable-text-content, .bp5-dark .bp5-editable-text.bp5-intent-success .bp5-editable-text-input, .bp5-dark .bp5-editable-text.bp5-intent-success .bp5-editable-text-input::placeholder {
  color: #72ca9b;
}

.bp5-dark .bp5-editable-text.bp5-intent-success:hover:before {
  box-shadow: 0 0 #72ca9b00, 0 0 #72ca9b00, inset 0 0 0 1px #72ca9b66;
}

.bp5-dark .bp5-editable-text.bp5-intent-success.bp5-editable-text-editing:before {
  box-shadow: inset 0 0 0 1px #72ca9b, 0 0 0 2px #72ca9b4d, inset 0 0 0 1px #fff3, inset 0 -1px 1px #ffffff4d;
}

.bp5-dark .bp5-editable-text.bp5-intent-warning .bp5-editable-text-input::-moz-placeholder {
  color: #fbb360;
}

.bp5-dark .bp5-editable-text.bp5-intent-warning .bp5-editable-text-input:-ms-input-placeholder {
  color: #fbb360;
}

.bp5-dark .bp5-editable-text.bp5-intent-warning .bp5-editable-text-content, .bp5-dark .bp5-editable-text.bp5-intent-warning .bp5-editable-text-input, .bp5-dark .bp5-editable-text.bp5-intent-warning .bp5-editable-text-input::placeholder {
  color: #fbb360;
}

.bp5-dark .bp5-editable-text.bp5-intent-warning:hover:before {
  box-shadow: 0 0 #fbb36000, 0 0 #fbb36000, inset 0 0 0 1px #fbb36066;
}

.bp5-dark .bp5-editable-text.bp5-intent-warning.bp5-editable-text-editing:before {
  box-shadow: inset 0 0 0 1px #fbb360, 0 0 0 2px #fbb3604d, inset 0 0 0 1px #fff3, inset 0 -1px 1px #ffffff4d;
}

.bp5-dark .bp5-editable-text.bp5-intent-danger .bp5-editable-text-input::-moz-placeholder {
  color: #fa999c;
}

.bp5-dark .bp5-editable-text.bp5-intent-danger .bp5-editable-text-input:-ms-input-placeholder {
  color: #fa999c;
}

.bp5-dark .bp5-editable-text.bp5-intent-danger .bp5-editable-text-content, .bp5-dark .bp5-editable-text.bp5-intent-danger .bp5-editable-text-input, .bp5-dark .bp5-editable-text.bp5-intent-danger .bp5-editable-text-input::placeholder {
  color: #fa999c;
}

.bp5-dark .bp5-editable-text.bp5-intent-danger:hover:before {
  box-shadow: 0 0 #fa999c00, 0 0 #fa999c00, inset 0 0 0 1px #fa999c66;
}

.bp5-dark .bp5-editable-text.bp5-intent-danger.bp5-editable-text-editing:before {
  box-shadow: inset 0 0 0 1px #fa999c, 0 0 0 2px #fa999c4d, inset 0 0 0 1px #fff3, inset 0 -1px 1px #ffffff4d;
}

.bp5-editable-text.bp5-disabled:before {
  box-shadow: none !important;
}

.bp5-editable-text-input, .bp5-editable-text-content {
  color: inherit;
  display: inherit;
  font: inherit;
  letter-spacing: inherit;
  max-width: inherit;
  min-width: inherit;
  resize: none;
  text-transform: inherit;
  vertical-align: top;
  position: relative;
}

.bp5-editable-text-input {
  box-shadow: none;
  white-space: pre-wrap;
  background: none;
  border: none;
  width: 100%;
  padding: 0;
}

.bp5-editable-text-input::-moz-placeholder {
  color: #5f6b7c;
  opacity: 1;
}

.bp5-editable-text-input:-ms-input-placeholder {
  color: #5f6b7c;
  opacity: 1;
}

.bp5-editable-text-input::placeholder {
  color: #5f6b7c;
  opacity: 1;
}

.bp5-editable-text-input:focus {
  outline: none;
}

.bp5-editable-text-input::-ms-clear {
  display: none;
}

.bp5-editable-text-content {
  text-overflow: ellipsis;
  white-space: pre;
  padding-right: 2px;
  overflow: hidden;
}

.bp5-editable-text-editing > .bp5-editable-text-content {
  visibility: hidden;
  position: absolute;
  left: 0;
}

.bp5-editable-text-placeholder > .bp5-editable-text-content {
  color: #5f6b7c;
}

.bp5-dark .bp5-editable-text-placeholder > .bp5-editable-text-content {
  color: #abb3bf;
}

.bp5-editable-text.bp5-multiline {
  display: block;
}

.bp5-editable-text.bp5-multiline .bp5-editable-text-content {
  white-space: pre-wrap;
  word-wrap: break-word;
  overflow: auto;
}

.bp5-entity-title {
  align-items: center;
  gap: 7px;
  min-width: 0;
  display: flex;
}

.bp5-entity-title-icon-container.bp5-entity-title-has-subtitle {
  align-self: flex-start;
}

.bp5-entity-title-icon-container:not(.bp5-entity-title-has-subtitle) {
  align-items: center;
  display: flex;
}

.bp5-entity-title-text {
  flex-direction: column;
  display: flex;
}

.bp5-entity-title-title-and-tags {
  flex-direction: row;
  align-items: center;
  gap: 5px;
  display: flex;
}

.bp5-entity-title-tags-container {
  gap: 2px;
  margin-left: 5px;
  display: flex;
}

.bp5-entity-title-title {
  overflow-wrap: break-word;
  min-width: 0;
  margin-bottom: 0;
}

.bp5-entity-title-subtitle {
  margin-top: 2px;
  font-size: 12px;
}

.bp5-entity-title-ellipsize, .bp5-entity-title-ellipsize .bp5-entity-title-text {
  overflow: hidden;
}

.bp5-entity-title-heading-h1 .bp5-icon-container {
  align-items: center;
  height: 40px;
  display: flex;
}

.bp5-entity-title-heading-h2 .bp5-icon-container {
  align-items: center;
  height: 32px;
  display: flex;
}

.bp5-entity-title-heading-h3 .bp5-icon-container {
  align-items: center;
  height: 25px;
  display: flex;
}

.bp5-entity-title-heading-h4 .bp5-icon-container {
  align-items: center;
  height: 21px;
  display: flex;
}

.bp5-entity-title-heading-h5 .bp5-icon-container {
  align-items: center;
  height: 19px;
  display: flex;
}

.bp5-entity-title-heading-h6 .bp5-icon-container {
  align-items: center;
  height: 16px;
  display: flex;
}

.bp5-entity-title-heading-h1, .bp5-entity-title-heading-h2, .bp5-entity-title-heading-h3 {
  gap: 15px;
}

.bp5-entity-title-heading-h1 .bp5-entity-title-status-tag, .bp5-entity-title-heading-h2 .bp5-entity-title-status-tag, .bp5-entity-title-heading-h3 .bp5-entity-title-status-tag {
  margin-left: 10px;
}

.bp5-entity-title-heading-h1 .bp5-entity-title-subtitle, .bp5-entity-title-heading-h2 .bp5-entity-title-subtitle, .bp5-entity-title-heading-h3 .bp5-entity-title-subtitle {
  font-size: 14px;
}

.bp5-entity-title-heading-h4, .bp5-entity-title-heading-h5, .bp5-entity-title-heading-h6 {
  gap: 10px;
}

.bp5-entity-title-heading-h4 .bp5-entity-title-subtitle, .bp5-entity-title-heading-h5 .bp5-entity-title-subtitle, .bp5-entity-title-heading-h6 .bp5-entity-title-subtitle {
  font-size: 12px;
}

.bp5-divider {
  border-bottom: 1px solid #11141826;
  border-right: 1px solid #11141826;
  margin: 5px;
}

.bp5-dark .bp5-divider {
  border-color: #fff3;
}

.bp5-control-group {
  flex-direction: row;
  align-items: stretch;
  display: flex;
  transform: translateZ(0);
}

.bp5-control-group > * {
  flex-grow: 0;
  flex-shrink: 0;
}

.bp5-control-group > .bp5-fill {
  flex-grow: 1;
  flex-shrink: 1;
}

.bp5-control-group .bp5-button, .bp5-control-group .bp5-html-select, .bp5-control-group .bp5-input, .bp5-control-group .bp5-select {
  position: relative;
}

.bp5-control-group .bp5-input {
  z-index: 2;
}

.bp5-control-group .bp5-input:focus {
  z-index: 14;
}

.bp5-control-group .bp5-input[class*="bp5-intent"] {
  z-index: 13;
}

.bp5-control-group .bp5-input[class*="bp5-intent"]:focus {
  z-index: 15;
}

.bp5-control-group .bp5-input[readonly], .bp5-control-group .bp5-input:disabled, .bp5-control-group .bp5-input.bp5-disabled {
  z-index: 1;
}

.bp5-control-group .bp5-input-group[class*="bp5-intent"] .bp5-input {
  z-index: 13;
}

.bp5-control-group .bp5-input-group[class*="bp5-intent"] .bp5-input:focus {
  z-index: 15;
}

.bp5-control-group .bp5-button, .bp5-control-group .bp5-html-select select, .bp5-control-group .bp5-select select {
  z-index: 4;
  transform: translateZ(0);
}

.bp5-control-group .bp5-button:focus, .bp5-control-group .bp5-html-select select:focus, .bp5-control-group .bp5-select select:focus {
  z-index: 5;
}

.bp5-control-group .bp5-button:hover, .bp5-control-group .bp5-html-select select:hover, .bp5-control-group .bp5-select select:hover {
  z-index: 6;
}

.bp5-control-group .bp5-button:active, .bp5-control-group .bp5-html-select select:active, .bp5-control-group .bp5-select select:active {
  z-index: 7;
}

.bp5-control-group .bp5-button[readonly], .bp5-control-group .bp5-button:disabled, .bp5-control-group .bp5-button.bp5-disabled, .bp5-control-group .bp5-html-select select[readonly], .bp5-control-group .bp5-html-select select:disabled, .bp5-control-group .bp5-html-select select.bp5-disabled, .bp5-control-group .bp5-select select[readonly], .bp5-control-group .bp5-select select:disabled, .bp5-control-group .bp5-select select.bp5-disabled {
  z-index: 3;
}

.bp5-control-group .bp5-button[class*="bp5-intent"], .bp5-control-group .bp5-html-select select[class*="bp5-intent"], .bp5-control-group .bp5-select select[class*="bp5-intent"] {
  z-index: 9;
}

.bp5-control-group .bp5-button[class*="bp5-intent"]:focus, .bp5-control-group .bp5-html-select select[class*="bp5-intent"]:focus, .bp5-control-group .bp5-select select[class*="bp5-intent"]:focus {
  z-index: 10;
}

.bp5-control-group .bp5-button[class*="bp5-intent"]:hover, .bp5-control-group .bp5-html-select select[class*="bp5-intent"]:hover, .bp5-control-group .bp5-select select[class*="bp5-intent"]:hover {
  z-index: 11;
}

.bp5-control-group .bp5-button[class*="bp5-intent"]:active, .bp5-control-group .bp5-html-select select[class*="bp5-intent"]:active, .bp5-control-group .bp5-select select[class*="bp5-intent"]:active {
  z-index: 12;
}

.bp5-control-group .bp5-button[class*="bp5-intent"][readonly], .bp5-control-group .bp5-button[class*="bp5-intent"]:disabled, .bp5-control-group .bp5-button[class*="bp5-intent"].bp5-disabled, .bp5-control-group .bp5-html-select select[class*="bp5-intent"][readonly], .bp5-control-group .bp5-html-select select[class*="bp5-intent"]:disabled, .bp5-control-group .bp5-html-select select[class*="bp5-intent"].bp5-disabled, .bp5-control-group .bp5-select select[class*="bp5-intent"][readonly], .bp5-control-group .bp5-select select[class*="bp5-intent"]:disabled, .bp5-control-group .bp5-select select[class*="bp5-intent"].bp5-disabled {
  z-index: 8;
}

.bp5-control-group .bp5-input-group > .bp5-icon, .bp5-control-group .bp5-input-group > .bp5-button, .bp5-control-group .bp5-input-group > .bp5-input-left-container, .bp5-control-group .bp5-input-group > .bp5-input-action {
  z-index: 16;
}

.bp5-control-group .bp5-select:after, .bp5-control-group .bp5-html-select:after, .bp5-control-group .bp5-select > .bp5-icon, .bp5-control-group .bp5-html-select > .bp5-icon {
  z-index: 17;
}

.bp5-control-group .bp5-html-select:focus-within, .bp5-control-group .bp5-select:focus-within {
  z-index: 5;
}

.bp5-control-group:not(.bp5-vertical) > :not(:last-child) {
  margin-right: 2px;
}

.bp5-control-group .bp5-numeric-input:not(:first-child) .bp5-input-group {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.bp5-control-group.bp5-fill {
  width: 100%;
}

.bp5-control-group > .bp5-fill, .bp5-control-group.bp5-fill > :not(.bp5-fixed) {
  flex: auto;
}

.bp5-control-group.bp5-vertical {
  flex-direction: column;
}

.bp5-control-group.bp5-vertical > :not(:last-child) {
  margin-bottom: 2px;
}

.bp5-control {
  cursor: pointer;
  text-transform: none;
  margin-bottom: 10px;
  display: block;
  position: relative;
}

.bp5-control input:checked ~ .bp5-control-indicator {
  color: #fff;
  background-color: #2d72d2;
  box-shadow: inset 0 0 0 1px #11141833;
}

@media (forced-colors: active) and (prefers-color-scheme: dark) {
  .bp5-control input:checked ~ .bp5-control-indicator {
    background-color: highlight;
    border: 1px solid highlight;
  }
}

.bp5-control:hover input:checked ~ .bp5-control-indicator {
  background-color: #215db0;
}

@media (forced-colors: active) and (prefers-color-scheme: dark) {
  .bp5-control:hover input:checked ~ .bp5-control-indicator {
    background-color: highlight;
  }
}

.bp5-control input:not(:disabled):active:checked ~ .bp5-control-indicator {
  background: #184a90;
}

.bp5-control input:disabled:checked ~ .bp5-control-indicator {
  box-shadow: none;
  color: #fff9;
  background: #2d72d280;
}

@media (forced-colors: active) and (prefers-color-scheme: dark) {
  .bp5-control input:disabled:checked ~ .bp5-control-indicator {
    background-color: graytext;
    border-color: graytext;
  }
}

.bp5-dark .bp5-control input:checked ~ .bp5-control-indicator {
  box-shadow: inset 0 0 0 1px #ffffff1a;
}

@media (forced-colors: active) and (prefers-color-scheme: dark) {
  .bp5-dark .bp5-control input:checked ~ .bp5-control-indicator {
    border: 1px solid buttonborder;
  }
}

.bp5-dark .bp5-control:hover input:checked ~ .bp5-control-indicator {
  background-color: #215db0;
  box-shadow: inset 0 0 0 1px #ffffff1a;
}

.bp5-dark .bp5-control input:not(:disabled):active:checked ~ .bp5-control-indicator {
  background-color: #184a90;
  box-shadow: inset 0 0 0 1px #ffffff1a;
}

.bp5-dark .bp5-control input:disabled:checked ~ .bp5-control-indicator {
  box-shadow: none;
  color: #fff9;
  background: #2d72d280;
}

.bp5-control:not(.bp5-align-right) {
  padding-left: 26px;
}

.bp5-control:not(.bp5-align-right) .bp5-control-indicator {
  margin-left: -26px;
}

.bp5-control.bp5-align-right {
  padding-right: 26px;
}

.bp5-control.bp5-align-right .bp5-control-indicator {
  margin-right: -26px;
}

.bp5-control.bp5-disabled {
  color: #5f6b7c99;
  cursor: not-allowed;
}

.bp5-control.bp5-inline {
  margin-right: 20px;
  display: inline-block;
}

.bp5-control input {
  opacity: 0;
  z-index: -1;
  position: absolute;
  top: 0;
  left: 0;
}

.bp5-control .bp5-control-indicator {
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  vertical-align: middle;
  background-color: #0000;
  background-clip: padding-box;
  border: none;
  width: 1em;
  height: 1em;
  margin-top: -3px;
  margin-right: 10px;
  font-size: 16px;
  display: inline-block;
  position: relative;
  box-shadow: inset 0 0 0 1px #738091;
}

.bp5-control .bp5-control-indicator:before {
  content: "";
  width: 1em;
  height: 1em;
  display: block;
}

@media (forced-colors: active) and (prefers-color-scheme: dark) {
  .bp5-control .bp5-control-indicator {
    border: 1px solid buttonborder;
  }

  .bp5-control .bp5-control-indicator:before {
    margin-top: -1px;
    margin-left: -1px;
  }
}

.bp5-control:hover .bp5-control-indicator {
  background-color: #8f99a826;
}

.bp5-control input:not(:disabled):active ~ .bp5-control-indicator {
  background: #8f99a84d;
  box-shadow: inset 0 0 0 1px #738091;
}

.bp5-control input:disabled ~ .bp5-control-indicator {
  box-shadow: none;
  cursor: not-allowed;
  background: #8f99a826;
}

.bp5-control.bp5-align-right .bp5-control-indicator {
  float: right;
  margin-top: 1px;
  margin-left: 10px;
}

.bp5-control.bp5-large {
  font-size: 16px;
}

.bp5-control.bp5-large:not(.bp5-align-right) {
  padding-left: 30px;
}

.bp5-control.bp5-large:not(.bp5-align-right) .bp5-control-indicator {
  margin-left: -30px;
}

.bp5-control.bp5-large.bp5-align-right {
  padding-right: 30px;
}

.bp5-control.bp5-large.bp5-align-right .bp5-control-indicator {
  margin-right: -30px;
}

.bp5-control.bp5-large .bp5-control-indicator {
  font-size: 20px;
}

.bp5-control.bp5-large.bp5-align-right .bp5-control-indicator {
  margin-top: 0;
}

.bp5-control.bp5-checkbox input:indeterminate ~ .bp5-control-indicator {
  color: #fff;
  background-color: #2d72d2;
  box-shadow: inset 0 0 0 1px #11141833;
}

@media (forced-colors: active) and (prefers-color-scheme: dark) {
  .bp5-control.bp5-checkbox input:indeterminate ~ .bp5-control-indicator {
    background-color: highlight;
    border: 1px solid highlight;
  }
}

.bp5-control.bp5-checkbox:hover input:indeterminate ~ .bp5-control-indicator {
  background-color: #215db0;
}

@media (forced-colors: active) and (prefers-color-scheme: dark) {
  .bp5-control.bp5-checkbox:hover input:indeterminate ~ .bp5-control-indicator {
    background-color: highlight;
  }
}

.bp5-control.bp5-checkbox input:not(:disabled):active:indeterminate ~ .bp5-control-indicator {
  background: #184a90;
}

.bp5-control.bp5-checkbox input:disabled:indeterminate ~ .bp5-control-indicator {
  box-shadow: none;
  color: #fff9;
  background: #2d72d280;
}

@media (forced-colors: active) and (prefers-color-scheme: dark) {
  .bp5-control.bp5-checkbox input:disabled:indeterminate ~ .bp5-control-indicator {
    background-color: graytext;
    border-color: graytext;
  }
}

.bp5-dark .bp5-control.bp5-checkbox input:indeterminate ~ .bp5-control-indicator {
  box-shadow: inset 0 0 0 1px #ffffff1a;
}

@media (forced-colors: active) and (prefers-color-scheme: dark) {
  .bp5-dark .bp5-control.bp5-checkbox input:indeterminate ~ .bp5-control-indicator {
    border: 1px solid buttonborder;
  }
}

.bp5-dark .bp5-control.bp5-checkbox:hover input:indeterminate ~ .bp5-control-indicator {
  background-color: #215db0;
  box-shadow: inset 0 0 0 1px #ffffff1a;
}

.bp5-dark .bp5-control.bp5-checkbox input:not(:disabled):active:indeterminate ~ .bp5-control-indicator {
  background-color: #184a90;
  box-shadow: inset 0 0 0 1px #ffffff1a;
}

.bp5-dark .bp5-control.bp5-checkbox input:disabled:indeterminate ~ .bp5-control-indicator {
  box-shadow: none;
  color: #fff9;
  background: #2d72d280;
}

.bp5-control.bp5-checkbox .bp5-control-indicator {
  border-radius: 2px;
}

.bp5-control.bp5-checkbox input:checked ~ .bp5-control-indicator:before {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='white'/%3e%3c/svg%3e");
}

.bp5-control.bp5-checkbox input:indeterminate ~ .bp5-control-indicator:before {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M11 7H5c-.55 0-1 .45-1 1s.45 1 1 1h6c.55 0 1-.45 1-1s-.45-1-1-1z' fill='white'/%3e%3c/svg%3e");
}

@media (forced-colors: active) and (prefers-color-scheme: dark) {
  .bp5-control.bp5-checkbox input:checked:not(:disabled) ~ .bp5-control-indicator:before {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23111418'/%3e%3c/svg%3e");
  }

  .bp5-control.bp5-checkbox input:indeterminate:not(:disabled) ~ .bp5-control-indicator:before {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M11 7H5c-.55 0-1 .45-1 1s.45 1 1 1h6c.55 0 1-.45 1-1s-.45-1-1-1z' fill='%23111418'/%3e%3c/svg%3e");
  }

  .bp5-control.bp5-checkbox input:disabled ~ .bp5-control-indicator {
    border-color: graytext;
  }
}

.bp5-control.bp5-radio .bp5-control-indicator {
  border-radius: 50%;
}

.bp5-control.bp5-radio input:checked ~ .bp5-control-indicator:before {
  background-image: radial-gradient(#fff, #fff 28%, #0000 32%);
}

@media (forced-colors: active) and (prefers-color-scheme: dark) {
  .bp5-control.bp5-radio input:checked ~ .bp5-control-indicator:before {
    background: highlight;
    width: 12px;
    height: 12px;
    margin-top: 1px;
    margin-left: 1px;
  }
}

.bp5-control.bp5-radio input:checked:disabled ~ .bp5-control-indicator:before {
  opacity: .5;
}

@media (forced-colors: active) and (prefers-color-scheme: dark) {
  .bp5-control.bp5-radio input:checked:disabled ~ .bp5-control-indicator:before {
    background: graytext;
  }
}

.bp5-control.bp5-radio input:focus ~ .bp5-control-indicator {
  -moz-outline-radius: 16px;
}

@media (forced-colors: active) and (prefers-color-scheme: dark) {
  .bp5-control.bp5-radio input:disabled ~ .bp5-control-indicator {
    border-color: graytext;
  }
}

.bp5-control.bp5-switch input ~ .bp5-control-indicator {
  color: #1c2127;
  background: #8f99a84d;
}

.bp5-control.bp5-switch:hover input ~ .bp5-control-indicator {
  background: #8f99a866;
}

.bp5-control.bp5-switch input:not(:disabled):active ~ .bp5-control-indicator {
  background: #8f99a880;
}

.bp5-control.bp5-switch input:disabled ~ .bp5-control-indicator {
  color: #5f6b7c99;
  background: #8f99a826;
}

.bp5-control.bp5-switch input:disabled ~ .bp5-control-indicator:before {
  box-shadow: none;
  background: #fffc;
}

.bp5-control.bp5-switch input:checked ~ .bp5-control-indicator {
  color: #fff;
  background: #2d72d2;
}

.bp5-control.bp5-switch:hover input:checked ~ .bp5-control-indicator {
  background: #215db0;
}

.bp5-control.bp5-switch input:checked:not(:disabled):active ~ .bp5-control-indicator {
  background: #184a90;
}

.bp5-control.bp5-switch input:checked:disabled ~ .bp5-control-indicator {
  color: #fff9;
  background: #2d72d280;
}

.bp5-control.bp5-switch input:checked:disabled ~ .bp5-control-indicator:before {
  box-shadow: none;
  background: #ffffff80;
}

.bp5-control.bp5-switch:not(.bp5-align-right) {
  padding-left: 38px;
}

.bp5-control.bp5-switch:not(.bp5-align-right) .bp5-control-indicator {
  margin-left: -38px;
}

.bp5-control.bp5-switch.bp5-align-right {
  padding-right: 38px;
}

.bp5-control.bp5-switch.bp5-align-right .bp5-control-indicator {
  margin-right: -38px;
}

@media (forced-colors: active) and (prefers-color-scheme: dark) {
  .bp5-control.bp5-switch input:checked ~ .bp5-control-indicator {
    background: highlight;
    border: 1px solid buttonborder;
  }

  .bp5-control.bp5-switch input:checked:disabled ~ .bp5-control-indicator {
    background-color: graytext;
  }

  .bp5-control.bp5-switch input:not(:checked):disabled ~ .bp5-control-indicator, .bp5-control.bp5-switch input:not(:checked):disabled ~ .bp5-control-indicator:before {
    border-color: graytext;
  }

  .bp5-control.bp5-switch:hover input:checked ~ .bp5-control-indicator {
    background: highlight;
  }
}

.bp5-control.bp5-switch .bp5-control-indicator {
  border: none;
  border-radius: 1.75em;
  width: auto;
  min-width: 1.75em;
  transition: background-color .1s cubic-bezier(.4, 1, .75, .9);
  box-shadow: none !important;
}

.bp5-control.bp5-switch .bp5-control-indicator:before {
  background: #fff;
  border-radius: 50%;
  width: calc(1em - 4px);
  height: calc(1em - 4px);
  margin: 2px;
  transition: left .1s cubic-bezier(.4, 1, .75, .9);
  position: absolute;
  left: 0;
  box-shadow: 0 0 0 1px #11141880;
}

@media (forced-colors: active) and (prefers-color-scheme: dark) {
  .bp5-control.bp5-switch .bp5-control-indicator:before {
    border: 1px solid buttonborder;
    margin-top: 1px;
  }

  .bp5-control.bp5-switch .bp5-control-indicator {
    border: 1px solid buttonborder;
  }
}

.bp5-control.bp5-switch input:checked ~ .bp5-control-indicator:before {
  left: calc(100% - 1em);
}

.bp5-control.bp5-switch.bp5-large:not(.bp5-align-right) {
  padding-left: 45px;
}

.bp5-control.bp5-switch.bp5-large:not(.bp5-align-right) .bp5-control-indicator {
  margin-left: -45px;
}

.bp5-control.bp5-switch.bp5-large.bp5-align-right {
  padding-right: 45px;
}

.bp5-control.bp5-switch.bp5-large.bp5-align-right .bp5-control-indicator {
  margin-right: -45px;
}

.bp5-dark .bp5-control.bp5-switch input ~ .bp5-control-indicator {
  color: #f6f7f9;
  background: #11141880;
}

.bp5-dark .bp5-control.bp5-switch:hover input ~ .bp5-control-indicator {
  background: #111418cc;
}

.bp5-dark .bp5-control.bp5-switch input:not(:disabled):active ~ .bp5-control-indicator {
  background: #111418e6;
}

.bp5-dark .bp5-control.bp5-switch input:disabled ~ .bp5-control-indicator {
  color: #abb3bf99;
  background: #8f99a826;
}

.bp5-dark .bp5-control.bp5-switch input:disabled ~ .bp5-control-indicator:before {
  box-shadow: none;
  background: #abb3bf80;
}

.bp5-dark .bp5-control.bp5-switch input:checked ~ .bp5-control-indicator {
  color: #fff;
  background: #2d72d2;
}

.bp5-dark .bp5-control.bp5-switch:hover input:checked ~ .bp5-control-indicator {
  background: #215db0;
}

.bp5-dark .bp5-control.bp5-switch input:checked:not(:disabled):active ~ .bp5-control-indicator {
  background: #184a90;
}

.bp5-dark .bp5-control.bp5-switch input:checked:disabled ~ .bp5-control-indicator {
  color: #abb3bf99;
  background: #2d72d280;
}

.bp5-dark .bp5-control.bp5-switch input:checked:disabled ~ .bp5-control-indicator:before {
  box-shadow: none;
  background: #ffffff4d;
}

.bp5-dark .bp5-control.bp5-switch .bp5-control-indicator:before {
  background: #abb3bf;
}

.bp5-dark .bp5-control.bp5-switch input:checked ~ .bp5-control-indicator:before {
  background: #fff;
}

@media (forced-colors: active) and (prefers-color-scheme: dark) {
  .bp5-dark .bp5-control.bp5-switch input:checked ~ .bp5-control-indicator {
    background: highlight;
    border: 1px solid buttonborder;
  }

  .bp5-dark .bp5-control.bp5-switch input:checked:disabled ~ .bp5-control-indicator {
    background-color: graytext;
  }

  .bp5-dark .bp5-control.bp5-switch input:not(:checked):disabled ~ .bp5-control-indicator, .bp5-dark .bp5-control.bp5-switch input:not(:checked):disabled ~ .bp5-control-indicator:before {
    border-color: graytext;
  }

  .bp5-dark .bp5-control.bp5-switch:hover input:checked ~ .bp5-control-indicator {
    background: highlight;
  }
}

.bp5-control.bp5-switch .bp5-switch-inner-text {
  text-align: center;
  font-size: .7em;
}

.bp5-control.bp5-switch .bp5-control-indicator-child:first-child {
  visibility: hidden;
  margin-left: .5em;
  margin-right: 1.2em;
  line-height: 0;
}

.bp5-control.bp5-switch .bp5-control-indicator-child:last-child {
  visibility: visible;
  margin-left: 1.2em;
  margin-right: .5em;
  line-height: 1em;
}

.bp5-control.bp5-switch input:checked ~ .bp5-control-indicator .bp5-control-indicator-child:first-child {
  visibility: visible;
  line-height: 1em;
}

.bp5-control.bp5-switch input:checked ~ .bp5-control-indicator .bp5-control-indicator-child:last-child {
  visibility: hidden;
  line-height: 0;
}

.bp5-dark .bp5-control {
  color: #f6f7f9;
}

.bp5-dark .bp5-control.bp5-disabled {
  color: #abb3bf99;
}

.bp5-dark .bp5-control .bp5-control-indicator {
  background-color: #0000;
  box-shadow: inset 0 0 0 1px #8f99a8;
}

.bp5-dark .bp5-control:hover .bp5-control-indicator {
  background-color: #8f99a826;
}

.bp5-dark .bp5-control input:focus ~ .bp5-control-indicator {
  outline: 2px solid #8abbff;
}

.bp5-dark .bp5-control input:not(:disabled):active ~ .bp5-control-indicator {
  background: #8f99a84d;
  box-shadow: inset 0 0 0 1px #8f99a8;
}

.bp5-dark .bp5-control input:disabled ~ .bp5-control-indicator {
  box-shadow: none;
  cursor: not-allowed;
  background: #8f99a826;
}

.bp5-dark .bp5-control.bp5-checkbox input:disabled:checked ~ .bp5-control-indicator, .bp5-dark .bp5-control.bp5-checkbox input:disabled:indeterminate ~ .bp5-control-indicator {
  background: #2d72d280;
}

.bp5-file-input {
  cursor: pointer;
  height: 30px;
  display: inline-block;
  position: relative;
}

.bp5-file-input input {
  opacity: 0;
  min-width: 200px;
  margin: 0;
}

.bp5-file-input input:disabled + .bp5-file-upload-input, .bp5-file-input input.bp5-disabled + .bp5-file-upload-input {
  box-shadow: none;
  color: #5f6b7c99;
  cursor: not-allowed;
  resize: none;
  background: #d3d8de80;
}

.bp5-file-input input:disabled + .bp5-file-upload-input::-moz-placeholder {
  color: #5f6b7c99;
}

.bp5-file-input input.bp5-disabled + .bp5-file-upload-input::-moz-placeholder {
  color: #5f6b7c99;
}

:is(.bp5-file-input input:disabled + .bp5-file-upload-input:-ms-input-placeholder, .bp5-file-input input.bp5-disabled + .bp5-file-upload-input:-ms-input-placeholder), .bp5-file-input input:disabled + .bp5-file-upload-input::placeholder, .bp5-file-input input.bp5-disabled + .bp5-file-upload-input::placeholder {
  color: #5f6b7c99;
}

.bp5-file-input input:disabled + .bp5-file-upload-input:after, .bp5-file-input input.bp5-disabled + .bp5-file-upload-input:after {
  box-shadow: none;
  color: #5f6b7c99;
  cursor: not-allowed;
  background-color: #d3d8de80;
  outline: none;
}

.bp5-dark .bp5-file-input input:disabled + .bp5-file-upload-input, .bp5-dark .bp5-file-input input.bp5-disabled + .bp5-file-upload-input {
  box-shadow: none;
  color: #abb3bf99;
  background: #40485480;
}

.bp5-dark .bp5-file-input input:disabled + .bp5-file-upload-input:after, .bp5-dark .bp5-file-input input.bp5-disabled + .bp5-file-upload-input:after {
  box-shadow: none;
  color: #abb3bf99;
  background-color: #40485480;
}

.bp5-file-input.bp5-file-input-has-selection .bp5-file-upload-input {
  color: #1c2127;
}

.bp5-dark .bp5-file-input.bp5-file-input-has-selection .bp5-file-upload-input {
  color: #f6f7f9;
}

.bp5-file-input.bp5-fill {
  width: 100%;
}

.bp5-file-input.bp5-large, .bp5-large .bp5-file-input {
  height: 40px;
}

.bp5-file-input.bp5-small, .bp5-small .bp5-file-input {
  height: 24px;
}

.bp5-file-input .bp5-file-upload-input-custom-text:after {
  content: attr(bp5-button-text);
}

.bp5-file-upload-input {
  -webkit-appearance: none;
  appearance: none;
  color: #5f6b7c99;
  vertical-align: middle;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-wrap: normal;
  -webkit-user-select: none;
  user-select: none;
  background: #fff;
  border: none;
  border-radius: 2px;
  outline: none;
  height: 30px;
  padding: 0 80px 0 10px;
  font-size: 14px;
  font-weight: 400;
  line-height: 30px;
  transition: box-shadow .1s cubic-bezier(.4, 1, .75, .9);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  overflow: hidden;
  box-shadow: 0 0 #2d72d200, 0 0 #2d72d200, inset 0 0 0 1px #11141833, inset 0 1px 1px #1114184d;
}

.bp5-file-upload-input::-moz-placeholder {
  color: #5f6b7c;
  opacity: 1;
}

.bp5-file-upload-input:-ms-input-placeholder {
  color: #5f6b7c;
  opacity: 1;
}

.bp5-file-upload-input::placeholder {
  color: #5f6b7c;
  opacity: 1;
}

.bp5-file-upload-input:focus, .bp5-file-upload-input.bp5-active {
  box-shadow: inset 0 0 0 1px #2d72d2, 0 0 0 2px #2d72d24d, inset 0 1px 1px #11141833;
}

.bp5-file-upload-input[type="search"], .bp5-file-upload-input.bp5-round {
  box-sizing: border-box;
  border-radius: 30px;
  padding-left: 10px;
}

.bp5-file-upload-input[readonly] {
  box-shadow: inset 0 0 0 1px #11141826;
}

.bp5-file-upload-input:disabled, .bp5-file-upload-input.bp5-disabled {
  box-shadow: none;
  color: #5f6b7c99;
  cursor: not-allowed;
  resize: none;
  background: #d3d8de80;
}

.bp5-file-upload-input:disabled::-moz-placeholder {
  color: #5f6b7c99;
}

.bp5-file-upload-input.bp5-disabled::-moz-placeholder {
  color: #5f6b7c99;
}

:is(.bp5-file-upload-input:disabled:-ms-input-placeholder, .bp5-file-upload-input.bp5-disabled:-ms-input-placeholder), .bp5-file-upload-input:disabled::placeholder, .bp5-file-upload-input.bp5-disabled::placeholder {
  color: #5f6b7c99;
}

@media (forced-colors: active) and (prefers-color-scheme: dark) {
  .bp5-file-upload-input {
    border: 1px solid buttonborder;
  }
}

.bp5-file-upload-input:after {
  color: #1c2127;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-wrap: normal;
  content: "Browse";
  text-align: center;
  background-color: #f6f7f9;
  border-radius: 2px;
  width: 70px;
  min-width: 24px;
  min-height: 24px;
  margin: 3px;
  line-height: 24px;
  position: absolute;
  top: 0;
  right: 0;
  overflow: hidden;
  box-shadow: inset 0 0 0 1px #11141833, 0 1px 2px #1114181a;
}

.bp5-file-upload-input:hover:after {
  background-color: #edeff2;
  background-clip: padding-box;
  box-shadow: inset 0 0 0 1px #11141833, 0 1px 2px #11141833;
}

.bp5-file-upload-input:active:after {
  background-color: #dce0e5;
  box-shadow: inset 0 0 0 1px #11141833, 0 1px 2px #11141833;
}

@media (forced-colors: active) and (prefers-color-scheme: dark) {
  .bp5-file-upload-input:active:after {
    background: highlight;
  }
}

.bp5-large .bp5-file-upload-input {
  height: 40px;
  padding-right: 95px;
  font-size: 16px;
  line-height: 40px;
}

.bp5-large .bp5-file-upload-input[type="search"], .bp5-large .bp5-file-upload-input.bp5-round {
  padding: 0 15px;
}

.bp5-large .bp5-file-upload-input:after {
  width: 85px;
  min-width: 30px;
  min-height: 30px;
  margin: 5px;
  line-height: 30px;
}

.bp5-small .bp5-file-upload-input {
  height: 24px;
  padding-left: 8px;
  padding-right: 65px;
  font-size: 12px;
  line-height: 24px;
}

.bp5-small .bp5-file-upload-input[type="search"], .bp5-small .bp5-file-upload-input.bp5-round {
  padding: 0 12px;
}

.bp5-small .bp5-file-upload-input:after {
  width: 55px;
  min-width: 20px;
  min-height: 20px;
  margin: 2px;
  line-height: 20px;
}

.bp5-dark .bp5-file-upload-input {
  color: #abb3bf99;
  background: #1114184d;
  box-shadow: 0 0 #4c90f000, 0 0 #4c90f000, 0 0 #4c90f000, inset 0 0 0 1px #fff3, inset 0 -1px 1px #ffffff4d;
}

.bp5-dark .bp5-file-upload-input::-moz-placeholder {
  color: #abb3bf;
}

.bp5-dark .bp5-file-upload-input:-ms-input-placeholder {
  color: #abb3bf;
}

.bp5-dark .bp5-file-upload-input::placeholder {
  color: #abb3bf;
}

.bp5-dark .bp5-file-upload-input:focus {
  box-shadow: inset 0 0 0 1px #4c90f0, inset 0 0 0 1px #4c90f0, 0 0 0 2px #4c90f04d;
}

.bp5-dark .bp5-file-upload-input[readonly] {
  box-shadow: inset 0 0 0 1px #11141866;
}

.bp5-dark .bp5-file-upload-input:disabled, .bp5-dark .bp5-file-upload-input.bp5-disabled {
  box-shadow: none;
  color: #abb3bf99;
  background: #40485480;
}

.bp5-dark .bp5-file-upload-input:after {
  color: #f6f7f9;
  background-color: #383e47;
  box-shadow: inset 0 0 0 1px #ffffff1a, 0 1px 2px #11141833;
}

.bp5-dark .bp5-file-upload-input:hover:after {
  background-color: #2f343c;
  box-shadow: inset 0 0 0 1px #ffffff1a, 0 1px 2px #11141866;
}

.bp5-dark .bp5-file-upload-input:active:after {
  background-color: #1c2127;
  box-shadow: inset 0 0 0 1px #ffffff1a, 0 1px 2px #11141866;
}

.bp5-file-upload-input:after {
  box-shadow: inset 0 0 0 1px #11141833, 0 1px 2px #1114181a;
}

.bp5-form-group {
  flex-direction: column;
  margin: 0 0 15px;
  display: flex;
}

.bp5-form-group label.bp5-label {
  margin-bottom: 5px;
  font-weight: 500;
}

.bp5-form-group .bp5-control {
  margin-top: 7px;
}

.bp5-form-group .bp5-form-group-sub-label, .bp5-form-group .bp5-form-helper-text {
  color: #5f6b7c;
  font-size: 12px;
}

.bp5-form-group .bp5-form-group-sub-label {
  margin-bottom: 5px;
}

.bp5-form-group .bp5-form-helper-text {
  margin-top: 5px;
}

.bp5-form-group.bp5-intent-primary .bp5-form-helper-text {
  color: #215db0;
}

.bp5-form-group.bp5-intent-success .bp5-form-helper-text {
  color: #1c6e42;
}

.bp5-form-group.bp5-intent-warning .bp5-form-helper-text {
  color: #935610;
}

.bp5-form-group.bp5-intent-danger .bp5-form-helper-text {
  color: #ac2f33;
}

.bp5-form-group.bp5-fill {
  width: 100%;
}

.bp5-form-group.bp5-inline {
  flex-direction: row;
  align-items: flex-start;
}

.bp5-form-group.bp5-inline.bp5-large label.bp5-label {
  margin: 0 10px 0 0;
  line-height: 40px;
}

.bp5-form-group.bp5-inline label.bp5-label {
  margin: 0 10px 0 0;
  line-height: 30px;
}

.bp5-form-group.bp5-disabled .bp5-label, .bp5-form-group.bp5-disabled .bp5-text-muted, .bp5-form-group.bp5-disabled .bp5-form-group-sub-label, .bp5-form-group.bp5-disabled .bp5-form-helper-text {
  color: #5f6b7c99 !important;
}

.bp5-dark .bp5-form-group.bp5-intent-primary .bp5-form-helper-text {
  color: #8abbff;
}

.bp5-dark .bp5-form-group.bp5-intent-success .bp5-form-helper-text {
  color: #72ca9b;
}

.bp5-dark .bp5-form-group.bp5-intent-warning .bp5-form-helper-text {
  color: #fbb360;
}

.bp5-dark .bp5-form-group.bp5-intent-danger .bp5-form-helper-text {
  color: #fa999c;
}

.bp5-dark .bp5-form-group .bp5-form-group-sub-label, .bp5-dark .bp5-form-group .bp5-form-helper-text {
  color: #abb3bf;
}

.bp5-dark .bp5-form-group.bp5-disabled .bp5-label, .bp5-dark .bp5-form-group.bp5-disabled .bp5-text-muted, .bp5-dark .bp5-form-group.bp5-disabled .bp5-form-group-sub-label, .bp5-dark .bp5-form-group.bp5-disabled .bp5-form-helper-text {
  color: #abb3bf99 !important;
}

.bp5-input-group {
  display: block;
  position: relative;
}

.bp5-input-group .bp5-input {
  width: 100%;
  position: relative;
}

.bp5-input-group .bp5-input:not(:first-child) {
  padding-left: 30px;
}

.bp5-input-group .bp5-input:not(:last-child) {
  padding-right: 30px;
}

.bp5-input-group .bp5-input-action, .bp5-input-group > .bp5-input-left-container, .bp5-input-group > .bp5-button, .bp5-input-group > .bp5-icon {
  position: absolute;
  top: 0;
}

.bp5-input-group .bp5-input-action:first-child, .bp5-input-group > .bp5-input-left-container:first-child, .bp5-input-group > .bp5-button:first-child, .bp5-input-group > .bp5-icon:first-child {
  left: 0;
}

.bp5-input-group .bp5-input-action:last-child, .bp5-input-group > .bp5-input-left-container:last-child, .bp5-input-group > .bp5-button:last-child, .bp5-input-group > .bp5-icon:last-child {
  right: 0;
}

.bp5-input-group .bp5-button {
  min-width: 24px;
  min-height: 24px;
  margin: 3px;
  padding: 0 7px;
}

.bp5-input-group .bp5-button:empty {
  padding: 0;
}

.bp5-input-group > .bp5-input-left-container, .bp5-input-group > .bp5-icon {
  z-index: 1;
}

.bp5-input-group > .bp5-input-left-container > .bp5-icon, .bp5-input-group > .bp5-icon {
  color: #5f6b7c;
}

.bp5-input-group > .bp5-input-left-container > .bp5-icon:empty, .bp5-input-group > .bp5-icon:empty {
  font-variant: normal;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  width: 16px;
  height: 16px;
  font-family: blueprint-icons-16, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 1;
}

.bp5-input-group > .bp5-input-left-container > .bp5-icon, .bp5-input-group > .bp5-icon, .bp5-input-group .bp5-input-action > .bp5-spinner {
  margin: 7px;
}

.bp5-input-group .bp5-tag {
  margin: 5px;
}

.bp5-input-group .bp5-input:not(:focus) + .bp5-button.bp5-minimal:not(:hover):not(:focus), .bp5-input-group .bp5-input:not(:focus) + .bp5-input-action .bp5-button.bp5-minimal:not(:hover):not(:focus) {
  color: #5f6b7c;
}

.bp5-dark .bp5-input-group .bp5-input:not(:focus) + .bp5-button.bp5-minimal:not(:hover):not(:focus), .bp5-dark .bp5-input-group .bp5-input:not(:focus) + .bp5-input-action .bp5-button.bp5-minimal:not(:hover):not(:focus) {
  color: #abb3bf;
}

.bp5-input-group .bp5-input:not(:focus) + .bp5-button.bp5-minimal:not(:hover):not(:focus) .bp5-icon, .bp5-input-group .bp5-input:not(:focus) + .bp5-button.bp5-minimal:not(:hover):not(:focus) .bp5-icon-standard, .bp5-input-group .bp5-input:not(:focus) + .bp5-button.bp5-minimal:not(:hover):not(:focus) .bp5-icon-large, .bp5-input-group .bp5-input:not(:focus) + .bp5-input-action .bp5-button.bp5-minimal:not(:hover):not(:focus) .bp5-icon, .bp5-input-group .bp5-input:not(:focus) + .bp5-input-action .bp5-button.bp5-minimal:not(:hover):not(:focus) .bp5-icon-standard, .bp5-input-group .bp5-input:not(:focus) + .bp5-input-action .bp5-button.bp5-minimal:not(:hover):not(:focus) .bp5-icon-large {
  color: #5f6b7c;
}

.bp5-input-group .bp5-input:not(:focus) + .bp5-button.bp5-minimal:disabled, .bp5-input-group .bp5-input:not(:focus) + .bp5-input-action .bp5-button.bp5-minimal:disabled, .bp5-input-group .bp5-input:not(:focus) + .bp5-button.bp5-minimal:disabled .bp5-icon, .bp5-input-group .bp5-input:not(:focus) + .bp5-button.bp5-minimal:disabled .bp5-icon-standard, .bp5-input-group .bp5-input:not(:focus) + .bp5-button.bp5-minimal:disabled .bp5-icon-large, .bp5-input-group .bp5-input:not(:focus) + .bp5-input-action .bp5-button.bp5-minimal:disabled .bp5-icon, .bp5-input-group .bp5-input:not(:focus) + .bp5-input-action .bp5-button.bp5-minimal:disabled .bp5-icon-standard, .bp5-input-group .bp5-input:not(:focus) + .bp5-input-action .bp5-button.bp5-minimal:disabled .bp5-icon-large {
  color: #5f6b7c99 !important;
}

.bp5-input-group.bp5-disabled {
  cursor: not-allowed;
}

.bp5-input-group.bp5-disabled .bp5-icon {
  color: #5f6b7c99;
}

.bp5-input-group.bp5-large .bp5-button {
  min-width: 30px;
  min-height: 30px;
  margin: 5px;
}

.bp5-input-group.bp5-large > .bp5-input-left-container > .bp5-icon, .bp5-input-group.bp5-large > .bp5-icon, .bp5-input-group.bp5-large .bp5-input-action > .bp5-spinner {
  margin: 12px;
}

.bp5-input-group.bp5-large .bp5-input {
  height: 40px;
  font-size: 16px;
  line-height: 40px;
}

.bp5-input-group.bp5-large .bp5-input[type="search"], .bp5-input-group.bp5-large .bp5-input.bp5-round {
  padding: 0 15px;
}

.bp5-input-group.bp5-large .bp5-input:not(:first-child) {
  padding-left: 40px;
}

.bp5-input-group.bp5-large .bp5-input:not(:last-child) {
  padding-right: 40px;
}

.bp5-input-group.bp5-small .bp5-button, .bp5-input-group.bp5-small .bp5-tag {
  min-width: 20px;
  min-height: 20px;
  margin: 2px;
}

.bp5-input-group.bp5-small > .bp5-input-left-container > .bp5-icon, .bp5-input-group.bp5-small > .bp5-icon, .bp5-input-group.bp5-small .bp5-input-action > .bp5-spinner {
  margin: 4px;
}

.bp5-input-group.bp5-small .bp5-input {
  height: 24px;
  padding-left: 8px;
  padding-right: 8px;
  font-size: 12px;
  line-height: 24px;
}

.bp5-input-group.bp5-small .bp5-input[type="search"], .bp5-input-group.bp5-small .bp5-input.bp5-round {
  padding: 0 12px;
}

.bp5-input-group.bp5-small .bp5-input:not(:first-child) {
  padding-left: 24px;
}

.bp5-input-group.bp5-small .bp5-input:not(:last-child) {
  padding-right: 24px;
}

.bp5-input-group.bp5-fill {
  flex: auto;
  width: 100%;
}

.bp5-input-group.bp5-round .bp5-button, .bp5-input-group.bp5-round .bp5-input, .bp5-input-group.bp5-round .bp5-tag {
  border-radius: 30px;
}

.bp5-dark .bp5-input-group .bp5-icon {
  color: #abb3bf;
}

.bp5-dark .bp5-input-group.bp5-disabled .bp5-icon {
  color: #abb3bf99;
}

.bp5-input-group.bp5-intent-primary .bp5-input {
  box-shadow: 0 0 #2d72d200, 0 0 #2d72d200, inset 0 0 0 1px #2d72d2, inset 0 0 0 1px #11141833, inset 0 1px 1px #1114184d;
}

.bp5-input-group.bp5-intent-primary .bp5-input:focus {
  box-shadow: inset 0 0 0 1px #2d72d2, 0 0 0 2px #2d72d24d, inset 0 1px 1px #11141833;
}

.bp5-input-group.bp5-intent-primary .bp5-input[readonly] {
  box-shadow: inset 0 0 0 1px #2d72d2;
}

.bp5-input-group.bp5-intent-primary .bp5-input:disabled, .bp5-input-group.bp5-intent-primary .bp5-input.bp5-disabled {
  box-shadow: none;
}

.bp5-dark .bp5-input-group.bp5-intent-primary .bp5-input {
  box-shadow: 0 0 #4c90f000, 0 0 #4c90f000, 0 0 #4c90f000, inset 0 0 0 1px #4c90f0, inset 0 0 0 1px #fff3, inset 0 -1px 1px #ffffff4d;
}

.bp5-dark .bp5-input-group.bp5-intent-primary .bp5-input:focus {
  box-shadow: inset 0 0 0 1px #4c90f0, inset 0 0 0 1px #4c90f0, 0 0 0 2px #4c90f04d, inset 0 0 0 1px #fff3, inset 0 -1px 1px #ffffff4d;
}

.bp5-dark .bp5-input-group.bp5-intent-primary .bp5-input[readonly] {
  box-shadow: inset 0 0 0 1px #4c90f0;
}

.bp5-dark .bp5-input-group.bp5-intent-primary .bp5-input:disabled, .bp5-dark .bp5-input-group.bp5-intent-primary .bp5-input.bp5-disabled {
  box-shadow: none;
}

.bp5-input-group.bp5-intent-primary > .bp5-icon {
  color: #215db0;
}

.bp5-dark .bp5-input-group.bp5-intent-primary > .bp5-icon {
  color: #8abbff;
}

.bp5-input-group.bp5-intent-success .bp5-input {
  box-shadow: 0 0 #23855100, 0 0 #23855100, inset 0 0 0 1px #238551, inset 0 0 0 1px #11141833, inset 0 1px 1px #1114184d;
}

.bp5-input-group.bp5-intent-success .bp5-input:focus {
  box-shadow: inset 0 0 0 1px #238551, 0 0 0 2px #2385514d, inset 0 1px 1px #11141833;
}

.bp5-input-group.bp5-intent-success .bp5-input[readonly] {
  box-shadow: inset 0 0 0 1px #238551;
}

.bp5-input-group.bp5-intent-success .bp5-input:disabled, .bp5-input-group.bp5-intent-success .bp5-input.bp5-disabled {
  box-shadow: none;
}

.bp5-dark .bp5-input-group.bp5-intent-success .bp5-input {
  box-shadow: 0 0 #32a46700, 0 0 #32a46700, 0 0 #32a46700, inset 0 0 0 1px #32a467, inset 0 0 0 1px #fff3, inset 0 -1px 1px #ffffff4d;
}

.bp5-dark .bp5-input-group.bp5-intent-success .bp5-input:focus {
  box-shadow: inset 0 0 0 1px #32a467, inset 0 0 0 1px #32a467, 0 0 0 2px #32a4674d, inset 0 0 0 1px #fff3, inset 0 -1px 1px #ffffff4d;
}

.bp5-dark .bp5-input-group.bp5-intent-success .bp5-input[readonly] {
  box-shadow: inset 0 0 0 1px #32a467;
}

.bp5-dark .bp5-input-group.bp5-intent-success .bp5-input:disabled, .bp5-dark .bp5-input-group.bp5-intent-success .bp5-input.bp5-disabled {
  box-shadow: none;
}

.bp5-input-group.bp5-intent-success > .bp5-icon {
  color: #1c6e42;
}

.bp5-dark .bp5-input-group.bp5-intent-success > .bp5-icon {
  color: #72ca9b;
}

.bp5-input-group.bp5-intent-warning .bp5-input {
  box-shadow: 0 0 #c8761900, 0 0 #c8761900, inset 0 0 0 1px #c87619, inset 0 0 0 1px #11141833, inset 0 1px 1px #1114184d;
}

.bp5-input-group.bp5-intent-warning .bp5-input:focus {
  box-shadow: inset 0 0 0 1px #c87619, 0 0 0 2px #c876194d, inset 0 1px 1px #11141833;
}

.bp5-input-group.bp5-intent-warning .bp5-input[readonly] {
  box-shadow: inset 0 0 0 1px #c87619;
}

.bp5-input-group.bp5-intent-warning .bp5-input:disabled, .bp5-input-group.bp5-intent-warning .bp5-input.bp5-disabled {
  box-shadow: none;
}

.bp5-dark .bp5-input-group.bp5-intent-warning .bp5-input {
  box-shadow: 0 0 #ec9a3c00, 0 0 #ec9a3c00, 0 0 #ec9a3c00, inset 0 0 0 1px #ec9a3c, inset 0 0 0 1px #fff3, inset 0 -1px 1px #ffffff4d;
}

.bp5-dark .bp5-input-group.bp5-intent-warning .bp5-input:focus {
  box-shadow: inset 0 0 0 1px #ec9a3c, inset 0 0 0 1px #ec9a3c, 0 0 0 2px #ec9a3c4d, inset 0 0 0 1px #fff3, inset 0 -1px 1px #ffffff4d;
}

.bp5-dark .bp5-input-group.bp5-intent-warning .bp5-input[readonly] {
  box-shadow: inset 0 0 0 1px #ec9a3c;
}

.bp5-dark .bp5-input-group.bp5-intent-warning .bp5-input:disabled, .bp5-dark .bp5-input-group.bp5-intent-warning .bp5-input.bp5-disabled {
  box-shadow: none;
}

.bp5-input-group.bp5-intent-warning > .bp5-icon {
  color: #935610;
}

.bp5-dark .bp5-input-group.bp5-intent-warning > .bp5-icon {
  color: #fbb360;
}

.bp5-input-group.bp5-intent-danger .bp5-input {
  box-shadow: 0 0 #cd424600, 0 0 #cd424600, inset 0 0 0 1px #cd4246, inset 0 0 0 1px #11141833, inset 0 1px 1px #1114184d;
}

.bp5-input-group.bp5-intent-danger .bp5-input:focus {
  box-shadow: inset 0 0 0 1px #cd4246, 0 0 0 2px #cd42464d, inset 0 1px 1px #11141833;
}

.bp5-input-group.bp5-intent-danger .bp5-input[readonly] {
  box-shadow: inset 0 0 0 1px #cd4246;
}

.bp5-input-group.bp5-intent-danger .bp5-input:disabled, .bp5-input-group.bp5-intent-danger .bp5-input.bp5-disabled {
  box-shadow: none;
}

.bp5-dark .bp5-input-group.bp5-intent-danger .bp5-input {
  box-shadow: 0 0 #e76a6e00, 0 0 #e76a6e00, 0 0 #e76a6e00, inset 0 0 0 1px #e76a6e, inset 0 0 0 1px #fff3, inset 0 -1px 1px #ffffff4d;
}

.bp5-dark .bp5-input-group.bp5-intent-danger .bp5-input:focus {
  box-shadow: inset 0 0 0 1px #e76a6e, inset 0 0 0 1px #e76a6e, 0 0 0 2px #e76a6e4d, inset 0 0 0 1px #fff3, inset 0 -1px 1px #ffffff4d;
}

.bp5-dark .bp5-input-group.bp5-intent-danger .bp5-input[readonly] {
  box-shadow: inset 0 0 0 1px #e76a6e;
}

.bp5-dark .bp5-input-group.bp5-intent-danger .bp5-input:disabled, .bp5-dark .bp5-input-group.bp5-intent-danger .bp5-input.bp5-disabled {
  box-shadow: none;
}

.bp5-input-group.bp5-intent-danger > .bp5-icon {
  color: #ac2f33;
}

.bp5-dark .bp5-input-group.bp5-intent-danger > .bp5-icon {
  color: #fa999c;
}

.bp5-input {
  -webkit-appearance: none;
  appearance: none;
  color: #1c2127;
  vertical-align: middle;
  background: #fff;
  border: none;
  border-radius: 2px;
  outline: none;
  height: 30px;
  padding: 0 10px;
  font-size: 14px;
  font-weight: 400;
  line-height: 30px;
  transition: box-shadow .1s cubic-bezier(.4, 1, .75, .9);
  box-shadow: 0 0 #2d72d200, 0 0 #2d72d200, inset 0 0 0 1px #11141833, inset 0 1px 1px #1114184d;
}

.bp5-input::-moz-placeholder {
  color: #5f6b7c;
  opacity: 1;
}

.bp5-input:-ms-input-placeholder {
  color: #5f6b7c;
  opacity: 1;
}

.bp5-input::placeholder {
  color: #5f6b7c;
  opacity: 1;
}

.bp5-input[type="search"], .bp5-input.bp5-round {
  box-sizing: border-box;
  border-radius: 30px;
  padding-left: 10px;
}

.bp5-input[readonly] {
  box-shadow: inset 0 0 0 1px #11141826;
}

.bp5-input:disabled, .bp5-input.bp5-disabled {
  box-shadow: none;
  color: #5f6b7c99;
  cursor: not-allowed;
  resize: none;
  background: #d3d8de80;
}

.bp5-input:disabled::-moz-placeholder {
  color: #5f6b7c99;
}

.bp5-input.bp5-disabled::-moz-placeholder {
  color: #5f6b7c99;
}

:is(.bp5-input:disabled:-ms-input-placeholder, .bp5-input.bp5-disabled:-ms-input-placeholder), .bp5-input:disabled::placeholder, .bp5-input.bp5-disabled::placeholder {
  color: #5f6b7c99;
}

@media (forced-colors: active) and (prefers-color-scheme: dark) {
  .bp5-input {
    border: 1px solid buttonborder;
  }
}

.bp5-input.bp5-large {
  height: 40px;
  font-size: 16px;
  line-height: 40px;
}

.bp5-input.bp5-large[type="search"], .bp5-input.bp5-large.bp5-round {
  padding: 0 15px;
}

.bp5-input.bp5-small {
  height: 24px;
  padding-left: 8px;
  padding-right: 8px;
  font-size: 12px;
  line-height: 24px;
}

.bp5-input.bp5-small[type="search"], .bp5-input.bp5-small.bp5-round {
  padding: 0 12px;
}

.bp5-input.bp5-fill {
  flex: auto;
  width: 100%;
}

.bp5-dark .bp5-input {
  color: #f6f7f9;
  background: #1114184d;
  box-shadow: 0 0 #4c90f000, 0 0 #4c90f000, 0 0 #4c90f000, inset 0 0 0 1px #fff3, inset 0 -1px 1px #ffffff4d;
}

.bp5-dark .bp5-input::-moz-placeholder {
  color: #abb3bf;
}

.bp5-dark .bp5-input:-ms-input-placeholder {
  color: #abb3bf;
}

.bp5-dark .bp5-input::placeholder {
  color: #abb3bf;
}

.bp5-dark .bp5-input:focus {
  box-shadow: inset 0 0 0 1px #4c90f0, inset 0 0 0 1px #4c90f0, 0 0 0 2px #4c90f04d;
}

.bp5-dark .bp5-input[readonly] {
  box-shadow: inset 0 0 0 1px #11141866;
}

.bp5-dark .bp5-input:disabled, .bp5-dark .bp5-input.bp5-disabled {
  box-shadow: none;
  color: #abb3bf99;
  background: #40485480;
}

.bp5-input.bp5-intent-primary {
  box-shadow: 0 0 #2d72d200, 0 0 #2d72d200, inset 0 0 0 1px #2d72d2, inset 0 0 0 1px #11141833, inset 0 1px 1px #1114184d;
}

.bp5-input.bp5-intent-primary:focus {
  box-shadow: inset 0 0 0 1px #2d72d2, 0 0 0 2px #2d72d24d, inset 0 1px 1px #11141833;
}

.bp5-input.bp5-intent-primary[readonly] {
  box-shadow: inset 0 0 0 1px #2d72d2;
}

.bp5-input.bp5-intent-primary:disabled, .bp5-input.bp5-intent-primary.bp5-disabled {
  box-shadow: none;
}

.bp5-dark .bp5-input.bp5-intent-primary {
  box-shadow: 0 0 #4c90f000, 0 0 #4c90f000, 0 0 #4c90f000, inset 0 0 0 1px #4c90f0, inset 0 0 0 1px #fff3, inset 0 -1px 1px #ffffff4d;
}

.bp5-dark .bp5-input.bp5-intent-primary:focus {
  box-shadow: inset 0 0 0 1px #4c90f0, inset 0 0 0 1px #4c90f0, 0 0 0 2px #4c90f04d, inset 0 0 0 1px #fff3, inset 0 -1px 1px #ffffff4d;
}

.bp5-dark .bp5-input.bp5-intent-primary[readonly] {
  box-shadow: inset 0 0 0 1px #4c90f0;
}

.bp5-dark .bp5-input.bp5-intent-primary:disabled, .bp5-dark .bp5-input.bp5-intent-primary.bp5-disabled {
  box-shadow: none;
}

.bp5-input.bp5-intent-success {
  box-shadow: 0 0 #23855100, 0 0 #23855100, inset 0 0 0 1px #238551, inset 0 0 0 1px #11141833, inset 0 1px 1px #1114184d;
}

.bp5-input.bp5-intent-success:focus {
  box-shadow: inset 0 0 0 1px #238551, 0 0 0 2px #2385514d, inset 0 1px 1px #11141833;
}

.bp5-input.bp5-intent-success[readonly] {
  box-shadow: inset 0 0 0 1px #238551;
}

.bp5-input.bp5-intent-success:disabled, .bp5-input.bp5-intent-success.bp5-disabled {
  box-shadow: none;
}

.bp5-dark .bp5-input.bp5-intent-success {
  box-shadow: 0 0 #32a46700, 0 0 #32a46700, 0 0 #32a46700, inset 0 0 0 1px #32a467, inset 0 0 0 1px #fff3, inset 0 -1px 1px #ffffff4d;
}

.bp5-dark .bp5-input.bp5-intent-success:focus {
  box-shadow: inset 0 0 0 1px #32a467, inset 0 0 0 1px #32a467, 0 0 0 2px #32a4674d, inset 0 0 0 1px #fff3, inset 0 -1px 1px #ffffff4d;
}

.bp5-dark .bp5-input.bp5-intent-success[readonly] {
  box-shadow: inset 0 0 0 1px #32a467;
}

.bp5-dark .bp5-input.bp5-intent-success:disabled, .bp5-dark .bp5-input.bp5-intent-success.bp5-disabled {
  box-shadow: none;
}

.bp5-input.bp5-intent-warning {
  box-shadow: 0 0 #c8761900, 0 0 #c8761900, inset 0 0 0 1px #c87619, inset 0 0 0 1px #11141833, inset 0 1px 1px #1114184d;
}

.bp5-input.bp5-intent-warning:focus {
  box-shadow: inset 0 0 0 1px #c87619, 0 0 0 2px #c876194d, inset 0 1px 1px #11141833;
}

.bp5-input.bp5-intent-warning[readonly] {
  box-shadow: inset 0 0 0 1px #c87619;
}

.bp5-input.bp5-intent-warning:disabled, .bp5-input.bp5-intent-warning.bp5-disabled {
  box-shadow: none;
}

.bp5-dark .bp5-input.bp5-intent-warning {
  box-shadow: 0 0 #ec9a3c00, 0 0 #ec9a3c00, 0 0 #ec9a3c00, inset 0 0 0 1px #ec9a3c, inset 0 0 0 1px #fff3, inset 0 -1px 1px #ffffff4d;
}

.bp5-dark .bp5-input.bp5-intent-warning:focus {
  box-shadow: inset 0 0 0 1px #ec9a3c, inset 0 0 0 1px #ec9a3c, 0 0 0 2px #ec9a3c4d, inset 0 0 0 1px #fff3, inset 0 -1px 1px #ffffff4d;
}

.bp5-dark .bp5-input.bp5-intent-warning[readonly] {
  box-shadow: inset 0 0 0 1px #ec9a3c;
}

.bp5-dark .bp5-input.bp5-intent-warning:disabled, .bp5-dark .bp5-input.bp5-intent-warning.bp5-disabled {
  box-shadow: none;
}

.bp5-input.bp5-intent-danger {
  box-shadow: 0 0 #cd424600, 0 0 #cd424600, inset 0 0 0 1px #cd4246, inset 0 0 0 1px #11141833, inset 0 1px 1px #1114184d;
}

.bp5-input.bp5-intent-danger:focus {
  box-shadow: inset 0 0 0 1px #cd4246, 0 0 0 2px #cd42464d, inset 0 1px 1px #11141833;
}

.bp5-input.bp5-intent-danger[readonly] {
  box-shadow: inset 0 0 0 1px #cd4246;
}

.bp5-input.bp5-intent-danger:disabled, .bp5-input.bp5-intent-danger.bp5-disabled {
  box-shadow: none;
}

.bp5-dark .bp5-input.bp5-intent-danger {
  box-shadow: 0 0 #e76a6e00, 0 0 #e76a6e00, 0 0 #e76a6e00, inset 0 0 0 1px #e76a6e, inset 0 0 0 1px #fff3, inset 0 -1px 1px #ffffff4d;
}

.bp5-dark .bp5-input.bp5-intent-danger:focus {
  box-shadow: inset 0 0 0 1px #e76a6e, inset 0 0 0 1px #e76a6e, 0 0 0 2px #e76a6e4d, inset 0 0 0 1px #fff3, inset 0 -1px 1px #ffffff4d;
}

.bp5-dark .bp5-input.bp5-intent-danger[readonly] {
  box-shadow: inset 0 0 0 1px #e76a6e;
}

.bp5-dark .bp5-input.bp5-intent-danger:disabled, .bp5-dark .bp5-input.bp5-intent-danger.bp5-disabled {
  box-shadow: none;
}

.bp5-input::-ms-clear {
  display: none;
}

@supports (-webkit-touch-callout: none) {
  input.bp5-input:disabled, input.bp5-input.bp5-disabled {
    opacity: 1;
    -webkit-text-fill-color: #5f6b7c99;
  }

  .bp5-dark input.bp5-input:disabled, .bp5-dark input.bp5-input.bp5-disabled {
    -webkit-text-fill-color: #abb3bf99;
  }
}

textarea.bp5-input {
  max-width: 100%;
  padding: 10px;
}

textarea.bp5-input, textarea.bp5-input.bp5-large, textarea.bp5-input.bp5-small {
  line-height: inherit;
  height: auto;
}

textarea.bp5-input.bp5-small {
  padding: 8px;
}

.bp5-text-area.bp5-text-area-auto-resize {
  resize: horizontal;
}

label.bp5-label {
  margin-top: 0;
  margin-bottom: 15px;
  display: block;
}

label.bp5-label .bp5-html-select, label.bp5-label .bp5-input, label.bp5-label .bp5-select, label.bp5-label .bp5-slider, label.bp5-label .bp5-popover-wrapper {
  text-transform: none;
  margin-top: 5px;
  display: block;
}

label.bp5-label .bp5-button-group {
  margin-top: 5px;
}

label.bp5-label .bp5-select select, label.bp5-label .bp5-html-select select {
  vertical-align: top;
  width: 100%;
  font-weight: 400;
}

label.bp5-label .bp5-control-group {
  margin-top: 5px;
}

label.bp5-label .bp5-control-group > .bp5-button-group, label.bp5-label .bp5-control-group > .bp5-html-select, label.bp5-label .bp5-control-group > .bp5-input, label.bp5-label .bp5-control-group > .bp5-select, label.bp5-label .bp5-control-group > .bp5-slider, label.bp5-label .bp5-control-group > .bp5-popover-wrapper {
  margin-top: 0;
}

label.bp5-label.bp5-disabled, label.bp5-label.bp5-disabled .bp5-text-muted {
  color: #5f6b7c99;
}

label.bp5-label.bp5-inline {
  line-height: 30px;
}

label.bp5-label.bp5-inline .bp5-html-select, label.bp5-label.bp5-inline .bp5-input, label.bp5-label.bp5-inline .bp5-input-group, label.bp5-label.bp5-inline .bp5-select, label.bp5-label.bp5-inline .bp5-popover-wrapper {
  vertical-align: top;
  margin: 0 0 0 5px;
  display: inline-block;
}

label.bp5-label.bp5-inline .bp5-button-group {
  margin: 0 0 0 5px;
}

label.bp5-label.bp5-inline .bp5-input-group .bp5-input {
  margin-left: 0;
}

label.bp5-label.bp5-inline.bp5-large {
  line-height: 40px;
}

label.bp5-label.bp5-inline .bp5-control-group {
  margin: 0 0 0 5px;
}

label.bp5-label.bp5-inline .bp5-control-group > .bp5-button-group, label.bp5-label.bp5-inline .bp5-control-group > .bp5-html-select, label.bp5-label.bp5-inline .bp5-control-group > .bp5-input, label.bp5-label.bp5-inline .bp5-control-group > .bp5-select, label.bp5-label.bp5-inline .bp5-control-group > .bp5-slider, label.bp5-label.bp5-inline .bp5-control-group > .bp5-popover-wrapper {
  margin: 0;
}

label.bp5-label:not(.bp5-inline) .bp5-popover-target {
  display: block;
}

.bp5-dark label.bp5-label {
  color: #f6f7f9;
}

.bp5-dark label.bp5-label.bp5-disabled, .bp5-dark label.bp5-label.bp5-disabled .bp5-text-muted {
  color: #abb3bf99;
}

.bp5-numeric-input .bp5-button-group.bp5-vertical > .bp5-button {
  flex: 11px;
  width: 24px;
  min-height: 0;
  padding: 0;
}

.bp5-numeric-input.bp5-large .bp5-button-group.bp5-vertical > .bp5-button {
  width: 40px;
}

.bp5-numeric-input.bp5-small .bp5-button-group.bp5-vertical > .bp5-button {
  width: 24px;
}

form {
  display: block;
}

.bp5-html-select select, .bp5-select select {
  cursor: pointer;
  text-align: left;
  vertical-align: middle;
  color: #1c2127;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-color: #f6f7f9;
  border: none;
  border-radius: 2px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 30px;
  padding: 0 30px 0 10px;
  font-size: 14px;
  display: inline-flex;
  box-shadow: inset 0 0 0 1px #11141833, 0 1px 2px #1114181a;
}

.bp5-html-select select > *, .bp5-select select > * {
  flex-grow: 0;
  flex-shrink: 0;
}

.bp5-html-select select > .bp5-fill, .bp5-select select > .bp5-fill {
  flex-grow: 1;
  flex-shrink: 1;
}

.bp5-html-select select:before, .bp5-select select:before, .bp5-html-select select > *, .bp5-select select > * {
  margin-right: 7px;
}

.bp5-html-select select:empty:before, .bp5-select select:empty:before, .bp5-html-select select > :last-child, .bp5-select select > :last-child {
  margin-right: 0;
}

.bp5-html-select select:hover, .bp5-select select:hover {
  background-color: #edeff2;
  background-clip: padding-box;
  box-shadow: inset 0 0 0 1px #11141833, 0 1px 2px #11141833;
}

.bp5-html-select select:active, .bp5-select select:active, .bp5-html-select select.bp5-active, .bp5-select select.bp5-active {
  background-color: #dce0e5;
  box-shadow: inset 0 0 0 1px #11141833, 0 1px 2px #11141833;
}

@media (forced-colors: active) and (prefers-color-scheme: dark) {
  .bp5-html-select select:active, .bp5-select select:active, .bp5-html-select select.bp5-active, .bp5-select select.bp5-active {
    background: highlight;
  }
}

.bp5-html-select select:disabled, .bp5-select select:disabled, .bp5-html-select select.bp5-disabled, .bp5-select select.bp5-disabled {
  box-shadow: none;
  color: #5f6b7c99;
  cursor: not-allowed;
  background-color: #d3d8de80;
  outline: none;
}

.bp5-html-select select:disabled.bp5-active, .bp5-select select:disabled.bp5-active, .bp5-html-select select.bp5-disabled.bp5-active, .bp5-select select.bp5-disabled.bp5-active {
  background: #d3d8deb3;
}

@media (forced-colors: active) and (prefers-color-scheme: dark) {
  .bp5-html-select select, .bp5-select select {
    border: 1px solid buttonborder;
  }
}

.bp5-html-select.bp5-minimal select, .bp5-select.bp5-minimal select {
  box-shadow: none;
  background: none;
}

.bp5-html-select.bp5-minimal select:hover, .bp5-select.bp5-minimal select:hover {
  box-shadow: none;
  color: #1c2127;
  background: #8f99a826;
  text-decoration: none;
}

.bp5-html-select.bp5-minimal select:active, .bp5-select.bp5-minimal select:active, .bp5-html-select.bp5-minimal select.bp5-active, .bp5-select.bp5-minimal select.bp5-active {
  box-shadow: none;
  color: #1c2127;
  background: #8f99a84d;
}

.bp5-html-select.bp5-minimal select:disabled, .bp5-select.bp5-minimal select:disabled, .bp5-html-select.bp5-minimal select.bp5-disabled, .bp5-select.bp5-minimal select.bp5-disabled {
  color: #5f6b7c99;
  cursor: not-allowed;
  background: none;
}

.bp5-html-select.bp5-minimal select:disabled.bp5-active, .bp5-select.bp5-minimal select:disabled.bp5-active, .bp5-html-select.bp5-minimal select.bp5-disabled.bp5-active, .bp5-select.bp5-minimal select.bp5-disabled.bp5-active {
  background: #8f99a84d;
}

.bp5-dark .bp5-html-select.bp5-minimal select, .bp5-html-select.bp5-minimal .bp5-dark select, .bp5-dark .bp5-select.bp5-minimal select, .bp5-select.bp5-minimal .bp5-dark select, .bp5-dark .bp5-html-select.bp5-minimal select:hover, .bp5-html-select.bp5-minimal .bp5-dark select:hover, .bp5-dark .bp5-select.bp5-minimal select:hover, .bp5-select.bp5-minimal .bp5-dark select:hover, .bp5-dark .bp5-html-select.bp5-minimal select:active, .bp5-html-select.bp5-minimal .bp5-dark select:active, .bp5-dark .bp5-select.bp5-minimal select:active, .bp5-select.bp5-minimal .bp5-dark select:active, .bp5-dark .bp5-html-select.bp5-minimal select.bp5-active, .bp5-html-select.bp5-minimal .bp5-dark select.bp5-active, .bp5-dark .bp5-select.bp5-minimal select.bp5-active, .bp5-select.bp5-minimal .bp5-dark select.bp5-active {
  box-shadow: none;
  color: #fff;
  background: none;
}

.bp5-dark .bp5-html-select.bp5-minimal select:hover, .bp5-html-select.bp5-minimal .bp5-dark select:hover, .bp5-dark .bp5-select.bp5-minimal select:hover, .bp5-select.bp5-minimal .bp5-dark select:hover {
  background: #8f99a826;
}

.bp5-dark .bp5-html-select.bp5-minimal select:active, .bp5-html-select.bp5-minimal .bp5-dark select:active, .bp5-dark .bp5-select.bp5-minimal select:active, .bp5-select.bp5-minimal .bp5-dark select:active, .bp5-dark .bp5-html-select.bp5-minimal select.bp5-active, .bp5-html-select.bp5-minimal .bp5-dark select.bp5-active, .bp5-dark .bp5-select.bp5-minimal select.bp5-active, .bp5-select.bp5-minimal .bp5-dark select.bp5-active {
  background: #8f99a84d;
}

.bp5-dark .bp5-html-select.bp5-minimal select:disabled, .bp5-html-select.bp5-minimal .bp5-dark select:disabled, .bp5-dark .bp5-select.bp5-minimal select:disabled, .bp5-select.bp5-minimal .bp5-dark select:disabled, .bp5-dark .bp5-html-select.bp5-minimal select.bp5-disabled, .bp5-html-select.bp5-minimal .bp5-dark select.bp5-disabled, .bp5-dark .bp5-select.bp5-minimal select.bp5-disabled, .bp5-select.bp5-minimal .bp5-dark select.bp5-disabled {
  color: #abb3bf99;
  cursor: not-allowed;
  background: none;
}

.bp5-dark .bp5-html-select.bp5-minimal select:disabled.bp5-active, .bp5-html-select.bp5-minimal .bp5-dark select:disabled.bp5-active, .bp5-dark .bp5-select.bp5-minimal select:disabled.bp5-active, .bp5-select.bp5-minimal .bp5-dark select:disabled.bp5-active, .bp5-dark .bp5-html-select.bp5-minimal select.bp5-disabled.bp5-active, .bp5-html-select.bp5-minimal .bp5-dark select.bp5-disabled.bp5-active, .bp5-dark .bp5-select.bp5-minimal select.bp5-disabled.bp5-active, .bp5-select.bp5-minimal .bp5-dark select.bp5-disabled.bp5-active {
  background: #8f99a84d;
}

.bp5-html-select.bp5-minimal select.bp5-intent-primary, .bp5-select.bp5-minimal select.bp5-intent-primary {
  color: #215db0;
}

.bp5-html-select.bp5-minimal select.bp5-intent-primary:hover, .bp5-select.bp5-minimal select.bp5-intent-primary:hover, .bp5-html-select.bp5-minimal select.bp5-intent-primary:active, .bp5-select.bp5-minimal select.bp5-intent-primary:active, .bp5-html-select.bp5-minimal select.bp5-intent-primary.bp5-active, .bp5-select.bp5-minimal select.bp5-intent-primary.bp5-active {
  box-shadow: none;
  color: #215db0;
  background: none;
}

.bp5-html-select.bp5-minimal select.bp5-intent-primary:hover, .bp5-select.bp5-minimal select.bp5-intent-primary:hover {
  color: #215db0;
  background: #2d72d226;
}

.bp5-html-select.bp5-minimal select.bp5-intent-primary:active, .bp5-select.bp5-minimal select.bp5-intent-primary:active, .bp5-html-select.bp5-minimal select.bp5-intent-primary.bp5-active, .bp5-select.bp5-minimal select.bp5-intent-primary.bp5-active {
  color: #184a90;
  background: #2d72d24d;
}

.bp5-html-select.bp5-minimal select.bp5-intent-primary:disabled, .bp5-select.bp5-minimal select.bp5-intent-primary:disabled, .bp5-html-select.bp5-minimal select.bp5-intent-primary.bp5-disabled, .bp5-select.bp5-minimal select.bp5-intent-primary.bp5-disabled {
  color: #215db080;
  background: none;
}

.bp5-html-select.bp5-minimal select.bp5-intent-primary:disabled.bp5-active, .bp5-select.bp5-minimal select.bp5-intent-primary:disabled.bp5-active, .bp5-html-select.bp5-minimal select.bp5-intent-primary.bp5-disabled.bp5-active, .bp5-select.bp5-minimal select.bp5-intent-primary.bp5-disabled.bp5-active {
  background: #2d72d24d;
}

.bp5-html-select.bp5-minimal select.bp5-intent-primary .bp5-button-spinner .bp5-spinner-head, .bp5-select.bp5-minimal select.bp5-intent-primary .bp5-button-spinner .bp5-spinner-head {
  stroke: #215db0;
}

.bp5-dark .bp5-html-select.bp5-minimal select.bp5-intent-primary, .bp5-html-select.bp5-minimal .bp5-dark select.bp5-intent-primary, .bp5-dark .bp5-select.bp5-minimal select.bp5-intent-primary, .bp5-select.bp5-minimal .bp5-dark select.bp5-intent-primary {
  color: #8abbff;
}

.bp5-dark .bp5-html-select.bp5-minimal select.bp5-intent-primary:hover, .bp5-html-select.bp5-minimal .bp5-dark select.bp5-intent-primary:hover, .bp5-dark .bp5-select.bp5-minimal select.bp5-intent-primary:hover, .bp5-select.bp5-minimal .bp5-dark select.bp5-intent-primary:hover {
  color: #8abbff;
  background: #2d72d233;
}

.bp5-dark .bp5-html-select.bp5-minimal select.bp5-intent-primary:active, .bp5-html-select.bp5-minimal .bp5-dark select.bp5-intent-primary:active, .bp5-dark .bp5-select.bp5-minimal select.bp5-intent-primary:active, .bp5-select.bp5-minimal .bp5-dark select.bp5-intent-primary:active, .bp5-dark .bp5-html-select.bp5-minimal select.bp5-intent-primary.bp5-active, .bp5-html-select.bp5-minimal .bp5-dark select.bp5-intent-primary.bp5-active, .bp5-dark .bp5-select.bp5-minimal select.bp5-intent-primary.bp5-active, .bp5-select.bp5-minimal .bp5-dark select.bp5-intent-primary.bp5-active {
  color: #99c4ff;
  background: #2d72d24d;
}

.bp5-dark .bp5-html-select.bp5-minimal select.bp5-intent-primary:disabled, .bp5-html-select.bp5-minimal .bp5-dark select.bp5-intent-primary:disabled, .bp5-dark .bp5-select.bp5-minimal select.bp5-intent-primary:disabled, .bp5-select.bp5-minimal .bp5-dark select.bp5-intent-primary:disabled, .bp5-dark .bp5-html-select.bp5-minimal select.bp5-intent-primary.bp5-disabled, .bp5-html-select.bp5-minimal .bp5-dark select.bp5-intent-primary.bp5-disabled, .bp5-dark .bp5-select.bp5-minimal select.bp5-intent-primary.bp5-disabled, .bp5-select.bp5-minimal .bp5-dark select.bp5-intent-primary.bp5-disabled {
  color: #8abbff80;
  background: none;
}

.bp5-dark .bp5-html-select.bp5-minimal select.bp5-intent-primary:disabled.bp5-active, .bp5-html-select.bp5-minimal .bp5-dark select.bp5-intent-primary:disabled.bp5-active, .bp5-dark .bp5-select.bp5-minimal select.bp5-intent-primary:disabled.bp5-active, .bp5-select.bp5-minimal .bp5-dark select.bp5-intent-primary:disabled.bp5-active, .bp5-dark .bp5-html-select.bp5-minimal select.bp5-intent-primary.bp5-disabled.bp5-active, .bp5-html-select.bp5-minimal .bp5-dark select.bp5-intent-primary.bp5-disabled.bp5-active, .bp5-dark .bp5-select.bp5-minimal select.bp5-intent-primary.bp5-disabled.bp5-active, .bp5-select.bp5-minimal .bp5-dark select.bp5-intent-primary.bp5-disabled.bp5-active {
  background: #2d72d24d;
}

.bp5-html-select.bp5-minimal select.bp5-intent-success, .bp5-select.bp5-minimal select.bp5-intent-success {
  color: #1c6e42;
}

.bp5-html-select.bp5-minimal select.bp5-intent-success:hover, .bp5-select.bp5-minimal select.bp5-intent-success:hover, .bp5-html-select.bp5-minimal select.bp5-intent-success:active, .bp5-select.bp5-minimal select.bp5-intent-success:active, .bp5-html-select.bp5-minimal select.bp5-intent-success.bp5-active, .bp5-select.bp5-minimal select.bp5-intent-success.bp5-active {
  box-shadow: none;
  color: #1c6e42;
  background: none;
}

.bp5-html-select.bp5-minimal select.bp5-intent-success:hover, .bp5-select.bp5-minimal select.bp5-intent-success:hover {
  color: #1c6e42;
  background: #23855126;
}

.bp5-html-select.bp5-minimal select.bp5-intent-success:active, .bp5-select.bp5-minimal select.bp5-intent-success:active, .bp5-html-select.bp5-minimal select.bp5-intent-success.bp5-active, .bp5-select.bp5-minimal select.bp5-intent-success.bp5-active {
  color: #165a36;
  background: #2385514d;
}

.bp5-html-select.bp5-minimal select.bp5-intent-success:disabled, .bp5-select.bp5-minimal select.bp5-intent-success:disabled, .bp5-html-select.bp5-minimal select.bp5-intent-success.bp5-disabled, .bp5-select.bp5-minimal select.bp5-intent-success.bp5-disabled {
  color: #1c6e4280;
  background: none;
}

.bp5-html-select.bp5-minimal select.bp5-intent-success:disabled.bp5-active, .bp5-select.bp5-minimal select.bp5-intent-success:disabled.bp5-active, .bp5-html-select.bp5-minimal select.bp5-intent-success.bp5-disabled.bp5-active, .bp5-select.bp5-minimal select.bp5-intent-success.bp5-disabled.bp5-active {
  background: #2385514d;
}

.bp5-html-select.bp5-minimal select.bp5-intent-success .bp5-button-spinner .bp5-spinner-head, .bp5-select.bp5-minimal select.bp5-intent-success .bp5-button-spinner .bp5-spinner-head {
  stroke: #1c6e42;
}

.bp5-dark .bp5-html-select.bp5-minimal select.bp5-intent-success, .bp5-html-select.bp5-minimal .bp5-dark select.bp5-intent-success, .bp5-dark .bp5-select.bp5-minimal select.bp5-intent-success, .bp5-select.bp5-minimal .bp5-dark select.bp5-intent-success {
  color: #72ca9b;
}

.bp5-dark .bp5-html-select.bp5-minimal select.bp5-intent-success:hover, .bp5-html-select.bp5-minimal .bp5-dark select.bp5-intent-success:hover, .bp5-dark .bp5-select.bp5-minimal select.bp5-intent-success:hover, .bp5-select.bp5-minimal .bp5-dark select.bp5-intent-success:hover {
  color: #72ca9b;
  background: #23855133;
}

.bp5-dark .bp5-html-select.bp5-minimal select.bp5-intent-success:active, .bp5-html-select.bp5-minimal .bp5-dark select.bp5-intent-success:active, .bp5-dark .bp5-select.bp5-minimal select.bp5-intent-success:active, .bp5-select.bp5-minimal .bp5-dark select.bp5-intent-success:active, .bp5-dark .bp5-html-select.bp5-minimal select.bp5-intent-success.bp5-active, .bp5-html-select.bp5-minimal .bp5-dark select.bp5-intent-success.bp5-active, .bp5-dark .bp5-select.bp5-minimal select.bp5-intent-success.bp5-active, .bp5-select.bp5-minimal .bp5-dark select.bp5-intent-success.bp5-active {
  color: #7cd7a2;
  background: #2385514d;
}

.bp5-dark .bp5-html-select.bp5-minimal select.bp5-intent-success:disabled, .bp5-html-select.bp5-minimal .bp5-dark select.bp5-intent-success:disabled, .bp5-dark .bp5-select.bp5-minimal select.bp5-intent-success:disabled, .bp5-select.bp5-minimal .bp5-dark select.bp5-intent-success:disabled, .bp5-dark .bp5-html-select.bp5-minimal select.bp5-intent-success.bp5-disabled, .bp5-html-select.bp5-minimal .bp5-dark select.bp5-intent-success.bp5-disabled, .bp5-dark .bp5-select.bp5-minimal select.bp5-intent-success.bp5-disabled, .bp5-select.bp5-minimal .bp5-dark select.bp5-intent-success.bp5-disabled {
  color: #72ca9b80;
  background: none;
}

.bp5-dark .bp5-html-select.bp5-minimal select.bp5-intent-success:disabled.bp5-active, .bp5-html-select.bp5-minimal .bp5-dark select.bp5-intent-success:disabled.bp5-active, .bp5-dark .bp5-select.bp5-minimal select.bp5-intent-success:disabled.bp5-active, .bp5-select.bp5-minimal .bp5-dark select.bp5-intent-success:disabled.bp5-active, .bp5-dark .bp5-html-select.bp5-minimal select.bp5-intent-success.bp5-disabled.bp5-active, .bp5-html-select.bp5-minimal .bp5-dark select.bp5-intent-success.bp5-disabled.bp5-active, .bp5-dark .bp5-select.bp5-minimal select.bp5-intent-success.bp5-disabled.bp5-active, .bp5-select.bp5-minimal .bp5-dark select.bp5-intent-success.bp5-disabled.bp5-active {
  background: #2385514d;
}

.bp5-html-select.bp5-minimal select.bp5-intent-warning, .bp5-select.bp5-minimal select.bp5-intent-warning {
  color: #935610;
}

.bp5-html-select.bp5-minimal select.bp5-intent-warning:hover, .bp5-select.bp5-minimal select.bp5-intent-warning:hover, .bp5-html-select.bp5-minimal select.bp5-intent-warning:active, .bp5-select.bp5-minimal select.bp5-intent-warning:active, .bp5-html-select.bp5-minimal select.bp5-intent-warning.bp5-active, .bp5-select.bp5-minimal select.bp5-intent-warning.bp5-active {
  box-shadow: none;
  color: #935610;
  background: none;
}

.bp5-html-select.bp5-minimal select.bp5-intent-warning:hover, .bp5-select.bp5-minimal select.bp5-intent-warning:hover {
  color: #935610;
  background: #c8761926;
}

.bp5-html-select.bp5-minimal select.bp5-intent-warning:active, .bp5-select.bp5-minimal select.bp5-intent-warning:active, .bp5-html-select.bp5-minimal select.bp5-intent-warning.bp5-active, .bp5-select.bp5-minimal select.bp5-intent-warning.bp5-active {
  color: #77450d;
  background: #c876194d;
}

.bp5-html-select.bp5-minimal select.bp5-intent-warning:disabled, .bp5-select.bp5-minimal select.bp5-intent-warning:disabled, .bp5-html-select.bp5-minimal select.bp5-intent-warning.bp5-disabled, .bp5-select.bp5-minimal select.bp5-intent-warning.bp5-disabled {
  color: #93561080;
  background: none;
}

.bp5-html-select.bp5-minimal select.bp5-intent-warning:disabled.bp5-active, .bp5-select.bp5-minimal select.bp5-intent-warning:disabled.bp5-active, .bp5-html-select.bp5-minimal select.bp5-intent-warning.bp5-disabled.bp5-active, .bp5-select.bp5-minimal select.bp5-intent-warning.bp5-disabled.bp5-active {
  background: #c876194d;
}

.bp5-html-select.bp5-minimal select.bp5-intent-warning .bp5-button-spinner .bp5-spinner-head, .bp5-select.bp5-minimal select.bp5-intent-warning .bp5-button-spinner .bp5-spinner-head {
  stroke: #935610;
}

.bp5-dark .bp5-html-select.bp5-minimal select.bp5-intent-warning, .bp5-html-select.bp5-minimal .bp5-dark select.bp5-intent-warning, .bp5-dark .bp5-select.bp5-minimal select.bp5-intent-warning, .bp5-select.bp5-minimal .bp5-dark select.bp5-intent-warning {
  color: #fbb360;
}

.bp5-dark .bp5-html-select.bp5-minimal select.bp5-intent-warning:hover, .bp5-html-select.bp5-minimal .bp5-dark select.bp5-intent-warning:hover, .bp5-dark .bp5-select.bp5-minimal select.bp5-intent-warning:hover, .bp5-select.bp5-minimal .bp5-dark select.bp5-intent-warning:hover {
  color: #fbb360;
  background: #c8761933;
}

.bp5-dark .bp5-html-select.bp5-minimal select.bp5-intent-warning:active, .bp5-html-select.bp5-minimal .bp5-dark select.bp5-intent-warning:active, .bp5-dark .bp5-select.bp5-minimal select.bp5-intent-warning:active, .bp5-select.bp5-minimal .bp5-dark select.bp5-intent-warning:active, .bp5-dark .bp5-html-select.bp5-minimal select.bp5-intent-warning.bp5-active, .bp5-html-select.bp5-minimal .bp5-dark select.bp5-intent-warning.bp5-active, .bp5-dark .bp5-select.bp5-minimal select.bp5-intent-warning.bp5-active, .bp5-select.bp5-minimal .bp5-dark select.bp5-intent-warning.bp5-active {
  color: #f5c186;
  background: #c876194d;
}

.bp5-dark .bp5-html-select.bp5-minimal select.bp5-intent-warning:disabled, .bp5-html-select.bp5-minimal .bp5-dark select.bp5-intent-warning:disabled, .bp5-dark .bp5-select.bp5-minimal select.bp5-intent-warning:disabled, .bp5-select.bp5-minimal .bp5-dark select.bp5-intent-warning:disabled, .bp5-dark .bp5-html-select.bp5-minimal select.bp5-intent-warning.bp5-disabled, .bp5-html-select.bp5-minimal .bp5-dark select.bp5-intent-warning.bp5-disabled, .bp5-dark .bp5-select.bp5-minimal select.bp5-intent-warning.bp5-disabled, .bp5-select.bp5-minimal .bp5-dark select.bp5-intent-warning.bp5-disabled {
  color: #fbb36080;
  background: none;
}

.bp5-dark .bp5-html-select.bp5-minimal select.bp5-intent-warning:disabled.bp5-active, .bp5-html-select.bp5-minimal .bp5-dark select.bp5-intent-warning:disabled.bp5-active, .bp5-dark .bp5-select.bp5-minimal select.bp5-intent-warning:disabled.bp5-active, .bp5-select.bp5-minimal .bp5-dark select.bp5-intent-warning:disabled.bp5-active, .bp5-dark .bp5-html-select.bp5-minimal select.bp5-intent-warning.bp5-disabled.bp5-active, .bp5-html-select.bp5-minimal .bp5-dark select.bp5-intent-warning.bp5-disabled.bp5-active, .bp5-dark .bp5-select.bp5-minimal select.bp5-intent-warning.bp5-disabled.bp5-active, .bp5-select.bp5-minimal .bp5-dark select.bp5-intent-warning.bp5-disabled.bp5-active {
  background: #c876194d;
}

.bp5-html-select.bp5-minimal select.bp5-intent-danger, .bp5-select.bp5-minimal select.bp5-intent-danger {
  color: #ac2f33;
}

.bp5-html-select.bp5-minimal select.bp5-intent-danger:hover, .bp5-select.bp5-minimal select.bp5-intent-danger:hover, .bp5-html-select.bp5-minimal select.bp5-intent-danger:active, .bp5-select.bp5-minimal select.bp5-intent-danger:active, .bp5-html-select.bp5-minimal select.bp5-intent-danger.bp5-active, .bp5-select.bp5-minimal select.bp5-intent-danger.bp5-active {
  box-shadow: none;
  color: #ac2f33;
  background: none;
}

.bp5-html-select.bp5-minimal select.bp5-intent-danger:hover, .bp5-select.bp5-minimal select.bp5-intent-danger:hover {
  color: #ac2f33;
  background: #cd424626;
}

.bp5-html-select.bp5-minimal select.bp5-intent-danger:active, .bp5-select.bp5-minimal select.bp5-intent-danger:active, .bp5-html-select.bp5-minimal select.bp5-intent-danger.bp5-active, .bp5-select.bp5-minimal select.bp5-intent-danger.bp5-active {
  color: #8e292c;
  background: #cd42464d;
}

.bp5-html-select.bp5-minimal select.bp5-intent-danger:disabled, .bp5-select.bp5-minimal select.bp5-intent-danger:disabled, .bp5-html-select.bp5-minimal select.bp5-intent-danger.bp5-disabled, .bp5-select.bp5-minimal select.bp5-intent-danger.bp5-disabled {
  color: #ac2f3380;
  background: none;
}

.bp5-html-select.bp5-minimal select.bp5-intent-danger:disabled.bp5-active, .bp5-select.bp5-minimal select.bp5-intent-danger:disabled.bp5-active, .bp5-html-select.bp5-minimal select.bp5-intent-danger.bp5-disabled.bp5-active, .bp5-select.bp5-minimal select.bp5-intent-danger.bp5-disabled.bp5-active {
  background: #cd42464d;
}

.bp5-html-select.bp5-minimal select.bp5-intent-danger .bp5-button-spinner .bp5-spinner-head, .bp5-select.bp5-minimal select.bp5-intent-danger .bp5-button-spinner .bp5-spinner-head {
  stroke: #ac2f33;
}

.bp5-dark .bp5-html-select.bp5-minimal select.bp5-intent-danger, .bp5-html-select.bp5-minimal .bp5-dark select.bp5-intent-danger, .bp5-dark .bp5-select.bp5-minimal select.bp5-intent-danger, .bp5-select.bp5-minimal .bp5-dark select.bp5-intent-danger {
  color: #fa999c;
}

.bp5-dark .bp5-html-select.bp5-minimal select.bp5-intent-danger:hover, .bp5-html-select.bp5-minimal .bp5-dark select.bp5-intent-danger:hover, .bp5-dark .bp5-select.bp5-minimal select.bp5-intent-danger:hover, .bp5-select.bp5-minimal .bp5-dark select.bp5-intent-danger:hover {
  color: #fa999c;
  background: #cd424633;
}

.bp5-dark .bp5-html-select.bp5-minimal select.bp5-intent-danger:active, .bp5-html-select.bp5-minimal .bp5-dark select.bp5-intent-danger:active, .bp5-dark .bp5-select.bp5-minimal select.bp5-intent-danger:active, .bp5-select.bp5-minimal .bp5-dark select.bp5-intent-danger:active, .bp5-dark .bp5-html-select.bp5-minimal select.bp5-intent-danger.bp5-active, .bp5-html-select.bp5-minimal .bp5-dark select.bp5-intent-danger.bp5-active, .bp5-dark .bp5-select.bp5-minimal select.bp5-intent-danger.bp5-active, .bp5-select.bp5-minimal .bp5-dark select.bp5-intent-danger.bp5-active {
  color: #ffa1a4;
  background: #cd42464d;
}

.bp5-dark .bp5-html-select.bp5-minimal select.bp5-intent-danger:disabled, .bp5-html-select.bp5-minimal .bp5-dark select.bp5-intent-danger:disabled, .bp5-dark .bp5-select.bp5-minimal select.bp5-intent-danger:disabled, .bp5-select.bp5-minimal .bp5-dark select.bp5-intent-danger:disabled, .bp5-dark .bp5-html-select.bp5-minimal select.bp5-intent-danger.bp5-disabled, .bp5-html-select.bp5-minimal .bp5-dark select.bp5-intent-danger.bp5-disabled, .bp5-dark .bp5-select.bp5-minimal select.bp5-intent-danger.bp5-disabled, .bp5-select.bp5-minimal .bp5-dark select.bp5-intent-danger.bp5-disabled {
  color: #fa999c80;
  background: none;
}

.bp5-dark .bp5-html-select.bp5-minimal select.bp5-intent-danger:disabled.bp5-active, .bp5-html-select.bp5-minimal .bp5-dark select.bp5-intent-danger:disabled.bp5-active, .bp5-dark .bp5-select.bp5-minimal select.bp5-intent-danger:disabled.bp5-active, .bp5-select.bp5-minimal .bp5-dark select.bp5-intent-danger:disabled.bp5-active, .bp5-dark .bp5-html-select.bp5-minimal select.bp5-intent-danger.bp5-disabled.bp5-active, .bp5-html-select.bp5-minimal .bp5-dark select.bp5-intent-danger.bp5-disabled.bp5-active, .bp5-dark .bp5-select.bp5-minimal select.bp5-intent-danger.bp5-disabled.bp5-active, .bp5-select.bp5-minimal .bp5-dark select.bp5-intent-danger.bp5-disabled.bp5-active {
  background: #cd42464d;
}

.bp5-html-select.bp5-large select, .bp5-select.bp5-large select {
  height: 40px;
  padding-right: 35px;
  font-size: 16px;
}

.bp5-dark .bp5-html-select select, .bp5-dark .bp5-select select {
  color: #f6f7f9;
  background-color: #383e47;
  box-shadow: inset 0 0 0 1px #ffffff1a, 0 1px 2px #11141833;
}

.bp5-dark .bp5-html-select select:hover, .bp5-dark .bp5-select select:hover, .bp5-dark .bp5-html-select select:active, .bp5-dark .bp5-select select:active, .bp5-dark .bp5-html-select select.bp5-active, .bp5-dark .bp5-select select.bp5-active {
  color: #f6f7f9;
}

.bp5-dark .bp5-html-select select:hover, .bp5-dark .bp5-select select:hover {
  background-color: #2f343c;
  box-shadow: inset 0 0 0 1px #ffffff1a, 0 1px 2px #11141866;
}

.bp5-dark .bp5-html-select select:active, .bp5-dark .bp5-select select:active, .bp5-dark .bp5-html-select select.bp5-active, .bp5-dark .bp5-select select.bp5-active {
  background-color: #1c2127;
  box-shadow: inset 0 0 0 1px #ffffff1a, 0 1px 2px #11141866;
}

.bp5-dark .bp5-html-select select:disabled, .bp5-dark .bp5-select select:disabled, .bp5-dark .bp5-html-select select.bp5-disabled, .bp5-dark .bp5-select select.bp5-disabled {
  box-shadow: none;
  color: #abb3bf99;
  background-color: #40485480;
}

.bp5-dark .bp5-html-select select:disabled.bp5-active, .bp5-dark .bp5-select select:disabled.bp5-active, .bp5-dark .bp5-html-select select.bp5-disabled.bp5-active, .bp5-dark .bp5-select select.bp5-disabled.bp5-active {
  background: #404854b3;
}

.bp5-dark .bp5-html-select select .bp5-button-spinner .bp5-spinner-head, .bp5-dark .bp5-select select .bp5-button-spinner .bp5-spinner-head {
  stroke: #8f99a8;
  background: #11141880;
}

.bp5-html-select select:disabled, .bp5-select select:disabled {
  box-shadow: none;
  color: #5f6b7c99;
  cursor: not-allowed;
  background-color: #d3d8de80;
}

.bp5-select:after, .bp5-html-select .bp5-icon, .bp5-select .bp5-icon {
  color: #5f6b7c;
  pointer-events: none;
  position: absolute;
  top: 7px;
  right: 10px;
}

.bp5-disabled.bp5-select:after, .bp5-html-select .bp5-disabled.bp5-icon, .bp5-select .bp5-disabled.bp5-icon {
  color: #5f6b7c99;
}

.bp5-html-select, .bp5-select {
  letter-spacing: normal;
  vertical-align: middle;
  display: inline-block;
  position: relative;
}

.bp5-html-select .bp5-icon, .bp5-select .bp5-icon {
  color: #5f6b7c;
}

.bp5-html-select .bp5-icon:hover, .bp5-select .bp5-icon:hover {
  color: #1c2127;
}

.bp5-dark .bp5-html-select .bp5-icon, .bp5-dark .bp5-select .bp5-icon {
  color: #abb3bf;
}

.bp5-dark .bp5-html-select .bp5-icon:hover, .bp5-dark .bp5-select .bp5-icon:hover {
  color: #f6f7f9;
}

.bp5-html-select.bp5-large:after, .bp5-html-select.bp5-large .bp5-icon, .bp5-select.bp5-large:after, .bp5-select.bp5-large .bp5-icon {
  top: 12px;
  right: 12px;
}

.bp5-html-select.bp5-fill, .bp5-html-select.bp5-fill select, .bp5-select.bp5-fill, .bp5-select.bp5-fill select {
  width: 100%;
}

.bp5-dark .bp5-html-select option, .bp5-dark .bp5-select option {
  color: #f6f7f9;
  background-color: #2f343c;
}

.bp5-dark .bp5-html-select option:disabled, .bp5-dark .bp5-select option:disabled {
  color: #abb3bf99;
}

.bp5-dark .bp5-html-select:after, .bp5-dark .bp5-select:after {
  color: #abb3bf;
}

.bp5-select:after {
  font-variant: normal;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  content: "";
  width: 16px;
  height: 16px;
  font-family: blueprint-icons-16, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 1;
}

table.bp5-html-table, .bp5-running-text table {
  border-spacing: 0;
  font-size: 14px;
}

table.bp5-html-table th, .bp5-running-text table th, table.bp5-html-table td, .bp5-running-text table td {
  text-align: left;
  vertical-align: top;
  padding: 11px;
}

table.bp5-html-table th, .bp5-running-text table th {
  color: #1c2127;
  font-weight: 600;
}

table.bp5-html-table td, .bp5-running-text table td {
  color: #1c2127;
}

table.bp5-html-table tbody tr:first-child th, .bp5-running-text table tbody tr:first-child th, table.bp5-html-table tbody tr:first-child td, .bp5-running-text table tbody tr:first-child td, table.bp5-html-table tfoot tr:first-child th, .bp5-running-text table tfoot tr:first-child th, table.bp5-html-table tfoot tr:first-child td, .bp5-running-text table tfoot tr:first-child td {
  box-shadow: inset 0 1px #11141826;
}

.bp5-dark table.bp5-html-table th, .bp5-dark .bp5-running-text table th, .bp5-running-text .bp5-dark table th, .bp5-dark table.bp5-html-table td, .bp5-dark .bp5-running-text table td, .bp5-running-text .bp5-dark table td {
  color: #f6f7f9;
}

.bp5-dark table.bp5-html-table tbody tr:first-child th, .bp5-dark .bp5-running-text table tbody tr:first-child th, .bp5-running-text .bp5-dark table tbody tr:first-child th, .bp5-dark table.bp5-html-table tbody tr:first-child td, .bp5-dark .bp5-running-text table tbody tr:first-child td, .bp5-running-text .bp5-dark table tbody tr:first-child td, .bp5-dark table.bp5-html-table tfoot tr:first-child th, .bp5-dark .bp5-running-text table tfoot tr:first-child th, .bp5-running-text .bp5-dark table tfoot tr:first-child th, .bp5-dark table.bp5-html-table tfoot tr:first-child td, .bp5-dark .bp5-running-text table tfoot tr:first-child td, .bp5-running-text .bp5-dark table tfoot tr:first-child td {
  box-shadow: inset 0 1px #fff3;
}

table.bp5-html-table.bp5-compact th, table.bp5-html-table.bp5-compact td {
  padding-top: 6px;
  padding-bottom: 6px;
}

table.bp5-html-table.bp5-html-table-striped tbody tr:nth-child(odd) td {
  background: #8f99a826;
}

table.bp5-html-table.bp5-html-table-bordered th:not(:first-child) {
  box-shadow: inset 1px 0 #11141826;
}

@media (forced-colors: active) and (prefers-color-scheme: dark) {
  table.bp5-html-table.bp5-html-table-bordered th:not(:first-child) {
    border-left: 1px solid buttonborder;
  }
}

table.bp5-html-table.bp5-html-table-bordered tbody tr td, table.bp5-html-table.bp5-html-table-bordered tfoot tr td {
  box-shadow: inset 0 1px #11141826;
}

table.bp5-html-table.bp5-html-table-bordered tbody tr td:not(:first-child), table.bp5-html-table.bp5-html-table-bordered tfoot tr td:not(:first-child) {
  box-shadow: inset 1px 1px #11141826;
}

@media (forced-colors: active) and (prefers-color-scheme: dark) {
  table.bp5-html-table.bp5-html-table-bordered tbody tr td:not(:first-child), table.bp5-html-table.bp5-html-table-bordered tfoot tr td:not(:first-child) {
    border-left: 1px solid buttonborder;
    border-top: 1px solid buttonborder;
  }

  table.bp5-html-table.bp5-html-table-bordered tbody tr td, table.bp5-html-table.bp5-html-table-bordered tfoot tr td {
    border-top: 1px solid buttonborder;
  }
}

table.bp5-html-table.bp5-html-table-bordered.bp5-html-table-striped tbody tr:not(:first-child) td {
  box-shadow: none;
}

table.bp5-html-table.bp5-html-table-bordered.bp5-html-table-striped tbody tr:not(:first-child) td:not(:first-child) {
  box-shadow: inset 1px 0 #11141826;
}

table.bp5-html-table.bp5-interactive tbody tr:hover td {
  cursor: pointer;
  background-color: #8f99a84d;
}

@media (forced-colors: active) and (prefers-color-scheme: dark) {
  table.bp5-html-table.bp5-interactive tbody tr:hover td {
    background-color: highlight;
  }
}

table.bp5-html-table.bp5-interactive tbody tr:active td {
  background-color: #8f99a859;
}

@media (forced-colors: active) and (prefers-color-scheme: dark) {
  table.bp5-html-table.bp5-interactive tbody tr:active td {
    background-color: highlight;
  }
}

.bp5-dark table.bp5-html-table.bp5-html-table-striped tbody tr:nth-child(odd) td {
  background: #5f6b7c26;
}

.bp5-dark table.bp5-html-table.bp5-html-table-bordered th:not(:first-child) {
  box-shadow: inset 1px 0 #fff3;
}

.bp5-dark table.bp5-html-table.bp5-html-table-bordered tbody tr td, .bp5-dark table.bp5-html-table.bp5-html-table-bordered tfoot tr td {
  box-shadow: inset 0 1px #fff3;
}

.bp5-dark table.bp5-html-table.bp5-html-table-bordered tbody tr td:not(:first-child), .bp5-dark table.bp5-html-table.bp5-html-table-bordered tfoot tr td:not(:first-child) {
  box-shadow: inset 1px 1px #fff3;
}

.bp5-dark table.bp5-html-table.bp5-html-table-bordered.bp5-html-table-striped tbody tr:not(:first-child) td {
  box-shadow: inset 1px 0 #fff3;
}

.bp5-dark table.bp5-html-table.bp5-html-table-bordered.bp5-html-table-striped tbody tr:not(:first-child) td:first-child {
  box-shadow: none;
}

.bp5-dark table.bp5-html-table.bp5-interactive tbody tr:hover td {
  cursor: pointer;
  background-color: #5f6b7c4d;
}

.bp5-dark table.bp5-html-table.bp5-interactive tbody tr:active td {
  background-color: #5f6b7c66;
}

.bp5-key-combo {
  flex-direction: row;
  align-items: center;
  display: flex;
}

.bp5-key-combo > * {
  flex-grow: 0;
  flex-shrink: 0;
}

.bp5-key-combo > .bp5-fill {
  flex-grow: 1;
  flex-shrink: 1;
}

.bp5-key-combo:before, .bp5-key-combo > * {
  margin-right: 5px;
}

.bp5-key-combo:empty:before, .bp5-key-combo > :last-child {
  margin-right: 0;
}

.bp5-hotkey-dialog {
  padding-bottom: 0;
  top: 40px;
}

.bp5-hotkey-dialog .bp5-dialog-body {
  margin: 0;
  padding: 0;
}

.bp5-hotkey-dialog .bp5-hotkey-label {
  flex-grow: 1;
}

.bp5-hotkey-column {
  margin: auto;
  padding: 30px;
}

.bp5-hotkey-column .bp5-heading {
  margin-bottom: 20px;
}

.bp5-hotkey-column .bp5-heading:not(:first-child) {
  margin-top: 40px;
}

.bp5-hotkey {
  justify-content: space-between;
  align-items: center;
  margin-left: 0;
  margin-right: 0;
  display: flex;
}

.bp5-hotkey:not(:last-child) {
  margin-bottom: 10px;
}

.bp5-icon {
  vertical-align: text-bottom;
  flex: none;
  display: inline-block;
}

.bp5-icon:not(:empty):before {
  content: "" !important;
  content: unset !important;
}

.bp5-icon > svg {
  display: block;
}

.bp5-icon > svg:not([fill]) {
  fill: currentColor;
}

.bp5-icon.bp5-icon-muted svg {
  fill-opacity: .15;
  overflow: visible;
}

.bp5-icon.bp5-icon-muted svg path {
  stroke: #8f99a8;
  stroke-opacity: .5;
  stroke-width: .5px;
}

.bp5-dark .bp5-icon .bp5-icon-muted svg {
  fill-opacity: .2;
}

span.bp5-icon-standard {
  font-variant: normal;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  width: 16px;
  height: 16px;
  font-family: blueprint-icons-16, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 1;
  display: inline-block;
}

span.bp5-icon-large {
  font-variant: normal;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  width: 20px;
  height: 20px;
  font-family: blueprint-icons-20, sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 1;
  display: inline-block;
}

span.bp5-icon:empty {
  font-family: blueprint-icons-20;
  font-size: inherit;
  font-style: normal;
  font-weight: 400;
  line-height: 1;
}

span.bp5-icon:empty:before {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}

span.bp5-icon:empty.bp5-icon-standard {
  font-size: 16px;
}

span.bp5-icon:empty.bp5-icon-large {
  font-size: 20px;
}

.bp5-icon-add:before {
  content: "";
}

.bp5-icon-add-clip:before {
  content: "";
}

.bp5-icon-add-column-left:before {
  content: "";
}

.bp5-icon-add-column-right:before {
  content: "";
}

.bp5-icon-add-location:before {
  content: "";
}

.bp5-icon-add-row-bottom:before {
  content: "";
}

.bp5-icon-add-row-top:before {
  content: "";
}

.bp5-icon-add-to-artifact:before {
  content: "";
}

.bp5-icon-add-to-folder:before {
  content: "";
}

.bp5-icon-aimpoints-target:before {
  content: "";
}

.bp5-icon-airplane:before {
  content: "";
}

.bp5-icon-align-center:before {
  content: "";
}

.bp5-icon-align-justify:before {
  content: "";
}

.bp5-icon-align-left:before {
  content: "";
}

.bp5-icon-align-right:before {
  content: "";
}

.bp5-icon-alignment-bottom:before {
  content: "";
}

.bp5-icon-alignment-horizontal-center:before {
  content: "";
}

.bp5-icon-alignment-left:before {
  content: "";
}

.bp5-icon-alignment-right:before {
  content: "";
}

.bp5-icon-alignment-top:before {
  content: "";
}

.bp5-icon-alignment-vertical-center:before {
  content: "";
}

.bp5-icon-ammunition:before {
  content: "";
}

.bp5-icon-anchor:before {
  content: "";
}

.bp5-icon-annotation:before {
  content: "";
}

.bp5-icon-antenna:before {
  content: "";
}

.bp5-icon-app-header:before {
  content: "";
}

.bp5-icon-application:before {
  content: "";
}

.bp5-icon-applications:before {
  content: "";
}

.bp5-icon-archive:before {
  content: "";
}

.bp5-icon-area-of-interest:before {
  content: "";
}

.bp5-icon-array:before {
  content: "";
}

.bp5-icon-array-boolean:before {
  content: "";
}

.bp5-icon-array-date:before {
  content: "";
}

.bp5-icon-array-floating-point:before {
  content: "";
}

.bp5-icon-array-numeric:before {
  content: "";
}

.bp5-icon-array-string:before {
  content: "";
}

.bp5-icon-array-timestamp:before {
  content: "";
}

.bp5-icon-arrow-bottom-left:before {
  content: "";
}

.bp5-icon-arrow-bottom-right:before {
  content: "";
}

.bp5-icon-arrow-down:before {
  content: "";
}

.bp5-icon-arrow-left:before {
  content: "";
}

.bp5-icon-arrow-right:before {
  content: "";
}

.bp5-icon-arrow-top-left:before {
  content: "";
}

.bp5-icon-arrow-top-right:before {
  content: "";
}

.bp5-icon-arrow-up:before {
  content: "";
}

.bp5-icon-arrows-horizontal:before {
  content: "";
}

.bp5-icon-arrows-vertical:before {
  content: "";
}

.bp5-icon-asterisk:before {
  content: "";
}

.bp5-icon-at:before {
  content: "";
}

.bp5-icon-automatic-updates:before {
  content: "";
}

.bp5-icon-axle:before {
  content: "";
}

.bp5-icon-backlink:before {
  content: "";
}

.bp5-icon-backward-ten:before {
  content: "";
}

.bp5-icon-badge:before {
  content: "";
}

.bp5-icon-ban-circle:before {
  content: "";
}

.bp5-icon-bank-account:before {
  content: "";
}

.bp5-icon-barcode:before {
  content: "";
}

.bp5-icon-binary-number:before {
  content: "";
}

.bp5-icon-blank:before {
  content: "";
}

.bp5-icon-blocked-person:before {
  content: "";
}

.bp5-icon-bold:before {
  content: "";
}

.bp5-icon-book:before {
  content: "";
}

.bp5-icon-bookmark:before {
  content: "";
}

.bp5-icon-box:before {
  content: "";
}

.bp5-icon-briefcase:before {
  content: "";
}

.bp5-icon-bring-data:before {
  content: "";
}

.bp5-icon-bring-forward:before {
  content: "";
}

.bp5-icon-bug:before {
  content: "";
}

.bp5-icon-buggy:before {
  content: "";
}

.bp5-icon-build:before {
  content: "";
}

.bp5-icon-bullseye:before {
  content: "";
}

.bp5-icon-calculator:before {
  content: "";
}

.bp5-icon-calendar:before {
  content: "";
}

.bp5-icon-camera:before {
  content: "";
}

.bp5-icon-caret-down:before {
  content: "";
}

.bp5-icon-caret-left:before {
  content: "";
}

.bp5-icon-caret-right:before {
  content: "";
}

.bp5-icon-caret-up:before {
  content: "";
}

.bp5-icon-cargo-ship:before {
  content: "";
}

.bp5-icon-cell-tower:before {
  content: "";
}

.bp5-icon-changes:before {
  content: "";
}

.bp5-icon-chart:before {
  content: "";
}

.bp5-icon-chat:before {
  content: "";
}

.bp5-icon-chevron-backward:before {
  content: "";
}

.bp5-icon-chevron-down:before {
  content: "";
}

.bp5-icon-chevron-forward:before {
  content: "";
}

.bp5-icon-chevron-left:before {
  content: "";
}

.bp5-icon-chevron-right:before {
  content: "";
}

.bp5-icon-chevron-up:before {
  content: "";
}

.bp5-icon-circle:before {
  content: "";
}

.bp5-icon-circle-arrow-down:before {
  content: "";
}

.bp5-icon-circle-arrow-left:before {
  content: "";
}

.bp5-icon-circle-arrow-right:before {
  content: "";
}

.bp5-icon-circle-arrow-up:before {
  content: "";
}

.bp5-icon-citation:before {
  content: "";
}

.bp5-icon-clean:before {
  content: "";
}

.bp5-icon-clip:before {
  content: "";
}

.bp5-icon-clipboard:before {
  content: "";
}

.bp5-icon-clipboard-file:before {
  content: "";
}

.bp5-icon-cloud:before {
  content: "";
}

.bp5-icon-cloud-download:before {
  content: "";
}

.bp5-icon-cloud-server:before {
  content: "";
}

.bp5-icon-cloud-tick:before {
  content: "";
}

.bp5-icon-cloud-upload:before {
  content: "";
}

.bp5-icon-code:before {
  content: "";
}

.bp5-icon-code-block:before {
  content: "";
}

.bp5-icon-cog:before {
  content: "";
}

.bp5-icon-collapse-all:before {
  content: "";
}

.bp5-icon-color-fill:before {
  content: "";
}

.bp5-icon-column-layout:before {
  content: "";
}

.bp5-icon-comment:before {
  content: "";
}

.bp5-icon-comparison:before {
  content: "";
}

.bp5-icon-compass:before {
  content: "";
}

.bp5-icon-compressed:before {
  content: "";
}

.bp5-icon-confirm:before {
  content: "";
}

.bp5-icon-console:before {
  content: "";
}

.bp5-icon-contrast:before {
  content: "";
}

.bp5-icon-control:before {
  content: "";
}

.bp5-icon-credit-card:before {
  content: "";
}

.bp5-icon-crop:before {
  content: "";
}

.bp5-icon-cross:before {
  content: "";
}

.bp5-icon-cross-circle:before {
  content: "";
}

.bp5-icon-crown:before {
  content: "";
}

.bp5-icon-css-style:before {
  content: "";
}

.bp5-icon-cube:before {
  content: "";
}

.bp5-icon-cube-add:before {
  content: "";
}

.bp5-icon-cube-remove:before {
  content: "";
}

.bp5-icon-curly-braces:before {
  content: "";
}

.bp5-icon-curved-range-chart:before {
  content: "";
}

.bp5-icon-cut:before {
  content: "";
}

.bp5-icon-cycle:before {
  content: "";
}

.bp5-icon-dashboard:before {
  content: "";
}

.bp5-icon-data-connection:before {
  content: "";
}

.bp5-icon-data-lineage:before {
  content: "";
}

.bp5-icon-data-sync:before {
  content: "";
}

.bp5-icon-database:before {
  content: "";
}

.bp5-icon-delete:before {
  content: "";
}

.bp5-icon-delta:before {
  content: "";
}

.bp5-icon-derive-column:before {
  content: "";
}

.bp5-icon-desktop:before {
  content: "";
}

.bp5-icon-detection:before {
  content: "";
}

.bp5-icon-diagnosis:before {
  content: "";
}

.bp5-icon-diagram-tree:before {
  content: "";
}

.bp5-icon-direction-left:before {
  content: "";
}

.bp5-icon-direction-right:before {
  content: "";
}

.bp5-icon-disable:before {
  content: "";
}

.bp5-icon-divide:before {
  content: "";
}

.bp5-icon-document:before {
  content: "";
}

.bp5-icon-document-open:before {
  content: "";
}

.bp5-icon-document-share:before {
  content: "";
}

.bp5-icon-dollar:before {
  content: "";
}

.bp5-icon-dot:before {
  content: "";
}

.bp5-icon-double-caret-horizontal:before {
  content: "";
}

.bp5-icon-double-caret-vertical:before {
  content: "";
}

.bp5-icon-double-chevron-down:before {
  content: "";
}

.bp5-icon-double-chevron-left:before {
  content: "";
}

.bp5-icon-double-chevron-right:before {
  content: "";
}

.bp5-icon-double-chevron-up:before {
  content: "";
}

.bp5-icon-doughnut-chart:before {
  content: "";
}

.bp5-icon-download:before {
  content: "";
}

.bp5-icon-drag-handle-horizontal:before {
  content: "";
}

.bp5-icon-drag-handle-vertical:before {
  content: "";
}

.bp5-icon-draw:before {
  content: "";
}

.bp5-icon-drawer-left:before {
  content: "";
}

.bp5-icon-drawer-left-filled:before {
  content: "";
}

.bp5-icon-drawer-right:before {
  content: "";
}

.bp5-icon-drawer-right-filled:before {
  content: "";
}

.bp5-icon-drive-time:before {
  content: "";
}

.bp5-icon-duplicate:before {
  content: "";
}

.bp5-icon-edit:before {
  content: "";
}

.bp5-icon-eject:before {
  content: "";
}

.bp5-icon-emoji:before {
  content: "";
}

.bp5-icon-endnote:before {
  content: "";
}

.bp5-icon-endorsed:before {
  content: "";
}

.bp5-icon-envelope:before {
  content: "";
}

.bp5-icon-equals:before {
  content: "";
}

.bp5-icon-eraser:before {
  content: "";
}

.bp5-icon-error:before {
  content: "";
}

.bp5-icon-euro:before {
  content: "";
}

.bp5-icon-excavator:before {
  content: "";
}

.bp5-icon-exchange:before {
  content: "";
}

.bp5-icon-exclude-row:before {
  content: "";
}

.bp5-icon-expand-all:before {
  content: "";
}

.bp5-icon-explain:before {
  content: "";
}

.bp5-icon-export:before {
  content: "";
}

.bp5-icon-eye-off:before {
  content: "";
}

.bp5-icon-eye-on:before {
  content: "";
}

.bp5-icon-eye-open:before {
  content: "";
}

.bp5-icon-fast-backward:before {
  content: "";
}

.bp5-icon-fast-forward:before {
  content: "";
}

.bp5-icon-feed:before {
  content: "";
}

.bp5-icon-feed-subscribed:before {
  content: "";
}

.bp5-icon-film:before {
  content: "";
}

.bp5-icon-filter:before {
  content: "";
}

.bp5-icon-filter-keep:before {
  content: "";
}

.bp5-icon-filter-list:before {
  content: "";
}

.bp5-icon-filter-open:before {
  content: "";
}

.bp5-icon-filter-remove:before {
  content: "";
}

.bp5-icon-flag:before {
  content: "";
}

.bp5-icon-flame:before {
  content: "";
}

.bp5-icon-flash:before {
  content: "";
}

.bp5-icon-floating-point:before {
  content: "";
}

.bp5-icon-floppy-disk:before {
  content: "";
}

.bp5-icon-flow-branch:before {
  content: "";
}

.bp5-icon-flow-end:before {
  content: "";
}

.bp5-icon-flow-linear:before {
  content: "";
}

.bp5-icon-flow-review:before {
  content: "";
}

.bp5-icon-flow-review-branch:before {
  content: "";
}

.bp5-icon-flows:before {
  content: "";
}

.bp5-icon-folder-close:before {
  content: "";
}

.bp5-icon-folder-new:before {
  content: "";
}

.bp5-icon-folder-open:before {
  content: "";
}

.bp5-icon-folder-shared:before {
  content: "";
}

.bp5-icon-folder-shared-open:before {
  content: "";
}

.bp5-icon-follower:before {
  content: "";
}

.bp5-icon-following:before {
  content: "";
}

.bp5-icon-font:before {
  content: "";
}

.bp5-icon-fork:before {
  content: "";
}

.bp5-icon-form:before {
  content: "";
}

.bp5-icon-forward-ten:before {
  content: "";
}

.bp5-icon-fuel:before {
  content: "";
}

.bp5-icon-full-circle:before {
  content: "";
}

.bp5-icon-full-stacked-chart:before {
  content: "";
}

.bp5-icon-fullscreen:before {
  content: "";
}

.bp5-icon-function:before {
  content: "";
}

.bp5-icon-gantt-chart:before {
  content: "";
}

.bp5-icon-generate:before {
  content: "";
}

.bp5-icon-geofence:before {
  content: "";
}

.bp5-icon-geolocation:before {
  content: "";
}

.bp5-icon-geosearch:before {
  content: "";
}

.bp5-icon-geotime:before {
  content: "";
}

.bp5-icon-git-branch:before {
  content: "";
}

.bp5-icon-git-commit:before {
  content: "";
}

.bp5-icon-git-merge:before {
  content: "";
}

.bp5-icon-git-new-branch:before {
  content: "";
}

.bp5-icon-git-pull:before {
  content: "";
}

.bp5-icon-git-push:before {
  content: "";
}

.bp5-icon-git-repo:before {
  content: "";
}

.bp5-icon-glass:before {
  content: "";
}

.bp5-icon-globe:before {
  content: "";
}

.bp5-icon-globe-network:before {
  content: "";
}

.bp5-icon-graph:before {
  content: "";
}

.bp5-icon-graph-remove:before {
  content: "";
}

.bp5-icon-greater-than:before {
  content: "";
}

.bp5-icon-greater-than-or-equal-to:before {
  content: "";
}

.bp5-icon-grid:before {
  content: "";
}

.bp5-icon-grid-view:before {
  content: "";
}

.bp5-icon-group-item:before {
  content: "";
}

.bp5-icon-group-objects:before {
  content: "";
}

.bp5-icon-grouped-bar-chart:before {
  content: "";
}

.bp5-icon-hand:before {
  content: "";
}

.bp5-icon-hand-down:before {
  content: "";
}

.bp5-icon-hand-left:before {
  content: "";
}

.bp5-icon-hand-right:before {
  content: "";
}

.bp5-icon-hand-up:before {
  content: "";
}

.bp5-icon-hat:before {
  content: "";
}

.bp5-icon-header:before {
  content: "";
}

.bp5-icon-header-one:before {
  content: "";
}

.bp5-icon-header-three:before {
  content: "";
}

.bp5-icon-header-two:before {
  content: "";
}

.bp5-icon-headset:before {
  content: "";
}

.bp5-icon-heart:before {
  content: "";
}

.bp5-icon-heart-broken:before {
  content: "";
}

.bp5-icon-heat-grid:before {
  content: "";
}

.bp5-icon-heatmap:before {
  content: "";
}

.bp5-icon-helicopter:before {
  content: "";
}

.bp5-icon-help:before {
  content: "";
}

.bp5-icon-helper-management:before {
  content: "";
}

.bp5-icon-high-priority:before {
  content: "";
}

.bp5-icon-high-voltage-pole:before {
  content: "";
}

.bp5-icon-highlight:before {
  content: "";
}

.bp5-icon-history:before {
  content: "";
}

.bp5-icon-home:before {
  content: "";
}

.bp5-icon-horizontal-bar-chart:before {
  content: "";
}

.bp5-icon-horizontal-bar-chart-asc:before {
  content: "";
}

.bp5-icon-horizontal-bar-chart-desc:before {
  content: "";
}

.bp5-icon-horizontal-distribution:before {
  content: "";
}

.bp5-icon-horizontal-inbetween:before {
  content: "";
}

.bp5-icon-hurricane:before {
  content: "";
}

.bp5-icon-id-number:before {
  content: "";
}

.bp5-icon-image-rotate-left:before {
  content: "";
}

.bp5-icon-image-rotate-right:before {
  content: "";
}

.bp5-icon-import:before {
  content: "";
}

.bp5-icon-inbox:before {
  content: "";
}

.bp5-icon-inbox-filtered:before {
  content: "";
}

.bp5-icon-inbox-geo:before {
  content: "";
}

.bp5-icon-inbox-search:before {
  content: "";
}

.bp5-icon-inbox-update:before {
  content: "";
}

.bp5-icon-info-sign:before {
  content: "";
}

.bp5-icon-inheritance:before {
  content: "";
}

.bp5-icon-inherited-group:before {
  content: "";
}

.bp5-icon-inner-join:before {
  content: "";
}

.bp5-icon-input:before {
  content: "";
}

.bp5-icon-insert:before {
  content: "";
}

.bp5-icon-intelligence:before {
  content: "";
}

.bp5-icon-intersection:before {
  content: "";
}

.bp5-icon-ip-address:before {
  content: "";
}

.bp5-icon-issue:before {
  content: "";
}

.bp5-icon-issue-closed:before {
  content: "";
}

.bp5-icon-issue-new:before {
  content: "";
}

.bp5-icon-italic:before {
  content: "";
}

.bp5-icon-join-table:before {
  content: "";
}

.bp5-icon-key:before {
  content: "";
}

.bp5-icon-key-backspace:before {
  content: "";
}

.bp5-icon-key-command:before {
  content: "";
}

.bp5-icon-key-control:before {
  content: "";
}

.bp5-icon-key-delete:before {
  content: "";
}

.bp5-icon-key-enter:before {
  content: "";
}

.bp5-icon-key-escape:before {
  content: "";
}

.bp5-icon-key-option:before {
  content: "";
}

.bp5-icon-key-shift:before {
  content: "";
}

.bp5-icon-key-tab:before {
  content: "";
}

.bp5-icon-known-vehicle:before {
  content: "";
}

.bp5-icon-lab-test:before {
  content: "";
}

.bp5-icon-label:before {
  content: "";
}

.bp5-icon-layer:before {
  content: "";
}

.bp5-icon-layer-outline:before {
  content: "";
}

.bp5-icon-layers:before {
  content: "";
}

.bp5-icon-layout:before {
  content: "";
}

.bp5-icon-layout-auto:before {
  content: "";
}

.bp5-icon-layout-balloon:before {
  content: "";
}

.bp5-icon-layout-bottom-row-three-tiles:before {
  content: "";
}

.bp5-icon-layout-bottom-row-two-tiles:before {
  content: "";
}

.bp5-icon-layout-circle:before {
  content: "";
}

.bp5-icon-layout-grid:before {
  content: "";
}

.bp5-icon-layout-group-by:before {
  content: "";
}

.bp5-icon-layout-hierarchy:before {
  content: "";
}

.bp5-icon-layout-left-column-three-tiles:before {
  content: "";
}

.bp5-icon-layout-left-column-two-tiles:before {
  content: "";
}

.bp5-icon-layout-linear:before {
  content: "";
}

.bp5-icon-layout-right-column-three-tiles:before {
  content: "";
}

.bp5-icon-layout-right-column-two-tiles:before {
  content: "";
}

.bp5-icon-layout-skew-grid:before {
  content: "";
}

.bp5-icon-layout-sorted-clusters:before {
  content: "";
}

.bp5-icon-layout-three-columns:before {
  content: "";
}

.bp5-icon-layout-three-rows:before {
  content: "";
}

.bp5-icon-layout-top-row-three-tiles:before {
  content: "";
}

.bp5-icon-layout-top-row-two-tiles:before {
  content: "";
}

.bp5-icon-layout-two-columns:before {
  content: "";
}

.bp5-icon-layout-two-rows:before {
  content: "";
}

.bp5-icon-learning:before {
  content: "";
}

.bp5-icon-left-join:before {
  content: "";
}

.bp5-icon-lengthen-text:before {
  content: "";
}

.bp5-icon-less-than:before {
  content: "";
}

.bp5-icon-less-than-or-equal-to:before {
  content: "";
}

.bp5-icon-lifesaver:before {
  content: "";
}

.bp5-icon-lightbulb:before {
  content: "";
}

.bp5-icon-lightning:before {
  content: "";
}

.bp5-icon-link:before {
  content: "";
}

.bp5-icon-list:before {
  content: "";
}

.bp5-icon-list-columns:before {
  content: "";
}

.bp5-icon-list-detail-view:before {
  content: "";
}

.bp5-icon-locate:before {
  content: "";
}

.bp5-icon-lock:before {
  content: "";
}

.bp5-icon-locomotive:before {
  content: "";
}

.bp5-icon-log-in:before {
  content: "";
}

.bp5-icon-log-out:before {
  content: "";
}

.bp5-icon-low-voltage-pole:before {
  content: "";
}

.bp5-icon-manual:before {
  content: "";
}

.bp5-icon-manually-entered-data:before {
  content: "";
}

.bp5-icon-many-to-many:before {
  content: "";
}

.bp5-icon-many-to-one:before {
  content: "";
}

.bp5-icon-map:before {
  content: "";
}

.bp5-icon-map-create:before {
  content: "";
}

.bp5-icon-map-marker:before {
  content: "";
}

.bp5-icon-maximize:before {
  content: "";
}

.bp5-icon-media:before {
  content: "";
}

.bp5-icon-menu:before {
  content: "";
}

.bp5-icon-menu-closed:before {
  content: "";
}

.bp5-icon-menu-open:before {
  content: "";
}

.bp5-icon-merge-columns:before {
  content: "";
}

.bp5-icon-merge-links:before {
  content: "";
}

.bp5-icon-microphone:before {
  content: "";
}

.bp5-icon-minimize:before {
  content: "";
}

.bp5-icon-minus:before {
  content: "";
}

.bp5-icon-mobile-phone:before {
  content: "";
}

.bp5-icon-mobile-video:before {
  content: "";
}

.bp5-icon-modal:before {
  content: "";
}

.bp5-icon-modal-filled:before {
  content: "";
}

.bp5-icon-model:before {
  content: "";
}

.bp5-icon-moon:before {
  content: "";
}

.bp5-icon-more:before {
  content: "";
}

.bp5-icon-mountain:before {
  content: "";
}

.bp5-icon-move:before {
  content: "";
}

.bp5-icon-mugshot:before {
  content: "";
}

.bp5-icon-multi-select:before {
  content: "";
}

.bp5-icon-music:before {
  content: "";
}

.bp5-icon-nest:before {
  content: "";
}

.bp5-icon-new-drawing:before {
  content: "";
}

.bp5-icon-new-grid-item:before {
  content: "";
}

.bp5-icon-new-layer:before {
  content: "";
}

.bp5-icon-new-layers:before {
  content: "";
}

.bp5-icon-new-link:before {
  content: "";
}

.bp5-icon-new-object:before {
  content: "";
}

.bp5-icon-new-person:before {
  content: "";
}

.bp5-icon-new-prescription:before {
  content: "";
}

.bp5-icon-new-shield:before {
  content: "";
}

.bp5-icon-new-text-box:before {
  content: "";
}

.bp5-icon-ninja:before {
  content: "";
}

.bp5-icon-not-equal-to:before {
  content: "";
}

.bp5-icon-notifications:before {
  content: "";
}

.bp5-icon-notifications-snooze:before {
  content: "";
}

.bp5-icon-notifications-updated:before {
  content: "";
}

.bp5-icon-numbered-list:before {
  content: "";
}

.bp5-icon-numerical:before {
  content: "";
}

.bp5-icon-office:before {
  content: "";
}

.bp5-icon-offline:before {
  content: "";
}

.bp5-icon-oil-field:before {
  content: "";
}

.bp5-icon-one-column:before {
  content: "";
}

.bp5-icon-one-to-many:before {
  content: "";
}

.bp5-icon-one-to-one:before {
  content: "";
}

.bp5-icon-open-application:before {
  content: "";
}

.bp5-icon-outdated:before {
  content: "";
}

.bp5-icon-page-layout:before {
  content: "";
}

.bp5-icon-panel-stats:before {
  content: "";
}

.bp5-icon-panel-table:before {
  content: "";
}

.bp5-icon-paperclip:before {
  content: "";
}

.bp5-icon-paragraph:before {
  content: "";
}

.bp5-icon-paste-variable:before {
  content: "";
}

.bp5-icon-path:before {
  content: "";
}

.bp5-icon-path-search:before {
  content: "";
}

.bp5-icon-pause:before {
  content: "";
}

.bp5-icon-people:before {
  content: "";
}

.bp5-icon-percentage:before {
  content: "";
}

.bp5-icon-person:before {
  content: "";
}

.bp5-icon-phone:before {
  content: "";
}

.bp5-icon-phone-call:before {
  content: "";
}

.bp5-icon-phone-forward:before {
  content: "";
}

.bp5-icon-pie-chart:before {
  content: "";
}

.bp5-icon-pin:before {
  content: "";
}

.bp5-icon-pivot:before {
  content: "";
}

.bp5-icon-pivot-table:before {
  content: "";
}

.bp5-icon-play:before {
  content: "";
}

.bp5-icon-playbook:before {
  content: "";
}

.bp5-icon-plus:before {
  content: "";
}

.bp5-icon-polygon-filter:before {
  content: "";
}

.bp5-icon-power:before {
  content: "";
}

.bp5-icon-predictive-analysis:before {
  content: "";
}

.bp5-icon-prescription:before {
  content: "";
}

.bp5-icon-presentation:before {
  content: "";
}

.bp5-icon-print:before {
  content: "";
}

.bp5-icon-projects:before {
  content: "";
}

.bp5-icon-properties:before {
  content: "";
}

.bp5-icon-property:before {
  content: "";
}

.bp5-icon-publish-function:before {
  content: "";
}

.bp5-icon-pulse:before {
  content: "";
}

.bp5-icon-rain:before {
  content: "";
}

.bp5-icon-random:before {
  content: "";
}

.bp5-icon-record:before {
  content: "";
}

.bp5-icon-rect-height:before {
  content: "";
}

.bp5-icon-rect-width:before {
  content: "";
}

.bp5-icon-rectangle:before {
  content: "";
}

.bp5-icon-redo:before {
  content: "";
}

.bp5-icon-refresh:before {
  content: "";
}

.bp5-icon-regex:before {
  content: "";
}

.bp5-icon-regression-chart:before {
  content: "";
}

.bp5-icon-remove:before {
  content: "";
}

.bp5-icon-remove-column:before {
  content: "";
}

.bp5-icon-remove-column-left:before {
  content: "";
}

.bp5-icon-remove-column-right:before {
  content: "";
}

.bp5-icon-remove-row-bottom:before {
  content: "";
}

.bp5-icon-remove-row-top:before {
  content: "";
}

.bp5-icon-repeat:before {
  content: "";
}

.bp5-icon-reset:before {
  content: "";
}

.bp5-icon-resolve:before {
  content: "";
}

.bp5-icon-rig:before {
  content: "";
}

.bp5-icon-right-join:before {
  content: "";
}

.bp5-icon-ring:before {
  content: "";
}

.bp5-icon-rocket:before {
  content: "";
}

.bp5-icon-rocket-slant:before {
  content: "";
}

.bp5-icon-rotate-document:before {
  content: "";
}

.bp5-icon-rotate-page:before {
  content: "";
}

.bp5-icon-route:before {
  content: "";
}

.bp5-icon-satellite:before {
  content: "";
}

.bp5-icon-saved:before {
  content: "";
}

.bp5-icon-scatter-plot:before {
  content: "";
}

.bp5-icon-search:before {
  content: "";
}

.bp5-icon-search-around:before {
  content: "";
}

.bp5-icon-search-template:before {
  content: "";
}

.bp5-icon-search-text:before {
  content: "";
}

.bp5-icon-segmented-control:before {
  content: "";
}

.bp5-icon-select:before {
  content: "";
}

.bp5-icon-selection:before {
  content: "";
}

.bp5-icon-send-backward:before {
  content: "";
}

.bp5-icon-send-message:before {
  content: "";
}

.bp5-icon-send-to:before {
  content: "";
}

.bp5-icon-send-to-graph:before {
  content: "";
}

.bp5-icon-send-to-map:before {
  content: "";
}

.bp5-icon-sensor:before {
  content: "";
}

.bp5-icon-series-add:before {
  content: "";
}

.bp5-icon-series-configuration:before {
  content: "";
}

.bp5-icon-series-derived:before {
  content: "";
}

.bp5-icon-series-filtered:before {
  content: "";
}

.bp5-icon-series-search:before {
  content: "";
}

.bp5-icon-settings:before {
  content: "";
}

.bp5-icon-shapes:before {
  content: "";
}

.bp5-icon-share:before {
  content: "";
}

.bp5-icon-shared-filter:before {
  content: "";
}

.bp5-icon-shield:before {
  content: "";
}

.bp5-icon-ship:before {
  content: "";
}

.bp5-icon-shop:before {
  content: "";
}

.bp5-icon-shopping-cart:before {
  content: "";
}

.bp5-icon-shorten-text:before {
  content: "";
}

.bp5-icon-signal-search:before {
  content: "";
}

.bp5-icon-sim-card:before {
  content: "";
}

.bp5-icon-slash:before {
  content: "";
}

.bp5-icon-small-cross:before {
  content: "";
}

.bp5-icon-small-info-sign:before {
  content: "";
}

.bp5-icon-small-minus:before {
  content: "";
}

.bp5-icon-small-plus:before {
  content: "";
}

.bp5-icon-small-square:before {
  content: "";
}

.bp5-icon-small-tick:before {
  content: "";
}

.bp5-icon-snowflake:before {
  content: "";
}

.bp5-icon-soccer-ball:before {
  content: "";
}

.bp5-icon-social-media:before {
  content: "";
}

.bp5-icon-sort:before {
  content: "";
}

.bp5-icon-sort-alphabetical:before {
  content: "";
}

.bp5-icon-sort-alphabetical-desc:before {
  content: "";
}

.bp5-icon-sort-asc:before {
  content: "";
}

.bp5-icon-sort-desc:before {
  content: "";
}

.bp5-icon-sort-numerical:before {
  content: "";
}

.bp5-icon-sort-numerical-desc:before {
  content: "";
}

.bp5-icon-spell-check:before {
  content: "";
}

.bp5-icon-split-columns:before {
  content: "";
}

.bp5-icon-sports-stadium:before {
  content: "";
}

.bp5-icon-square:before {
  content: "";
}

.bp5-icon-stacked-chart:before {
  content: "";
}

.bp5-icon-stadium-geometry:before {
  content: "";
}

.bp5-icon-star:before {
  content: "";
}

.bp5-icon-star-empty:before {
  content: "";
}

.bp5-icon-step-backward:before {
  content: "";
}

.bp5-icon-step-chart:before {
  content: "";
}

.bp5-icon-step-forward:before {
  content: "";
}

.bp5-icon-stop:before {
  content: "";
}

.bp5-icon-stopwatch:before {
  content: "";
}

.bp5-icon-strikethrough:before {
  content: "";
}

.bp5-icon-style:before {
  content: "";
}

.bp5-icon-subscript:before {
  content: "";
}

.bp5-icon-superscript:before {
  content: "";
}

.bp5-icon-swap-horizontal:before {
  content: "";
}

.bp5-icon-swap-vertical:before {
  content: "";
}

.bp5-icon-switch:before {
  content: "";
}

.bp5-icon-symbol-circle:before {
  content: "";
}

.bp5-icon-symbol-cross:before {
  content: "";
}

.bp5-icon-symbol-diamond:before {
  content: "";
}

.bp5-icon-symbol-rectangle:before {
  content: "";
}

.bp5-icon-symbol-square:before {
  content: "";
}

.bp5-icon-symbol-triangle-down:before {
  content: "";
}

.bp5-icon-symbol-triangle-up:before {
  content: "";
}

.bp5-icon-syringe:before {
  content: "";
}

.bp5-icon-tag:before {
  content: "";
}

.bp5-icon-take-action:before {
  content: "";
}

.bp5-icon-tank:before {
  content: "";
}

.bp5-icon-target:before {
  content: "";
}

.bp5-icon-taxi:before {
  content: "";
}

.bp5-icon-team:before {
  content: "";
}

.bp5-icon-temperature:before {
  content: "";
}

.bp5-icon-text-highlight:before {
  content: "";
}

.bp5-icon-th:before {
  content: "";
}

.bp5-icon-th-derived:before {
  content: "";
}

.bp5-icon-th-disconnect:before {
  content: "";
}

.bp5-icon-th-filtered:before {
  content: "";
}

.bp5-icon-th-list:before {
  content: "";
}

.bp5-icon-third-party:before {
  content: "";
}

.bp5-icon-thumbs-down:before {
  content: "";
}

.bp5-icon-thumbs-up:before {
  content: "";
}

.bp5-icon-tick:before {
  content: "";
}

.bp5-icon-tick-circle:before {
  content: "";
}

.bp5-icon-time:before {
  content: "";
}

.bp5-icon-timeline-area-chart:before {
  content: "";
}

.bp5-icon-timeline-bar-chart:before {
  content: "";
}

.bp5-icon-timeline-events:before {
  content: "";
}

.bp5-icon-timeline-line-chart:before {
  content: "";
}

.bp5-icon-tint:before {
  content: "";
}

.bp5-icon-torch:before {
  content: "";
}

.bp5-icon-tractor:before {
  content: "";
}

.bp5-icon-train:before {
  content: "";
}

.bp5-icon-translate:before {
  content: "";
}

.bp5-icon-trash:before {
  content: "";
}

.bp5-icon-tree:before {
  content: "";
}

.bp5-icon-trending-down:before {
  content: "";
}

.bp5-icon-trending-up:before {
  content: "";
}

.bp5-icon-trophy:before {
  content: "";
}

.bp5-icon-truck:before {
  content: "";
}

.bp5-icon-two-columns:before {
  content: "";
}

.bp5-icon-unarchive:before {
  content: "";
}

.bp5-icon-underline:before {
  content: "";
}

.bp5-icon-undo:before {
  content: "";
}

.bp5-icon-ungroup-objects:before {
  content: "";
}

.bp5-icon-unknown-vehicle:before {
  content: "";
}

.bp5-icon-unlink:before {
  content: "";
}

.bp5-icon-unlock:before {
  content: "";
}

.bp5-icon-unpin:before {
  content: "";
}

.bp5-icon-unresolve:before {
  content: "";
}

.bp5-icon-updated:before {
  content: "";
}

.bp5-icon-upload:before {
  content: "";
}

.bp5-icon-user:before {
  content: "";
}

.bp5-icon-variable:before {
  content: "";
}

.bp5-icon-vector:before {
  content: "";
}

.bp5-icon-vertical-bar-chart-asc:before {
  content: "";
}

.bp5-icon-vertical-bar-chart-desc:before {
  content: "";
}

.bp5-icon-vertical-distribution:before {
  content: "";
}

.bp5-icon-vertical-inbetween:before {
  content: "";
}

.bp5-icon-video:before {
  content: "";
}

.bp5-icon-virus:before {
  content: "";
}

.bp5-icon-volume-down:before {
  content: "";
}

.bp5-icon-volume-off:before {
  content: "";
}

.bp5-icon-volume-up:before {
  content: "";
}

.bp5-icon-walk:before {
  content: "";
}

.bp5-icon-warning-sign:before {
  content: "";
}

.bp5-icon-waterfall-chart:before {
  content: "";
}

.bp5-icon-waves:before {
  content: "";
}

.bp5-icon-widget:before {
  content: "";
}

.bp5-icon-widget-button:before {
  content: "";
}

.bp5-icon-widget-footer:before {
  content: "";
}

.bp5-icon-widget-header:before {
  content: "";
}

.bp5-icon-wind:before {
  content: "";
}

.bp5-icon-wrench:before {
  content: "";
}

.bp5-icon-zoom-in:before {
  content: "";
}

.bp5-icon-zoom-out:before {
  content: "";
}

.bp5-icon-zoom-to-fit:before {
  content: "";
}

.bp5-submenu > .bp5-popover-wrapper, .bp5-submenu .bp5-popover-target {
  display: block;
}

.bp5-submenu .bp5-popover-target.bp5-popover-open > .bp5-menu-item:not([class*="bp5-intent-"]) {
  color: inherit;
  cursor: pointer;
  background-color: #8f99a826;
  text-decoration: none;
}

.bp5-dark .bp5-submenu .bp5-popover-target.bp5-popover-open > .bp5-menu-item:not([class*="bp5-intent-"]) {
  color: inherit;
}

.bp5-dark .bp5-submenu .bp5-popover-target.bp5-popover-open > .bp5-menu-item:not([class*="bp5-intent-"]) .bp5-menu-item-icon, .bp5-dark .bp5-submenu .bp5-popover-target.bp5-popover-open > .bp5-menu-item:not([class*="bp5-intent-"]) .bp5-submenu-icon {
  color: #abb3bf;
}

.bp5-submenu .bp5-popover-target.bp5-popover-open > .bp5-menu-item[class*="bp5-intent-"], .bp5-submenu .bp5-popover-target.bp5-popover-open > .bp5-menu-item[class*="bp5-intent-"]:hover, .bp5-submenu .bp5-popover-target.bp5-popover-open > .bp5-menu-item[class*="bp5-intent-"]:active {
  color: #215db0;
  background-color: #2d72d21a;
}

.bp5-submenu .bp5-popover-target.bp5-popover-open > .bp5-menu-item[class*="bp5-intent-"] .bp5-menu-item-label, .bp5-submenu .bp5-popover-target.bp5-popover-open > .bp5-menu-item[class*="bp5-intent-"]:hover .bp5-menu-item-label, .bp5-submenu .bp5-popover-target.bp5-popover-open > .bp5-menu-item[class*="bp5-intent-"]:active .bp5-menu-item-label {
  color: inherit;
}

.bp5-submenu .bp5-popover-target.bp5-popover-open > .bp5-menu-item[class*="bp5-intent-"]:before, .bp5-submenu .bp5-popover-target.bp5-popover-open > .bp5-menu-item[class*="bp5-intent-"] .bp5-menu-item-icon, .bp5-submenu .bp5-popover-target.bp5-popover-open > .bp5-menu-item[class*="bp5-intent-"] .bp5-menu-item-selected-icon, .bp5-submenu .bp5-popover-target.bp5-popover-open > .bp5-menu-item[class*="bp5-intent-"] .bp5-submenu-icon, .bp5-submenu .bp5-popover-target.bp5-popover-open > .bp5-menu-item[class*="bp5-intent-"]:hover:before, .bp5-submenu .bp5-popover-target.bp5-popover-open > .bp5-menu-item[class*="bp5-intent-"]:hover .bp5-menu-item-icon, .bp5-submenu .bp5-popover-target.bp5-popover-open > .bp5-menu-item[class*="bp5-intent-"]:hover .bp5-menu-item-selected-icon, .bp5-submenu .bp5-popover-target.bp5-popover-open > .bp5-menu-item[class*="bp5-intent-"]:hover .bp5-submenu-icon, .bp5-submenu .bp5-popover-target.bp5-popover-open > .bp5-menu-item[class*="bp5-intent-"]:active:before, .bp5-submenu .bp5-popover-target.bp5-popover-open > .bp5-menu-item[class*="bp5-intent-"]:active .bp5-menu-item-icon, .bp5-submenu .bp5-popover-target.bp5-popover-open > .bp5-menu-item[class*="bp5-intent-"]:active .bp5-menu-item-selected-icon, .bp5-submenu .bp5-popover-target.bp5-popover-open > .bp5-menu-item[class*="bp5-intent-"]:active .bp5-submenu-icon {
  color: #215db0;
}

.bp5-submenu .bp5-popover-target.bp5-popover-open > .bp5-menu-item[class*="bp5-intent-"].bp5-intent-success, .bp5-submenu .bp5-popover-target.bp5-popover-open > .bp5-menu-item[class*="bp5-intent-"]:hover.bp5-intent-success, .bp5-submenu .bp5-popover-target.bp5-popover-open > .bp5-menu-item[class*="bp5-intent-"]:active.bp5-intent-success {
  color: #1c6e42;
  background-color: #2385511a;
}

.bp5-submenu .bp5-popover-target.bp5-popover-open > .bp5-menu-item[class*="bp5-intent-"].bp5-intent-success:before, .bp5-submenu .bp5-popover-target.bp5-popover-open > .bp5-menu-item[class*="bp5-intent-"].bp5-intent-success .bp5-menu-item-icon, .bp5-submenu .bp5-popover-target.bp5-popover-open > .bp5-menu-item[class*="bp5-intent-"].bp5-intent-success .bp5-submenu-icon, .bp5-submenu .bp5-popover-target.bp5-popover-open > .bp5-menu-item[class*="bp5-intent-"]:hover.bp5-intent-success:before, .bp5-submenu .bp5-popover-target.bp5-popover-open > .bp5-menu-item[class*="bp5-intent-"]:hover.bp5-intent-success .bp5-menu-item-icon, .bp5-submenu .bp5-popover-target.bp5-popover-open > .bp5-menu-item[class*="bp5-intent-"]:hover.bp5-intent-success .bp5-submenu-icon, .bp5-submenu .bp5-popover-target.bp5-popover-open > .bp5-menu-item[class*="bp5-intent-"]:active.bp5-intent-success:before, .bp5-submenu .bp5-popover-target.bp5-popover-open > .bp5-menu-item[class*="bp5-intent-"]:active.bp5-intent-success .bp5-menu-item-icon, .bp5-submenu .bp5-popover-target.bp5-popover-open > .bp5-menu-item[class*="bp5-intent-"]:active.bp5-intent-success .bp5-submenu-icon {
  color: inherit;
}

.bp5-submenu .bp5-popover-target.bp5-popover-open > .bp5-menu-item[class*="bp5-intent-"].bp5-intent-warning, .bp5-submenu .bp5-popover-target.bp5-popover-open > .bp5-menu-item[class*="bp5-intent-"]:hover.bp5-intent-warning, .bp5-submenu .bp5-popover-target.bp5-popover-open > .bp5-menu-item[class*="bp5-intent-"]:active.bp5-intent-warning {
  color: #935610;
  background-color: #c876191a;
}

.bp5-submenu .bp5-popover-target.bp5-popover-open > .bp5-menu-item[class*="bp5-intent-"].bp5-intent-warning:before, .bp5-submenu .bp5-popover-target.bp5-popover-open > .bp5-menu-item[class*="bp5-intent-"].bp5-intent-warning .bp5-menu-item-icon, .bp5-submenu .bp5-popover-target.bp5-popover-open > .bp5-menu-item[class*="bp5-intent-"].bp5-intent-warning .bp5-submenu-icon, .bp5-submenu .bp5-popover-target.bp5-popover-open > .bp5-menu-item[class*="bp5-intent-"]:hover.bp5-intent-warning:before, .bp5-submenu .bp5-popover-target.bp5-popover-open > .bp5-menu-item[class*="bp5-intent-"]:hover.bp5-intent-warning .bp5-menu-item-icon, .bp5-submenu .bp5-popover-target.bp5-popover-open > .bp5-menu-item[class*="bp5-intent-"]:hover.bp5-intent-warning .bp5-submenu-icon, .bp5-submenu .bp5-popover-target.bp5-popover-open > .bp5-menu-item[class*="bp5-intent-"]:active.bp5-intent-warning:before, .bp5-submenu .bp5-popover-target.bp5-popover-open > .bp5-menu-item[class*="bp5-intent-"]:active.bp5-intent-warning .bp5-menu-item-icon, .bp5-submenu .bp5-popover-target.bp5-popover-open > .bp5-menu-item[class*="bp5-intent-"]:active.bp5-intent-warning .bp5-submenu-icon {
  color: inherit;
}

.bp5-submenu .bp5-popover-target.bp5-popover-open > .bp5-menu-item[class*="bp5-intent-"].bp5-intent-danger, .bp5-submenu .bp5-popover-target.bp5-popover-open > .bp5-menu-item[class*="bp5-intent-"]:hover.bp5-intent-danger, .bp5-submenu .bp5-popover-target.bp5-popover-open > .bp5-menu-item[class*="bp5-intent-"]:active.bp5-intent-danger {
  color: #ac2f33;
  background-color: #cd42461a;
}

.bp5-submenu .bp5-popover-target.bp5-popover-open > .bp5-menu-item[class*="bp5-intent-"].bp5-intent-danger:before, .bp5-submenu .bp5-popover-target.bp5-popover-open > .bp5-menu-item[class*="bp5-intent-"].bp5-intent-danger .bp5-menu-item-icon, .bp5-submenu .bp5-popover-target.bp5-popover-open > .bp5-menu-item[class*="bp5-intent-"].bp5-intent-danger .bp5-submenu-icon, .bp5-submenu .bp5-popover-target.bp5-popover-open > .bp5-menu-item[class*="bp5-intent-"]:hover.bp5-intent-danger:before, .bp5-submenu .bp5-popover-target.bp5-popover-open > .bp5-menu-item[class*="bp5-intent-"]:hover.bp5-intent-danger .bp5-menu-item-icon, .bp5-submenu .bp5-popover-target.bp5-popover-open > .bp5-menu-item[class*="bp5-intent-"]:hover.bp5-intent-danger .bp5-submenu-icon, .bp5-submenu .bp5-popover-target.bp5-popover-open > .bp5-menu-item[class*="bp5-intent-"]:active.bp5-intent-danger:before, .bp5-submenu .bp5-popover-target.bp5-popover-open > .bp5-menu-item[class*="bp5-intent-"]:active.bp5-intent-danger .bp5-menu-item-icon, .bp5-submenu .bp5-popover-target.bp5-popover-open > .bp5-menu-item[class*="bp5-intent-"]:active.bp5-intent-danger .bp5-submenu-icon {
  color: inherit;
}

@media (forced-colors: active) and (prefers-color-scheme: dark) {
  .bp5-submenu .bp5-popover-target.bp5-popover-open > .bp5-menu-item[class*="bp5-intent-"], .bp5-submenu .bp5-popover-target.bp5-popover-open > .bp5-menu-item[class*="bp5-intent-"]:hover, .bp5-submenu .bp5-popover-target.bp5-popover-open > .bp5-menu-item[class*="bp5-intent-"]:active {
    background-color: highlight;
  }
}

.bp5-dark .bp5-submenu .bp5-popover-target.bp5-popover-open > .bp5-menu-item[class*="bp5-intent-"], .bp5-dark .bp5-submenu .bp5-popover-target.bp5-popover-open > .bp5-menu-item[class*="bp5-intent-"]:hover, .bp5-dark .bp5-submenu .bp5-popover-target.bp5-popover-open > .bp5-menu-item[class*="bp5-intent-"]:active {
  color: #8abbff;
  background-color: #2d72d233;
}

.bp5-dark .bp5-submenu .bp5-popover-target.bp5-popover-open > .bp5-menu-item[class*="bp5-intent-"]:before, .bp5-dark .bp5-submenu .bp5-popover-target.bp5-popover-open > .bp5-menu-item[class*="bp5-intent-"] .bp5-menu-item-icon, .bp5-dark .bp5-submenu .bp5-popover-target.bp5-popover-open > .bp5-menu-item[class*="bp5-intent-"] .bp5-menu-item-selected-icon, .bp5-dark .bp5-submenu .bp5-popover-target.bp5-popover-open > .bp5-menu-item[class*="bp5-intent-"] .bp5-submenu-icon, .bp5-dark .bp5-submenu .bp5-popover-target.bp5-popover-open > .bp5-menu-item[class*="bp5-intent-"]:hover:before, .bp5-dark .bp5-submenu .bp5-popover-target.bp5-popover-open > .bp5-menu-item[class*="bp5-intent-"]:hover .bp5-menu-item-icon, .bp5-dark .bp5-submenu .bp5-popover-target.bp5-popover-open > .bp5-menu-item[class*="bp5-intent-"]:hover .bp5-menu-item-selected-icon, .bp5-dark .bp5-submenu .bp5-popover-target.bp5-popover-open > .bp5-menu-item[class*="bp5-intent-"]:hover .bp5-submenu-icon, .bp5-dark .bp5-submenu .bp5-popover-target.bp5-popover-open > .bp5-menu-item[class*="bp5-intent-"]:active:before, .bp5-dark .bp5-submenu .bp5-popover-target.bp5-popover-open > .bp5-menu-item[class*="bp5-intent-"]:active .bp5-menu-item-icon, .bp5-dark .bp5-submenu .bp5-popover-target.bp5-popover-open > .bp5-menu-item[class*="bp5-intent-"]:active .bp5-menu-item-selected-icon, .bp5-dark .bp5-submenu .bp5-popover-target.bp5-popover-open > .bp5-menu-item[class*="bp5-intent-"]:active .bp5-submenu-icon {
  color: #8abbff;
}

.bp5-dark .bp5-submenu .bp5-popover-target.bp5-popover-open > .bp5-menu-item[class*="bp5-intent-"].bp5-intent-success, .bp5-dark .bp5-submenu .bp5-popover-target.bp5-popover-open > .bp5-menu-item[class*="bp5-intent-"]:hover.bp5-intent-success, .bp5-dark .bp5-submenu .bp5-popover-target.bp5-popover-open > .bp5-menu-item[class*="bp5-intent-"]:active.bp5-intent-success {
  color: #72ca9b;
  background-color: #23855133;
}

.bp5-dark .bp5-submenu .bp5-popover-target.bp5-popover-open > .bp5-menu-item[class*="bp5-intent-"].bp5-intent-success:before, .bp5-dark .bp5-submenu .bp5-popover-target.bp5-popover-open > .bp5-menu-item[class*="bp5-intent-"].bp5-intent-success .bp5-menu-item-icon, .bp5-dark .bp5-submenu .bp5-popover-target.bp5-popover-open > .bp5-menu-item[class*="bp5-intent-"].bp5-intent-success .bp5-submenu-icon, .bp5-dark .bp5-submenu .bp5-popover-target.bp5-popover-open > .bp5-menu-item[class*="bp5-intent-"]:hover.bp5-intent-success:before, .bp5-dark .bp5-submenu .bp5-popover-target.bp5-popover-open > .bp5-menu-item[class*="bp5-intent-"]:hover.bp5-intent-success .bp5-menu-item-icon, .bp5-dark .bp5-submenu .bp5-popover-target.bp5-popover-open > .bp5-menu-item[class*="bp5-intent-"]:hover.bp5-intent-success .bp5-submenu-icon, .bp5-dark .bp5-submenu .bp5-popover-target.bp5-popover-open > .bp5-menu-item[class*="bp5-intent-"]:active.bp5-intent-success:before, .bp5-dark .bp5-submenu .bp5-popover-target.bp5-popover-open > .bp5-menu-item[class*="bp5-intent-"]:active.bp5-intent-success .bp5-menu-item-icon, .bp5-dark .bp5-submenu .bp5-popover-target.bp5-popover-open > .bp5-menu-item[class*="bp5-intent-"]:active.bp5-intent-success .bp5-submenu-icon {
  color: inherit;
}

.bp5-dark .bp5-submenu .bp5-popover-target.bp5-popover-open > .bp5-menu-item[class*="bp5-intent-"].bp5-intent-warning, .bp5-dark .bp5-submenu .bp5-popover-target.bp5-popover-open > .bp5-menu-item[class*="bp5-intent-"]:hover.bp5-intent-warning, .bp5-dark .bp5-submenu .bp5-popover-target.bp5-popover-open > .bp5-menu-item[class*="bp5-intent-"]:active.bp5-intent-warning {
  color: #fbb360;
  background-color: #c8761933;
}

.bp5-dark .bp5-submenu .bp5-popover-target.bp5-popover-open > .bp5-menu-item[class*="bp5-intent-"].bp5-intent-warning:before, .bp5-dark .bp5-submenu .bp5-popover-target.bp5-popover-open > .bp5-menu-item[class*="bp5-intent-"].bp5-intent-warning .bp5-menu-item-icon, .bp5-dark .bp5-submenu .bp5-popover-target.bp5-popover-open > .bp5-menu-item[class*="bp5-intent-"].bp5-intent-warning .bp5-submenu-icon, .bp5-dark .bp5-submenu .bp5-popover-target.bp5-popover-open > .bp5-menu-item[class*="bp5-intent-"]:hover.bp5-intent-warning:before, .bp5-dark .bp5-submenu .bp5-popover-target.bp5-popover-open > .bp5-menu-item[class*="bp5-intent-"]:hover.bp5-intent-warning .bp5-menu-item-icon, .bp5-dark .bp5-submenu .bp5-popover-target.bp5-popover-open > .bp5-menu-item[class*="bp5-intent-"]:hover.bp5-intent-warning .bp5-submenu-icon, .bp5-dark .bp5-submenu .bp5-popover-target.bp5-popover-open > .bp5-menu-item[class*="bp5-intent-"]:active.bp5-intent-warning:before, .bp5-dark .bp5-submenu .bp5-popover-target.bp5-popover-open > .bp5-menu-item[class*="bp5-intent-"]:active.bp5-intent-warning .bp5-menu-item-icon, .bp5-dark .bp5-submenu .bp5-popover-target.bp5-popover-open > .bp5-menu-item[class*="bp5-intent-"]:active.bp5-intent-warning .bp5-submenu-icon {
  color: inherit;
}

.bp5-dark .bp5-submenu .bp5-popover-target.bp5-popover-open > .bp5-menu-item[class*="bp5-intent-"].bp5-intent-danger, .bp5-dark .bp5-submenu .bp5-popover-target.bp5-popover-open > .bp5-menu-item[class*="bp5-intent-"]:hover.bp5-intent-danger, .bp5-dark .bp5-submenu .bp5-popover-target.bp5-popover-open > .bp5-menu-item[class*="bp5-intent-"]:active.bp5-intent-danger {
  color: #fa999c;
  background-color: #cd424633;
}

.bp5-dark .bp5-submenu .bp5-popover-target.bp5-popover-open > .bp5-menu-item[class*="bp5-intent-"].bp5-intent-danger:before, .bp5-dark .bp5-submenu .bp5-popover-target.bp5-popover-open > .bp5-menu-item[class*="bp5-intent-"].bp5-intent-danger .bp5-menu-item-icon, .bp5-dark .bp5-submenu .bp5-popover-target.bp5-popover-open > .bp5-menu-item[class*="bp5-intent-"].bp5-intent-danger .bp5-submenu-icon, .bp5-dark .bp5-submenu .bp5-popover-target.bp5-popover-open > .bp5-menu-item[class*="bp5-intent-"]:hover.bp5-intent-danger:before, .bp5-dark .bp5-submenu .bp5-popover-target.bp5-popover-open > .bp5-menu-item[class*="bp5-intent-"]:hover.bp5-intent-danger .bp5-menu-item-icon, .bp5-dark .bp5-submenu .bp5-popover-target.bp5-popover-open > .bp5-menu-item[class*="bp5-intent-"]:hover.bp5-intent-danger .bp5-submenu-icon, .bp5-dark .bp5-submenu .bp5-popover-target.bp5-popover-open > .bp5-menu-item[class*="bp5-intent-"]:active.bp5-intent-danger:before, .bp5-dark .bp5-submenu .bp5-popover-target.bp5-popover-open > .bp5-menu-item[class*="bp5-intent-"]:active.bp5-intent-danger .bp5-menu-item-icon, .bp5-dark .bp5-submenu .bp5-popover-target.bp5-popover-open > .bp5-menu-item[class*="bp5-intent-"]:active.bp5-intent-danger .bp5-submenu-icon {
  color: inherit;
}

@media (forced-colors: active) and (prefers-color-scheme: dark) {
  .bp5-dark .bp5-submenu .bp5-popover-target.bp5-popover-open > .bp5-menu-item[class*="bp5-intent-"], .bp5-dark .bp5-submenu .bp5-popover-target.bp5-popover-open > .bp5-menu-item[class*="bp5-intent-"]:hover, .bp5-dark .bp5-submenu .bp5-popover-target.bp5-popover-open > .bp5-menu-item[class*="bp5-intent-"]:active {
    background-color: highlight;
  }
}

.bp5-menu {
  color: #1c2127;
  text-align: left;
  background: #fff;
  border-radius: 2px;
  min-width: 180px;
  margin: 0;
  padding: 5px;
  list-style: none;
}

.bp5-menu-divider {
  border-top: 1px solid #11141826;
  margin: 5px -5px;
  display: block;
}

.bp5-dark .bp5-menu-divider {
  border-top-color: #fff3;
}

.bp5-menu-item {
  color: inherit;
  -webkit-user-select: none;
  user-select: none;
  border-radius: 2px;
  flex-direction: row;
  align-items: flex-start;
  padding: 5px 7px;
  line-height: 20px;
  text-decoration: none;
  display: flex;
}

.bp5-menu-item > * {
  flex-grow: 0;
  flex-shrink: 0;
}

.bp5-menu-item > .bp5-fill {
  flex-grow: 1;
  flex-shrink: 1;
}

.bp5-menu-item:before, .bp5-menu-item > * {
  margin-right: 7px;
}

.bp5-menu-item:empty:before, .bp5-menu-item > :last-child {
  margin-right: 0;
}

.bp5-menu-item > .bp5-fill {
  word-break: break-word;
}

.bp5-menu-item .bp5-menu-item-icon {
  flex-direction: column;
  justify-content: center;
  height: 20px;
  display: flex;
}

.bp5-menu-item .bp5-menu-item-label, .bp5-menu-item:before, .bp5-menu-item .bp5-menu-item-icon, .bp5-menu-item .bp5-menu-item-selected-icon, .bp5-menu-item .bp5-submenu-icon {
  color: #5f6b7c;
}

.bp5-menu-item:before, .bp5-menu-item .bp5-submenu-icon {
  margin-top: 2px;
}

.bp5-menu-item:hover {
  color: inherit;
  cursor: pointer;
  background-color: #8f99a826;
  text-decoration: none;
}

.bp5-menu-item:active {
  background-color: #8f99a84d;
}

.bp5-menu-item:active .bp5-menu-item-label {
  color: #1c2127;
}

.bp5-menu-item.bp5-active {
  color: #215db0;
  background-color: #2d72d21a;
}

.bp5-menu-item.bp5-active .bp5-menu-item-label {
  color: inherit;
}

.bp5-menu-item.bp5-active:before, .bp5-menu-item.bp5-active .bp5-menu-item-icon, .bp5-menu-item.bp5-active .bp5-menu-item-selected-icon, .bp5-menu-item.bp5-active .bp5-submenu-icon {
  color: #215db0;
}

.bp5-menu-item.bp5-active.bp5-intent-success {
  color: #1c6e42;
  background-color: #2385511a;
}

.bp5-menu-item.bp5-active.bp5-intent-success:before, .bp5-menu-item.bp5-active.bp5-intent-success .bp5-menu-item-icon, .bp5-menu-item.bp5-active.bp5-intent-success .bp5-submenu-icon {
  color: inherit;
}

.bp5-menu-item.bp5-active.bp5-intent-warning {
  color: #935610;
  background-color: #c876191a;
}

.bp5-menu-item.bp5-active.bp5-intent-warning:before, .bp5-menu-item.bp5-active.bp5-intent-warning .bp5-menu-item-icon, .bp5-menu-item.bp5-active.bp5-intent-warning .bp5-submenu-icon {
  color: inherit;
}

.bp5-menu-item.bp5-active.bp5-intent-danger {
  color: #ac2f33;
  background-color: #cd42461a;
}

.bp5-menu-item.bp5-active.bp5-intent-danger:before, .bp5-menu-item.bp5-active.bp5-intent-danger .bp5-menu-item-icon, .bp5-menu-item.bp5-active.bp5-intent-danger .bp5-submenu-icon {
  color: inherit;
}

@media (forced-colors: active) and (prefers-color-scheme: dark) {
  .bp5-menu-item.bp5-active {
    background-color: highlight;
  }
}

.bp5-menu-item.bp5-menu-item-is-selectable {
  padding-left: 20px;
}

.bp5-menu-item.bp5-menu-item-is-selectable.bp5-selected {
  padding-left: 0;
}

.bp5-menu-item.bp5-menu-item-is-selectable .bp5-menu-item-selected-icon {
  align-self: center;
  margin: 0 2px;
}

.bp5-menu-item.bp5-disabled {
  background-color: inherit !important;
  color: #5f6b7c99 !important;
  cursor: not-allowed !important;
  outline: none !important;
}

.bp5-menu-item.bp5-disabled:before, .bp5-menu-item.bp5-disabled .bp5-menu-item-icon, .bp5-menu-item.bp5-disabled .bp5-submenu-icon, .bp5-menu-item.bp5-disabled .bp5-menu-item-label {
  color: #5f6b7c99 !important;
}

.bp5-menu-item.bp5-intent-primary {
  color: #215db0;
}

.bp5-menu-item.bp5-intent-primary:before, .bp5-menu-item.bp5-intent-primary .bp5-menu-item-icon, .bp5-menu-item.bp5-intent-primary .bp5-menu-item-selected-icon, .bp5-menu-item.bp5-intent-primary .bp5-submenu-icon, .bp5-menu-item.bp5-intent-primary .bp5-menu-item-label {
  color: inherit;
}

.bp5-menu-item.bp5-intent-primary:hover {
  background-color: #2d72d21a;
}

.bp5-menu-item.bp5-intent-primary:active, .bp5-menu-item.bp5-intent-primary.bp5-active {
  color: #184a90;
  background-color: #2d72d233;
}

.bp5-menu-item.bp5-intent-success {
  color: #1c6e42;
}

.bp5-menu-item.bp5-intent-success:before, .bp5-menu-item.bp5-intent-success .bp5-menu-item-icon, .bp5-menu-item.bp5-intent-success .bp5-menu-item-selected-icon, .bp5-menu-item.bp5-intent-success .bp5-submenu-icon, .bp5-menu-item.bp5-intent-success .bp5-menu-item-label {
  color: inherit;
}

.bp5-menu-item.bp5-intent-success:hover {
  background-color: #2385511a;
}

.bp5-menu-item.bp5-intent-success:active, .bp5-menu-item.bp5-intent-success.bp5-active {
  color: #165a36;
  background-color: #23855133;
}

.bp5-menu-item.bp5-intent-warning {
  color: #935610;
}

.bp5-menu-item.bp5-intent-warning:before, .bp5-menu-item.bp5-intent-warning .bp5-menu-item-icon, .bp5-menu-item.bp5-intent-warning .bp5-menu-item-selected-icon, .bp5-menu-item.bp5-intent-warning .bp5-submenu-icon, .bp5-menu-item.bp5-intent-warning .bp5-menu-item-label {
  color: inherit;
}

.bp5-menu-item.bp5-intent-warning:hover {
  background-color: #c876191a;
}

.bp5-menu-item.bp5-intent-warning:active, .bp5-menu-item.bp5-intent-warning.bp5-active {
  color: #77450d;
  background-color: #c8761933;
}

.bp5-menu-item.bp5-intent-danger {
  color: #ac2f33;
}

.bp5-menu-item.bp5-intent-danger:before, .bp5-menu-item.bp5-intent-danger .bp5-menu-item-icon, .bp5-menu-item.bp5-intent-danger .bp5-menu-item-selected-icon, .bp5-menu-item.bp5-intent-danger .bp5-submenu-icon, .bp5-menu-item.bp5-intent-danger .bp5-menu-item-label {
  color: inherit;
}

.bp5-menu-item.bp5-intent-danger:hover {
  background-color: #cd42461a;
}

.bp5-menu-item.bp5-intent-danger:active, .bp5-menu-item.bp5-intent-danger.bp5-active {
  color: #8e292c;
  background-color: #cd424633;
}

.bp5-menu-item:before {
  font-variant: normal;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  width: 16px;
  height: 16px;
  margin-right: 7px;
  font-family: blueprint-icons-16, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 1;
}

.bp5-large .bp5-menu-item {
  padding-top: 9px;
  padding-bottom: 9px;
  font-size: 16px;
  line-height: 22px;
}

.bp5-large .bp5-menu-item .bp5-menu-item-icon {
  height: 22px;
}

.bp5-large .bp5-menu-item:before, .bp5-large .bp5-menu-item .bp5-submenu-icon {
  margin-top: 3px;
}

.bp5-large .bp5-menu-item:before {
  font-variant: normal;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  width: 20px;
  height: 20px;
  margin-right: 10px;
  font-family: blueprint-icons-20, sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 1;
}

.bp5-small .bp5-menu-item {
  padding-top: 2px;
  padding-bottom: 2px;
}

button.bp5-menu-item {
  text-align: left;
  background: none;
  border: none;
  width: 100%;
}

.bp5-menu-header {
  cursor: default;
  border-top: 1px solid #11141826;
  margin: 5px -5px;
  padding-left: 2px;
  display: block;
}

.bp5-dark .bp5-menu-header {
  border-top-color: #fff3;
}

.bp5-menu-header:first-of-type {
  border-top: none;
}

.bp5-menu-header > h6 {
  color: #1c2127;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-wrap: normal;
  margin: 0;
  padding: 10px 7px 0 6px;
  font-weight: 600;
  line-height: 17px;
  overflow: hidden;
}

.bp5-menu-header > h6.bp5-text-muted {
  color: #5f6b7c;
}

.bp5-dark .bp5-menu-header > h6.bp5-text-muted {
  color: #abb3bf;
}

.bp5-menu-header:first-of-type > h6 {
  padding-top: 0;
}

.bp5-large .bp5-menu-header > h6 {
  padding-top: 15px;
  padding-bottom: 5px;
  font-size: 18px;
}

.bp5-large .bp5-menu-header:first-of-type > h6 {
  padding-top: 0;
}

.bp5-dark .bp5-menu {
  color: #f6f7f9;
  background: #2f343c;
}

.bp5-dark .bp5-menu-item {
  color: inherit;
}

.bp5-dark .bp5-menu-item .bp5-menu-item-label, .bp5-dark .bp5-menu-item:before, .bp5-dark .bp5-menu-item .bp5-menu-item-icon, .bp5-dark .bp5-menu-item .bp5-menu-item-selected-icon, .bp5-dark .bp5-menu-item .bp5-submenu-icon {
  color: #abb3bf;
}

.bp5-dark .bp5-menu-item:hover {
  color: inherit;
}

.bp5-dark .bp5-menu-item:hover .bp5-menu-item-icon, .bp5-dark .bp5-menu-item:hover .bp5-submenu-icon {
  color: #abb3bf;
}

.bp5-dark .bp5-menu-item:active {
  background-color: #8f99a84d;
}

.bp5-dark .bp5-menu-item:active .bp5-menu-item-label {
  color: #f6f7f9;
}

.bp5-dark .bp5-menu-item.bp5-active {
  color: #8abbff;
  background-color: #2d72d233;
}

.bp5-dark .bp5-menu-item.bp5-active:before, .bp5-dark .bp5-menu-item.bp5-active .bp5-menu-item-icon, .bp5-dark .bp5-menu-item.bp5-active .bp5-menu-item-selected-icon, .bp5-dark .bp5-menu-item.bp5-active .bp5-submenu-icon {
  color: #8abbff;
}

.bp5-dark .bp5-menu-item.bp5-active.bp5-intent-success {
  color: #72ca9b;
  background-color: #23855133;
}

.bp5-dark .bp5-menu-item.bp5-active.bp5-intent-success:before, .bp5-dark .bp5-menu-item.bp5-active.bp5-intent-success .bp5-menu-item-icon, .bp5-dark .bp5-menu-item.bp5-active.bp5-intent-success .bp5-submenu-icon {
  color: inherit;
}

.bp5-dark .bp5-menu-item.bp5-active.bp5-intent-warning {
  color: #fbb360;
  background-color: #c8761933;
}

.bp5-dark .bp5-menu-item.bp5-active.bp5-intent-warning:before, .bp5-dark .bp5-menu-item.bp5-active.bp5-intent-warning .bp5-menu-item-icon, .bp5-dark .bp5-menu-item.bp5-active.bp5-intent-warning .bp5-submenu-icon {
  color: inherit;
}

.bp5-dark .bp5-menu-item.bp5-active.bp5-intent-danger {
  color: #fa999c;
  background-color: #cd424633;
}

.bp5-dark .bp5-menu-item.bp5-active.bp5-intent-danger:before, .bp5-dark .bp5-menu-item.bp5-active.bp5-intent-danger .bp5-menu-item-icon, .bp5-dark .bp5-menu-item.bp5-active.bp5-intent-danger .bp5-submenu-icon {
  color: inherit;
}

@media (forced-colors: active) and (prefers-color-scheme: dark) {
  .bp5-dark .bp5-menu-item.bp5-active {
    background-color: highlight;
  }
}

.bp5-dark .bp5-menu-item.bp5-disabled, .bp5-dark .bp5-menu-item.bp5-disabled:before, .bp5-dark .bp5-menu-item.bp5-disabled .bp5-menu-item-icon, .bp5-dark .bp5-menu-item.bp5-disabled .bp5-submenu-icon, .bp5-dark .bp5-menu-item.bp5-disabled .bp5-menu-item-label {
  color: #abb3bf99 !important;
}

.bp5-dark .bp5-menu-item.bp5-intent-primary {
  color: #8abbff;
}

.bp5-dark .bp5-menu-item.bp5-intent-primary:before, .bp5-dark .bp5-menu-item.bp5-intent-primary .bp5-menu-item-icon, .bp5-dark .bp5-menu-item.bp5-intent-primary .bp5-menu-item-selected-icon, .bp5-dark .bp5-menu-item.bp5-intent-primary .bp5-submenu-icon, .bp5-dark .bp5-menu-item.bp5-intent-primary .bp5-menu-item-label {
  color: inherit;
}

.bp5-dark .bp5-menu-item.bp5-intent-primary:hover {
  background-color: #2d72d233;
}

.bp5-dark .bp5-menu-item.bp5-intent-primary:active, .bp5-dark .bp5-menu-item.bp5-intent-primary.bp5-active {
  color: #99c4ff;
  background-color: #2d72d24d;
}

.bp5-dark .bp5-menu-item.bp5-intent-success {
  color: #72ca9b;
}

.bp5-dark .bp5-menu-item.bp5-intent-success:before, .bp5-dark .bp5-menu-item.bp5-intent-success .bp5-menu-item-icon, .bp5-dark .bp5-menu-item.bp5-intent-success .bp5-menu-item-selected-icon, .bp5-dark .bp5-menu-item.bp5-intent-success .bp5-submenu-icon, .bp5-dark .bp5-menu-item.bp5-intent-success .bp5-menu-item-label {
  color: inherit;
}

.bp5-dark .bp5-menu-item.bp5-intent-success:hover {
  background-color: #23855133;
}

.bp5-dark .bp5-menu-item.bp5-intent-success:active, .bp5-dark .bp5-menu-item.bp5-intent-success.bp5-active {
  color: #7cd7a2;
  background-color: #2385514d;
}

.bp5-dark .bp5-menu-item.bp5-intent-warning {
  color: #fbb360;
}

.bp5-dark .bp5-menu-item.bp5-intent-warning:before, .bp5-dark .bp5-menu-item.bp5-intent-warning .bp5-menu-item-icon, .bp5-dark .bp5-menu-item.bp5-intent-warning .bp5-menu-item-selected-icon, .bp5-dark .bp5-menu-item.bp5-intent-warning .bp5-submenu-icon, .bp5-dark .bp5-menu-item.bp5-intent-warning .bp5-menu-item-label {
  color: inherit;
}

.bp5-dark .bp5-menu-item.bp5-intent-warning:hover {
  background-color: #c8761933;
}

.bp5-dark .bp5-menu-item.bp5-intent-warning:active, .bp5-dark .bp5-menu-item.bp5-intent-warning.bp5-active {
  color: #f5c186;
  background-color: #c876194d;
}

.bp5-dark .bp5-menu-item.bp5-intent-danger {
  color: #fa999c;
}

.bp5-dark .bp5-menu-item.bp5-intent-danger:before, .bp5-dark .bp5-menu-item.bp5-intent-danger .bp5-menu-item-icon, .bp5-dark .bp5-menu-item.bp5-intent-danger .bp5-menu-item-selected-icon, .bp5-dark .bp5-menu-item.bp5-intent-danger .bp5-submenu-icon, .bp5-dark .bp5-menu-item.bp5-intent-danger .bp5-menu-item-label {
  color: inherit;
}

.bp5-dark .bp5-menu-item.bp5-intent-danger:hover {
  background-color: #cd424633;
}

.bp5-dark .bp5-menu-item.bp5-intent-danger:active, .bp5-dark .bp5-menu-item.bp5-intent-danger.bp5-active {
  color: #ffa1a4;
  background-color: #cd42464d;
}

.bp5-dark .bp5-menu-divider, .bp5-dark .bp5-menu-header {
  border-color: #fff3;
}

.bp5-dark .bp5-menu-header > h6 {
  color: #f6f7f9;
}

.bp5-label .bp5-menu {
  margin-top: 5px;
}

.bp5-navbar {
  z-index: 10;
  background-color: #fff;
  width: 100%;
  height: 50px;
  padding: 0 15px;
  position: relative;
  box-shadow: 0 0 0 1px #1114181a, 0 1px 1px #11141833;
}

.bp5-navbar.bp5-dark, .bp5-dark .bp5-navbar {
  background-color: #2f343c;
}

.bp5-navbar.bp5-dark {
  box-shadow: inset inset 0 0 0 1px #fff3, 0 1px 1px 0 #11141866;
}

.bp5-dark .bp5-navbar {
  box-shadow: inset 0 0 0 1px #fff3, 0 1px 1px #11141866;
}

.bp5-navbar.bp5-fixed-top {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
}

@media (forced-colors: active) and (prefers-color-scheme: dark) {
  .bp5-navbar {
    border: 1px solid buttonborder;
  }
}

.bp5-navbar-heading {
  margin-right: 15px;
  font-size: 16px;
}

.bp5-navbar-group {
  align-items: center;
  height: 50px;
  display: flex;
}

.bp5-navbar-group.bp5-align-left {
  float: left;
}

.bp5-navbar-group.bp5-align-right {
  float: right;
}

.bp5-navbar-divider {
  border-left: 1px solid #11141826;
  height: 20px;
  margin: 0 10px;
}

.bp5-dark .bp5-navbar-divider {
  border-left-color: #fff3;
}

.bp5-non-ideal-state {
  color: #5f6b7c;
  text-align: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
}

.bp5-non-ideal-state > * {
  flex-grow: 0;
  flex-shrink: 0;
}

.bp5-non-ideal-state > .bp5-fill {
  flex-grow: 1;
  flex-shrink: 1;
}

.bp5-non-ideal-state:before, .bp5-non-ideal-state > * {
  margin-bottom: 20px;
}

.bp5-non-ideal-state:empty:before, .bp5-non-ideal-state > :last-child {
  margin-bottom: 0;
}

.bp5-non-ideal-state > * {
  max-width: 400px;
}

.bp5-non-ideal-state .bp5-heading {
  color: #5f6b7c;
  margin-bottom: 10px;
  line-height: 20px;
}

.bp5-non-ideal-state .bp5-heading:only-child {
  margin-bottom: 0;
}

.bp5-non-ideal-state.bp5-non-ideal-state-horizontal {
  text-align: left;
  flex-direction: row;
  display: flex;
}

.bp5-non-ideal-state.bp5-non-ideal-state-horizontal > * {
  flex-grow: 0;
  flex-shrink: 0;
}

.bp5-non-ideal-state.bp5-non-ideal-state-horizontal > .bp5-fill {
  flex-grow: 1;
  flex-shrink: 1;
}

.bp5-non-ideal-state.bp5-non-ideal-state-horizontal:before, .bp5-non-ideal-state.bp5-non-ideal-state-horizontal > * {
  margin-right: 20px;
}

.bp5-non-ideal-state.bp5-non-ideal-state-horizontal:empty:before, .bp5-non-ideal-state.bp5-non-ideal-state-horizontal > :last-child {
  margin-right: 0;
}

.bp5-non-ideal-state.bp5-non-ideal-state-horizontal > * {
  margin-bottom: 0;
}

.bp5-dark .bp5-non-ideal-state, .bp5-dark .bp5-non-ideal-state .bp5-heading {
  color: #abb3bf;
}

.bp5-non-ideal-state-visual {
  color: #8f99a8;
}

.bp5-overflow-list {
  flex-wrap: nowrap;
  min-width: 0;
  display: flex;
}

.bp5-overflow-list-spacer {
  flex-shrink: 1;
  width: 1px;
}

body.bp5-overlay-open {
  overflow: hidden;
}

.bp5-overlay {
  z-index: 20;
  position: static;
  inset: 0;
}

.bp5-overlay:not(.bp5-overlay-open) {
  pointer-events: none;
}

.bp5-overlay.bp5-overlay-container {
  position: fixed;
  overflow: hidden;
}

.bp5-overlay.bp5-overlay-container.bp5-overlay-inline {
  position: absolute;
}

.bp5-overlay.bp5-overlay-scroll-container {
  position: fixed;
  overflow: auto;
}

.bp5-overlay.bp5-overlay-scroll-container.bp5-overlay-inline {
  position: absolute;
}

.bp5-overlay.bp5-overlay-inline {
  display: inline;
  overflow: visible;
}

.bp5-overlay-content {
  z-index: 20;
  position: fixed;
}

.bp5-overlay-inline .bp5-overlay-content, .bp5-overlay-scroll-container .bp5-overlay-content {
  position: absolute;
}

.bp5-overlay-backdrop {
  opacity: 1;
  -webkit-user-select: none;
  user-select: none;
  z-index: 20;
  background-color: #111418b3;
  position: fixed;
  inset: 0;
  overflow: auto;
}

.bp5-overlay-backdrop.bp5-overlay-enter, .bp5-overlay-backdrop.bp5-overlay-appear {
  opacity: 0;
}

.bp5-overlay-backdrop.bp5-overlay-enter-active, .bp5-overlay-backdrop.bp5-overlay-appear-active {
  opacity: 1;
  transition-delay: 0;
  transition-property: opacity;
  transition-duration: .2s;
  transition-timing-function: cubic-bezier(.4, 1, .75, .9);
}

.bp5-overlay-backdrop.bp5-overlay-exit {
  opacity: 1;
}

.bp5-overlay-backdrop.bp5-overlay-exit-active {
  opacity: 0;
  transition-delay: 0;
  transition-property: opacity;
  transition-duration: .2s;
  transition-timing-function: cubic-bezier(.4, 1, .75, .9);
}

.bp5-overlay-backdrop:focus {
  outline: none;
}

.bp5-overlay-inline .bp5-overlay-backdrop {
  position: absolute;
}

.bp5-panel-stack {
  position: relative;
  overflow: hidden;
}

.bp5-panel-stack-header {
  z-index: 1;
  flex-shrink: 0;
  align-items: center;
  height: 30px;
  display: flex;
  box-shadow: 0 1px #11141826;
}

.bp5-dark .bp5-panel-stack-header {
  box-shadow: 0 1px #fff3;
}

.bp5-panel-stack-header > span {
  flex: 1;
  align-items: stretch;
  display: flex;
}

.bp5-panel-stack-header .bp5-heading {
  margin: 0 5px;
}

.bp5-button.bp5-panel-stack-header-back {
  white-space: nowrap;
  margin-left: 5px;
  padding-left: 0;
}

.bp5-button.bp5-panel-stack-header-back .bp5-icon {
  margin: 0 2px;
}

.bp5-panel-stack-view {
  z-index: 1;
  background-color: #fff;
  border-right: 1px solid #11141826;
  flex-direction: column;
  margin-right: -1px;
  display: flex;
  position: absolute;
  inset: 0;
  overflow-y: auto;
}

.bp5-dark .bp5-panel-stack-view {
  background-color: #2f343c;
}

.bp5-panel-stack-view:nth-last-child(n+4) {
  display: none;
}

.bp5-panel-stack-push .bp5-panel-stack-enter, .bp5-panel-stack-push .bp5-panel-stack-appear {
  opacity: 0;
  transform: translateX(100%);
}

.bp5-panel-stack-push .bp5-panel-stack-enter-active, .bp5-panel-stack-push .bp5-panel-stack-appear-active {
  opacity: 1;
  transition-delay: 0;
  transition-property: transform, opacity;
  transition-duration: .4s;
  transition-timing-function: ease;
  transform: translate(0%);
}

.bp5-panel-stack-push .bp5-panel-stack-exit {
  opacity: 1;
  transform: translate(0%);
}

.bp5-panel-stack-push .bp5-panel-stack-exit-active {
  opacity: 0;
  transition-delay: 0;
  transition-property: transform, opacity;
  transition-duration: .4s;
  transition-timing-function: ease;
  transform: translateX(-50%);
}

.bp5-panel-stack-pop .bp5-panel-stack-enter, .bp5-panel-stack-pop .bp5-panel-stack-appear {
  opacity: 0;
  transform: translateX(-50%);
}

.bp5-panel-stack-pop .bp5-panel-stack-enter-active, .bp5-panel-stack-pop .bp5-panel-stack-appear-active {
  opacity: 1;
  transition-delay: 0;
  transition-property: transform, opacity;
  transition-duration: .4s;
  transition-timing-function: ease;
  transform: translate(0%);
}

.bp5-panel-stack-pop .bp5-panel-stack-exit {
  opacity: 1;
  transform: translate(0%);
}

.bp5-panel-stack-pop .bp5-panel-stack-exit-active {
  opacity: 0;
  transition-delay: 0;
  transition-property: transform, opacity;
  transition-duration: .4s;
  transition-timing-function: ease;
  transform: translateX(100%);
}

.bp5-panel-stack2 {
  position: relative;
  overflow: hidden;
}

.bp5-panel-stack2-header {
  z-index: 1;
  flex-shrink: 0;
  align-items: center;
  height: 30px;
  display: flex;
  box-shadow: 0 1px #11141826;
}

.bp5-dark .bp5-panel-stack2-header {
  box-shadow: 0 1px #fff3;
}

.bp5-panel-stack2-header > span {
  flex: 1;
  align-items: stretch;
  display: flex;
}

.bp5-panel-stack2-header .bp5-heading {
  margin: 0 5px;
}

.bp5-button.bp5-panel-stack2-header-back {
  white-space: nowrap;
  margin-left: 5px;
  padding-left: 0;
}

.bp5-button.bp5-panel-stack2-header-back .bp5-icon {
  margin: 0 2px;
}

.bp5-panel-stack2-view {
  z-index: 1;
  background-color: #fff;
  border-right: 1px solid #11141826;
  flex-direction: column;
  margin-right: -1px;
  display: flex;
  position: absolute;
  inset: 0;
  overflow-y: auto;
}

.bp5-dark .bp5-panel-stack2-view {
  background-color: #2f343c;
}

.bp5-panel-stack2-view:nth-last-child(n+4) {
  display: none;
}

.bp5-panel-stack2-push .bp5-panel-stack2-enter, .bp5-panel-stack2-push .bp5-panel-stack2-appear {
  opacity: 0;
  transform: translateX(100%);
}

.bp5-panel-stack2-push .bp5-panel-stack2-enter-active, .bp5-panel-stack2-push .bp5-panel-stack2-appear-active {
  opacity: 1;
  transition-delay: 0;
  transition-property: transform, opacity;
  transition-duration: .4s;
  transition-timing-function: ease;
  transform: translate(0%);
}

.bp5-panel-stack2-push .bp5-panel-stack2-exit {
  opacity: 1;
  transform: translate(0%);
}

.bp5-panel-stack2-push .bp5-panel-stack2-exit-active {
  opacity: 0;
  transition-delay: 0;
  transition-property: transform, opacity;
  transition-duration: .4s;
  transition-timing-function: ease;
  transform: translateX(-50%);
}

.bp5-panel-stack2-pop .bp5-panel-stack2-enter, .bp5-panel-stack2-pop .bp5-panel-stack2-appear {
  opacity: 0;
  transform: translateX(-50%);
}

.bp5-panel-stack2-pop .bp5-panel-stack2-enter-active, .bp5-panel-stack2-pop .bp5-panel-stack2-appear-active {
  opacity: 1;
  transition-delay: 0;
  transition-property: transform, opacity;
  transition-duration: .4s;
  transition-timing-function: ease;
  transform: translate(0%);
}

.bp5-panel-stack2-pop .bp5-panel-stack2-exit {
  opacity: 1;
  transform: translate(0%);
}

.bp5-panel-stack2-pop .bp5-panel-stack2-exit-active {
  opacity: 0;
  transition-delay: 0;
  transition-property: transform, opacity;
  transition-duration: .4s;
  transition-timing-function: ease;
  transform: translateX(100%);
}

.bp5-button-group:not(.bp5-minimal) > .bp5-popover-target:not(:first-child) .bp5-button {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.bp5-button-group:not(.bp5-minimal) > .bp5-popover-target:not(:last-child) .bp5-button {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  margin-right: -1px;
}

.bp5-button-group .bp5-popover-target {
  flex: auto;
  display: flex;
}

.bp5-button-group.bp5-vertical:not(.bp5-minimal) > .bp5-popover-target:first-child .bp5-button {
  border-radius: 2px 2px 0 0;
}

.bp5-button-group.bp5-vertical:not(.bp5-minimal) > .bp5-popover-target:last-child .bp5-button {
  border-radius: 0 0 2px 2px;
}

.bp5-button-group.bp5-vertical:not(.bp5-minimal) > .bp5-popover-target:not(:last-child) .bp5-button {
  margin-bottom: -1px;
}

.bp5-control-group .bp5-popover-target {
  border-radius: inherit;
}

label.bp5-label .bp5-popover-target {
  text-transform: none;
  margin-top: 5px;
  display: block;
}

.bp5-submenu .bp5-popover-target {
  display: block;
}

.bp5-submenu.bp5-popover {
  box-shadow: none;
  padding: 0 5px;
}

.bp5-submenu.bp5-popover > .bp5-popover-content {
  box-shadow: 0 0 0 1px #1114181a, 0 2px 4px #11141833, 0 8px 24px #11141833;
}

.bp5-dark .bp5-submenu.bp5-popover, .bp5-submenu.bp5-popover.bp5-dark {
  box-shadow: none;
}

.bp5-dark .bp5-submenu.bp5-popover > .bp5-popover-content, .bp5-submenu.bp5-popover.bp5-dark > .bp5-popover-content {
  box-shadow: 0 0 0 1px #5e6064, inset 0 0 0 1px #fff3, 0 2px 4px #11141866, 0 8px 24px #11141866;
}

.bp5-tree-node-secondary-label .bp5-popover-target {
  align-items: center;
  display: flex;
}

.bp5-popover {
  z-index: 20;
  border-radius: 2px;
  display: inline-block;
  transform: scale(1);
  box-shadow: 0 0 0 1px #1114181a, 0 2px 4px #11141833, 0 8px 24px #11141833;
}

.bp5-popover .bp5-popover-arrow {
  width: 30px;
  height: 30px;
  position: absolute;
}

.bp5-popover .bp5-popover-arrow:before {
  width: 20px;
  height: 20px;
  margin: 5px;
}

.bp5-popover .bp5-popover-content {
  background: #fff;
}

.bp5-popover .bp5-popover-content, .bp5-popover .bp5-heading {
  color: inherit;
}

.bp5-popover .bp5-popover-arrow:before {
  box-shadow: 1px 1px 6px #11141833;
}

.bp5-popover .bp5-popover-arrow-border {
  fill: #111418;
  fill-opacity: .1;
}

.bp5-popover .bp5-popover-arrow-fill {
  fill: #fff;
}

@media (forced-colors: active) and (prefers-color-scheme: dark) {
  .bp5-popover .bp5-popover-arrow-fill {
    fill: buttonborder;
  }

  .bp5-popover {
    border: 1px solid buttonborder;
  }
}

.bp5-popover-enter > .bp5-popover, .bp5-popover-appear > .bp5-popover {
  transform: scale(.3);
}

.bp5-popover-enter-active > .bp5-popover, .bp5-popover-appear-active > .bp5-popover {
  transition-delay: 0;
  transition-property: transform;
  transition-duration: .3s;
  transition-timing-function: cubic-bezier(.54, 1.12, .38, 1.11);
  transform: scale(1);
}

.bp5-popover-exit > .bp5-popover {
  transform: scale(1);
}

.bp5-popover-exit-active > .bp5-popover {
  transition-delay: 0;
  transition-property: transform;
  transition-duration: .3s;
  transition-timing-function: cubic-bezier(.54, 1.12, .38, 1.11);
  transform: scale(.3);
}

.bp5-popover .bp5-popover-content {
  border-radius: 2px;
  position: relative;
}

.bp5-popover-target + .bp5-overlay .bp5-popover.bp5-popover-content-sizing {
  width: 350px;
}

.bp5-popover.bp5-minimal {
  margin: 0 !important;
}

.bp5-popover.bp5-minimal .bp5-popover-arrow {
  display: none;
}

.bp5-popover.bp5-minimal.bp5-popover, .bp5-popover-enter > .bp5-popover.bp5-minimal.bp5-popover, .bp5-popover-appear > .bp5-popover.bp5-minimal.bp5-popover {
  transform: scale(1);
}

.bp5-popover-enter-active > .bp5-popover.bp5-minimal.bp5-popover, .bp5-popover-appear-active > .bp5-popover.bp5-minimal.bp5-popover {
  transition-delay: 0;
  transition-property: transform;
  transition-duration: .1s;
  transition-timing-function: cubic-bezier(.4, 1, .75, .9);
  transform: scale(1);
}

.bp5-popover-exit > .bp5-popover.bp5-minimal.bp5-popover {
  transform: scale(1);
}

.bp5-popover-exit-active > .bp5-popover.bp5-minimal.bp5-popover {
  transition-delay: 0;
  transition-property: transform;
  transition-duration: .1s;
  transition-timing-function: cubic-bezier(.4, 1, .75, .9);
  transform: scale(1);
}

.bp5-popover.bp5-popover-match-target-width {
  width: 100%;
}

.bp5-popover.bp5-dark, .bp5-dark .bp5-popover {
  box-shadow: 0 0 0 1px #5e6064, inset 0 0 0 1px #fff3, 0 2px 4px #11141866, 0 8px 24px #11141866;
}

.bp5-popover.bp5-dark .bp5-popover-content, .bp5-dark .bp5-popover .bp5-popover-content {
  background: #2f343c;
}

.bp5-popover.bp5-dark .bp5-popover-content, .bp5-popover.bp5-dark .bp5-heading, .bp5-dark .bp5-popover .bp5-popover-content, .bp5-dark .bp5-popover .bp5-heading {
  color: inherit;
}

.bp5-popover.bp5-dark .bp5-popover-arrow:before, .bp5-dark .bp5-popover .bp5-popover-arrow:before {
  box-shadow: 0 0 0 1px #777a7e, 1px 1px 6px #11141866;
}

.bp5-popover.bp5-dark .bp5-popover-arrow-border, .bp5-dark .bp5-popover .bp5-popover-arrow-border {
  fill: #111418;
  fill-opacity: .2;
}

.bp5-popover.bp5-dark .bp5-popover-arrow-fill, .bp5-dark .bp5-popover .bp5-popover-arrow-fill {
  fill: #2f343c;
}

@media (forced-colors: active) and (prefers-color-scheme: dark) {
  .bp5-popover.bp5-dark .bp5-popover-arrow-fill, .bp5-dark .bp5-popover .bp5-popover-arrow-fill {
    fill: buttonborder;
  }

  .bp5-popover.bp5-dark, .bp5-dark .bp5-popover {
    border: 1px solid buttonborder;
  }

  .bp5-popover {
    border: 1px solid buttonborder;
    box-shadow: none;
  }
}

.bp5-popover-arrow:before {
  content: "";
  border-radius: 1px;
  display: block;
  position: absolute;
  transform: rotate(45deg);
}

.bp5-overlay-backdrop.bp5-popover-backdrop {
  background: #fff0;
}

.bp5-popover-transition-container {
  opacity: 1;
  z-index: 20;
  display: flex;
}

.bp5-popover-transition-container.bp5-popover-enter, .bp5-popover-transition-container.bp5-popover-appear {
  opacity: 0;
}

.bp5-popover-transition-container.bp5-popover-enter-active, .bp5-popover-transition-container.bp5-popover-appear-active {
  opacity: 1;
  transition-delay: 0;
  transition-property: opacity;
  transition-duration: .1s;
  transition-timing-function: cubic-bezier(.4, 1, .75, .9);
}

.bp5-popover-transition-container.bp5-popover-exit {
  opacity: 1;
}

.bp5-popover-transition-container.bp5-popover-exit-active {
  opacity: 0;
  transition-delay: 0;
  transition-property: opacity;
  transition-duration: .1s;
  transition-timing-function: cubic-bezier(.4, 1, .75, .9);
}

.bp5-popover-transition-container:focus {
  outline: none;
}

.bp5-popover-transition-container.bp5-popover-leave .bp5-popover-content {
  pointer-events: none;
}

span.bp5-popover-target {
  display: inline-block;
}

.bp5-popover-target.bp5-fill {
  width: 100%;
}

.bp5-portal {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

@keyframes linear-progress-bar-stripes {
  from {
    background-position: 0 0;
  }

  to {
    background-position: 30px 0;
  }
}

.bp5-progress-bar {
  background: #5f6b7c33;
  border-radius: 40px;
  width: 100%;
  height: 8px;
  display: block;
  position: relative;
  overflow: hidden;
}

.bp5-progress-bar .bp5-progress-meter {
  background: #5f6b7ccc linear-gradient(-45deg, #fff3 25%, #0000 25% 50%, #fff3 50% 75%, #0000 75%) 0 0 / 30px 30px;
  border-radius: 40px;
  width: 100%;
  height: 100%;
  transition: width .2s cubic-bezier(.4, 1, .75, .9);
  position: absolute;
}

.bp5-progress-bar:not(.bp5-no-animation):not(.bp5-no-stripes) .bp5-progress-meter {
  animation: .3s linear infinite reverse linear-progress-bar-stripes;
}

.bp5-progress-bar.bp5-no-stripes .bp5-progress-meter {
  background-image: none;
}

.bp5-dark .bp5-progress-bar {
  background: #11141880;
}

.bp5-dark .bp5-progress-bar .bp5-progress-meter {
  background-color: #8f99a8;
}

.bp5-progress-bar.bp5-intent-primary .bp5-progress-meter {
  background-color: #427762;
}

.bp5-progress-bar.bp5-intent-success .bp5-progress-meter {
  background-color: #238551;
}

.bp5-progress-bar.bp5-intent-warning .bp5-progress-meter {
  background-color: #c87619;
}

.bp5-progress-bar.bp5-intent-danger .bp5-progress-meter {
  background-color: #cd4246;
}

.bp5-section {
  width: 100%;
  overflow: hidden;
}

.bp5-section, .bp5-section.bp5-compact {
  padding: 0;
}

.bp5-section-header {
  border-bottom: 1px solid #11141826;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  width: 100%;
  min-height: 50px;
  padding: 0 20px;
  display: flex;
  position: relative;
}

.bp5-section-header.bp5-dark, .bp5-dark .bp5-section-header {
  border-color: #fff3;
}

.bp5-section-header-left {
  align-items: center;
  gap: 10px;
  padding: 10px 0;
  display: flex;
}

.bp5-section-header-title {
  margin-bottom: 0;
}

.bp5-section-header-sub-title {
  margin-top: 2px;
}

.bp5-section-header-right {
  align-items: center;
  gap: 10px;
  margin-left: auto;
  display: flex;
}

.bp5-section-header-divider {
  align-self: stretch;
  margin: 15px 0;
}

.bp5-section-header.bp5-interactive {
  cursor: pointer;
}

.bp5-section-header.bp5-interactive:hover, .bp5-section-header.bp5-interactive:active {
  background: #f6f7f9;
}

.bp5-section-header.bp5-interactive:hover.bp5-dark, .bp5-dark .bp5-section-header.bp5-interactive:hover, .bp5-section-header.bp5-interactive:active.bp5-dark, .bp5-dark .bp5-section-header.bp5-interactive:active {
  background: #383e47;
}

.bp5-section-card.bp5-padded {
  padding: 20px;
}

.bp5-section-card:not(:last-child) {
  border-bottom: 1px solid #11141826;
}

.bp5-section-card:not(:last-child).bp5-dark, .bp5-dark .bp5-section-card:not(:last-child) {
  border-color: #fff3;
}

.bp5-section.bp5-section-collapsed .bp5-section-header {
  border: none;
}

.bp5-section.bp5-compact .bp5-section-header {
  min-height: 40px;
  padding: 0 15px;
}

.bp5-section.bp5-compact .bp5-section-header-left {
  padding: 7px 0;
}

.bp5-section.bp5-compact .bp5-section-card.bp5-padded {
  padding: 15px;
}

.bp5-segmented-control {
  background-color: #f6f7f9;
  border-radius: 2px;
  gap: 3px;
  padding: 3px;
  display: flex;
}

.bp5-segmented-control.bp5-inline {
  display: inline-flex;
}

.bp5-segmented-control.bp5-fill {
  width: 100%;
}

.bp5-segmented-control.bp5-fill > .bp5-button {
  flex-grow: 1;
}

.bp5-segmented-control > .bp5-button:not(.bp5-minimal):not(.bp5-intent-primary) {
  background-color: #fff;
}

.bp5-dark .bp5-segmented-control > .bp5-button:not(.bp5-minimal):not(.bp5-intent-primary) {
  background-color: #404854;
}

.bp5-segmented-control > .bp5-button.bp5-minimal {
  color: #5f6b7c;
}

.bp5-dark .bp5-segmented-control > .bp5-button.bp5-minimal {
  color: #abb3bf;
}

.bp5-segmented-control > .bp5-button.bp5-minimal:disabled {
  color: #5f6b7c99;
}

.bp5-dark .bp5-segmented-control > .bp5-button.bp5-minimal:disabled {
  color: #abb3bf99;
}

.bp5-dark .bp5-segmented-control {
  background-color: #2f343c;
}

@keyframes skeleton-glow {
  from {
    background: #d3d8de33;
    border-color: #d3d8de33;
  }

  to {
    background: #5f6b7c33;
    border-color: #5f6b7c33;
  }
}

.bp5-skeleton {
  cursor: default;
  pointer-events: none;
  -webkit-user-select: none;
  user-select: none;
  background: #d3d8de33;
  border-radius: 2px;
  animation: 1s linear infinite alternate skeleton-glow;
  box-shadow: none !important;
  color: #0000 !important;
  background-clip: padding-box !important;
  border-color: #d3d8de33 !important;
}

.bp5-skeleton:before, .bp5-skeleton:after, .bp5-skeleton * {
  visibility: hidden !important;
}

@media (forced-colors: active) and (prefers-color-scheme: dark) {
  .bp5-tooltip .bp5-popover-arrow-fill {
    fill: buttonborder;
  }

  .bp5-tooltip {
    border: 1px solid buttonborder;
  }
}

.bp5-tooltip a, .bp5-tooltip a:hover {
  color: #8abbff;
}

.bp5-tooltip a .bp5-icon, .bp5-tooltip a .bp5-icon-standard, .bp5-tooltip a .bp5-icon-large, .bp5-tooltip a code {
  color: inherit;
}

@media (forced-colors: active) and (prefers-color-scheme: dark) {
  .bp5-tooltip.bp5-dark .bp5-popover-arrow-fill, .bp5-dark .bp5-tooltip .bp5-popover-arrow-fill {
    fill: buttonborder;
  }

  .bp5-tooltip.bp5-dark, .bp5-dark .bp5-tooltip {
    border: 1px solid buttonborder;
  }
}

.bp5-tooltip.bp5-dark a, .bp5-dark .bp5-tooltip a, .bp5-tooltip.bp5-dark a:hover, .bp5-dark .bp5-tooltip a:hover {
  color: #215db0;
}

.bp5-tooltip.bp5-dark a .bp5-icon, .bp5-tooltip.bp5-dark a .bp5-icon-standard, .bp5-tooltip.bp5-dark a .bp5-icon-large, .bp5-dark .bp5-tooltip a .bp5-icon, .bp5-dark .bp5-tooltip a .bp5-icon-standard, .bp5-dark .bp5-tooltip a .bp5-icon-large, .bp5-tooltip.bp5-dark a code, .bp5-dark .bp5-tooltip a code {
  color: inherit;
}

@media (forced-colors: active) and (prefers-color-scheme: dark) {
  .bp5-tooltip.bp5-dark .bp5-code-block, .bp5-tooltip.bp5-dark .bp5-running-text pre, .bp5-dark .bp5-tooltip .bp5-code-block, .bp5-dark .bp5-tooltip .bp5-running-text pre {
    border: 1px solid buttonborder;
    box-shadow: none;
  }
}

@media (forced-colors: active) and (prefers-color-scheme: dark) {
  .bp5-tooltip.bp5-intent-primary .bp5-popover-arrow-fill {
    fill: buttonborder;
  }
}

@media (forced-colors: active) and (prefers-color-scheme: dark) {
  .bp5-tooltip.bp5-intent-success .bp5-popover-arrow-fill {
    fill: buttonborder;
  }
}

@media (forced-colors: active) and (prefers-color-scheme: dark) {
  .bp5-tooltip.bp5-intent-warning .bp5-popover-arrow-fill {
    fill: buttonborder;
  }
}

@media (forced-colors: active) and (prefers-color-scheme: dark) {
  .bp5-tooltip.bp5-intent-danger .bp5-popover-arrow-fill {
    fill: buttonborder;
  }
}

.bp5-slider {
  cursor: default;
  -webkit-user-select: none;
  user-select: none;
  outline: none;
  width: 100%;
  min-width: 150px;
  height: 40px;
  position: relative;
}

.bp5-slider:hover {
  cursor: pointer;
}

.bp5-slider:active {
  cursor: grabbing;
}

.bp5-slider.bp5-disabled {
  cursor: not-allowed;
  opacity: .5;
}

.bp5-slider.bp5-slider-unlabeled {
  height: 16px;
}

.bp5-slider-track, .bp5-slider-progress {
  height: 6px;
  position: absolute;
  top: 5px;
  left: 0;
  right: 0;
}

.bp5-slider-track {
  border-radius: 2px;
  overflow: hidden;
}

.bp5-slider-progress {
  background: #5f6b7c33;
}

.bp5-dark .bp5-slider-progress {
  background: #11141880;
}

.bp5-slider-progress.bp5-intent-primary {
  background-color: #2d72d2;
}

.bp5-slider-progress.bp5-intent-success {
  background-color: #238551;
}

.bp5-slider-progress.bp5-intent-warning {
  background-color: #c87619;
}

.bp5-slider-progress.bp5-intent-danger {
  background-color: #cd4246;
}

@media (forced-colors: active) and (prefers-color-scheme: dark) {
  .bp5-slider-progress {
    background: ButtonText;
  }
}

.bp5-slider-handle {
  color: #1c2127;
  cursor: pointer;
  background-color: #f6f7f9;
  border-radius: 2px;
  width: 16px;
  height: 16px;
  position: absolute;
  top: 0;
  left: 0;
  box-shadow: 0 0 0 1px #11141880, 0 1px 1px #11141880;
}

.bp5-slider-handle:hover {
  background-color: #edeff2;
  background-clip: padding-box;
  box-shadow: inset 0 0 0 1px #11141833, 0 1px 2px #11141833;
}

.bp5-slider-handle:active, .bp5-slider-handle.bp5-active {
  background-color: #dce0e5;
  box-shadow: inset 0 0 0 1px #11141833, 0 1px 2px #11141833;
}

@media (forced-colors: active) and (prefers-color-scheme: dark) {
  .bp5-slider-handle:active, .bp5-slider-handle.bp5-active {
    background: highlight;
  }
}

.bp5-slider-handle:disabled, .bp5-slider-handle.bp5-disabled {
  box-shadow: none;
  color: #5f6b7c99;
  cursor: not-allowed;
  background-color: #d3d8de80;
  outline: none;
}

.bp5-slider-handle:disabled.bp5-active, .bp5-slider-handle:disabled.bp5-active:hover, .bp5-slider-handle.bp5-disabled.bp5-active, .bp5-slider-handle.bp5-disabled.bp5-active:hover {
  background: #d3d8deb3;
}

@media (forced-colors: active) and (prefers-color-scheme: dark) {
  .bp5-slider-handle {
    border: 1px solid buttonborder;
  }
}

.bp5-slider-handle:focus {
  z-index: 1;
}

.bp5-slider-handle:hover {
  cursor: grab;
  z-index: 2;
  background-color: #edeff2;
  background-clip: padding-box;
  box-shadow: 0 0 0 1px #11141880, 0 1px 2px #11141899;
}

.bp5-slider-handle.bp5-active {
  cursor: grabbing;
  background-color: #dce0e5;
  box-shadow: inset 0 1px 1px #1114181a, 0 0 0 1px #11141880, 0 1px 2px #11141833;
}

@media (forced-colors: active) and (prefers-color-scheme: dark) {
  .bp5-slider-handle.bp5-active {
    background: highlight;
  }
}

.bp5-disabled .bp5-slider-handle {
  box-shadow: none;
  pointer-events: none;
  background: #c5cbd3;
}

.bp5-dark .bp5-slider-handle {
  background-color: #abb3bf;
  box-shadow: inset 0 0 0 1px #ffffff1a, 0 1px 2px #11141833;
}

.bp5-dark .bp5-slider-handle:hover {
  background-color: #8f99a8;
  box-shadow: inset 0 0 0 1px #ffffff1a, 0 1px 2px #11141866;
}

.bp5-dark .bp5-slider-handle.bp5-active {
  background-color: #738091;
  box-shadow: inset 0 1px 1px #1114181a, inset 0 0 0 1px #ffffff1a, 0 1px 2px #11141866;
}

.bp5-dark .bp5-disabled .bp5-slider-handle {
  box-shadow: none;
  background: #5f6b7c;
  border-color: #5f6b7c;
}

.bp5-slider-handle .bp5-slider-label {
  color: #f6f7f9;
  background: #404854;
  border-radius: 2px;
  margin-left: 8px;
  box-shadow: 0 0 0 1px #1114181a, 0 2px 4px #11141833, 0 8px 24px #11141833;
}

.bp5-dark .bp5-slider-handle .bp5-slider-label {
  color: #404854;
  background: #e5e8eb;
  box-shadow: 0 2px 4px #11141866, 0 8px 24px #11141866;
}

.bp5-disabled .bp5-slider-handle .bp5-slider-label {
  box-shadow: none;
}

.bp5-slider-handle.bp5-start, .bp5-slider-handle.bp5-end {
  width: 8px;
}

.bp5-slider-handle.bp5-start {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.bp5-slider-handle.bp5-end {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  margin-left: 8px;
}

.bp5-slider-handle.bp5-end .bp5-slider-label {
  margin-left: 0;
}

.bp5-slider-label {
  vertical-align: top;
  padding: 2px 5px;
  font-size: 12px;
  line-height: 1;
  display: inline-block;
  position: absolute;
  transform: translate(-50%, 20px);
}

.bp5-slider.bp5-vertical {
  width: 40px;
  min-width: 40px;
  height: 150px;
}

.bp5-slider.bp5-vertical .bp5-slider-track, .bp5-slider.bp5-vertical .bp5-slider-progress {
  width: 6px;
  height: auto;
  top: 0;
  bottom: 0;
  left: 5px;
}

.bp5-slider.bp5-vertical .bp5-slider-progress {
  top: auto;
}

.bp5-slider.bp5-vertical .bp5-slider-label {
  transform: translate(20px, 50%);
}

.bp5-slider.bp5-vertical .bp5-slider-handle {
  top: auto;
}

.bp5-slider.bp5-vertical .bp5-slider-handle .bp5-slider-label {
  margin-top: -8px;
  margin-left: 0;
}

.bp5-slider.bp5-vertical .bp5-slider-handle.bp5-end, .bp5-slider.bp5-vertical .bp5-slider-handle.bp5-start {
  width: 16px;
  height: 8px;
  margin-left: 0;
}

.bp5-slider.bp5-vertical .bp5-slider-handle.bp5-start {
  border-top-left-radius: 0;
  border-bottom-right-radius: 2px;
}

.bp5-slider.bp5-vertical .bp5-slider-handle.bp5-start .bp5-slider-label {
  transform: translate(20px);
}

.bp5-slider.bp5-vertical .bp5-slider-handle.bp5-end {
  border-top-left-radius: 2px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  margin-bottom: 8px;
}

@keyframes pt-spinner-animation {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

.bp5-spinner {
  vertical-align: middle;
  justify-content: center;
  align-items: center;
  display: flex;
  overflow: visible;
}

.bp5-spinner svg {
  display: block;
}

.bp5-spinner path {
  fill-opacity: 0;
}

.bp5-spinner .bp5-spinner-head {
  stroke: #5f6b7ccc;
  stroke-linecap: round;
  transform-origin: center;
  transition: stroke-dashoffset .2s cubic-bezier(.4, 1, .75, .9);
}

.bp5-spinner .bp5-spinner-track {
  stroke: #5f6b7c33;
}

.bp5-spinner-animation {
  animation: .5s linear infinite pt-spinner-animation;
}

.bp5-no-spin > .bp5-spinner-animation {
  animation: none;
}

.bp5-dark .bp5-spinner .bp5-spinner-head {
  stroke: #8f99a8;
}

.bp5-dark .bp5-spinner .bp5-spinner-track {
  stroke: #11141880;
}

.bp5-spinner.bp5-intent-primary .bp5-spinner-head {
  stroke: #2d72d2;
}

.bp5-spinner.bp5-intent-success .bp5-spinner-head {
  stroke: #238551;
}

.bp5-spinner.bp5-intent-warning .bp5-spinner-head {
  stroke: #c87619;
}

.bp5-spinner.bp5-intent-danger .bp5-spinner-head {
  stroke: #cd4246;
}

.bp5-tabs:not(.bp5-vertical).bp5-fill, .bp5-tabs:not(.bp5-vertical).bp5-fill .bp5-tab-list {
  height: 100%;
}

.bp5-tabs.bp5-vertical {
  display: flex;
}

.bp5-tabs.bp5-vertical > .bp5-tab-list {
  flex-direction: column;
  align-items: flex-start;
}

.bp5-tabs.bp5-vertical > .bp5-tab-list .bp5-tab {
  border-radius: 2px;
  align-items: center;
  width: 100%;
  padding: 0 10px;
  display: flex;
}

.bp5-tabs.bp5-vertical > .bp5-tab-list .bp5-tab[aria-selected="true"] {
  box-shadow: none;
  background-color: #2d72d233;
}

@media (forced-colors: active) and (prefers-color-scheme: dark) {
  .bp5-tabs.bp5-vertical > .bp5-tab-list .bp5-tab[aria-selected="true"] {
    background-color: highlight;
    color: #111418;
  }
}

.bp5-tabs.bp5-vertical > .bp5-tab-list .bp5-tab-indicator-wrapper .bp5-tab-indicator {
  background-color: #2d72d233;
  border-radius: 2px;
  height: auto;
  inset: 0;
}

.bp5-tabs.bp5-vertical > .bp5-tab-panel {
  margin-top: 0;
  padding-left: 20px;
}

.bp5-tab-list {
  -moz-column-gap: 20px;
  border: none;
  flex: none;
  align-items: flex-end;
  column-gap: 20px;
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  position: relative;
}

.bp5-tab {
  text-overflow: ellipsis;
  white-space: nowrap;
  word-wrap: normal;
  color: #1c2127;
  cursor: pointer;
  vertical-align: top;
  flex: none;
  align-self: stretch;
  align-items: center;
  max-width: 100%;
  font-size: 14px;
  line-height: 30px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.bp5-tab a {
  color: inherit;
  text-decoration: none;
  display: block;
}

.bp5-tab-indicator-wrapper ~ .bp5-tab {
  box-shadow: none !important;
  background-color: #0000 !important;
}

.bp5-tab[aria-disabled="true"] {
  color: #5f6b7c99;
  cursor: not-allowed;
}

@media (forced-colors: active) and (prefers-color-scheme: dark) {
  .bp5-tab[aria-disabled="true"] {
    color: graytext;
  }
}

.bp5-tab[aria-selected="true"] {
  border-radius: 0;
  box-shadow: inset 0 -3px #215db0;
}

.bp5-tab[aria-selected="true"], .bp5-tab:not([aria-disabled="true"]):hover {
  color: #215db0;
}

@media (forced-colors: active) and (prefers-color-scheme: dark) {
  .bp5-tab[aria-selected="true"], .bp5-tab:not([aria-disabled="true"]):hover {
    color: highlight;
  }
}

.bp5-tab:focus {
  -moz-outline-radius: 0;
}

.bp5-large > .bp5-tab {
  font-size: 16px;
  line-height: 40px;
}

.bp5-tab-panel {
  margin-top: 20px;
}

.bp5-tab-panel[aria-hidden="true"] {
  display: none;
}

.bp5-tab-icon {
  margin-right: 7px;
}

.bp5-tab-tag {
  margin-left: 7px;
}

.bp5-tab-indicator-wrapper {
  pointer-events: none;
  transform: translateX(0), translateY(0);
  transition: height .2s cubic-bezier(.4, 1, .75, .9), transform .2s cubic-bezier(.4, 1, .75, .9), width .2s cubic-bezier(.4, 1, .75, .9);
  position: absolute;
  top: 0;
  left: 0;
}

.bp5-tab-indicator-wrapper .bp5-tab-indicator {
  background-color: #215db0;
  height: 3px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

@media (forced-colors: active) and (prefers-color-scheme: dark) {
  .bp5-tab-indicator-wrapper .bp5-tab-indicator {
    background-color: highlight;
  }
}

.bp5-tab-indicator-wrapper.bp5-no-animation {
  transition: none;
}

.bp5-tabs:not(.bp5-vertical) .bp5-tab-indicator-wrapper {
  height: 100%;
}

.bp5-dark .bp5-tab {
  color: #f6f7f9;
}

.bp5-dark .bp5-tab[aria-disabled="true"] {
  color: #abb3bf99;
}

@media (forced-colors: active) and (prefers-color-scheme: dark) {
  .bp5-dark .bp5-tab[aria-disabled="true"] {
    color: graytext;
  }
}

.bp5-dark .bp5-tab[aria-selected="true"] {
  box-shadow: inset 0 -3px #8abbff;
}

.bp5-dark .bp5-tab[aria-selected="true"], .bp5-dark .bp5-tab:not([aria-disabled="true"]):hover {
  color: #8abbff;
}

@media (forced-colors: active) and (prefers-color-scheme: dark) {
  .bp5-dark .bp5-tab[aria-selected="true"], .bp5-dark .bp5-tab:not([aria-disabled="true"]):hover {
    color: highlight;
  }
}

.bp5-dark .bp5-tab-indicator {
  background-color: #8abbff;
}

@media (forced-colors: active) and (prefers-color-scheme: dark) {
  .bp5-dark .bp5-tab-indicator {
    background-color: highlight;
  }
}

.bp5-flex-expander {
  flex: 1;
}

.bp5-tag {
  box-shadow: none;
  color: #fff;
  background-color: #5f6b7c;
  border: none;
  border-radius: 2px;
  flex-direction: row;
  align-items: center;
  min-width: 20px;
  max-width: 100%;
  min-height: 20px;
  padding: 2px 6px;
  font-size: 12px;
  line-height: 16px;
  display: inline-flex;
  position: relative;
}

.bp5-tag > * {
  flex-grow: 0;
  flex-shrink: 0;
}

.bp5-tag > .bp5-fill {
  flex-grow: 1;
  flex-shrink: 1;
}

.bp5-tag:before, .bp5-tag > * {
  margin-right: 4px;
}

.bp5-tag:empty:before, .bp5-tag > :last-child {
  margin-right: 0;
}

.bp5-tag:focus {
  outline-offset: 0;
  -moz-outline-radius: 6px;
  outline: 2px solid #2d72d299;
}

.bp5-tag.bp5-interactive {
  cursor: pointer;
}

.bp5-tag.bp5-interactive:hover {
  background: #404854;
}

.bp5-tag.bp5-interactive:active, .bp5-tag.bp5-interactive.bp5-active {
  background: #383e47;
}

.bp5-tag.bp5-round {
  border-radius: 30px;
  padding-left: 8px;
  padding-right: 8px;
}

.bp5-dark .bp5-tag {
  color: #1c2127;
  background-color: #c5cbd3;
}

.bp5-dark .bp5-tag > .bp5-icon, .bp5-dark .bp5-tag .bp5-icon-standard, .bp5-dark .bp5-tag .bp5-icon-large {
  fill: currentColor;
}

.bp5-dark .bp5-tag.bp5-interactive:hover {
  background: #abb3bf;
}

.bp5-dark .bp5-tag.bp5-interactive:active, .bp5-dark .bp5-tag.bp5-interactive.bp5-active {
  background: #8f99a8;
}

.bp5-tag > .bp5-icon, .bp5-tag .bp5-icon-standard, .bp5-tag .bp5-icon-large {
  fill: #fff;
}

@media (forced-colors: active) and (prefers-color-scheme: dark) {
  .bp5-tag {
    border: 1px solid buttonborder;
  }
}

.bp5-tag.bp5-large, .bp5-large .bp5-tag {
  min-width: 30px;
  min-height: 30px;
  padding: 5px 10px;
  font-size: 14px;
  line-height: 20px;
}

.bp5-tag.bp5-large:before, .bp5-tag.bp5-large > *, .bp5-large .bp5-tag:before, .bp5-large .bp5-tag > * {
  margin-right: 7px;
}

.bp5-tag.bp5-large:empty:before, .bp5-tag.bp5-large > :last-child, .bp5-large .bp5-tag:empty:before, .bp5-large .bp5-tag > :last-child {
  margin-right: 0;
}

.bp5-tag.bp5-large.bp5-round, .bp5-large .bp5-tag.bp5-round {
  padding-left: 12px;
  padding-right: 12px;
}

.bp5-tag.bp5-intent-primary {
  color: #fff;
  background: #427762;
}

.bp5-tag.bp5-intent-primary.bp5-interactive:hover {
  background-color: #215db0;
}

.bp5-tag.bp5-intent-primary.bp5-interactive:active, .bp5-tag.bp5-intent-primary.bp5-interactive.bp5-active {
  background-color: #184a90;
}

.bp5-tag.bp5-intent-primary .bp5-tag-remove {
  color: #ffffffb3;
}

.bp5-tag.bp5-intent-primary .bp5-tag-remove:hover, .bp5-tag.bp5-intent-primary .bp5-tag-remove:active {
  color: #fff;
}

.bp5-tag.bp5-intent-success {
  color: #fff;
  background: #238551;
}

.bp5-tag.bp5-intent-success.bp5-interactive:hover {
  background-color: #1c6e42;
}

.bp5-tag.bp5-intent-success.bp5-interactive:active, .bp5-tag.bp5-intent-success.bp5-interactive.bp5-active {
  background-color: #165a36;
}

.bp5-tag.bp5-intent-success .bp5-tag-remove {
  color: #ffffffb3;
}

.bp5-tag.bp5-intent-success .bp5-tag-remove:hover, .bp5-tag.bp5-intent-success .bp5-tag-remove:active {
  color: #fff;
}

.bp5-tag.bp5-intent-warning {
  color: #1c2127;
  background: #fbb360;
}

.bp5-tag.bp5-intent-warning.bp5-interactive:hover {
  background-color: #ec9a3c;
}

.bp5-tag.bp5-intent-warning.bp5-interactive:active, .bp5-tag.bp5-intent-warning.bp5-interactive.bp5-active {
  background-color: #c87619;
}

.bp5-tag.bp5-intent-warning .bp5-tag-remove {
  color: #1c2127b3;
}

.bp5-tag.bp5-intent-warning .bp5-tag-remove:hover, .bp5-tag.bp5-intent-warning .bp5-tag-remove:active {
  color: #1c2127;
}

.bp5-tag.bp5-intent-danger {
  color: #fff;
  background: #cd4246;
}

.bp5-tag.bp5-intent-danger.bp5-interactive:hover {
  background-color: #ac2f33;
}

.bp5-tag.bp5-intent-danger.bp5-interactive:active, .bp5-tag.bp5-intent-danger.bp5-interactive.bp5-active {
  background-color: #8e292c;
}

.bp5-tag.bp5-intent-danger .bp5-tag-remove {
  color: #ffffffb3;
}

.bp5-tag.bp5-intent-danger .bp5-tag-remove:hover, .bp5-tag.bp5-intent-danger .bp5-tag-remove:active {
  color: #fff;
}

.bp5-tag.bp5-fill {
  width: 100%;
  display: flex;
}

.bp5-tag.bp5-minimal > .bp5-icon, .bp5-tag.bp5-minimal .bp5-icon-standard, .bp5-tag.bp5-minimal .bp5-icon-large {
  fill: #5f6b7c;
}

.bp5-tag.bp5-minimal:not([class*="bp5-intent-"]) {
  color: #1c2127;
  background-color: #8f99a826;
}

.bp5-tag.bp5-minimal:not([class*="bp5-intent-"]).bp5-interactive {
  cursor: pointer;
}

.bp5-tag.bp5-minimal:not([class*="bp5-intent-"]).bp5-interactive:hover {
  color: #111418;
  background-color: #8f99a84d;
}

.bp5-tag.bp5-minimal:not([class*="bp5-intent-"]).bp5-interactive.bp5-active, .bp5-tag.bp5-minimal:not([class*="bp5-intent-"]).bp5-interactive:active {
  color: #111418;
  background-color: #8f99a859;
}

.bp5-tag.bp5-minimal:not([class*="bp5-intent-"]) .bp5-tag-remove {
  color: #5f6b7c;
}

.bp5-tag.bp5-minimal:not([class*="bp5-intent-"]) .bp5-tag-remove:hover, .bp5-tag.bp5-minimal:not([class*="bp5-intent-"]) .bp5-tag-remove:active {
  color: #404854;
}

.bp5-dark .bp5-tag.bp5-minimal:not([class*="bp5-intent-"]) {
  color: #f6f7f9;
  background-color: #8f99a826;
}

.bp5-dark .bp5-tag.bp5-minimal:not([class*="bp5-intent-"]).bp5-interactive {
  cursor: pointer;
}

.bp5-dark .bp5-tag.bp5-minimal:not([class*="bp5-intent-"]).bp5-interactive:hover {
  color: #fff;
  background-color: #8f99a84d;
}

.bp5-dark .bp5-tag.bp5-minimal:not([class*="bp5-intent-"]).bp5-interactive.bp5-active, .bp5-dark .bp5-tag.bp5-minimal:not([class*="bp5-intent-"]).bp5-interactive:active {
  color: #fff;
  background-color: #8f99a859;
}

.bp5-dark .bp5-tag.bp5-minimal:not([class*="bp5-intent-"]) .bp5-tag-remove {
  color: #abb3bf;
}

.bp5-dark .bp5-tag.bp5-minimal:not([class*="bp5-intent-"]) .bp5-tag-remove:hover, .bp5-dark .bp5-tag.bp5-minimal:not([class*="bp5-intent-"]) .bp5-tag-remove:active {
  color: #d3d8de;
}

.bp5-tag.bp5-minimal.bp5-intent-primary {
  color: #215db0;
  background-color: #2d72d21a;
}

.bp5-tag.bp5-minimal.bp5-intent-primary > .bp5-icon, .bp5-tag.bp5-minimal.bp5-intent-primary .bp5-icon-standard, .bp5-tag.bp5-minimal.bp5-intent-primary .bp5-icon-large {
  fill: #215db0;
}

.bp5-tag.bp5-minimal.bp5-intent-primary.bp5-interactive:hover {
  color: #184a90;
  background-color: #2d72d233;
}

.bp5-tag.bp5-minimal.bp5-intent-primary.bp5-interactive:active, .bp5-tag.bp5-minimal.bp5-intent-primary.bp5-interactive.bp5-active {
  color: #184a90;
  background-color: #2d72d24d;
}

.bp5-tag.bp5-minimal.bp5-intent-primary .bp5-tag-remove {
  color: #215db0;
}

.bp5-tag.bp5-minimal.bp5-intent-primary .bp5-tag-remove:hover, .bp5-tag.bp5-minimal.bp5-intent-primary .bp5-tag-remove:active {
  color: #184a90;
}

.bp5-tag.bp5-minimal.bp5-intent-success {
  color: #1c6e42;
  background-color: #2385511a;
}

.bp5-tag.bp5-minimal.bp5-intent-success > .bp5-icon, .bp5-tag.bp5-minimal.bp5-intent-success .bp5-icon-standard, .bp5-tag.bp5-minimal.bp5-intent-success .bp5-icon-large {
  fill: #1c6e42;
}

.bp5-tag.bp5-minimal.bp5-intent-success.bp5-interactive:hover {
  color: #165a36;
  background-color: #23855133;
}

.bp5-tag.bp5-minimal.bp5-intent-success.bp5-interactive:active, .bp5-tag.bp5-minimal.bp5-intent-success.bp5-interactive.bp5-active {
  color: #165a36;
  background-color: #2385514d;
}

.bp5-tag.bp5-minimal.bp5-intent-success .bp5-tag-remove {
  color: #1c6e42;
}

.bp5-tag.bp5-minimal.bp5-intent-success .bp5-tag-remove:hover, .bp5-tag.bp5-minimal.bp5-intent-success .bp5-tag-remove:active {
  color: #165a36;
}

.bp5-tag.bp5-minimal.bp5-intent-warning {
  color: #935610;
  background-color: #c876191a;
}

.bp5-tag.bp5-minimal.bp5-intent-warning > .bp5-icon, .bp5-tag.bp5-minimal.bp5-intent-warning .bp5-icon-standard, .bp5-tag.bp5-minimal.bp5-intent-warning .bp5-icon-large {
  fill: #935610;
}

.bp5-tag.bp5-minimal.bp5-intent-warning.bp5-interactive:hover {
  color: #77450d;
  background-color: #c8761933;
}

.bp5-tag.bp5-minimal.bp5-intent-warning.bp5-interactive:active, .bp5-tag.bp5-minimal.bp5-intent-warning.bp5-interactive.bp5-active {
  color: #77450d;
  background-color: #c876194d;
}

.bp5-tag.bp5-minimal.bp5-intent-warning .bp5-tag-remove {
  color: #935610;
}

.bp5-tag.bp5-minimal.bp5-intent-warning .bp5-tag-remove:hover, .bp5-tag.bp5-minimal.bp5-intent-warning .bp5-tag-remove:active {
  color: #77450d;
}

.bp5-tag.bp5-minimal.bp5-intent-danger {
  color: #ac2f33;
  background-color: #cd42461a;
}

.bp5-tag.bp5-minimal.bp5-intent-danger > .bp5-icon, .bp5-tag.bp5-minimal.bp5-intent-danger .bp5-icon-standard, .bp5-tag.bp5-minimal.bp5-intent-danger .bp5-icon-large {
  fill: #ac2f33;
}

.bp5-tag.bp5-minimal.bp5-intent-danger.bp5-interactive:hover {
  color: #8e292c;
  background-color: #cd424633;
}

.bp5-tag.bp5-minimal.bp5-intent-danger.bp5-interactive:active, .bp5-tag.bp5-minimal.bp5-intent-danger.bp5-interactive.bp5-active {
  color: #8e292c;
  background-color: #cd42464d;
}

.bp5-tag.bp5-minimal.bp5-intent-danger .bp5-tag-remove {
  color: #ac2f33;
}

.bp5-tag.bp5-minimal.bp5-intent-danger .bp5-tag-remove:hover, .bp5-tag.bp5-minimal.bp5-intent-danger .bp5-tag-remove:active {
  color: #8e292c;
}

.bp5-dark .bp5-tag.bp5-minimal.bp5-intent-primary {
  color: #8abbff;
  background-color: #2d72d233;
}

.bp5-dark .bp5-tag.bp5-minimal.bp5-intent-primary.bp5-interactive:hover {
  color: #99c4ff;
  background-color: #2d72d24d;
}

.bp5-dark .bp5-tag.bp5-minimal.bp5-intent-primary.bp5-interactive:active, .bp5-dark .bp5-tag.bp5-minimal.bp5-intent-primary.bp5-interactive.bp5-active {
  color: #99c4ff;
  background-color: #2d72d259;
}

.bp5-dark .bp5-tag.bp5-minimal.bp5-intent-primary .bp5-tag-remove {
  color: #8abbff;
}

.bp5-dark .bp5-tag.bp5-minimal.bp5-intent-primary .bp5-tag-remove:hover, .bp5-dark .bp5-tag.bp5-minimal.bp5-intent-primary .bp5-tag-remove:active {
  color: #99c4ff;
}

.bp5-dark .bp5-tag.bp5-minimal.bp5-intent-success {
  color: #72ca9b;
  background-color: #23855133;
}

.bp5-dark .bp5-tag.bp5-minimal.bp5-intent-success.bp5-interactive:hover {
  color: #7cd7a2;
  background-color: #2385514d;
}

.bp5-dark .bp5-tag.bp5-minimal.bp5-intent-success.bp5-interactive:active, .bp5-dark .bp5-tag.bp5-minimal.bp5-intent-success.bp5-interactive.bp5-active {
  color: #7cd7a2;
  background-color: #23855159;
}

.bp5-dark .bp5-tag.bp5-minimal.bp5-intent-success .bp5-tag-remove {
  color: #72ca9b;
}

.bp5-dark .bp5-tag.bp5-minimal.bp5-intent-success .bp5-tag-remove:hover, .bp5-dark .bp5-tag.bp5-minimal.bp5-intent-success .bp5-tag-remove:active {
  color: #7cd7a2;
}

.bp5-dark .bp5-tag.bp5-minimal.bp5-intent-warning {
  color: #fbb360;
  background-color: #c8761933;
}

.bp5-dark .bp5-tag.bp5-minimal.bp5-intent-warning.bp5-interactive:hover {
  color: #f5c186;
  background-color: #c876194d;
}

.bp5-dark .bp5-tag.bp5-minimal.bp5-intent-warning.bp5-interactive:active, .bp5-dark .bp5-tag.bp5-minimal.bp5-intent-warning.bp5-interactive.bp5-active {
  color: #f5c186;
  background-color: #c8761959;
}

.bp5-dark .bp5-tag.bp5-minimal.bp5-intent-warning .bp5-tag-remove {
  color: #fbb360;
}

.bp5-dark .bp5-tag.bp5-minimal.bp5-intent-warning .bp5-tag-remove:hover, .bp5-dark .bp5-tag.bp5-minimal.bp5-intent-warning .bp5-tag-remove:active {
  color: #f5c186;
}

.bp5-dark .bp5-tag.bp5-minimal.bp5-intent-danger {
  color: #fa999c;
  background-color: #cd424633;
}

.bp5-dark .bp5-tag.bp5-minimal.bp5-intent-danger.bp5-interactive:hover {
  color: #ffa1a4;
  background-color: #cd42464d;
}

.bp5-dark .bp5-tag.bp5-minimal.bp5-intent-danger.bp5-interactive:active, .bp5-dark .bp5-tag.bp5-minimal.bp5-intent-danger.bp5-interactive.bp5-active {
  color: #ffa1a4;
  background-color: #cd424659;
}

.bp5-dark .bp5-tag.bp5-minimal.bp5-intent-danger .bp5-tag-remove {
  color: #fa999c;
}

.bp5-dark .bp5-tag.bp5-minimal.bp5-intent-danger .bp5-tag-remove:hover, .bp5-dark .bp5-tag.bp5-minimal.bp5-intent-danger .bp5-tag-remove:active {
  color: #ffa1a4;
}

.bp5-tag-remove {
  color: #ffffffb3;
  cursor: pointer;
  background: none;
  border: none;
  margin-top: -2px;
  margin-bottom: -2px;
  padding: 2px 2px 2px 0;
  display: flex;
  margin-right: -6px !important;
}

.bp5-tag-remove:hover {
  color: inherit;
  background: none;
  text-decoration: none;
}

.bp5-tag-remove .bp5-icon:first-child {
  color: inherit;
}

.bp5-tag-remove:hover, .bp5-tag-remove:active {
  color: #fff;
}

.bp5-tag-remove:empty:before {
  font-variant: normal;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  content: "";
  width: 16px;
  height: 16px;
  font-family: blueprint-icons-16, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 1;
}

.bp5-large .bp5-tag-remove {
  padding: 0 5px 0 0;
  margin-right: -10px !important;
}

.bp5-large .bp5-tag-remove:empty:before {
  font-variant: normal;
  width: 20px;
  height: 20px;
  font-family: blueprint-icons-20, sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 1;
}

.bp5-dark .bp5-tag-remove {
  color: #1c2127b3;
}

.bp5-dark .bp5-tag-remove:hover, .bp5-dark .bp5-tag-remove:active {
  color: #1c2127;
}

.bp5-compound-tag {
  background: none;
  padding: 0;
}

.bp5-compound-tag .bp5-compound-tag-left {
  background-color: #404854;
}

.bp5-compound-tag .bp5-compound-tag-right {
  background-color: #5f6b7c;
}

.bp5-compound-tag.bp5-interactive:hover .bp5-compound-tag-left {
  background-color: #383e47;
}

.bp5-compound-tag.bp5-interactive:hover .bp5-compound-tag-right {
  background-color: #404854;
}

.bp5-compound-tag.bp5-interactive:active .bp5-compound-tag-left, .bp5-compound-tag.bp5-interactive.bp5-active .bp5-compound-tag-left {
  background-color: #2f343c;
}

.bp5-compound-tag.bp5-interactive:active .bp5-compound-tag-right, .bp5-compound-tag.bp5-interactive.bp5-active .bp5-compound-tag-right {
  background-color: #383e47;
}

.bp5-compound-tag .bp5-compound-tag-left, .bp5-compound-tag .bp5-compound-tag-right {
  align-items: center;
  padding: 2px 4px;
  display: inline-flex;
}

.bp5-compound-tag .bp5-compound-tag-left {
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
  margin-right: 0;
}

.bp5-compound-tag .bp5-compound-tag-left > .bp5-icon, .bp5-compound-tag .bp5-compound-tag-left .bp5-icon-standard, .bp5-compound-tag .bp5-compound-tag-left .bp5-icon-large {
  margin-right: 4px;
}

.bp5-compound-tag .bp5-compound-tag-right {
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
  flex-grow: 1;
  padding: 2px 4px;
}

.bp5-compound-tag .bp5-compound-tag-right > .bp5-icon, .bp5-compound-tag .bp5-compound-tag-right .bp5-icon-standard, .bp5-compound-tag .bp5-compound-tag-right .bp5-icon-large {
  margin-left: 4px;
}

.bp5-compound-tag .bp5-compound-tag-right .bp5-compound-tag-right-text {
  flex-grow: 1;
}

.bp5-compound-tag .bp5-compound-tag-right .bp5-tag-remove {
  margin-left: 2px;
  margin-right: -4px !important;
}

.bp5-compound-tag.bp5-round {
  padding: 0;
}

.bp5-compound-tag.bp5-round .bp5-compound-tag-left {
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  padding-left: 8px;
}

.bp5-compound-tag.bp5-round .bp5-compound-tag-right {
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  padding-right: 8px;
}

.bp5-compound-tag.bp5-large {
  padding: 0;
}

.bp5-compound-tag.bp5-large .bp5-compound-tag-left, .bp5-compound-tag.bp5-large .bp5-compound-tag-right {
  padding: 5px 8px;
}

.bp5-compound-tag.bp5-large .bp5-compound-tag-left > .bp5-icon, .bp5-compound-tag.bp5-large .bp5-compound-tag-left .bp5-icon-standard, .bp5-compound-tag.bp5-large .bp5-compound-tag-left .bp5-icon-large {
  margin-right: 7px;
}

.bp5-compound-tag.bp5-large .bp5-compound-tag-right > .bp5-icon, .bp5-compound-tag.bp5-large .bp5-compound-tag-right .bp5-icon-standard, .bp5-compound-tag.bp5-large .bp5-compound-tag-right .bp5-icon-large {
  margin-left: 7px;
}

.bp5-compound-tag.bp5-large .bp5-tag-remove {
  margin-left: 7px;
  margin-right: -10px !important;
}

.bp5-compound-tag.bp5-large.bp5-round {
  padding: 0;
}

.bp5-compound-tag.bp5-large.bp5-round .bp5-compound-tag-left {
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
  padding-left: 12px;
}

.bp5-compound-tag.bp5-large.bp5-round .bp5-compound-tag-right {
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
  padding-right: 12px;
}

.bp5-compound-tag.bp5-minimal:not([class*="bp5-intent-"]) {
  background: none;
}

.bp5-compound-tag.bp5-minimal:not([class*="bp5-intent-"]) .bp5-compound-tag-left {
  background-color: #5f6b7c33;
}

.bp5-compound-tag.bp5-minimal:not([class*="bp5-intent-"]) .bp5-compound-tag-right {
  background-color: #5f6b7c1a;
}

.bp5-compound-tag.bp5-minimal:not([class*="bp5-intent-"]).bp5-interactive:hover .bp5-compound-tag-left {
  background-color: #5f6b7c4d;
}

.bp5-compound-tag.bp5-minimal:not([class*="bp5-intent-"]).bp5-interactive:hover .bp5-compound-tag-right {
  background-color: #5f6b7c33;
}

.bp5-compound-tag.bp5-minimal:not([class*="bp5-intent-"]).bp5-interactive:active .bp5-compound-tag-left, .bp5-compound-tag.bp5-minimal:not([class*="bp5-intent-"]).bp5-interactive.bp5-active .bp5-compound-tag-left {
  background-color: #5f6b7c66;
}

.bp5-compound-tag.bp5-minimal:not([class*="bp5-intent-"]).bp5-interactive:active .bp5-compound-tag-right, .bp5-compound-tag.bp5-minimal:not([class*="bp5-intent-"]).bp5-interactive.bp5-active .bp5-compound-tag-right {
  background-color: #5f6b7c4d;
}

.bp5-compound-tag.bp5-minimal.bp5-intent-primary {
  background: none;
}

.bp5-compound-tag.bp5-minimal.bp5-intent-primary .bp5-compound-tag-left {
  background-color: #2d72d233;
}

.bp5-compound-tag.bp5-minimal.bp5-intent-primary .bp5-compound-tag-right {
  background-color: #2d72d21a;
}

.bp5-compound-tag.bp5-minimal.bp5-intent-primary.bp5-interactive:hover .bp5-compound-tag-left {
  background-color: #2d72d24d;
}

.bp5-compound-tag.bp5-minimal.bp5-intent-primary.bp5-interactive:hover .bp5-compound-tag-right {
  background-color: #2d72d233;
}

.bp5-compound-tag.bp5-minimal.bp5-intent-primary.bp5-interactive:active .bp5-compound-tag-left, .bp5-compound-tag.bp5-minimal.bp5-intent-primary.bp5-interactive.bp5-active .bp5-compound-tag-left {
  background-color: #2d72d266;
}

.bp5-compound-tag.bp5-minimal.bp5-intent-primary.bp5-interactive:active .bp5-compound-tag-right, .bp5-compound-tag.bp5-minimal.bp5-intent-primary.bp5-interactive.bp5-active .bp5-compound-tag-right {
  background-color: #2d72d24d;
}

.bp5-compound-tag.bp5-minimal.bp5-intent-success {
  background: none;
}

.bp5-compound-tag.bp5-minimal.bp5-intent-success .bp5-compound-tag-left {
  background-color: #23855133;
}

.bp5-compound-tag.bp5-minimal.bp5-intent-success .bp5-compound-tag-right {
  background-color: #2385511a;
}

.bp5-compound-tag.bp5-minimal.bp5-intent-success.bp5-interactive:hover .bp5-compound-tag-left {
  background-color: #2385514d;
}

.bp5-compound-tag.bp5-minimal.bp5-intent-success.bp5-interactive:hover .bp5-compound-tag-right {
  background-color: #23855133;
}

.bp5-compound-tag.bp5-minimal.bp5-intent-success.bp5-interactive:active .bp5-compound-tag-left, .bp5-compound-tag.bp5-minimal.bp5-intent-success.bp5-interactive.bp5-active .bp5-compound-tag-left {
  background-color: #23855166;
}

.bp5-compound-tag.bp5-minimal.bp5-intent-success.bp5-interactive:active .bp5-compound-tag-right, .bp5-compound-tag.bp5-minimal.bp5-intent-success.bp5-interactive.bp5-active .bp5-compound-tag-right {
  background-color: #2385514d;
}

.bp5-compound-tag.bp5-minimal.bp5-intent-warning {
  background: none;
}

.bp5-compound-tag.bp5-minimal.bp5-intent-warning .bp5-compound-tag-left {
  background-color: #c8761933;
}

.bp5-compound-tag.bp5-minimal.bp5-intent-warning .bp5-compound-tag-right {
  background-color: #c876191a;
}

.bp5-compound-tag.bp5-minimal.bp5-intent-warning.bp5-interactive:hover .bp5-compound-tag-left {
  background-color: #c876194d;
}

.bp5-compound-tag.bp5-minimal.bp5-intent-warning.bp5-interactive:hover .bp5-compound-tag-right {
  background-color: #c8761933;
}

.bp5-compound-tag.bp5-minimal.bp5-intent-warning.bp5-interactive:active .bp5-compound-tag-left, .bp5-compound-tag.bp5-minimal.bp5-intent-warning.bp5-interactive.bp5-active .bp5-compound-tag-left {
  background-color: #c8761966;
}

.bp5-compound-tag.bp5-minimal.bp5-intent-warning.bp5-interactive:active .bp5-compound-tag-right, .bp5-compound-tag.bp5-minimal.bp5-intent-warning.bp5-interactive.bp5-active .bp5-compound-tag-right {
  background-color: #c876194d;
}

.bp5-compound-tag.bp5-minimal.bp5-intent-danger {
  background: none;
}

.bp5-compound-tag.bp5-minimal.bp5-intent-danger .bp5-compound-tag-left {
  background-color: #cd424633;
}

.bp5-compound-tag.bp5-minimal.bp5-intent-danger .bp5-compound-tag-right {
  background-color: #cd42461a;
}

.bp5-compound-tag.bp5-minimal.bp5-intent-danger.bp5-interactive:hover .bp5-compound-tag-left {
  background-color: #cd42464d;
}

.bp5-compound-tag.bp5-minimal.bp5-intent-danger.bp5-interactive:hover .bp5-compound-tag-right {
  background-color: #cd424633;
}

.bp5-compound-tag.bp5-minimal.bp5-intent-danger.bp5-interactive:active .bp5-compound-tag-left, .bp5-compound-tag.bp5-minimal.bp5-intent-danger.bp5-interactive.bp5-active .bp5-compound-tag-left {
  background-color: #cd424666;
}

.bp5-compound-tag.bp5-minimal.bp5-intent-danger.bp5-interactive:active .bp5-compound-tag-right, .bp5-compound-tag.bp5-minimal.bp5-intent-danger.bp5-interactive.bp5-active .bp5-compound-tag-right {
  background-color: #cd42464d;
}

.bp5-dark .bp5-compound-tag {
  background: none;
}

.bp5-dark .bp5-compound-tag .bp5-compound-tag-left {
  background-color: #abb3bf;
}

.bp5-dark .bp5-compound-tag .bp5-compound-tag-right {
  background-color: #c5cbd3;
}

.bp5-dark .bp5-compound-tag.bp5-interactive:hover .bp5-compound-tag-left {
  background-color: #8f99a8;
}

.bp5-dark .bp5-compound-tag.bp5-interactive:hover .bp5-compound-tag-right {
  background-color: #abb3bf;
}

.bp5-dark .bp5-compound-tag.bp5-interactive:active .bp5-compound-tag-left, .bp5-dark .bp5-compound-tag.bp5-interactive.bp5-active .bp5-compound-tag-left {
  background-color: #738091;
}

.bp5-dark .bp5-compound-tag.bp5-interactive:active .bp5-compound-tag-right, .bp5-dark .bp5-compound-tag.bp5-interactive.bp5-active .bp5-compound-tag-right {
  background-color: gray3;
}

.bp5-dark .bp5-compound-tag.bp5-minimal:not([class*="bp5-intent-"]) {
  background: none;
}

.bp5-dark .bp5-compound-tag.bp5-minimal:not([class*="bp5-intent-"]) .bp5-compound-tag-left {
  background-color: #5f6b7c66;
}

.bp5-dark .bp5-compound-tag.bp5-minimal:not([class*="bp5-intent-"]) .bp5-compound-tag-right {
  background-color: #5f6b7c33;
}

.bp5-dark .bp5-compound-tag.bp5-minimal:not([class*="bp5-intent-"]).bp5-interactive:hover .bp5-compound-tag-left {
  background-color: #5f6b7c80;
}

.bp5-dark .bp5-compound-tag.bp5-minimal:not([class*="bp5-intent-"]).bp5-interactive:hover .bp5-compound-tag-right {
  background-color: #5f6b7c4d;
}

.bp5-dark .bp5-compound-tag.bp5-minimal:not([class*="bp5-intent-"]).bp5-interactive:active .bp5-compound-tag-left, .bp5-dark .bp5-compound-tag.bp5-minimal:not([class*="bp5-intent-"]).bp5-interactive.bp5-active .bp5-compound-tag-left {
  background-color: #5f6b7c8c;
}

.bp5-dark .bp5-compound-tag.bp5-minimal:not([class*="bp5-intent-"]).bp5-interactive:active .bp5-compound-tag-right, .bp5-dark .bp5-compound-tag.bp5-minimal:not([class*="bp5-intent-"]).bp5-interactive.bp5-active .bp5-compound-tag-right {
  background-color: #5f6b7c59;
}

.bp5-dark .bp5-compound-tag.bp5-minimal.bp5-intent-primary {
  background: none;
}

.bp5-dark .bp5-compound-tag.bp5-minimal.bp5-intent-primary .bp5-compound-tag-left {
  background-color: #2d72d266;
}

.bp5-dark .bp5-compound-tag.bp5-minimal.bp5-intent-primary .bp5-compound-tag-right {
  background-color: #2d72d233;
}

.bp5-dark .bp5-compound-tag.bp5-minimal.bp5-intent-primary.bp5-interactive:hover .bp5-compound-tag-left {
  background-color: #2d72d280;
}

.bp5-dark .bp5-compound-tag.bp5-minimal.bp5-intent-primary.bp5-interactive:hover .bp5-compound-tag-right {
  background-color: #2d72d24d;
}

.bp5-dark .bp5-compound-tag.bp5-minimal.bp5-intent-primary.bp5-interactive:active .bp5-compound-tag-left, .bp5-dark .bp5-compound-tag.bp5-minimal.bp5-intent-primary.bp5-interactive.bp5-active .bp5-compound-tag-left {
  background-color: #2d72d28c;
}

.bp5-dark .bp5-compound-tag.bp5-minimal.bp5-intent-primary.bp5-interactive:active .bp5-compound-tag-right, .bp5-dark .bp5-compound-tag.bp5-minimal.bp5-intent-primary.bp5-interactive.bp5-active .bp5-compound-tag-right {
  background-color: #2d72d259;
}

.bp5-dark .bp5-compound-tag.bp5-minimal.bp5-intent-success {
  background: none;
}

.bp5-dark .bp5-compound-tag.bp5-minimal.bp5-intent-success .bp5-compound-tag-left {
  background-color: #23855166;
}

.bp5-dark .bp5-compound-tag.bp5-minimal.bp5-intent-success .bp5-compound-tag-right {
  background-color: #23855133;
}

.bp5-dark .bp5-compound-tag.bp5-minimal.bp5-intent-success.bp5-interactive:hover .bp5-compound-tag-left {
  background-color: #23855180;
}

.bp5-dark .bp5-compound-tag.bp5-minimal.bp5-intent-success.bp5-interactive:hover .bp5-compound-tag-right {
  background-color: #2385514d;
}

.bp5-dark .bp5-compound-tag.bp5-minimal.bp5-intent-success.bp5-interactive:active .bp5-compound-tag-left, .bp5-dark .bp5-compound-tag.bp5-minimal.bp5-intent-success.bp5-interactive.bp5-active .bp5-compound-tag-left {
  background-color: #2385518c;
}

.bp5-dark .bp5-compound-tag.bp5-minimal.bp5-intent-success.bp5-interactive:active .bp5-compound-tag-right, .bp5-dark .bp5-compound-tag.bp5-minimal.bp5-intent-success.bp5-interactive.bp5-active .bp5-compound-tag-right {
  background-color: #23855159;
}

.bp5-dark .bp5-compound-tag.bp5-minimal.bp5-intent-warning {
  background: none;
}

.bp5-dark .bp5-compound-tag.bp5-minimal.bp5-intent-warning .bp5-compound-tag-left {
  background-color: #c8761966;
}

.bp5-dark .bp5-compound-tag.bp5-minimal.bp5-intent-warning .bp5-compound-tag-right {
  background-color: #c8761933;
}

.bp5-dark .bp5-compound-tag.bp5-minimal.bp5-intent-warning.bp5-interactive:hover .bp5-compound-tag-left {
  background-color: #c8761980;
}

.bp5-dark .bp5-compound-tag.bp5-minimal.bp5-intent-warning.bp5-interactive:hover .bp5-compound-tag-right {
  background-color: #c876194d;
}

.bp5-dark .bp5-compound-tag.bp5-minimal.bp5-intent-warning.bp5-interactive:active .bp5-compound-tag-left, .bp5-dark .bp5-compound-tag.bp5-minimal.bp5-intent-warning.bp5-interactive.bp5-active .bp5-compound-tag-left {
  background-color: #c876198c;
}

.bp5-dark .bp5-compound-tag.bp5-minimal.bp5-intent-warning.bp5-interactive:active .bp5-compound-tag-right, .bp5-dark .bp5-compound-tag.bp5-minimal.bp5-intent-warning.bp5-interactive.bp5-active .bp5-compound-tag-right {
  background-color: #c8761959;
}

.bp5-dark .bp5-compound-tag.bp5-minimal.bp5-intent-danger {
  background: none;
}

.bp5-dark .bp5-compound-tag.bp5-minimal.bp5-intent-danger .bp5-compound-tag-left {
  background-color: #cd424666;
}

.bp5-dark .bp5-compound-tag.bp5-minimal.bp5-intent-danger .bp5-compound-tag-right {
  background-color: #cd424633;
}

.bp5-dark .bp5-compound-tag.bp5-minimal.bp5-intent-danger.bp5-interactive:hover .bp5-compound-tag-left {
  background-color: #cd424680;
}

.bp5-dark .bp5-compound-tag.bp5-minimal.bp5-intent-danger.bp5-interactive:hover .bp5-compound-tag-right {
  background-color: #cd42464d;
}

.bp5-dark .bp5-compound-tag.bp5-minimal.bp5-intent-danger.bp5-interactive:active .bp5-compound-tag-left, .bp5-dark .bp5-compound-tag.bp5-minimal.bp5-intent-danger.bp5-interactive.bp5-active .bp5-compound-tag-left {
  background-color: #cd42468c;
}

.bp5-dark .bp5-compound-tag.bp5-minimal.bp5-intent-danger.bp5-interactive:active .bp5-compound-tag-right, .bp5-dark .bp5-compound-tag.bp5-minimal.bp5-intent-danger.bp5-interactive.bp5-active .bp5-compound-tag-right {
  background-color: #cd424659;
}

.bp5-compound-tag.bp5-intent-primary {
  background: none;
}

.bp5-compound-tag.bp5-intent-primary .bp5-compound-tag-left {
  background-color: #215db0;
}

.bp5-compound-tag.bp5-intent-primary .bp5-compound-tag-right {
  background-color: #2d72d2;
}

.bp5-compound-tag.bp5-intent-primary.bp5-interactive:hover .bp5-compound-tag-left {
  background-color: #184a90;
}

.bp5-compound-tag.bp5-intent-primary.bp5-interactive:hover .bp5-compound-tag-right {
  background-color: #215db0;
}

.bp5-compound-tag.bp5-intent-primary.bp5-interactive:active .bp5-compound-tag-left, .bp5-compound-tag.bp5-intent-primary.bp5-interactive.bp5-active .bp5-compound-tag-left {
  background-color: #11376b;
}

.bp5-compound-tag.bp5-intent-primary.bp5-interactive:active .bp5-compound-tag-right, .bp5-compound-tag.bp5-intent-primary.bp5-interactive.bp5-active .bp5-compound-tag-right {
  background-color: #184a90;
}

.bp5-compound-tag.bp5-intent-success {
  background: none;
}

.bp5-compound-tag.bp5-intent-success .bp5-compound-tag-left {
  background-color: #1c6e42;
}

.bp5-compound-tag.bp5-intent-success .bp5-compound-tag-right {
  background-color: #238551;
}

.bp5-compound-tag.bp5-intent-success.bp5-interactive:hover .bp5-compound-tag-left {
  background-color: #165a36;
}

.bp5-compound-tag.bp5-intent-success.bp5-interactive:hover .bp5-compound-tag-right {
  background-color: #1c6e42;
}

.bp5-compound-tag.bp5-intent-success.bp5-interactive:active .bp5-compound-tag-left, .bp5-compound-tag.bp5-intent-success.bp5-interactive.bp5-active .bp5-compound-tag-left {
  background-color: #0f3e25;
}

.bp5-compound-tag.bp5-intent-success.bp5-interactive:active .bp5-compound-tag-right, .bp5-compound-tag.bp5-intent-success.bp5-interactive.bp5-active .bp5-compound-tag-right {
  background-color: #165a36;
}

.bp5-compound-tag.bp5-intent-warning {
  background: none;
}

.bp5-compound-tag.bp5-intent-warning .bp5-compound-tag-left {
  background-color: #ec9a3c;
}

.bp5-compound-tag.bp5-intent-warning .bp5-compound-tag-right {
  background-color: #fbb360;
}

.bp5-compound-tag.bp5-intent-warning.bp5-interactive:hover .bp5-compound-tag-left {
  background-color: #c87619;
}

.bp5-compound-tag.bp5-intent-warning.bp5-interactive:hover .bp5-compound-tag-right {
  background-color: #ec9a3c;
}

.bp5-compound-tag.bp5-intent-warning.bp5-interactive:active .bp5-compound-tag-left, .bp5-compound-tag.bp5-intent-warning.bp5-interactive.bp5-active .bp5-compound-tag-left {
  background-color: #935610;
}

.bp5-compound-tag.bp5-intent-warning.bp5-interactive:active .bp5-compound-tag-right, .bp5-compound-tag.bp5-intent-warning.bp5-interactive.bp5-active .bp5-compound-tag-right {
  background-color: #c87619;
}

.bp5-compound-tag.bp5-intent-danger {
  background: none;
}

.bp5-compound-tag.bp5-intent-danger .bp5-compound-tag-left {
  background-color: #ac2f33;
}

.bp5-compound-tag.bp5-intent-danger .bp5-compound-tag-right {
  background-color: #cd4246;
}

.bp5-compound-tag.bp5-intent-danger.bp5-interactive:hover .bp5-compound-tag-left {
  background-color: #8e292c;
}

.bp5-compound-tag.bp5-intent-danger.bp5-interactive:hover .bp5-compound-tag-right {
  background-color: #ac2f33;
}

.bp5-compound-tag.bp5-intent-danger.bp5-interactive:active .bp5-compound-tag-left, .bp5-compound-tag.bp5-intent-danger.bp5-interactive.bp5-active .bp5-compound-tag-left {
  background-color: #782326;
}

.bp5-compound-tag.bp5-intent-danger.bp5-interactive:active .bp5-compound-tag-right, .bp5-compound-tag.bp5-intent-danger.bp5-interactive.bp5-active .bp5-compound-tag-right {
  background-color: #8e292c;
}

.bp5-tag-input {
  cursor: text;
  line-height: inherit;
  flex-direction: row;
  align-items: flex-start;
  height: auto;
  min-height: 30px;
  padding-left: 5px;
  padding-right: 0;
  display: flex;
}

.bp5-tag-input > * {
  flex-grow: 0;
  flex-shrink: 0;
}

.bp5-tag-input > .bp5-tag-input-values {
  flex-grow: 1;
  flex-shrink: 1;
}

.bp5-tag-input .bp5-tag-input-icon {
  color: #5f6b7c;
  margin-top: 7px;
  margin-left: 2px;
  margin-right: 7px;
}

.bp5-tag-input .bp5-tag-input-values {
  flex-flow: wrap;
  align-self: stretch;
  align-items: center;
  min-width: 0;
  margin-top: 5px;
  margin-right: 7px;
  display: flex;
  position: relative;
}

.bp5-tag-input .bp5-tag-input-values > * {
  flex-grow: 0;
  flex-shrink: 0;
}

.bp5-tag-input .bp5-tag-input-values > .bp5-fill {
  flex-grow: 1;
  flex-shrink: 1;
}

.bp5-tag-input .bp5-tag-input-values:before, .bp5-tag-input .bp5-tag-input-values > * {
  margin-right: 5px;
}

.bp5-tag-input .bp5-tag-input-values:empty:before, .bp5-tag-input .bp5-tag-input-values > :last-child {
  margin-right: 0;
}

.bp5-tag-input .bp5-tag-input-values:first-child .bp5-tag ~ .bp5-input-ghost {
  padding-left: 0;
}

.bp5-tag-input .bp5-tag-input-values:first-child .bp5-input-ghost {
  padding-left: 5px;
}

.bp5-tag-input .bp5-tag-input-values > * {
  margin-bottom: 5px;
}

.bp5-tag-input .bp5-tag {
  overflow-wrap: break-word;
}

.bp5-tag-input .bp5-tag.bp5-active {
  outline-offset: 0;
  -moz-outline-radius: 6px;
  outline: 2px solid #2d72d299;
}

.bp5-tag-input .bp5-input-ghost {
  flex: auto;
  width: 80px;
  line-height: 20px;
}

.bp5-tag-input .bp5-input-ghost:disabled, .bp5-tag-input .bp5-input-ghost.bp5-disabled {
  cursor: not-allowed;
}

.bp5-tag-input .bp5-button, .bp5-tag-input .bp5-spinner {
  margin: 3px 3px 3px 0;
}

.bp5-tag-input .bp5-button {
  min-width: 24px;
  min-height: 24px;
  padding: 0 7px;
}

.bp5-tag-input.bp5-large {
  height: auto;
  min-height: 40px;
}

.bp5-tag-input.bp5-large:before, .bp5-tag-input.bp5-large > * {
  margin-right: 10px;
}

.bp5-tag-input.bp5-large:empty:before, .bp5-tag-input.bp5-large > :last-child {
  margin-right: 0;
}

.bp5-tag-input.bp5-large .bp5-tag-input-icon {
  margin-top: 10px;
  margin-left: 5px;
}

.bp5-tag-input.bp5-large .bp5-input-ghost {
  line-height: 30px;
}

.bp5-tag-input.bp5-large .bp5-button {
  min-width: 30px;
  min-height: 30px;
  margin: 5px 5px 5px 0;
  padding: 5px 10px;
}

.bp5-tag-input.bp5-large .bp5-spinner {
  margin: 8px 8px 8px 0;
}

.bp5-tag-input.bp5-active {
  background-color: #fff;
  box-shadow: inset 0 0 0 1px #2d72d2, 0 0 0 2px #2d72d24d, inset 0 1px 1px #11141833;
}

.bp5-tag-input.bp5-active.bp5-intent-primary {
  box-shadow: inset 0 0 0 1px #2d72d2, 0 0 0 2px #2d72d24d, inset 0 1px 1px #11141833;
}

.bp5-tag-input.bp5-active.bp5-intent-success {
  box-shadow: inset 0 0 0 1px #238551, 0 0 0 2px #2385514d, inset 0 1px 1px #11141833;
}

.bp5-tag-input.bp5-active.bp5-intent-warning {
  box-shadow: inset 0 0 0 1px #c87619, 0 0 0 2px #c876194d, inset 0 1px 1px #11141833;
}

.bp5-tag-input.bp5-active.bp5-intent-danger {
  box-shadow: inset 0 0 0 1px #cd4246, 0 0 0 2px #cd42464d, inset 0 1px 1px #11141833;
}

.bp5-dark .bp5-tag-input .bp5-tag-input-icon, .bp5-tag-input.bp5-dark .bp5-tag-input-icon {
  color: #abb3bf;
}

.bp5-dark .bp5-tag-input .bp5-input-ghost, .bp5-tag-input.bp5-dark .bp5-input-ghost {
  color: #f6f7f9;
}

.bp5-dark .bp5-tag-input .bp5-input-ghost::-moz-placeholder {
  color: #abb3bf;
}

.bp5-tag-input.bp5-dark .bp5-input-ghost::-moz-placeholder {
  color: #abb3bf;
}

:is(.bp5-dark .bp5-tag-input .bp5-input-ghost:-ms-input-placeholder, .bp5-tag-input.bp5-dark .bp5-input-ghost:-ms-input-placeholder), .bp5-dark .bp5-tag-input .bp5-input-ghost::placeholder, .bp5-tag-input.bp5-dark .bp5-input-ghost::placeholder {
  color: #abb3bf;
}

.bp5-dark .bp5-tag-input.bp5-active, .bp5-tag-input.bp5-dark.bp5-active {
  background-color: #1114184d;
  box-shadow: inset 0 0 0 1px #4c90f0, inset 0 0 0 1px #4c90f0, 0 0 0 2px #4c90f04d, inset 0 0 0 1px #fff3, inset 0 -1px 1px #ffffff4d;
}

.bp5-dark .bp5-tag-input.bp5-active.bp5-intent-primary, .bp5-tag-input.bp5-dark.bp5-active.bp5-intent-primary {
  box-shadow: inset 0 0 0 1px #4c90f0, 0 0 0 2px #4c90f04d, inset 0 0 0 1px #fff3, inset 0 -1px 1px #ffffff4d;
}

.bp5-dark .bp5-tag-input.bp5-active.bp5-intent-success, .bp5-tag-input.bp5-dark.bp5-active.bp5-intent-success {
  box-shadow: inset 0 0 0 1px #32a467, 0 0 0 2px #32a4674d, inset 0 0 0 1px #fff3, inset 0 -1px 1px #ffffff4d;
}

.bp5-dark .bp5-tag-input.bp5-active.bp5-intent-warning, .bp5-tag-input.bp5-dark.bp5-active.bp5-intent-warning {
  box-shadow: inset 0 0 0 1px #ec9a3c, 0 0 0 2px #ec9a3c4d, inset 0 0 0 1px #fff3, inset 0 -1px 1px #ffffff4d;
}

.bp5-dark .bp5-tag-input.bp5-active.bp5-intent-danger, .bp5-tag-input.bp5-dark.bp5-active.bp5-intent-danger {
  box-shadow: inset 0 0 0 1px #e76a6e, 0 0 0 2px #e76a6e4d, inset 0 0 0 1px #fff3, inset 0 -1px 1px #ffffff4d;
}

.bp5-input-ghost {
  box-shadow: none;
  background: none;
  border: none;
  padding: 0;
}

.bp5-input-ghost::-moz-placeholder {
  color: #5f6b7c;
  opacity: 1;
}

.bp5-input-ghost:-ms-input-placeholder {
  color: #5f6b7c;
  opacity: 1;
}

.bp5-input-ghost::placeholder {
  color: #5f6b7c;
  opacity: 1;
}

.bp5-input-ghost:focus {
  outline: none !important;
}

.bp5-resizable-input-span {
  opacity: 0;
  white-space: nowrap;
  z-index: -1;
  min-width: 80px;
  max-width: 100%;
  max-height: 0;
  position: absolute;
  overflow: hidden;
}

.bp5-toast {
  pointer-events: all;
  background-color: #fff;
  border-radius: 2px;
  align-items: flex-start;
  min-width: 300px;
  max-width: 500px;
  margin: 20px 0 0;
  display: flex;
  box-shadow: inset 0 0 0 1px #11141833, 0 2px 4px #11141833, 0 8px 24px #11141833;
  position: relative !important;
}

.bp5-toast.bp5-toast-enter, .bp5-toast.bp5-toast-appear {
  transform: translateY(-40px);
}

.bp5-toast.bp5-toast-enter-active, .bp5-toast.bp5-toast-appear-active {
  transition-delay: 0;
  transition-property: transform;
  transition-duration: .3s;
  transition-timing-function: cubic-bezier(.54, 1.12, .38, 1.11);
  transform: translateY(0);
}

.bp5-toast.bp5-toast-enter ~ .bp5-toast, .bp5-toast.bp5-toast-appear ~ .bp5-toast {
  transform: translateY(-40px);
}

.bp5-toast.bp5-toast-enter-active ~ .bp5-toast, .bp5-toast.bp5-toast-appear-active ~ .bp5-toast {
  transition-delay: 0;
  transition-property: transform;
  transition-duration: .3s;
  transition-timing-function: cubic-bezier(.54, 1.12, .38, 1.11);
  transform: translateY(0);
}

.bp5-toast.bp5-toast-exit {
  opacity: 1;
  filter: blur();
}

.bp5-toast.bp5-toast-exit-active {
  opacity: 0;
  filter: blur(10px);
  transition-delay: 0;
  transition-property: opacity, filter;
  transition-duration: .3s;
  transition-timing-function: cubic-bezier(.4, 1, .75, .9);
}

.bp5-toast.bp5-toast-exit ~ .bp5-toast {
  transform: translateY(0);
}

.bp5-toast.bp5-toast-exit-active ~ .bp5-toast {
  transition: transform .1s cubic-bezier(.4, 1, .75, .9) 50ms;
  transform: translateY(-40px);
}

.bp5-toast .bp5-button-group {
  flex: none;
  padding: 5px 5px 5px 0;
}

.bp5-toast > .bp5-icon {
  color: #5f6b7c;
  margin: 12px 0 12px 12px;
}

.bp5-toast.bp5-dark, .bp5-dark .bp5-toast {
  background-color: #404854;
  box-shadow: inset 0 0 0 1px #fff3, 0 2px 4px #11141866, 0 8px 24px #11141866;
}

.bp5-toast.bp5-dark > .bp5-icon, .bp5-dark .bp5-toast > .bp5-icon {
  color: #abb3bf;
}

.bp5-toast.bp5-dark .bp5-button .bp5-icon, .bp5-dark .bp5-toast .bp5-button .bp5-icon, .bp5-toast[class*="bp5-intent-"] a {
  color: #ffffffb3;
}

.bp5-toast[class*="bp5-intent-"] a:hover, .bp5-toast[class*="bp5-intent-"] > .bp5-icon {
  color: #fff;
}

.bp5-toast.bp5-intent-primary {
  color: #fff;
  background-color: #2d72d2;
}

.bp5-toast.bp5-intent-primary .bp5-icon:first-child {
  color: #ffffffb3;
}

.bp5-toast.bp5-intent-primary .bp5-button {
  color: #fff !important;
  background-color: #2d72d2 !important;
}

.bp5-toast.bp5-intent-primary .bp5-button:hover {
  color: #fff !important;
  background-color: #215db0 !important;
}

.bp5-toast.bp5-intent-primary .bp5-button:active {
  color: #fff !important;
  background-color: #184a90 !important;
}

.bp5-toast.bp5-intent-primary .bp5-button:focus {
  outline-color: #ffffff80;
}

.bp5-toast.bp5-intent-primary .bp5-button:last-child > .bp5-icon-cross {
  color: #ffffffb3 !important;
}

.bp5-toast.bp5-intent-success {
  color: #fff;
  background-color: #427762;
}

.bp5-toast.bp5-intent-success .bp5-icon:first-child {
  color: #ffffffb3;
}

.bp5-toast.bp5-intent-success .bp5-button {
  color: #fff !important;
  background-color: #427762 !important;
}

.bp5-toast.bp5-intent-success .bp5-button:hover {
  color: #fff !important;
  background-color: #1c6e42 !important;
}

.bp5-toast.bp5-intent-success .bp5-button:active {
  color: #fff !important;
  background-color: #165a36 !important;
}

.bp5-toast.bp5-intent-success .bp5-button:focus {
  outline-color: #ffffff80;
}

.bp5-toast.bp5-intent-success .bp5-button:last-child > .bp5-icon-cross {
  color: #ffffffb3 !important;
}

.bp5-toast.bp5-intent-warning {
  color: #1c2127;
  background-color: #fbb360;
}

.bp5-toast.bp5-intent-warning .bp5-icon:first-child {
  color: #1c2127b3;
}

.bp5-toast.bp5-intent-warning .bp5-button {
  color: #1c2127 !important;
  background-color: #fbb360 !important;
}

.bp5-toast.bp5-intent-warning .bp5-button:hover {
  color: #1c2127 !important;
  background-color: #ec9a3c !important;
}

.bp5-toast.bp5-intent-warning .bp5-button:active {
  color: #1c2127 !important;
  background-color: #c87619 !important;
}

.bp5-toast.bp5-intent-warning .bp5-button:focus {
  outline-color: #ffffff80;
}

.bp5-toast.bp5-intent-warning .bp5-button:last-child > .bp5-icon-cross {
  color: #1c2127b3 !important;
}

.bp5-toast.bp5-intent-danger {
  color: #fff;
  background-color: #cd4246;
}

.bp5-toast.bp5-intent-danger .bp5-icon:first-child {
  color: #ffffffb3;
}

.bp5-toast.bp5-intent-danger .bp5-button {
  color: #fff !important;
  background-color: #cd4246 !important;
}

.bp5-toast.bp5-intent-danger .bp5-button:hover {
  color: #fff !important;
  background-color: #ac2f33 !important;
}

.bp5-toast.bp5-intent-danger .bp5-button:active {
  color: #fff !important;
  background-color: #8e292c !important;
}

.bp5-toast.bp5-intent-danger .bp5-button:focus {
  outline-color: #ffffff80;
}

.bp5-toast.bp5-intent-danger .bp5-button:last-child > .bp5-icon-cross {
  color: #ffffffb3 !important;
}

.bp5-toast-message {
  word-break: break-word;
  flex: auto;
  padding: 11px;
}

.bp5-toast-container {
  pointer-events: none;
  z-index: 40;
  flex-direction: column;
  align-items: center;
  padding: 0 20px 20px;
  left: 0;
  right: 0;
  overflow: hidden;
  display: flex !important;
}

.bp5-toast-container.bp5-toast-container-in-portal {
  position: fixed;
}

.bp5-toast-container.bp5-toast-container-inline {
  position: absolute;
}

.bp5-toast-container.bp5-toast-container-top {
  top: 0;
}

.bp5-toast-container.bp5-toast-container-bottom {
  flex-direction: column-reverse;
  top: auto;
  bottom: 0;
}

.bp5-toast-container.bp5-toast-container-left {
  align-items: flex-start;
}

.bp5-toast-container.bp5-toast-container-right {
  align-items: flex-end;
}

.bp5-toast-container-bottom .bp5-toast.bp5-toast-enter:not(.bp5-toast-enter-active), .bp5-toast-container-bottom .bp5-toast.bp5-toast-enter:not(.bp5-toast-enter-active) ~ .bp5-toast, .bp5-toast-container-bottom .bp5-toast.bp5-toast-appear:not(.bp5-toast-appear-active), .bp5-toast-container-bottom .bp5-toast.bp5-toast-appear:not(.bp5-toast-appear-active) ~ .bp5-toast, .bp5-toast-container-bottom .bp5-toast.bp5-toast-exit-active ~ .bp5-toast, .bp5-toast-container-bottom .bp5-toast.bp5-toast-leave-active ~ .bp5-toast {
  transform: translateY(60px);
}

.bp5-tooltip {
  color: #f6f7f9;
  transform: scale(1);
  box-shadow: 0 0 0 1px #1114181a, 0 2px 4px #11141833, 0 8px 24px #11141833;
}

.bp5-tooltip .bp5-popover-arrow {
  width: 22px;
  height: 22px;
  position: absolute;
}

.bp5-tooltip .bp5-popover-arrow:before {
  width: 14px;
  height: 14px;
  margin: 4px;
}

.bp5-tooltip .bp5-popover-content {
  background: #404854;
}

.bp5-tooltip .bp5-popover-content, .bp5-tooltip .bp5-heading {
  color: #f6f7f9;
}

.bp5-tooltip .bp5-popover-arrow:before {
  box-shadow: 1px 1px 6px #11141833;
}

.bp5-tooltip .bp5-popover-arrow-border {
  fill: #111418;
  fill-opacity: .1;
}

.bp5-tooltip .bp5-popover-arrow-fill {
  fill: #404854;
}

@media (forced-colors: active) and (prefers-color-scheme: dark) {
  .bp5-tooltip .bp5-popover-arrow-fill {
    fill: buttonborder;
  }

  .bp5-tooltip {
    border: 1px solid buttonborder;
  }
}

.bp5-popover-enter > .bp5-tooltip, .bp5-popover-appear > .bp5-tooltip {
  transform: scale(.8);
}

.bp5-popover-enter-active > .bp5-tooltip, .bp5-popover-appear-active > .bp5-tooltip {
  transition-delay: 0;
  transition-property: transform;
  transition-duration: .1s;
  transition-timing-function: cubic-bezier(.4, 1, .75, .9);
  transform: scale(1);
}

.bp5-popover-exit > .bp5-tooltip {
  transform: scale(1);
}

.bp5-popover-exit-active > .bp5-tooltip {
  transition-delay: 0;
  transition-property: transform;
  transition-duration: .1s;
  transition-timing-function: cubic-bezier(.4, 1, .75, .9);
  transform: scale(.8);
}

.bp5-tooltip .bp5-text-muted {
  color: #abb3bf;
}

.bp5-tooltip .bp5-text-disabled {
  color: #abb3bf99;
}

.bp5-tooltip .bp5-running-text hr {
  border-color: #fff3;
}

.bp5-tooltip a, .bp5-tooltip a:hover {
  color: #8abbff;
}

.bp5-tooltip a .bp5-icon, .bp5-tooltip a .bp5-icon-standard, .bp5-tooltip a .bp5-icon-large, .bp5-tooltip a code {
  color: inherit;
}

.bp5-tooltip .bp5-code, .bp5-tooltip .bp5-running-text code {
  color: #abb3bf;
  background: #1114184d;
  box-shadow: inset 0 0 0 1px #11141866;
}

a > .bp5-tooltip .bp5-code, a > .bp5-tooltip .bp5-running-text code {
  color: inherit;
}

.bp5-tooltip .bp5-code-block, .bp5-tooltip .bp5-running-text pre {
  color: #f6f7f9;
  background: #1114184d;
  box-shadow: inset 0 0 0 1px #11141866;
}

.bp5-tooltip .bp5-code-block > code, .bp5-tooltip .bp5-running-text pre > code {
  box-shadow: none;
  color: inherit;
  background: none;
}

.bp5-tooltip .bp5-key, .bp5-tooltip .bp5-running-text kbd {
  color: #abb3bf;
  background: #383e47;
  box-shadow: inset 0 0 0 1px #fff3, 0 1px 1px #11141866;
}

.bp5-tooltip .bp5-icon.bp5-intent-primary, .bp5-tooltip .bp5-icon-standard.bp5-intent-primary, .bp5-tooltip .bp5-icon-large.bp5-intent-primary {
  color: #8abbff;
}

.bp5-tooltip .bp5-icon.bp5-intent-success, .bp5-tooltip .bp5-icon-standard.bp5-intent-success, .bp5-tooltip .bp5-icon-large.bp5-intent-success {
  color: #72ca9b;
}

.bp5-tooltip .bp5-icon.bp5-intent-warning, .bp5-tooltip .bp5-icon-standard.bp5-intent-warning, .bp5-tooltip .bp5-icon-large.bp5-intent-warning {
  color: #fbb360;
}

.bp5-tooltip .bp5-icon.bp5-intent-danger, .bp5-tooltip .bp5-icon-standard.bp5-intent-danger, .bp5-tooltip .bp5-icon-large.bp5-intent-danger {
  color: #fa999c;
}

.bp5-tooltip .bp5-popover-content {
  padding: 10px 12px;
}

.bp5-tooltip.bp5-compact .bp5-popover-content {
  padding: 5px 7px;
  line-height: 1rem;
}

.bp5-tooltip.bp5-compact .bp5-code {
  vertical-align: text-bottom;
}

.bp5-tooltip.bp5-popover-placement-top .bp5-popover-arrow {
  transform: translateY(-3px);
}

.bp5-tooltip.bp5-popover-placement-left .bp5-popover-arrow {
  transform: translateX(-3px);
}

.bp5-tooltip.bp5-popover-placement-bottom .bp5-popover-arrow {
  transform: translateY(3px);
}

.bp5-tooltip.bp5-popover-placement-right .bp5-popover-arrow {
  transform: translateX(3px);
}

.bp5-tooltip.bp5-dark, .bp5-dark .bp5-tooltip {
  box-shadow: 0 2px 4px #11141866, 0 8px 24px #11141866;
}

.bp5-tooltip.bp5-dark .bp5-popover-content, .bp5-dark .bp5-tooltip .bp5-popover-content {
  background: #e5e8eb;
}

.bp5-tooltip.bp5-dark .bp5-popover-content, .bp5-tooltip.bp5-dark .bp5-heading, .bp5-dark .bp5-tooltip .bp5-popover-content, .bp5-dark .bp5-tooltip .bp5-heading {
  color: #404854;
}

.bp5-tooltip.bp5-dark .bp5-popover-arrow:before, .bp5-dark .bp5-tooltip .bp5-popover-arrow:before {
  box-shadow: 1px 1px 6px #11141866;
}

.bp5-tooltip.bp5-dark .bp5-popover-arrow-border, .bp5-dark .bp5-tooltip .bp5-popover-arrow-border {
  fill: #111418;
  fill-opacity: .2;
}

.bp5-tooltip.bp5-dark .bp5-popover-arrow-fill, .bp5-dark .bp5-tooltip .bp5-popover-arrow-fill {
  fill: #e5e8eb;
}

@media (forced-colors: active) and (prefers-color-scheme: dark) {
  .bp5-tooltip.bp5-dark .bp5-popover-arrow-fill, .bp5-dark .bp5-tooltip .bp5-popover-arrow-fill {
    fill: buttonborder;
  }

  .bp5-tooltip.bp5-dark, .bp5-dark .bp5-tooltip {
    border: 1px solid buttonborder;
  }
}

.bp5-tooltip.bp5-dark .bp5-text-muted, .bp5-dark .bp5-tooltip .bp5-text-muted {
  color: #5f6b7c;
}

.bp5-tooltip.bp5-dark .bp5-text-disabled, .bp5-dark .bp5-tooltip .bp5-text-disabled {
  color: #5f6b7c99;
}

.bp5-tooltip.bp5-dark .bp5-running-text hr, .bp5-dark .bp5-tooltip .bp5-running-text hr {
  border-color: #11141826;
}

.bp5-tooltip.bp5-dark a, .bp5-dark .bp5-tooltip a, .bp5-tooltip.bp5-dark a:hover, .bp5-dark .bp5-tooltip a:hover {
  color: #215db0;
}

.bp5-tooltip.bp5-dark a .bp5-icon, .bp5-tooltip.bp5-dark a .bp5-icon-standard, .bp5-tooltip.bp5-dark a .bp5-icon-large, .bp5-dark .bp5-tooltip a .bp5-icon, .bp5-dark .bp5-tooltip a .bp5-icon-standard, .bp5-dark .bp5-tooltip a .bp5-icon-large, .bp5-tooltip.bp5-dark a code, .bp5-dark .bp5-tooltip a code {
  color: inherit;
}

.bp5-tooltip.bp5-dark .bp5-code, .bp5-tooltip.bp5-dark .bp5-running-text code, .bp5-dark .bp5-tooltip .bp5-code, .bp5-dark .bp5-tooltip .bp5-running-text code {
  color: #5f6b7c;
  background: #ffffffb3;
  box-shadow: inset 0 0 0 1px #11141833;
}

a > .bp5-tooltip.bp5-dark .bp5-code, a > .bp5-tooltip.bp5-dark .bp5-running-text code, a > .bp5-dark .bp5-tooltip .bp5-code, a > .bp5-dark .bp5-tooltip .bp5-running-text code {
  color: #2d72d2;
}

.bp5-tooltip.bp5-dark .bp5-code-block, .bp5-tooltip.bp5-dark .bp5-running-text pre, .bp5-dark .bp5-tooltip .bp5-code-block, .bp5-dark .bp5-tooltip .bp5-running-text pre {
  color: #1c2127;
  background: #ffffffb3;
  box-shadow: inset 0 0 0 1px #11141826;
}

.bp5-tooltip.bp5-dark .bp5-code-block > code, .bp5-tooltip.bp5-dark .bp5-running-text pre > code, .bp5-dark .bp5-tooltip .bp5-code-block > code, .bp5-dark .bp5-tooltip .bp5-running-text pre > code {
  box-shadow: none;
  color: inherit;
  background: none;
}

@media (forced-colors: active) and (prefers-color-scheme: dark) {
  .bp5-tooltip.bp5-dark .bp5-code-block, .bp5-tooltip.bp5-dark .bp5-running-text pre, .bp5-dark .bp5-tooltip .bp5-code-block, .bp5-dark .bp5-tooltip .bp5-running-text pre {
    border: 1px solid buttonborder;
    box-shadow: none;
  }
}

.bp5-tooltip.bp5-dark .bp5-key, .bp5-tooltip.bp5-dark .bp5-running-text kbd, .bp5-dark .bp5-tooltip .bp5-key, .bp5-dark .bp5-tooltip .bp5-running-text kbd {
  color: #5f6b7c;
  background: #fff;
  box-shadow: 0 0 0 1px #1114181a, 0 1px 1px #11141833;
}

.bp5-tooltip.bp5-dark .bp5-icon.bp5-intent-primary, .bp5-tooltip.bp5-dark .bp5-icon-standard.bp5-intent-primary, .bp5-tooltip.bp5-dark .bp5-icon-large.bp5-intent-primary, .bp5-dark .bp5-tooltip .bp5-icon.bp5-intent-primary, .bp5-dark .bp5-tooltip .bp5-icon-standard.bp5-intent-primary, .bp5-dark .bp5-tooltip .bp5-icon-large.bp5-intent-primary {
  color: #215db0;
}

.bp5-tooltip.bp5-dark .bp5-icon.bp5-intent-success, .bp5-tooltip.bp5-dark .bp5-icon-standard.bp5-intent-success, .bp5-tooltip.bp5-dark .bp5-icon-large.bp5-intent-success, .bp5-dark .bp5-tooltip .bp5-icon.bp5-intent-success, .bp5-dark .bp5-tooltip .bp5-icon-standard.bp5-intent-success, .bp5-dark .bp5-tooltip .bp5-icon-large.bp5-intent-success {
  color: #1c6e42;
}

.bp5-tooltip.bp5-dark .bp5-icon.bp5-intent-warning, .bp5-tooltip.bp5-dark .bp5-icon-standard.bp5-intent-warning, .bp5-tooltip.bp5-dark .bp5-icon-large.bp5-intent-warning, .bp5-dark .bp5-tooltip .bp5-icon.bp5-intent-warning, .bp5-dark .bp5-tooltip .bp5-icon-standard.bp5-intent-warning, .bp5-dark .bp5-tooltip .bp5-icon-large.bp5-intent-warning {
  color: #935610;
}

.bp5-tooltip.bp5-dark .bp5-icon.bp5-intent-danger, .bp5-tooltip.bp5-dark .bp5-icon-standard.bp5-intent-danger, .bp5-tooltip.bp5-dark .bp5-icon-large.bp5-intent-danger, .bp5-dark .bp5-tooltip .bp5-icon.bp5-intent-danger, .bp5-dark .bp5-tooltip .bp5-icon-standard.bp5-intent-danger, .bp5-dark .bp5-tooltip .bp5-icon-large.bp5-intent-danger {
  color: #ac2f33;
}

.bp5-tooltip.bp5-intent-primary .bp5-popover-content {
  color: #fff;
  background: #2d72d2;
}

.bp5-tooltip.bp5-intent-primary .bp5-popover-arrow-fill {
  fill: #2d72d2;
}

@media (forced-colors: active) and (prefers-color-scheme: dark) {
  .bp5-tooltip.bp5-intent-primary .bp5-popover-arrow-fill {
    fill: buttonborder;
  }
}

.bp5-tooltip.bp5-intent-success .bp5-popover-content {
  color: #fff;
  background: #238551;
}

.bp5-tooltip.bp5-intent-success .bp5-popover-arrow-fill {
  fill: #238551;
}

@media (forced-colors: active) and (prefers-color-scheme: dark) {
  .bp5-tooltip.bp5-intent-success .bp5-popover-arrow-fill {
    fill: buttonborder;
  }
}

.bp5-tooltip.bp5-intent-warning .bp5-popover-content {
  color: #fff;
  background: #c87619;
}

.bp5-tooltip.bp5-intent-warning .bp5-popover-arrow-fill {
  fill: #c87619;
}

@media (forced-colors: active) and (prefers-color-scheme: dark) {
  .bp5-tooltip.bp5-intent-warning .bp5-popover-arrow-fill {
    fill: buttonborder;
  }
}

.bp5-tooltip.bp5-intent-danger .bp5-popover-content {
  color: #fff;
  background: #cd4246;
}

.bp5-tooltip.bp5-intent-danger .bp5-popover-arrow-fill {
  fill: #cd4246;
}

@media (forced-colors: active) and (prefers-color-scheme: dark) {
  .bp5-tooltip.bp5-intent-danger .bp5-popover-arrow-fill {
    fill: buttonborder;
  }
}

.bp5-tooltip-indicator {
  cursor: help;
  border-bottom: 1px dotted;
}

.bp5-tree .bp5-icon, .bp5-tree .bp5-icon-standard, .bp5-tree .bp5-icon-large {
  color: #5f6b7c;
}

.bp5-tree .bp5-icon.bp5-intent-primary, .bp5-tree .bp5-icon-standard.bp5-intent-primary, .bp5-tree .bp5-icon-large.bp5-intent-primary {
  color: #2d72d2;
}

.bp5-tree .bp5-icon.bp5-intent-success, .bp5-tree .bp5-icon-standard.bp5-intent-success, .bp5-tree .bp5-icon-large.bp5-intent-success {
  color: #238551;
}

.bp5-tree .bp5-icon.bp5-intent-warning, .bp5-tree .bp5-icon-standard.bp5-intent-warning, .bp5-tree .bp5-icon-large.bp5-intent-warning {
  color: #c87619;
}

.bp5-tree .bp5-icon.bp5-intent-danger, .bp5-tree .bp5-icon-standard.bp5-intent-danger, .bp5-tree .bp5-icon-large.bp5-intent-danger {
  color: #cd4246;
}

.bp5-tree-node-list {
  margin: 0;
  padding-left: 0;
  list-style: none;
}

.bp5-tree-root {
  cursor: default;
  background-color: #0000;
  padding-left: 0;
  position: relative;
}

.bp5-tree-node-content-0 {
  padding-left: 0;
}

.bp5-tree-node-content-1 {
  padding-left: 23px;
}

.bp5-tree-node-content-2 {
  padding-left: 46px;
}

.bp5-tree-node-content-3 {
  padding-left: 69px;
}

.bp5-tree-node-content-4 {
  padding-left: 92px;
}

.bp5-tree-node-content-5 {
  padding-left: 115px;
}

.bp5-tree-node-content-6 {
  padding-left: 138px;
}

.bp5-tree-node-content-7 {
  padding-left: 161px;
}

.bp5-tree-node-content-8 {
  padding-left: 184px;
}

.bp5-tree-node-content-9 {
  padding-left: 207px;
}

.bp5-tree-node-content-10 {
  padding-left: 230px;
}

.bp5-tree-node-content-11 {
  padding-left: 253px;
}

.bp5-tree-node-content-12 {
  padding-left: 276px;
}

.bp5-tree-node-content-13 {
  padding-left: 299px;
}

.bp5-tree-node-content-14 {
  padding-left: 322px;
}

.bp5-tree-node-content-15 {
  padding-left: 345px;
}

.bp5-tree-node-content-16 {
  padding-left: 368px;
}

.bp5-tree-node-content-17 {
  padding-left: 391px;
}

.bp5-tree-node-content-18 {
  padding-left: 414px;
}

.bp5-tree-node-content-19 {
  padding-left: 437px;
}

.bp5-tree-node-content-20 {
  padding-left: 460px;
}

.bp5-tree-node-content {
  background: none;
  align-items: center;
  width: 100%;
  height: 30px;
  padding-right: 5px;
  display: flex;
}

.bp5-tree-node-content:hover {
  background-color: #8f99a826;
}

.bp5-tree-node-content:active {
  background-color: #8f99a84d;
}

.bp5-tree-node-caret, .bp5-tree-node-caret-none {
  min-width: 30px;
}

.bp5-tree-node-caret {
  color: #5f6b7c;
  cursor: pointer;
  padding: 7px;
  transition: transform .2s cubic-bezier(.4, 1, .75, .9);
  transform: rotate(0);
}

.bp5-dark .bp5-tree-node-caret {
  color: #abb3bf;
}

.bp5-dark .bp5-tree-node-caret:hover {
  color: #f6f7f9;
}

.bp5-tree-node-caret:hover {
  color: #1c2127;
}

.bp5-tree-node-caret.bp5-tree-node-caret-open {
  transform: rotate(90deg);
}

.bp5-tree-node-caret.bp5-icon-standard:before {
  content: "";
}

.bp5-tree-node-icon {
  margin-right: 7px;
  position: relative;
}

.bp5-tree-node-label {
  text-overflow: ellipsis;
  white-space: nowrap;
  word-wrap: normal;
  -webkit-user-select: none;
  user-select: none;
  flex: auto;
  position: relative;
  overflow: hidden;
}

.bp5-tree-node-label span {
  display: inline;
}

.bp5-tree-node-secondary-label {
  -webkit-user-select: none;
  user-select: none;
  padding: 0 5px;
}

.bp5-tree-node-secondary-label .bp5-popover-wrapper, .bp5-tree-node-secondary-label .bp5-popover-target {
  align-items: center;
  display: flex;
}

.bp5-tree-node.bp5-disabled .bp5-tree-node-content {
  background-color: inherit;
  color: #5f6b7c99;
  cursor: not-allowed;
}

.bp5-tree-node.bp5-disabled .bp5-tree-node-caret, .bp5-tree-node.bp5-disabled .bp5-tree-node-icon {
  color: #5f6b7c99;
  cursor: not-allowed;
}

.bp5-tree-node.bp5-tree-node-selected > .bp5-tree-node-content {
  background-color: #2d72d2;
}

.bp5-tree-node.bp5-tree-node-selected > .bp5-tree-node-content, .bp5-tree-node.bp5-tree-node-selected > .bp5-tree-node-content .bp5-icon, .bp5-tree-node.bp5-tree-node-selected > .bp5-tree-node-content .bp5-icon-standard, .bp5-tree-node.bp5-tree-node-selected > .bp5-tree-node-content .bp5-icon-large {
  color: #fff;
}

.bp5-tree-node.bp5-tree-node-selected > .bp5-tree-node-content .bp5-tree-node-caret:before {
  color: #ffffffb3;
}

.bp5-tree-node.bp5-tree-node-selected > .bp5-tree-node-content .bp5-tree-node-caret:hover:before {
  color: #fff;
}

.bp5-tree.bp5-compact .bp5-tree-node-content {
  height: 24px;
}

.bp5-tree.bp5-compact .bp5-tree-node-caret {
  min-width: 24px;
  margin-right: 3px;
  padding: 4px;
}

.bp5-dark .bp5-tree-node-content:hover {
  background-color: #5f6b7c4d;
}

.bp5-dark .bp5-tree .bp5-icon, .bp5-dark .bp5-tree .bp5-icon-standard, .bp5-dark .bp5-tree .bp5-icon-large {
  color: #abb3bf;
}

.bp5-dark .bp5-tree .bp5-icon.bp5-intent-primary, .bp5-dark .bp5-tree .bp5-icon-standard.bp5-intent-primary, .bp5-dark .bp5-tree .bp5-icon-large.bp5-intent-primary {
  color: #8abbff;
}

.bp5-dark .bp5-tree .bp5-icon.bp5-intent-success, .bp5-dark .bp5-tree .bp5-icon-standard.bp5-intent-success, .bp5-dark .bp5-tree .bp5-icon-large.bp5-intent-success {
  color: #72ca9b;
}

.bp5-dark .bp5-tree .bp5-icon.bp5-intent-warning, .bp5-dark .bp5-tree .bp5-icon-standard.bp5-intent-warning, .bp5-dark .bp5-tree .bp5-icon-large.bp5-intent-warning {
  color: #fbb360;
}

.bp5-dark .bp5-tree .bp5-icon.bp5-intent-danger, .bp5-dark .bp5-tree .bp5-icon-standard.bp5-intent-danger, .bp5-dark .bp5-tree .bp5-icon-large.bp5-intent-danger {
  color: #fa999c;
}

.bp5-dark .bp5-tree-node:not(.bp5-disabled) .bp5-tree-node-caret:hover {
  color: #f6f7f9;
}

.bp5-dark .bp5-tree-node.bp5-tree-node-selected > .bp5-tree-node-content {
  background-color: #2d72d2;
}

.bp5-dark .bp5-tree-node.bp5-tree-node-selected > .bp5-tree-node-content .bp5-icon, .bp5-dark .bp5-tree-node.bp5-tree-node-selected > .bp5-tree-node-content .bp5-icon-standard, .bp5-dark .bp5-tree-node.bp5-tree-node-selected > .bp5-tree-node-content .bp5-icon-large {
  color: #fff;
}

.rdp {
  --rdp-cell-size: 40px;
  --rdp-caption-font-size: 18px;
  --rdp-accent-color: #00f;
  --rdp-background-color: #e7edff;
  --rdp-accent-color-dark: #3003e1;
  --rdp-background-color-dark: #180270;
  --rdp-outline: 2px solid var(--rdp-accent-color);
  --rdp-outline-selected: 3px solid var(--rdp-accent-color);
  --rdp-selected-color: #fff;
  margin: 1em;
}

.rdp-vhidden {
  box-sizing: border-box;
  -webkit-appearance: none;
  appearance: none;
  background: none;
  border: 0;
  margin: 0;
  padding: 0;
  top: 0;
  clip: rect(1px, 1px, 1px, 1px) !important;
  border: 0 !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  position: absolute !important;
  overflow: hidden !important;
}

.rdp-button_reset {
  -webkit-appearance: none;
  appearance: none;
  cursor: default;
  color: inherit;
  font: inherit;
  background: none;
  margin: 0;
  padding: 0;
  position: relative;
}

.rdp-button_reset:focus-visible {
  outline: none;
}

.rdp-button {
  border: 2px solid #0000;
}

.rdp-button[disabled]:not(.rdp-day_selected) {
  opacity: .25;
}

.rdp-button:not([disabled]) {
  cursor: pointer;
}

.rdp-button:focus-visible:not([disabled]) {
  color: inherit;
  background-color: var(--rdp-background-color);
  border: var(--rdp-outline);
}

.rdp-button:hover:not([disabled]):not(.rdp-day_selected) {
  background-color: var(--rdp-background-color);
}

.rdp-months {
  display: flex;
}

.rdp-month {
  margin: 0 1em;
}

.rdp-month:first-child {
  margin-left: 0;
}

.rdp-month:last-child {
  margin-right: 0;
}

.rdp-table {
  max-width: calc(var(--rdp-cell-size) * 7);
  border-collapse: collapse;
  margin: 0;
}

.rdp-with_weeknumber .rdp-table {
  max-width: calc(var(--rdp-cell-size) * 8);
  border-collapse: collapse;
}

.rdp-caption {
  text-align: left;
  justify-content: space-between;
  align-items: center;
  padding: 0;
  display: flex;
}

.rdp-multiple_months .rdp-caption {
  text-align: center;
  display: block;
  position: relative;
}

.rdp-caption_dropdowns {
  display: inline-flex;
  position: relative;
}

.rdp-caption_label {
  z-index: 1;
  white-space: nowrap;
  color: currentColor;
  font-family: inherit;
  font-size: var(--rdp-caption-font-size);
  border: 2px solid #0000;
  align-items: center;
  margin: 0;
  padding: 0 .25em;
  font-weight: bold;
  display: inline-flex;
  position: relative;
}

.rdp-nav {
  white-space: nowrap;
}

.rdp-multiple_months .rdp-caption_start .rdp-nav {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

.rdp-multiple_months .rdp-caption_end .rdp-nav {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}

.rdp-nav_button {
  width: var(--rdp-cell-size);
  height: var(--rdp-cell-size);
  border-radius: 100%;
  justify-content: center;
  align-items: center;
  padding: .25em;
  display: inline-flex;
}

.rdp-dropdown_year, .rdp-dropdown_month {
  align-items: center;
  display: inline-flex;
  position: relative;
}

.rdp-dropdown {
  -webkit-appearance: none;
  appearance: none;
  z-index: 2;
  cursor: inherit;
  opacity: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  background-color: #0000;
  border: none;
  width: 100%;
  margin: 0;
  padding: 0;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
}

.rdp-dropdown[disabled] {
  opacity: unset;
  color: unset;
}

.rdp-dropdown:focus-visible:not([disabled]) + .rdp-caption_label {
  background-color: var(--rdp-background-color);
  border: var(--rdp-outline);
  border-radius: 6px;
}

.rdp-dropdown_icon {
  margin: 0 0 0 5px;
}

.rdp-head {
  border: 0;
}

.rdp-head_row, .rdp-row {
  height: 100%;
}

.rdp-head_cell {
  vertical-align: middle;
  text-align: center;
  height: var(--rdp-cell-size);
  text-transform: uppercase;
  height: 100%;
  padding: 0;
  font-size: .75em;
  font-weight: 700;
}

.rdp-tbody {
  border: 0;
}

.rdp-tfoot {
  margin: .5em;
}

.rdp-cell {
  width: var(--rdp-cell-size);
  height: var(--rdp-cell-size);
  text-align: center;
  height: 100%;
  padding: 0;
}

.rdp-weeknumber {
  font-size: .75em;
}

.rdp-weeknumber, .rdp-day {
  box-sizing: border-box;
  width: var(--rdp-cell-size);
  max-width: var(--rdp-cell-size);
  height: var(--rdp-cell-size);
  border: 2px solid #0000;
  border-radius: 100%;
  justify-content: center;
  align-items: center;
  margin: 0;
  display: flex;
  overflow: hidden;
}

.rdp-day_today:not(.rdp-day_outside) {
  font-weight: bold;
}

.rdp-day_selected, .rdp-day_selected:focus-visible, .rdp-day_selected:hover {
  color: var(--rdp-selected-color);
  opacity: 1;
  background-color: var(--rdp-accent-color);
}

.rdp-day_outside {
  opacity: .5;
}

.rdp-day_selected:focus-visible {
  outline: var(--rdp-outline);
  outline-offset: 2px;
  z-index: 1;
}

.rdp:not([dir="rtl"]) .rdp-day_range_start:not(.rdp-day_range_end) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.rdp:not([dir="rtl"]) .rdp-day_range_end:not(.rdp-day_range_start), .rdp[dir="rtl"] .rdp-day_range_start:not(.rdp-day_range_end) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.rdp[dir="rtl"] .rdp-day_range_end:not(.rdp-day_range_start) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.rdp-day_range_end.rdp-day_range_start {
  border-radius: 100%;
}

.rdp-day_range_middle {
  border-radius: 0;
}

.bp5-datepicker-content .rdp {
  --rdp-cell-size: 30px;
  --rdp-accent-color: #2d72d2;
  --rdp-background-color: #fff;
  --rdp-accent-color-dark: #215db0;
  --rdp-background-color-dark: #2f343c;
  --rdp-outline: 2px solid var(--rdp-accent-color);
  --rdp-outline-selected: 2px solid #000000bf;
  min-width: auto;
  margin: 0;
}

.bp5-datepicker .rdp {
  vertical-align: top;
  min-width: 210px;
  display: inline-block;
  position: relative;
}

.bp5-datepicker .rdp:focus {
  outline: none;
}

.bp5-datepicker .rdp-month {
  -webkit-user-select: none;
  user-select: none;
  flex-direction: column;
  margin: 0 5px;
  display: flex;
}

.bp5-datepicker .rdp-month + .bp5-datepicker .rdp-month {
  margin-left: 10px;
}

.bp5-datepicker .rdp-caption {
  border-bottom: 1px solid #11141826;
  padding-bottom: 5px;
}

.bp5-datepicker .rdp-table {
  align-self: center;
}

.bp5-datepicker .rdp-head_cell {
  font-size: inherit;
  text-transform: none;
  padding-top: 5px;
  font-weight: 600;
  text-decoration: none;
}

.bp5-datepicker .rdp-weeknumber {
  color: #5f6b7c;
}

.bp5-datepicker .rdp-day {
  border-radius: 2px;
}

.bp5-datepicker .rdp-day.rdp-day_outside {
  color: #5f6b7c99;
}

.bp5-datepicker .rdp-day.rdp-day_today {
  font-weight: 400;
}

.bp5-datepicker .rdp-day:not([disabled]):not(.rdp-day_selected):hover, .bp5-datepicker .rdp-day:not([disabled]):not(.rdp-day_selected):focus {
  color: #1c2127;
  background: #8f99a826;
}

.bp5-datepicker .rdp-day:not([disabled]):not(.rdp-day_selected):active {
  background: #8f99a84d;
}

.bp5-datepicker .rdp-day.rdp-day_disabled {
  color: #5f6b7c99;
  cursor: not-allowed;
  background: none;
}

.bp5-datepicker .rdp-day.rdp-day_selected {
  color: #fff;
  background-color: #2d72d2;
  border-radius: 2px;
}

.bp5-datepicker .rdp-day.rdp-day_selected:hover {
  color: #fff;
  background-color: #215db0;
}

.bp5-datepicker .rdp-day.rdp-day_selected:active {
  background-color: #184a90;
}

.bp5-datepicker.bp5-datepicker-highlight-current-day .rdp-day.rdp-day_today {
  border: 1px solid #11141826;
}

.bp5-datepicker.bp5-datepicker-reverse-month-and-year .rdp-caption_dropdowns {
  flex-direction: row-reverse;
}

.bp5-datepicker-content {
  flex-direction: column;
  align-items: center;
  gap: 5px;
  display: flex;
}

.bp5-datepicker-content > .bp5-divider {
  width: calc(100% - 10px);
  margin: 0;
}

.bp5-datepicker-month-select select, .bp5-datepicker-year-select select {
  padding-left: 5px;
  padding-right: 16px;
  font-weight: 600;
}

.bp5-datepicker-month-select select + .bp5-icon, .bp5-datepicker-year-select select + .bp5-icon {
  right: 2px;
}

.bp5-datepicker-footer {
  justify-content: space-between;
  width: 100%;
  display: flex;
}

.bp5-dark .bp5-datepicker {
  background: #2f343c;
}

.bp5-dark .bp5-datepicker .rdp-week-number {
  color: #abb3bf;
}

.bp5-dark .bp5-datepicker .rdp-day.rdp-day_outside {
  color: #abb3bf99;
}

.bp5-dark .bp5-datepicker .rdp-day:not([disabled]):not(.rdp-day_selected):hover, .bp5-dark .bp5-datepicker .rdp-day:not([disabled]):not(.rdp-day_selected):focus {
  color: #fff;
  background: #8f99a826;
}

.bp5-dark .bp5-datepicker .rdp-day:not([disabled]):not(.rdp-day_selected):active {
  background: #8f99a84d;
}

.bp5-dark .bp5-datepicker .rdp-day.rdp-day_selected {
  background-color: #2d72d2;
}

.bp5-dark .bp5-datepicker .rdp-day.rdp-day_selected:hover {
  background-color: #215db0;
}

.bp5-dark .bp5-datepicker .rdp-day.rdp-day_selected:active {
  background-color: #184a90;
}

.bp5-dark .bp5-datepicker .rdp-day.rdp-day_disabled {
  color: #abb3bf99;
  background: none;
}

.bp5-dark .bp5-datepicker.bp5-datepicker-highlight-current-day .rdp-day.rdp-day_today {
  border: 1px solid #fff3;
}

.bp5-dark .bp5-datepicker .bp5-datepicker-footer {
  border-top-color: #11141866;
}

.bp5-datepicker-timepicker-wrapper {
  flex-direction: column;
  align-items: center;
  display: flex;
}

.bp5-datepicker-timepicker-wrapper .bp5-timepicker-arrow-row:empty + .bp5-timepicker-input-row {
  margin: 5px 0;
}

.bp5-datepicker-caption.rdp-caption {
  flex-direction: row;
  justify-content: space-between;
  margin: 0;
  display: flex;
}

.bp5-datepicker-caption.rdp-caption > * {
  flex-grow: 0;
  flex-shrink: 0;
}

.bp5-datepicker-caption.rdp-caption > .bp5-fill {
  flex-grow: 1;
  flex-shrink: 1;
}

.bp5-datepicker-caption.rdp-caption .bp5-html-select select {
  padding-left: 5px;
  padding-right: 16px;
  font-weight: 600;
}

.bp5-datepicker-caption.rdp-caption .bp5-html-select select + .bp5-icon {
  right: 2px;
}

.bp5-datepicker-caption.rdp-caption + .bp5-divider {
  margin: 0;
}

.bp5-datepicker-caption.rdp-caption .bp5-datepicker-nav-button-hidden {
  visibility: hidden;
}

.bp5-datepicker-month-select {
  flex-shrink: 1;
}

.bp5-datepicker-year-select {
  flex-shrink: 1;
  min-width: 60px;
}

.bp5-datepicker-caption-measure {
  padding-left: 5px;
  font-weight: 600;
}

.bp5-daterangepicker {
  display: flex;
}

.bp5-daterangepicker.bp5-daterangepicker-contiguous .rdp {
  min-width: 220px;
}

.bp5-daterangepicker.bp5-daterangepicker-single-month .rdp {
  min-width: 210px;
}

.bp5-daterangepicker .rdp.rdp-multiple_months .rdp-caption {
  flex-direction: row;
  justify-content: space-between;
  display: flex;
}

.bp5-daterangepicker .rdp.rdp-multiple_months .rdp-caption > * {
  flex-grow: 0;
  flex-shrink: 0;
}

.bp5-daterangepicker .rdp.rdp-multiple_months .rdp-caption > .bp5-fill {
  flex-grow: 1;
  flex-shrink: 1;
}

.bp5-daterangepicker .rdp.rdp-multiple_months .rdp-caption_start .rdp-caption {
  flex-direction: row-reverse;
}

.bp5-daterangepicker .rdp.rdp-multiple_months .rdp-caption_start .rdp-caption:before {
  content: "";
  width: 30px;
  height: 30px;
}

.bp5-daterangepicker .rdp.rdp-multiple_months .rdp-caption_end .rdp-caption {
  flex-direction: row;
}

.bp5-daterangepicker .rdp.rdp-multiple_months .rdp-caption_end .rdp-caption:before {
  content: "";
  width: 30px;
  height: 30px;
}

.bp5-daterangepicker .rdp.rdp-multiple_months .rdp-nav {
  left: initial;
  position: initial;
  top: initial;
  transform: none;
}

.bp5-daterangepicker.bp5-datepicker .rdp-day_outside {
  visibility: hidden;
}

.bp5-daterangepicker.bp5-datepicker .rdp-day_hovered:not(.rdp-day_selected) {
  color: #215db0;
  border-radius: 0;
}

.bp5-daterangepicker.bp5-datepicker .rdp-day_hovered:not(.rdp-day_selected):not(.rdp-day_range_start):not(.rdp-day_range_middle):not(.rdp-day_range_end) {
  background-color: #2d72d21a;
}

.bp5-daterangepicker.bp5-datepicker .rdp-day_range_middle {
  color: #215db0;
  background-color: #2d72d21a;
  border-radius: 0;
}

.bp5-daterangepicker.bp5-datepicker .rdp-day_range_middle:hover {
  color: #215db0;
  background-color: #2d72d233;
}

.bp5-daterangepicker.bp5-datepicker .rdp-day_range_start:not(.rdp-day_range_end):not(.rdp-day_hovered_end) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.bp5-daterangepicker.bp5-datepicker .rdp-day_range_end:not(.rdp-day_range_start):not(.rdp_day_hovered_start) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.bp5-daterangepicker.bp5-datepicker .rdp-day_hovered_start:not(.rdp-day_hovered_end) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.bp5-daterangepicker.bp5-datepicker .rdp-day_hovered_end:not(.rdp-day_hovered_start) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.bp5-daterangepicker.bp5-datepicker-highlight-current-day .rdp-day.rdp-day_today {
  border: 1px solid #11141826;
}

.bp5-daterangepicker.bp5-daterangepicker-reverse-month-and-year.bp5-daterangepicker-contiguous .rdp-caption_dropdowns {
  flex-direction: row-reverse;
}

.bp5-daterangepicker-timepickers.bp5-daterangepicker-timepickers-stacked {
  flex-direction: column;
  align-items: center;
}

.bp5-dark .bp5-daterangepicker.bp5-datepicker .rdp-day_hovered {
  color: #f6f7f9;
}

.bp5-dark .bp5-daterangepicker.bp5-datepicker .rdp-day_hovered:not(.rdp-day_selected):not(.rdp-day_range_start):not(.rdp-day_range_middle):not(.rdp-day_range_end) {
  background-color: #2d72d233;
}

.bp5-dark .bp5-daterangepicker.bp5-datepicker .rdp-day_range_middle {
  color: #f6f7f9;
  background-color: #2d72d233;
}

.bp5-dark .bp5-daterangepicker.bp5-datepicker .rdp-day_range_middle:hover {
  background-color: #2d72d266;
}

.bp5-dark .bp5-daterangepicker.bp5-datepicker-highlight-current-day .rdp-day.rdp-day_today {
  border: 1px solid #fff3;
}

.tui-image-editor-container {
  box-sizing: border-box;
  letter-spacing: .3px;
  background-color: #282828;
  height: 100%;
  min-height: 300px;
  margin: 0;
  padding: 0;
  position: relative;
  overflow: hidden;
}

.tui-image-editor-container div, .tui-image-editor-container ul, .tui-image-editor-container label, .tui-image-editor-container input, .tui-image-editor-container li {
  box-sizing: border-box;
  -webkit-user-select: none;
  user-select: none;
  -moz-user-select: -moz-none;
  -khtml-user-select: none;
  margin: 0;
  padding: 0;
}

.tui-image-editor-container .tui-image-editor-header {
  background-color: #151515;
  width: 100%;
  min-width: 533px;
  display: none;
  position: absolute;
  top: 0;
}

.tui-image-editor-container .tui-image-editor-header-buttons, .tui-image-editor-container .tui-image-editor-controls-buttons {
  float: right;
  margin: 8px;
}

.tui-image-editor-container .tui-image-editor-header-logo, .tui-image-editor-container .tui-image-editor-controls-logo {
  float: left;
  width: 30%;
  padding: 17px;
}

.tui-image-editor-container .tui-image-editor-controls-logo, .tui-image-editor-container .tui-image-editor-controls-buttons {
  width: 270px;
  height: 100%;
  display: none;
}

.tui-image-editor-container .tui-image-editor-header-buttons button, .tui-image-editor-container .tui-image-editor-header-buttons div, .tui-image-editor-container .tui-image-editor-controls-buttons button, .tui-image-editor-container .tui-image-editor-controls-buttons div {
  cursor: pointer;
  vertical-align: middle;
  letter-spacing: .3px;
  text-align: center;
  border: 1px solid #ddd;
  border-radius: 20px;
  outline: none;
  width: 120px;
  height: 40px;
  padding: 0;
  font-family: Noto Sans, sans-serif;
  font-size: 12px;
  font-weight: bold;
  line-height: 40px;
  display: inline-block;
  position: relative;
}

.tui-image-editor-container .tui-image-editor-download-btn {
  color: #fff;
  background-color: #fdba3b;
  border-color: #fdba3b;
}

.tui-image-editor-container .tui-image-editor-load-btn {
  cursor: pointer;
  opacity: 0;
  width: 100%;
  display: inline-block;
  position: absolute;
  inset: 0;
}

.tui-image-editor-container .tui-image-editor-main-container {
  width: 100%;
  position: absolute;
  top: 0;
  bottom: 64px;
}

.tui-image-editor-container .tui-image-editor-main {
  text-align: center;
  position: absolute;
  inset: 64px 0 0;
}

.tui-image-editor-container .tui-image-editor-wrap {
  width: 100%;
  position: absolute;
  bottom: 0;
  overflow: auto;
}

.tui-image-editor-container .tui-image-editor-wrap .tui-image-editor-size-wrap {
  width: 100%;
  height: 100%;
  display: table;
}

.tui-image-editor-container .tui-image-editor-wrap .tui-image-editor-size-wrap .tui-image-editor-align-wrap {
  vertical-align: middle;
  display: table-cell;
}

.tui-image-editor-container .tui-image-editor {
  display: inline-block;
  position: relative;
}

.tui-image-editor-container .tui-image-editor-menu {
  text-align: center;
  vertical-align: middle;
  white-space: nowrap;
  background: #fff;
  width: auto;
  margin: 0 auto;
  padding: 0;
  list-style: none;
  display: table-cell;
}

.tui-image-editor-container .tui-image-editor-menu > .tui-image-editor-item {
  cursor: pointer;
  border-radius: 2px;
  margin: 0 4px;
  padding: 7px 8px 3px;
  display: inline-block;
  position: relative;
}

.tui-image-editor-container .tui-image-editor-menu > .tui-image-editor-item[tooltip-content]:hover:before {
  content: "";
  border-top: 7px solid #2f2f2f;
  border-left: 7px solid #0000;
  border-right: 7px solid #0000;
  width: 0;
  height: 0;
  margin: 0 auto;
  display: inline-block;
  position: absolute;
  top: -2px;
  left: 13px;
}

.tui-image-editor-container .tui-image-editor-menu > .tui-image-editor-item[tooltip-content]:hover:after {
  content: attr(tooltip-content);
  color: #fff;
  background-color: #2f2f2f;
  border-radius: 3px;
  min-width: 24px;
  max-height: 23px;
  padding: 5px 8px;
  font-size: 11px;
  font-weight: lighter;
  display: inline-block;
  position: absolute;
  top: -25px;
  left: 0;
}

.tui-image-editor-container .tui-image-editor-menu > .tui-image-editor-item.active {
  background-color: #fff;
  transition: all .3s;
}

.tui-image-editor-container .tui-image-editor-wrap {
  position: absolute;
}

.tui-image-editor-container .tui-image-editor-grid-visual {
  border: 1px solid #ffffffb3;
  width: 100%;
  height: 100%;
  display: none;
  position: absolute;
}

.tui-image-editor-container .tui-image-editor-main.tui-image-editor-menu-flip .tui-image-editor, .tui-image-editor-container .tui-image-editor-main.tui-image-editor-menu-rotate .tui-image-editor {
  transition: none;
}

.tui-image-editor-container .tui-image-editor-main.tui-image-editor-menu-flip .tui-image-editor-grid-visual, .tui-image-editor-container .tui-image-editor-main.tui-image-editor-menu-rotate .tui-image-editor-grid-visual {
  display: block;
}

.tui-image-editor-container .tui-image-editor-grid-visual table {
  border-collapse: collapse;
  width: 100%;
  height: 100%;
}

.tui-image-editor-container .tui-image-editor-grid-visual table td {
  border: 1px solid #ffffff4d;
}

.tui-image-editor-container .tui-image-editor-grid-visual table td.dot:before {
  content: "";
  box-sizing: border-box;
  background-color: #fff;
  border: 0;
  border-radius: 100%;
  width: 10px;
  height: 10px;
  position: absolute;
  box-shadow: 0 0 1px #0000004d;
}

.tui-image-editor-container .tui-image-editor-grid-visual table td.dot.left-top:before {
  top: -5px;
  left: -5px;
}

.tui-image-editor-container .tui-image-editor-grid-visual table td.dot.right-top:before {
  top: -5px;
  right: -5px;
}

.tui-image-editor-container .tui-image-editor-grid-visual table td.dot.left-bottom:before {
  bottom: -5px;
  left: -5px;
}

.tui-image-editor-container .tui-image-editor-grid-visual table td.dot.right-bottom:before {
  bottom: -5px;
  right: -5px;
}

.tui-image-editor-container .tui-image-editor-submenu {
  white-space: nowrap;
  z-index: 2;
  width: 100%;
  height: 150px;
  display: none;
  position: absolute;
  bottom: 0;
}

.tui-image-editor-container .tui-image-editor-submenu .tui-image-editor-button:hover svg > use.active {
  display: block;
}

.tui-image-editor-container .tui-image-editor-submenu .tui-image-editor-submenu-item li {
  vertical-align: top;
  display: inline-block;
}

.tui-image-editor-container .tui-image-editor-submenu .tui-image-editor-submenu-item .tui-image-editor-newline {
  margin-top: 0;
  display: block;
}

.tui-image-editor-container .tui-image-editor-submenu .tui-image-editor-submenu-item .tui-image-editor-button {
  cursor: pointer;
  margin: 0 9px;
  font-size: 11px;
  font-weight: normal;
  display: inline-block;
  position: relative;
}

.tui-image-editor-container .tui-image-editor-submenu .tui-image-editor-submenu-item .tui-image-editor-button.preset {
  margin: 0 9px 20px 5px;
}

.tui-image-editor-container .tui-image-editor-submenu .tui-image-editor-submenu-item label > span {
  cursor: pointer;
  padding-top: 5px;
  font-family: Noto Sans, sans-serif;
  font-size: 11px;
  display: inline-block;
}

.tui-image-editor-container .tui-image-editor-submenu .tui-image-editor-submenu-item .tui-image-editor-button.apply label, .tui-image-editor-container .tui-image-editor-submenu .tui-image-editor-submenu-item .tui-image-editor-button.cancel label {
  vertical-align: 7px;
}

.tui-image-editor-container .tui-image-editor-submenu > div {
  vertical-align: bottom;
  display: none;
}

.tui-image-editor-container .tui-image-editor-submenu .tui-image-editor-submenu-style {
  opacity: .95;
  z-index: -1;
  display: block;
  position: absolute;
  inset: 0;
}

.tui-image-editor-container .tui-image-editor-partition > div {
  border-left: 1px solid #3c3c3c;
  width: 1px;
  height: 52px;
  margin: 0 8px;
}

.tui-image-editor-container .tui-image-editor-main.tui-image-editor-menu-filter .tui-image-editor-partition > div {
  height: 108px;
  margin: 0 29px 0 0;
}

.tui-image-editor-container .tui-image-editor-submenu-align {
  text-align: left;
  margin-right: 30px;
}

.tui-image-editor-container .tui-image-editor-submenu-align label > span {
  white-space: nowrap;
  width: 55px;
}

.tui-image-editor-container .tui-image-editor-submenu-align:first-child {
  margin-right: 0;
}

.tui-image-editor-container .tui-image-editor-submenu-align:first-child label > span {
  width: 70px;
}

.tui-image-editor-container .tui-image-editor-main.tui-image-editor-menu-crop .tui-image-editor-submenu > div.tui-image-editor-menu-crop, .tui-image-editor-container .tui-image-editor-main.tui-image-editor-menu-flip .tui-image-editor-submenu > div.tui-image-editor-menu-flip, .tui-image-editor-container .tui-image-editor-main.tui-image-editor-menu-rotate .tui-image-editor-submenu > div.tui-image-editor-menu-rotate, .tui-image-editor-container .tui-image-editor-main.tui-image-editor-menu-shape .tui-image-editor-submenu > div.tui-image-editor-menu-shape, .tui-image-editor-container .tui-image-editor-main.tui-image-editor-menu-text .tui-image-editor-submenu > div.tui-image-editor-menu-text, .tui-image-editor-container .tui-image-editor-main.tui-image-editor-menu-mask .tui-image-editor-submenu > div.tui-image-editor-menu-mask, .tui-image-editor-container .tui-image-editor-main.tui-image-editor-menu-icon .tui-image-editor-submenu > div.tui-image-editor-menu-icon, .tui-image-editor-container .tui-image-editor-main.tui-image-editor-menu-draw .tui-image-editor-submenu > div.tui-image-editor-menu-draw, .tui-image-editor-container .tui-image-editor-main.tui-image-editor-menu-filter .tui-image-editor-submenu > div.tui-image-editor-menu-filter {
  display: table-cell;
}

.tui-image-editor-container .tui-image-editor-main.tui-image-editor-menu-crop .tui-image-editor-submenu, .tui-image-editor-container .tui-image-editor-main.tui-image-editor-menu-flip .tui-image-editor-submenu, .tui-image-editor-container .tui-image-editor-main.tui-image-editor-menu-rotate .tui-image-editor-submenu, .tui-image-editor-container .tui-image-editor-main.tui-image-editor-menu-shape .tui-image-editor-submenu, .tui-image-editor-container .tui-image-editor-main.tui-image-editor-menu-text .tui-image-editor-submenu, .tui-image-editor-container .tui-image-editor-main.tui-image-editor-menu-mask .tui-image-editor-submenu, .tui-image-editor-container .tui-image-editor-main.tui-image-editor-menu-icon .tui-image-editor-submenu, .tui-image-editor-container .tui-image-editor-main.tui-image-editor-menu-draw .tui-image-editor-submenu, .tui-image-editor-container .tui-image-editor-main.tui-image-editor-menu-filter .tui-image-editor-submenu {
  display: table;
}

.tui-image-editor-container .filter-color-item {
  display: inline-block;
}

.tui-image-editor-container .filter-color-item .tui-image-editor-checkbox {
  display: block;
}

.tui-image-editor-container .tui-image-editor-checkbox-wrap {
  text-align: left;
  display: inline-block !important;
}

.tui-image-editor-container .tui-image-editor-checkbox-wrap.fixed-width {
  white-space: normal;
  width: 187px;
}

.tui-image-editor-container .tui-image-editor-checkbox {
  margin: 1px 0;
  display: inline-block;
}

.tui-image-editor-container .tui-image-editor-checkbox input {
  opacity: 0;
  width: 14px;
  height: 14px;
}

.tui-image-editor-container .tui-image-editor-checkbox > label > span {
  color: #fff;
  height: 14px;
  position: relative;
}

.tui-image-editor-container .tui-image-editor-checkbox input + label:before, .tui-image-editor-container .tui-image-editor-checkbox > label > span:before {
  content: "";
  text-align: center;
  box-sizing: border-box;
  background-color: #fff;
  border: 0;
  border-radius: 2px;
  width: 14px;
  height: 14px;
  margin: 0;
  padding-top: 1px;
  font-size: 11px;
  display: inline-block;
  position: absolute;
  top: 6px;
  left: -19px;
}

.tui-image-editor-container .tui-image-editor-checkbox input[type="checkbox"]:checked + span:before {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAOCAYAAAAfSC3RAAAAAXNSR0IArs4c6QAAAMBJREFUKBWVkjEOwjAMRe2WgZW7IIHEDdhghhuwcQ42rlJugAQS54Cxa5cq1QM5TUpByZfS2j9+dlJVt/tX5ZxbS4ZU9VLkQvSHKTIGRaVJYFmKrBbTCJxE2UgCdDzMZDkHrOV6b95V0US6UmgKodujEZbJg0B0ZgEModO5lrY1TMQf1TpyJGBEjD+E2NPN7ukIUDiF/BfEXgRiGEw8NgkffYGYwCi808fpn/6OvfUfsDr/Vc1IfRf8sKnFVqeiVQfDu0tf/nWH9gAAAABJRU5ErkJggg==");
  background-size: cover;
}

.tui-image-editor-container .tui-image-editor-selectlist-wrap {
  position: relative;
}

.tui-image-editor-container .tui-image-editor-selectlist-wrap select {
  -webkit-appearance: none;
  appearance: none;
  background-color: #fff;
  border: 1px solid #cbdbdb;
  border-radius: 0;
  outline: 0;
  width: 100%;
  height: 28px;
  margin-top: 4px;
  padding: 0 7px 0 10px;
}

.tui-image-editor-container .tui-image-editor-selectlist-wrap .tui-image-editor-selectlist {
  background-color: #fff;
  border: 1px solid #ccc;
  border-top: 0;
  padding: 4px 0;
  display: none;
  position: relative;
  top: -1px;
}

.tui-image-editor-container .tui-image-editor-selectlist-wrap .tui-image-editor-selectlist li {
  text-align: left;
  padding: 7px 10px;
  font-family: Noto Sans, sans-serif;
  display: block;
}

.tui-image-editor-container .tui-image-editor-selectlist-wrap .tui-image-editor-selectlist li:hover {
  background-color: #515ce60d;
}

.tui-image-editor-container .tui-image-editor-selectlist-wrap:before {
  content: "";
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAOCAYAAAAfSC3RAAAAAXNSR0IArs4c6QAAAHlJREFUKBVjYBgFOEOAEVkmPDxc89+/f6eAYjzI4kD2FyYmJrOVK1deh4kzwRggGiQBVJCELAZig8SQNYHEmEEEMrh69eo1HR0dfqCYJUickZGxf9WqVf3IakBsFBthklpaWmVA9mEQhrJhUoTp0NBQCRAmrHL4qgAAuu4cWZOZIGsAAAAASUVORK5CYII=");
  background-size: cover;
  width: 14px;
  height: 14px;
  display: inline-block;
  position: absolute;
  top: 10px;
  right: 5px;
}

.tui-image-editor-container .tui-image-editor-selectlist-wrap select::-ms-expand {
  display: none;
}

.tui-image-editor-container .tui-image-editor-virtual-range-bar .tui-image-editor-disabled, .tui-image-editor-container .tui-image-editor-virtual-range-subbar .tui-image-editor-disabled, .tui-image-editor-container .tui-image-editor-virtual-range-pointer .tui-image-editor-disabled {
  backbround-color: red;
}

.tui-image-editor-container .tui-image-editor-range {
  width: 166px;
  height: 17px;
  display: inline-block;
  position: relative;
  top: 5px;
}

.tui-image-editor-container .tui-image-editor-virtual-range-bar {
  background-color: #666;
  width: 100%;
  height: 2px;
  position: absolute;
  top: 7px;
}

.tui-image-editor-container .tui-image-editor-virtual-range-subbar {
  background-color: #d1d1d1;
  height: 100%;
  position: absolute;
  left: 0;
  right: 0;
}

.tui-image-editor-container .tui-image-editor-virtual-range-pointer {
  cursor: pointer;
  background-color: #fff;
  border-radius: 100%;
  width: 12px;
  height: 12px;
  position: absolute;
  top: -5px;
  left: 0;
}

.tui-image-editor-container .tui-image-editor-range-wrap {
  margin-left: 4px;
  display: inline-block;
}

.tui-image-editor-container .tui-image-editor-range-wrap.short .tui-image-editor-range {
  width: 100px;
}

.tui-image-editor-container .color-picker-control .tui-image-editor-range {
  width: 108px;
  margin-left: 10px;
}

.tui-image-editor-container .color-picker-control .tui-image-editor-virtual-range-pointer {
  background-color: #333;
}

.tui-image-editor-container .color-picker-control .tui-image-editor-virtual-range-bar {
  background-color: #ccc;
}

.tui-image-editor-container .color-picker-control .tui-image-editor-virtual-range-subbar {
  background-color: #606060;
}

.tui-image-editor-container .tui-image-editor-range-wrap.tui-image-editor-newline.short {
  margin-top: -2px;
  margin-left: 19px;
}

.tui-image-editor-container .tui-image-editor-range-wrap.tui-image-editor-newline.short label {
  color: #8e8e8e;
  font-weight: normal;
}

.tui-image-editor-container .tui-image-editor-range-wrap label {
  vertical-align: baseline;
  color: #fff;
  margin-right: 7px;
  font-size: 11px;
}

.tui-image-editor-container .tui-image-editor-range-value {
  cursor: default;
  box-shadow: none;
  text-align: center;
  color: #fff;
  vertical-align: baseline;
  background-color: #1c1c1c;
  border: 1px solid #d5d5d5;
  border-radius: 2px;
  outline: none;
  width: 40px;
  height: 24px;
  margin-top: 21px;
  margin-left: 4px;
  font-family: Noto Sans, sans-serif;
  font-weight: lighter;
}

.tui-image-editor-container .tui-image-editor-controls {
  z-index: 2;
  background-color: #151515;
  width: 100%;
  height: 64px;
  display: table;
  position: absolute;
  bottom: 0;
}

.tui-image-editor-container .tui-image-editor-icpartition {
  background-color: #282828;
  width: 1px;
  height: 24px;
  display: inline-block;
}

.tui-image-editor-container.left .tui-image-editor-menu > .tui-image-editor-item[tooltip-content]:before {
  border-top: 7px solid #0000;
  border-bottom: 7px solid #0000;
  border-right: 7px solid #2f2f2f;
  top: 11px;
  left: 28px;
}

.tui-image-editor-container.left .tui-image-editor-menu > .tui-image-editor-item[tooltip-content]:after {
  white-space: nowrap;
  top: 7px;
  left: 42px;
}

.tui-image-editor-container.left .tui-image-editor-submenu {
  width: 248px;
  height: 100%;
  left: 0;
}

.tui-image-editor-container.left .tui-image-editor-main-container {
  width: calc(100% - 64px);
  height: 100%;
  left: 64px;
}

.tui-image-editor-container.left .tui-image-editor-controls {
  width: 64px;
  height: 100%;
  display: table;
}

.tui-image-editor-container.left .tui-image-editor-menu, .tui-image-editor-container.right .tui-image-editor-menu {
  white-space: inherit;
}

.tui-image-editor-container.left .tui-image-editor-submenu, .tui-image-editor-container.right .tui-image-editor-submenu {
  white-space: normal;
}

.tui-image-editor-container.left .tui-image-editor-submenu > div, .tui-image-editor-container.right .tui-image-editor-submenu > div {
  vertical-align: middle;
}

.tui-image-editor-container.left .tui-image-editor-controls li, .tui-image-editor-container.right .tui-image-editor-controls li {
  margin: 4px auto;
  display: inline-block;
}

.tui-image-editor-container.left .tui-image-editor-icpartition, .tui-image-editor-container.right .tui-image-editor-icpartition {
  width: 24px;
  height: 1px;
  position: relative;
  top: -7px;
}

.tui-image-editor-container.left .tui-image-editor-submenu .tui-image-editor-partition, .tui-image-editor-container.right .tui-image-editor-submenu .tui-image-editor-partition {
  width: 75%;
  margin: auto;
  display: block;
}

.tui-image-editor-container.left .tui-image-editor-submenu .tui-image-editor-partition > div, .tui-image-editor-container.right .tui-image-editor-submenu .tui-image-editor-partition > div {
  border-bottom: 1px solid #3c3c3c;
  border-left: 0;
  width: 100%;
  height: 10px;
  margin: 0;
}

.tui-image-editor-container.left .tui-image-editor-submenu .tui-image-editor-submenu-align, .tui-image-editor-container.right .tui-image-editor-submenu .tui-image-editor-submenu-align {
  margin-right: 0;
}

.tui-image-editor-container.left .tui-image-editor-submenu .tui-image-editor-submenu-item li, .tui-image-editor-container.right .tui-image-editor-submenu .tui-image-editor-submenu-item li {
  margin-top: 15px;
}

.tui-image-editor-container.left .tui-image-editor-submenu .tui-image-editor-submenu-item .tui-colorpicker-clearfix li, .tui-image-editor-container.right .tui-image-editor-submenu .tui-image-editor-submenu-item .tui-colorpicker-clearfix li {
  margin-top: 0;
}

.tui-image-editor-container.left .tui-image-editor-checkbox-wrap.fixed-width, .tui-image-editor-container.right .tui-image-editor-checkbox-wrap.fixed-width {
  white-space: normal;
  width: 182px;
}

.tui-image-editor-container.left .tui-image-editor-range-wrap.tui-image-editor-newline label.range, .tui-image-editor-container.right .tui-image-editor-range-wrap.tui-image-editor-newline label.range {
  text-align: left;
  width: 75%;
  margin: auto;
  display: block;
}

.tui-image-editor-container.left .tui-image-editor-range, .tui-image-editor-container.right .tui-image-editor-range {
  width: 136px;
}

.tui-image-editor-container.right .tui-image-editor-menu > .tui-image-editor-item[tooltip-content]:before {
  border-top: 7px solid #0000;
  border-bottom: 7px solid #0000;
  border-left: 7px solid #2f2f2f;
  top: 11px;
  left: -3px;
}

.tui-image-editor-container.right .tui-image-editor-menu > .tui-image-editor-item[tooltip-content]:after {
  top: 7px;
  left: unset;
  white-space: nowrap;
  right: 43px;
}

.tui-image-editor-container.right .tui-image-editor-submenu {
  width: 248px;
  height: 100%;
  right: 0;
}

.tui-image-editor-container.right .tui-image-editor-main-container {
  width: calc(100% - 64px);
  height: 100%;
  right: 64px;
}

.tui-image-editor-container.right .tui-image-editor-controls {
  width: 64px;
  height: 100%;
  display: table;
  right: 0;
}

.tui-image-editor-container.top .tui-image-editor-submenu .tui-image-editor-partition.only-left-right, .tui-image-editor-container.bottom .tui-image-editor-submenu .tui-image-editor-partition.only-left-right {
  display: none;
}

.tui-image-editor-container.bottom .tui-image-editor-submenu > div {
  padding-bottom: 24px;
}

.tui-image-editor-container.top .color-picker-control .triangle {
  border: 7px solid #0000;
  border-top: 0;
  border-bottom: 8px solid #fff;
  top: -8px;
}

.tui-image-editor-container.top .tui-image-editor-size-wrap {
  height: 100%;
}

.tui-image-editor-container.top .tui-image-editor-main-container {
  bottom: 0;
}

.tui-image-editor-container.top .tui-image-editor-menu > .tui-image-editor-item[tooltip-content]:before {
  border-top: 0;
  border-bottom: 7px solid #2f2f2f;
  top: 33px;
  left: 13px;
}

.tui-image-editor-container.top .tui-image-editor-menu > .tui-image-editor-item[tooltip-content]:after {
  top: 38px;
}

.tui-image-editor-container.top .tui-image-editor-submenu {
  top: 0;
  bottom: auto;
}

.tui-image-editor-container.top .tui-image-editor-submenu > div {
  vertical-align: top;
  padding-top: 24px;
}

.tui-image-editor-container.top .tui-image-editor-controls-logo, .tui-image-editor-container.top .tui-image-editor-controls-buttons {
  display: none;
}

.tui-image-editor-container.top .tui-image-editor-main {
  height: calc(100% - 64px);
  top: 64px;
}

.tui-image-editor-container.top .tui-image-editor-controls {
  top: 0;
  bottom: inherit;
}

.tui-image-editor-container .tie-icon-add-button .tui-image-editor-button {
  min-width: 42px;
}

.tui-image-editor-container .svg_ic-menu, .tui-image-editor-container .svg_ic-helpmenu {
  width: 24px;
  height: 24px;
}

.tui-image-editor-container .svg_ic-submenu {
  width: 32px;
  height: 32px;
}

.tui-image-editor-container .svg_img-bi {
  width: 257px;
  height: 26px;
}

.tui-image-editor-container .tui-image-editor-controls svg > use {
  display: none;
}

.tui-image-editor-container .tui-image-editor-controls .enabled svg:hover > use.hover, .tui-image-editor-container .tui-image-editor-controls .normal svg:hover > use.hover {
  display: block;
}

.tui-image-editor-container .tui-image-editor-controls .active svg:hover > use.hover {
  display: none;
}

.tui-image-editor-container .tui-image-editor-controls svg > use.normal, .tui-image-editor-container .tui-image-editor-controls .active svg > use.active, .tui-image-editor-container .tui-image-editor-controls .enabled svg > use.enabled {
  display: block;
}

.tui-image-editor-container .tui-image-editor-controls .active svg > use.normal, .tui-image-editor-container .tui-image-editor-controls .enabled svg > use.normal {
  display: none;
}

.tui-image-editor-container .tui-image-editor-controls .help svg > use.disabled, .tui-image-editor-container .tui-image-editor-controls .help.enabled svg > use.normal {
  display: block;
}

.tui-image-editor-container .tui-image-editor-controls .help.enabled svg > use.disabled {
  display: none;
}

.tui-image-editor-container .tui-image-editor-controls:hover {
  z-index: 3;
}

.tui-image-editor-container div.tui-colorpicker-clearfix {
  background-color: #f5f5f5;
  border: 1px solid #d5d5d5;
  border-radius: 2px;
  width: 159px;
  height: 28px;
  margin-top: 6px;
  padding: 4px 7px;
}

.tui-image-editor-container .tui-colorpicker-palette-hex {
  background-color: #f5f5f5;
  border: 0;
  width: 114px;
  margin-top: 2px;
  font-family: Noto Sans, sans-serif;
  font-size: 11px;
}

.tui-image-editor-container .tui-colorpicker-palette-hex[value="#ffffff"] + .tui-colorpicker-palette-preview, .tui-image-editor-container .tui-colorpicker-palette-hex[value=""] + .tui-colorpicker-palette-preview {
  border: 1px solid #ccc;
}

.tui-image-editor-container .tui-colorpicker-palette-hex[value=""] + .tui-colorpicker-palette-preview {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAAAXNSR0IArs4c6QAAAdBJREFUWAnFl0FuwjAQRZ0ukiugHqFSOQNdseuKW3ALzkA4BateICvUGyCxrtRFd4WuunH/TzykaYJrnLEYaTJJsP2+x8GZZCbQrLU5mj7Bn+EP8HvnCObd+R7xBV5lWfaNON4AnsA38E94qLEt+0yiFaBzAV/Bv+Cxxr4co7hKCDpw1q9wLeNYYdlAwyn8TYt8Hme3+8D5ozcTaMCZ68PXa2tnM2sbEcOZAJhrrpl2DAcTOGNjZPSfCdzkw6JrfbiMv+osBe4y9WOedhm4jZfhbENWuxS44H9Wz/xw4WzqLOAqh1+zycgAwzEMzr5k5gaHOa9ULBwuuDkFlHI1Kl4PJ66kgIpnoywOTmRFAYcbwYk9UMApWkD8zAV5ihcwHk4Rx7gl0IFTQL0EFc+CTQ9OZHWH3YhlVJiVpTHbrTGLhTHLZVgff6s9lyBsI9KduSS83oj+34rTwJutmBmCnMsvozRwZqB5GTkBw6/jdPDu69iJ6BYk6eCcfbcgcQIK/MByaaiMqm8rHcjol2TnpWDhyAKSGdA3FrxtJUToX0ODqatetfGE+8tyEUOV8GY5dGRwLP/MBS4RHQr4bT7NRAQjlcOTfZxmv2G+c4hI8nn+Ax5PG/zhI393AAAAAElFTkSuQmCC");
  background-size: cover;
}

.tui-image-editor-container .tui-colorpicker-palette-preview {
  float: left;
  border: 0;
  border-radius: 100%;
  width: 17px;
  height: 17px;
}

.tui-image-editor-container .color-picker-control {
  z-index: 99;
  background-color: #fff;
  border-radius: 2px;
  width: 192px;
  padding: 16px;
  display: none;
  position: absolute;
  box-shadow: 0 3px 22px 6px #00000026;
}

.tui-image-editor-container .color-picker-control .tui-colorpicker-palette-toggle-slider {
  display: none;
}

.tui-image-editor-container .color-picker-control .tui-colorpicker-palette-button {
  background-size: cover;
  border: 0;
  border-radius: 100%;
  margin: 2px;
  font-size: 1px;
}

.tui-image-editor-container .color-picker-control .tui-colorpicker-palette-button[title="#ffffff"], .tui-image-editor-container .color-picker-control .tui-colorpicker-palette-button[title=""] {
  border: 1px solid #ccc;
}

.tui-image-editor-container .color-picker-control .triangle {
  border-top: 8px solid #fff;
  border-left: 7px solid #0000;
  border-right: 7px solid #0000;
  width: 0;
  height: 0;
  position: absolute;
  bottom: -8px;
  left: 84px;
}

.tui-image-editor-container .color-picker-control .tui-colorpicker-container, .tui-image-editor-container .color-picker-control .tui-colorpicker-palette-container ul, .tui-image-editor-container .color-picker-control .tui-colorpicker-palette-container {
  width: 100%;
  height: auto;
}

.tui-image-editor-container .filter-color-item .color-picker-control label {
  font-color: #333;
  margin-right: 7pxleft;
  font-weight: normal;
}

.tui-image-editor-container .filter-color-item .tui-image-editor-checkbox {
  margin-top: 0;
}

.tui-image-editor-container .filter-color-item .tui-image-editor-checkbox input + label:before, .tui-image-editor-container .filter-color-item .tui-image-editor-checkbox > label:before {
  left: -16px;
}

.tui-image-editor-container .color-picker {
  width: 100%;
  height: auto;
}

.tui-image-editor-container .color-picker-value {
  border: 0;
  border-radius: 100%;
  width: 32px;
  height: 32px;
  margin: auto auto 1px;
}

.tui-image-editor-container .color-picker-value.transparent {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAAAXNSR0IArs4c6QAAAdBJREFUWAnFl0FuwjAQRZ0ukiugHqFSOQNdseuKW3ALzkA4BateICvUGyCxrtRFd4WuunH/TzykaYJrnLEYaTJJsP2+x8GZZCbQrLU5mj7Bn+EP8HvnCObd+R7xBV5lWfaNON4AnsA38E94qLEt+0yiFaBzAV/Bv+Cxxr4co7hKCDpw1q9wLeNYYdlAwyn8TYt8Hme3+8D5ozcTaMCZ68PXa2tnM2sbEcOZAJhrrpl2DAcTOGNjZPSfCdzkw6JrfbiMv+osBe4y9WOedhm4jZfhbENWuxS44H9Wz/xw4WzqLOAqh1+zycgAwzEMzr5k5gaHOa9ULBwuuDkFlHI1Kl4PJ66kgIpnoywOTmRFAYcbwYk9UMApWkD8zAV5ihcwHk4Rx7gl0IFTQL0EFc+CTQ9OZHWH3YhlVJiVpTHbrTGLhTHLZVgff6s9lyBsI9KduSS83oj+34rTwJutmBmCnMsvozRwZqB5GTkBw6/jdPDu69iJ6BYk6eCcfbcgcQIK/MByaaiMqm8rHcjol2TnpWDhyAKSGdA3FrxtJUToX0ODqatetfGE+8tyEUOV8GY5dGRwLP/MBS4RHQr4bT7NRAQjlcOTfZxmv2G+c4hI8nn+Ax5PG/zhI393AAAAAElFTkSuQmCC");
  background-size: cover;
  border: 1px solid #cbcbcb;
}

.tui-image-editor-container .color-picker-value + label {
  color: #fff;
}

.tui-image-editor-container .tui-image-editor-submenu svg > use {
  display: none;
}

.tui-image-editor-container .tui-image-editor-submenu svg > use.normal, .tie-icon-add-button.icon-bubble .tui-image-editor-button[data-icontype="icon-bubble"] svg > use.active, .tie-icon-add-button.icon-heart .tui-image-editor-button[data-icontype="icon-heart"] svg > use.active, .tie-icon-add-button.icon-location .tui-image-editor-button[data-icontype="icon-location"] svg > use.active, .tie-icon-add-button.icon-polygon .tui-image-editor-button[data-icontype="icon-polygon"] svg > use.active, .tie-icon-add-button.icon-star .tui-image-editor-button[data-icontype="icon-star"] svg > use.active, .tie-icon-add-button.icon-star-2 .tui-image-editor-button[data-icontype="icon-star-2"] svg > use.active, .tie-icon-add-button.icon-arrow-3 .tui-image-editor-button[data-icontype="icon-arrow-3"] svg > use.active, .tie-icon-add-button.icon-arrow-2 .tui-image-editor-button[data-icontype="icon-arrow-2"] svg > use.active, .tie-icon-add-button.icon-arrow .tui-image-editor-button[data-icontype="icon-arrow"] svg > use.active {
  display: block;
}

.tie-draw-line-select-button.line .tui-image-editor-button.line svg > use.normal, .tie-draw-line-select-button.free .tui-image-editor-button.free svg > use.normal {
  display: none;
}

.tie-draw-line-select-button.line .tui-image-editor-button.line svg > use.active, .tie-draw-line-select-button.free .tui-image-editor-button.free svg > use.active {
  display: block;
}

.tie-flip-button.resetFlip .tui-image-editor-button.resetFlip svg > use.normal, .tie-flip-button.flipX .tui-image-editor-button.flipX svg > use.normal, .tie-flip-button.flipY .tui-image-editor-button.flipY svg > use.normal {
  display: none;
}

.tie-flip-button.resetFlip .tui-image-editor-button.resetFlip svg > use.active, .tie-flip-button.flipX .tui-image-editor-button.flipX svg > use.active, .tie-flip-button.flipY .tui-image-editor-button.flipY svg > use.active {
  display: block;
}

.tie-mask-apply.apply.active .tui-image-editor-button.apply label {
  color: #fff;
}

.tie-mask-apply.apply.active .tui-image-editor-button.apply svg > use.active {
  display: block;
}

.tie-crop-button .tui-image-editor-button.apply, .tie-crop-preset-button .tui-image-editor-button.apply {
  margin-right: 24px;
}

.tie-crop-button .tui-image-editor-button.preset.active svg > use.active, .tie-crop-preset-button .tui-image-editor-button.preset.active svg > use.active, .tie-crop-button .tui-image-editor-button.apply.active svg > use.active, .tie-crop-preset-button .tui-image-editor-button.apply.active svg > use.active {
  display: block;
}

.tie-shape-button.rect .tui-image-editor-button.rect svg > use.normal, .tie-shape-button.circle .tui-image-editor-button.circle svg > use.normal, .tie-shape-button.triangle .tui-image-editor-button.triangle svg > use.normal {
  display: none;
}

.tie-shape-button.rect .tui-image-editor-button.rect svg > use.active, .tie-shape-button.circle .tui-image-editor-button.circle svg > use.active, .tie-shape-button.triangle .tui-image-editor-button.triangle svg > use.active, .tie-text-effect-button .tui-image-editor-button.active svg > use.active, .tie-text-align-button.left .tui-image-editor-button.left svg > use.active, .tie-text-align-button.center .tui-image-editor-button.center svg > use.active, .tie-text-align-button.right .tui-image-editor-button.right svg > use.active {
  display: block;
}

.tie-mask-image-file, .tie-icon-image-file {
  opacity: 0;
  cursor: inherit;
  border: 1px solid green;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.tui-image-editor-container.top.tui-image-editor-top-optimization .tui-image-editor-controls ul {
  text-align: center;
}

.tui-image-editor-container.top.tui-image-editor-top-optimization .tui-image-editor-controls-logo {
  display: none;
}

@font-face {
  font-family: Inter;
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url("Inter-Thin-BETA.5780f111.woff2") format("woff2"), url("Inter-Thin-BETA.5a711261.woff") format("woff");
}

@font-face {
  font-family: Inter;
  font-style: italic;
  font-weight: 100;
  font-display: swap;
  src: url("Inter-ThinItalic-BETA.742d53f9.woff2") format("woff2"), url("Inter-ThinItalic-BETA.49eb8a88.woff") format("woff");
}

@font-face {
  font-family: Inter;
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url("Inter-ExtraLight-BETA.27dd8979.woff2") format("woff2"), url("Inter-ExtraLight-BETA.dce1c9cb.woff") format("woff");
}

@font-face {
  font-family: Inter;
  font-style: italic;
  font-weight: 200;
  font-display: swap;
  src: url("Inter-ExtraLightItalic-BETA.c45c82b6.woff2") format("woff2"), url("Inter-ExtraLightItalic-BETA.09ffb1a7.woff") format("woff");
}

@font-face {
  font-family: Inter;
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url("Inter-Light-BETA.33e4cabc.woff2") format("woff2"), url("Inter-Light-BETA.57259d4b.woff") format("woff");
}

@font-face {
  font-family: Inter;
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: url("Inter-LightItalic-BETA.442b49dc.woff2") format("woff2"), url("Inter-LightItalic-BETA.8dc6a785.woff") format("woff");
}

@font-face {
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("Inter-Regular.125a1153.woff2") format("woff2"), url("Inter-Regular.f4299d2c.woff") format("woff");
}

@font-face {
  font-family: Inter;
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url("Inter-Italic.9921185e.woff2") format("woff2"), url("Inter-Italic.65f530cb.woff") format("woff");
}

@font-face {
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url("Inter-Medium.85aead12.woff2") format("woff2"), url("Inter-Medium.344dca2b.woff") format("woff");
}

@font-face {
  font-family: Inter;
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: url("Inter-MediumItalic.322a30b2.woff2") format("woff2"), url("Inter-MediumItalic.7892b4ef.woff") format("woff");
}

@font-face {
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url("Inter-SemiBold.b6aaa354.woff2") format("woff2"), url("Inter-SemiBold.9e36978c.woff") format("woff");
}

@font-face {
  font-family: Inter;
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: url("Inter-SemiBoldItalic.5883f0f5.woff2") format("woff2"), url("Inter-SemiBoldItalic.63b655fb.woff") format("woff");
}

@font-face {
  font-family: Inter;
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("Inter-Bold.b3145103.woff2") format("woff2"), url("Inter-Bold.819a396b.woff") format("woff");
}

@font-face {
  font-family: Inter;
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url("Inter-BoldItalic.1c75a253.woff2") format("woff2"), url("Inter-BoldItalic.aedd3b03.woff") format("woff");
}

@font-face {
  font-family: Inter;
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url("Inter-ExtraBold.4873268c.woff2") format("woff2"), url("Inter-ExtraBold.705a1363.woff") format("woff");
}

@font-face {
  font-family: Inter;
  font-style: italic;
  font-weight: 800;
  font-display: swap;
  src: url("Inter-ExtraBoldItalic.f4699ac3.woff2") format("woff2"), url("Inter-ExtraBoldItalic.02e01d32.woff") format("woff");
}

@font-face {
  font-family: Inter;
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url("Inter-Black.f5ba952b.woff2") format("woff2"), url("Inter-Black.78555cc4.woff") format("woff");
}

@font-face {
  font-family: Inter;
  font-style: italic;
  font-weight: 900;
  font-display: swap;
  src: url("Inter-BlackItalic.631e6ea9.woff2") format("woff2"), url("Inter-BlackItalic.aa7bc164.woff") format("woff");
}

@font-face {
  font-family: Inter var;
  font-weight: 100 900;
  font-display: swap;
  font-style: normal;
  font-named-instance: "Regular";
  src: url("Inter-upright.var.2a017436.woff2") format("woff2");
}

@font-face {
  font-family: Inter var;
  font-weight: 100 900;
  font-display: swap;
  font-style: italic;
  font-named-instance: "Italic";
  src: url("Inter-italic.var.5386d9b7.woff2") format("woff2");
}

@font-face {
  font-family: Inter var experimental;
  font-weight: 100 900;
  font-display: swap;
  font-style: oblique 0deg 10deg;
  src: url("Inter.var.07e356be.woff2") format("woff2");
}

@font-face {
  font-family: icomoon;
  src: url("icomoon.ee4cd21a.eot");
  src: url("icomoon.ee4cd21a.eot#iefix") format("embedded-opentype"), url("icomoon.498fc9e3.ttf") format("truetype"), url("icomoon.209b13c0.woff") format("woff"), url("icomoon.1b83b16f.svg#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  speak: none;
  font-variant: normal;
  text-transform: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  font-family: icomoon !important;
}

.icon-billing:before {
  content: "";
}

.icon-filter:before {
  content: "";
}

.icon-side-panel:before {
  content: "";
}

.icon-merge:before {
  content: "";
}

.icon-help-thin:before {
  content: "";
}

.icon-download:before {
  content: "";
}

.icon-help:before {
  content: "";
}

.icon-xlsx:before {
  content: "";
}

.icon-csv:before {
  content: "";
}

.icon-share:before {
  content: "";
}

.icon-analytics:before {
  content: "";
}

.icon-exit:before {
  content: "";
}

.icon-trash:before {
  content: "";
}

.icon-bulk:before {
  content: "";
}

.icon-workbook:before {
  content: "";
}

.icon-worksheet:before {
  content: "";
}

.icon-marker:before {
  content: "";
}

.icon-history:before {
  content: "";
}

.icon-checkmark:before {
  content: "";
}

.icon-tag:before {
  content: "";
}

.icon-tag-add:before {
  content: "";
}

.icon-tag-remove:before {
  content: "";
}

.icon-math:before {
  content: "";
}

.icon-find-replace:before {
  content: "";
}

.icon-triangle-circle:before {
  content: "";
}

.icon-lock:before {
  content: "";
}

.icon-sort:before {
  content: "";
}

.icon-left-column:before {
  content: "";
}

.icon-top-row:before {
  content: "";
}

.icon-shopify:before {
  content: "";
}

body {
  color: #3c4357;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizelegibility;
  overscroll-behavior-x: none;
  background: #fff;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: Inter, Helvetica, Arial, sans-serif;
  font-size: 14px;
  line-height: 19px;
  position: relative;
}

html {
  height: 100%;
}

a {
  color: #3c4357;
  cursor: pointer;
  text-decoration: none;
}

a:hover {
  color: #3c4357;
  text-decoration: none;
}

h1, h2, h3, h4 {
  color: #1a1f36;
}

button {
  font-family: Inter, Helvetica, Arial, sans-serif;
  font-weight: 500;
}

h3 {
  font-size: 16px;
  line-height: 18px;
}

input:focus, textarea:focus, button:focus, select:focus {
  outline: none;
}

.icon-shopify-context-menu:before {
  content: "";
  color: #3c4357;
  margin-left: 3px;
  font-family: icomoon;
  font-size: 17px;
}

.icon-category-context-menu:before {
  content: "";
  color: #3c4357;
  font-family: icomoon;
  font-size: 15px;
}

.icon-history-context-menu:before {
  content: "";
  color: #3c4357;
  font-family: icomoon;
  font-size: 20px;
}

/*# sourceMappingURL=index.02711d12.css.map */
