@font-face {
  font-family: 'icomoon';
  src:  url('icomoon.eot?uv6hsv');
  src:  url('icomoon.eot?uv6hsv#iefix') format('embedded-opentype'),
  url('icomoon.ttf?uv6hsv') format('truetype'),
  url('icomoon.woff?uv6hsv') format('woff'),
  url('icomoon.svg?uv6hsv#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}



/* ========================= */
/* OVERWRITE BELOW WITH ICON DEFINITIONS from style.css that we get from icomoon */
/* ========================= */

.icon-billing:before {
  content: "\e91d";
}
.icon-filter:before {
  content: "\e91b";
}
.icon-side-panel:before {
  content: "\e91c";
}
.icon-merge:before {
  content: "\e91a";
}
.icon-help-thin:before {
  content: "\e919";
}
.icon-download:before {
  content: "\e90d";
}
.icon-help:before {
  content: "\e90e";
}
.icon-xlsx:before {
  content: "\e90f";
}
.icon-csv:before {
  content: "\e910";
}
.icon-share:before {
  content: "\e911";
}
.icon-analytics:before {
  content: "\e912";
}
.icon-exit:before {
  content: "\e913";
}
.icon-trash:before {
  content: "\e914";
}
.icon-bulk:before {
  content: "\e915";
}
.icon-workbook:before {
  content: "\e916";
}
.icon-worksheet:before {
  content: "\e917";
}
.icon-marker:before {
  content: "\e918";
}
.icon-history:before {
  content: "\e90c";
}
.icon-checkmark:before {
  content: "\e90b";
}
.icon-tag:before {
  content: "\e90a";
}
.icon-tag-add:before {
  content: "\e909";
}
.icon-tag-remove:before {
  content: "\e908";
}
.icon-math:before {
  content: "\e907";
}
.icon-find-replace:before {
  content: "\e906";
}
.icon-triangle-circle:before {
  content: "\e905";
}
.icon-lock:before {
  content: "\e904";
}
.icon-sort:before {
  content: "\e903";
}
.icon-left-column:before {
  content: "\e901";
}
.icon-top-row:before {
  content: "\e902";
}
.icon-shopify:before {
  content: "\e900";
}
